import WorkspaceService from "@/services/modules/WorkspaceService";

export const workspace = {
  namespaced: true,
  state: {},
  actions: {
    invite({ commit }, payload = {}) {
      return WorkspaceService.invite(payload).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {},
  getters: {},
};
