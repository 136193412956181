<template>
  <div class="members-search">
    <div :class="['members-search-teams', { 'is-search': addArea }]">
      <div class="members-search-teams-button" @click="toggleAddArea">
        <button type="button">
          {{ $t("team.add.button_label") }}
        </button>
      </div>
      <div class="members-search-teams-input">
        <input
          type="text"
          ref="input"
          :placeholder="$t('team.add.placeholder')"
        />
        <button type="button" class="is-close" @click="toggleAddArea">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <button
          type="button"
          :class="['is-checked', { 'btn-loader': isLoader }]"
          @click="addTeam"
        >
          <svg
            :class="[{ 'd-none': isLoader }]"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 6L9 17L4 12"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { setModalStatus, getErrorMessage } from "@/utils/Helpers";
import store from "@/store";
import { useToast } from "bootstrap-vue-3";
import i18n from "@/utils/i18n.vue";
const toast = useToast();
const input = ref(null);
const addArea = ref(false);
const isLoader = ref(false);

const toggleAddArea = () => {
  addArea.value = !addArea.value;
};

const addTeam = () => {
  const value = input.value.value;

  if (value) {
    isLoader.value = true;
    store
      .dispatch("team/create", {
        name: value,
      })
      .then((response) => {
        const id = response.item.id;
        store.dispatch("team/items");
        store.dispatch("member/items").then(() => {
          input.value.value = "";
          addArea.value = false;
          setModalStatus("team_add_member", true, {
            team_id: id,
          });

          isLoader.value = false;
        });
      })
      .catch((error) => {
        const message = error.response.data.message;
        toast.show({
          title: i18n.global.t("misc.error_occurred"),
          body: getErrorMessage(error),
          autoHideDelay: 5000,
          dismissible: true,
        });

        isLoader.value = false;
      });
  }
};
</script>
