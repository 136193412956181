<template>
  <div class="network-pool-body-content is-empty">
    <div class="network-pool-body-content-item no-connection">
      <div class="network-pool-body-content-item-block">
        <div class="network-pool-body-content-item-block-image">
          <img
            src="/img/static/members-body-content-item-block-image.png"
            alt=""
          />
        </div>
        <div class="network-pool-body-content-item-block-description">
          <p>
            {{ $t("network.empty.text_1") }}
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="11.5" cy="11.5" r="11.5" fill="white" />
              <rect x="10.5" y="5.5" width="1.5" height="12.5" fill="#167FFC" />
              <rect
                x="5"
                y="12.5"
                width="1.5"
                height="12.5"
                transform="rotate(-90 5 12.5)"
                fill="#167FFC"
              />
            </svg>
            {{ $t("network.empty.text_2") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
