<template>
  <div class="invite-team-member-button is-show">
    <button type="button" @click="openModal">
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="11" width="3" height="25" fill="#167FFC" />
        <rect
          y="14"
          width="3"
          height="25"
          transform="rotate(-90 0 14)"
          fill="#167FFC"
        />
      </svg>
    </button>
  </div>
</template>

<script setup>
import { setModalStatus } from "@/utils/Helpers";

const openModal = () => {
  setModalStatus("member_invite", true);
};
</script>
