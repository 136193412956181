import MemberService from "@/services/modules/MemberService";

export const member = {
  namespaced: true,
  state: {
    items: [],
    item: {},
  },
  actions: {
    items({ commit }) {
      return MemberService.list().then(
        (data) => {
          commit("items", data.items);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    item({ commit }, id) {
      return MemberService.get(id).then(
        (data) => {
          commit("item", data.item);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    assignRole({ commit }, payload) {
      return MemberService.assignRole(payload).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    changeMembership({ commit }, payload) {
      return MemberService.changeMembership(payload).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    reset({ commit }, payload) {
      return MemberService.reset(payload).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    items(state, items) {
      state.items = items;
    },
    item(state, item) {
      state.item = item;
    },
    setItems(state, items) {
      state.items = items;
    },
  },
  getters: {
    items: (state) => {
      return state.items;
    },
    item: (state) => {
      return state.item;
    },
  },
};
