import AccountService from "@/services/modules/AccountService";
import store from "@/store";
import router from "@/router";

export const account = {
  namespaced: true,
  state: {
    me: {},
    emails: [],
    is_loading: true,
  },
  actions: {
    me({ commit }) {
      const status = store.getters["auth/status"];

      if (!status.login) {
        return;
      }

      return AccountService.me().then(
        (data) => {
          commit("me", data);
          return Promise.resolve(data);
        },
        (error) => {
          router.push({
            name: "login",
          });

          return Promise.reject(error);
        }
      );
    },
    confirmPassword({ commit }, payload) {
      return AccountService.confirmPassword(payload).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    update({ commit }, payload) {
      return AccountService.update(payload).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    changePassword({ commit }, payload) {
      return AccountService.changePassword(payload).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updatePreferences({ commit }, payload) {
      return AccountService.updatePreferences(payload).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },

    getEmails({ commit }) {
      return AccountService.getEmails().then(
        (response) => {
          commit("emails", response.data);
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    addEmail({ commit }, payload) {
      return AccountService.addEmail(payload).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    setAccount({ commit }, payload) {
      return AccountService.setAccount(payload).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    sendRequest({ commit }, payload) {
      return AccountService.sendRequest(payload).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    disableRequest({ commit }, payload) {
      return AccountService.disableRequest(payload).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteRequest({ commit }, payload) {
      return AccountService.deleteRequest(payload).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    membershipCancelRequest({ commit }, payload) {
      return AccountService.membershipCancelRequest(payload).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    status({ commit }) {
      const status = store.getters["auth/status"];

      if (!status.login) {
        return;
      }

      return AccountService.status().then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    createOrder({ commit }, payload) {
      return AccountService.createOrder(payload).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    me(state, data) {
      localStorage.setItem("preferences", JSON.stringify(data.preferences));

      state.me = data;
    },
    emails(state, data) {
      state.emails = data;
    },
    is_loading(state, data) {
      state.is_loading = data;
    },
  },
  getters: {
    me: (state) => {
      return state.me;
    },
    membership: (state) => {
      return state.me.membership_level;
    },
    can: (state) => {
      return state.me.can;
    },
    workspace: (state) => {
      return state.me.current_workspace;
    },
    is_can: (state) => (can) => {
      return state.me.can.includes(can);
    },
    emails: (state) => {
      return state.emails;
    },
    is_loading: (state) => {
      return state.is_loading;
    },
  },
};
