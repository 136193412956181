<template>
  <main-layout :head="false" class="main-help-center">
    <div class="help-center">
      <div class="help-center-head">
        <div class="help-center-head-title">
          <h1>{{ $t("support.title") }}</h1>
        </div>
      </div>
      <div class="help-center-block">
        <div class="help-center-block-title">
          <h2>
            {{ $t("support.form_title") }}
          </h2>
        </div>
        <Form :validation-schema="schema" @submit="submit" v-slot="{ errors }">
          <label>
            {{ $t("support.form.email") }}
          </label>

          <Field
            name="email"
            :errors="errors"
            :placeholder="$t('support.form.email')"
          />

          <label>
            {{ $t("support.form.message") }}
          </label>

          <VField v-slot="{ field }" name="message">
            <div class="form-item">
              <textarea
                :placeholder="$t('support.form.message')"
                v-bind="field"
              />
            </div>
          </VField>

          <div class="form-item-footer">
            <div class="form-item-buttons">
              <button
                type="reset"
                class="btn btn-sm btn-white btn-round-4 btn-400"
              >
                {{ $t("misc.cancel") }}
              </button>
              <Button
                type="submit"
                class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
                :state="state"
              >
                {{ $t("misc.submit") }}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </main-layout>
</template>

<script setup>
import { ref } from "vue";
import MainLayout from "@/layouts/MainLayout.vue";
import store from "@/store";
import { Form, Field as VField } from "vee-validate";
import i18n from "@/utils/i18n.vue";
import Field from "@/components/inputs/Field.vue";
import Button from "@/components/inputs/Button.vue";
import * as yup from "yup";

const state = ref("default");
const schema = yup.object({
  email: yup
    .string()
    .email()
    .required()
    .label(i18n.global.t("support.form.email")),
  message: yup.string().required(),
});

const submit = (values) => {
  state.value = "loading";
  store.dispatch("misc/support", values).then(() => {
    state.value = "success";
  });
};
</script>
