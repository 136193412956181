<template>
  <div class="business-card-view-content-download is-show">
    <div class="business-card-view-content-download-block">
      <div class="business-card-view-content-download-block-content">
        <p>
          {{
            $t("public_profile.download_modal.info", {
              name: data.display_name,
            })
          }}
        </p>
        <ul v-if="osName !== 'ios'">
          <li>
            {{ $t("public_profile.download_modal.list_item_1") }}
          </li>
          <li>
            {{ $t("public_profile.download_modal.list_item_2") }}
          </li>
          <li>
            {{ $t("public_profile.download_modal.list_item_3") }}
          </li>
        </ul>
        <ul v-else>
          <li>
            {{ $t("public_profile.download_modal.ios.list_item_1") }}
          </li>
          <li>
            {{ $t("public_profile.download_modal.ios.list_item_2") }}
          </li>
        </ul>
      </div>
      <div class="business-card-view-content-download-block-button">
        <a
          class="btn btn-md btn-primary-blue btn-round-8 btn-500"
          href="javascript:;"
          @click="download"
        >
          {{ $t("public_profile.download_card") }}
        </a>
      </div>
      <div class="business-card-view-content-download-block-close">
        <button
          type="button"
          @click="setModalStatus('public_profile_download', false)"
        >
          <img src="/img/static/icon-x.svg" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import DeviceDetector from "device-detector-js";
import {
  getModalStatus,
  setModalStatus,
  getModalData,
  createAndDownloadFile,
  getBase64FromUrl,
} from "@/utils/Helpers";
import useProfile from "@/composables/profile";
import vCardsJS from "vcards-js";

const deviceDetector = new DeviceDetector();
const device = deviceDetector.parse(navigator.userAgent);
const osName = device.os.name.toLowerCase();
const {
  getValue,
  displayName,
  website,
  phones,
  address,
  addressData,
  socialMedias,
  profilePhoto,
  companyLogo,
  createVCard,
} = useProfile({
  getterName: "misc/profile",
  actionName: "misc/getProfile",
});

const data = getModalData("public_profile_download");

const download = () => {
  createVCard();
  setModalStatus("public_profile_download", false);
  setModalStatus("public_profile_share", true, data);
};
</script>
