<template>
  <div class="members-body-content-item-block-head-settings-item">
    <button
      type="button"
      class="subscription-management-button"
      @click="toggleArea"
      :content="$t('team.membership.label')"
      v-tippy
      v-click-away="onClickAway"
    >
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 6L17.1667 12.5L23 8.16667L20.6667 19H4.33333L2 8.16667L7.83333 12.5L12.5 6Z"
          stroke="#E3E3E3"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </svg>
    </button>
    <div
      :class="[
        'members-body-content-item-block-head-settings-item-menu role-assignment state-second',
        { 'is-show': isShow },
      ]"
    >
      <div
        class="members-body-content-item-block-head-settings-item-menu-block"
      >
        <div
          class="members-body-content-item-block-head-settings-item-menu-block-label"
        >
          {{ $t("team.membership.label") }}
        </div>
        <div
          class="members-body-content-item-block-head-settings-item-menu-block-list"
        >
          <div
            class="members-body-content-item-block-head-settings-item-menu-block-list-item"
          >
            <div class="form-item-radio">
              <input
                id="membership-free"
                name="membership"
                type="radio"
                value="free"
                v-model="selectedMembership"
              />
              <label for="membership-free">{{ $t("team.membership.free") }}</label>
            </div>
          </div>
          <div
            class="members-body-content-item-block-head-settings-item-menu-block-list-item"
          >
            <div class="form-item-radio">
              <input
                id="membership-corporate"
                name="membership"
                type="radio"
                value="corporate"
                v-model="selectedMembership"
              />
              <label for="membership-corporate">{{
                $t("team.membership.corporate")
              }}</label>
            </div>
          </div>
        </div>
        <div
          class="members-body-content-item-block-head-settings-item-menu-block-button"
        >
          <Button
            type="button"
            class="btn btn-sm btn-primary-blue btn-round-4 btn-400"
            @click="save"
            :state="state"
          >
            {{ $t("team.confirm") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, defineProps, watch } from "vue";
import store from "@/store";
import Button from "@/components/inputs/Button.vue";
import { useRoute } from "vue-router";
import { setModalStatus } from "@/utils/Helpers";

const route = useRoute();
const props = defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
});

const selectedMembers = computed(() => store.getters["team/selectedMembers"]);
const isShow = ref(false);
const state = ref("default");
const selectedMembership = ref("");

const toggleArea = () => {
  isShow.value = !isShow.value;
};

const save = () => {
  const members = selectedMembers.value.map((m) => m.id);
  const dispatchPath =
    route.name === "team-view" ? "team/item" : "member/items";
  const routeParamsId = route.params.id;

  if (!selectedMembers.value || !selectedMembership.value) return;

  if (selectedMembership.value === "free") {
    setModalStatus("membership_cancel", true, {
      selectedMembership: selectedMembership.value,
    });
  } else {
    setModalStatus("membership_upgrade", true, {
      selectedMembership: selectedMembership.value,
    });
  }

  toggleArea();
};

const onClickAway = (e) => {
  if (e.target.closest(".role-assignment")) return;
  isShow.value = false;
};

watch(
  () => props.isActive,
  (value) => {
    if (!value) {
      isShow.value = false;
    }
  }
);
</script>
