<template>
  <div class="edit-group-name is-show">
    <div class="edit-group-name-block">
      <div class="edit-group-name-block-label" v-if="data?.id">
        <h4>
          {{ $t("network.groups.edit.title") }}
        </h4>
      </div>
      <div class="edit-group-name-block-label" v-else>
        <h4>
          {{ $t("network.groups.create.title") }}
        </h4>
      </div>
      <div class="edit-group-name-block-input">
        <div class="form-item h-52">
          <input
            :value="data.name"
            type="text"
            ref="input"
            :placeholder="$t('network.groups.create.placeholder')"
          />
          <button type="button" @click="setModalStatus('group_action', false)">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_14382_29651)">
                <path
                  d="M7.99968 14.6654C11.6816 14.6654 14.6663 11.6806 14.6663 7.9987C14.6663 4.3168 11.6816 1.33203 7.99968 1.33203C4.31778 1.33203 1.33301 4.3168 1.33301 7.9987C1.33301 11.6806 4.31778 14.6654 7.99968 14.6654Z"
                  stroke="#979797"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 11.332H8.00667"
                  stroke="#979797"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 4.33203C8 5.66536 8 8.9987 8 8.9987"
                  stroke="#979797"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_14382_29651">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>
      <div class="edit-group-name-block-button">
        <Button
          type="button"
          class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
          :state="state"
          @click="save"
        >
          {{
            data?.id
              ? $t("network.groups.edit.button_label")
              : $t("network.groups.create.button_label")
          }}
        </Button>
      </div>
      <div
        class="edit-group-name-block-close"
        @click="setModalStatus('group_action', false)"
      >
        <button type="button">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { getModalData, setModalStatus, getErrorMessage } from "@/utils/Helpers";
import store from "@/store";
import Button from "@/components/inputs/Button.vue";
import { useToast } from "bootstrap-vue-3";
import i18n from "@/utils/i18n.vue";
const toast = useToast();

const state = ref("default");
const input = ref(null);
const data = getModalData("group_action");
const save = () => {
  const name = input.value.value;

  if (!name) return;

  state.value = "loading";

  data.name = name;

  if (data?.id) {
    store
      .dispatch("network/updateGroup", {
        id: data.id,
        name,
      })
      .then(() => {
        state.value = "success";
        store.dispatch("network/groups");
      })
      .catch((e) => {
        toast.show({
          title: i18n.global.t("misc.error_occurred"),
          body: getErrorMessage(e),
          autoHideDelay: 5000,
          dismissible: true,
        });
        state.value = "default";
      });

    return;
  }

  store
    .dispatch("network/createGroup", {
      name,
    })
    .then((response) => {
      const id = response.item.id;

      store.dispatch("network/groups").then(() => {
        state.value = "success";
        setTimeout(() => {
          setModalStatus("group_action", false);
          setModalStatus("group_networks", true, {
            group_id: id,
          });
        }, 500);
      });
    })
    .catch((e) => {
      toast.show({
        title: i18n.global.t("misc.error_occurred"),
        body: getErrorMessage(e),
        autoHideDelay: 5000,
        dismissible: true,
      });
      state.value = "default";
    });
};
</script>
