<template>
  <div :class="['is-detail-reminders-body-add']">
    <div class="is-detail-reminders-body-add-future">
      <swiper
        class="is-detail-reminders-body-add-future-block"
        :space-between="0"
        :pagination="{
          clickable: true,
          el: '.swiper-pagination',
        }"
      >
        <reminder-item
          v-for="item in upcomingItems"
          :key="item.id"
          :item="item"
        />
      </swiper>
    </div>
    <div class="is-detail-reminders-body-add-future is-past" v-if="pastItems">
      <div class="is-detail-reminders-body-add-future-label">
        <div class="is-detail-reminders-body-add-future-label-content">
          <span>{{ $t("network.reminders.past") }}</span>
        </div>
        <div
          :class="[
            'is-detail-reminders-body-add-future-label-button',
            {
              'is-active': isPastEventsOpen,
            },
          ]"
        >
          <button type="button" @click="togglePastEvents">
            <i>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.12065 15.2861L12.0006 11.4061L15.8806 15.2861C15.9732 15.3787 16.0831 15.4521 16.2041 15.5022C16.3251 15.5523 16.4547 15.5781 16.5856 15.5781C16.7166 15.5781 16.8462 15.5523 16.9672 15.5022C17.0882 15.4521 17.1981 15.3787 17.2906 15.2861C17.3832 15.1935 17.4567 15.0836 17.5068 14.9626C17.5569 14.8417 17.5827 14.712 17.5827 14.5811C17.5827 14.4502 17.5569 14.3205 17.5068 14.1996C17.4567 14.0786 17.3832 13.9687 17.2906 13.8761L12.7006 9.2861C12.6081 9.1934 12.4982 9.11985 12.3773 9.06967C12.2563 9.01949 12.1266 8.99366 11.9956 8.99366C11.8647 8.99366 11.735 9.01949 11.614 9.06967C11.493 9.11985 11.3832 9.1934 11.2906 9.2861L6.70065 13.8761C6.60794 13.9686 6.5344 14.0785 6.48421 14.1995C6.43403 14.3205 6.4082 14.4501 6.4082 14.5811C6.4082 14.7121 6.43403 14.8418 6.48421 14.9627C6.5344 15.0837 6.60794 15.1936 6.70065 15.2861C7.09065 15.6661 7.73065 15.6761 8.12065 15.2861Z"
                  fill="#707070"
                />
              </svg>
            </i>
          </button>
        </div>
      </div>
      <div
        class="is-detail-reminders-body-add-future-block is-detail-reminders-body-add-future-block-past"
        style="margin-top: 16px"
      >
        <reminder-item v-for="item in pastItems" :key="item.id" :item="item" />
      </div>
    </div>
    <DeleteReminder
      v-if="getModalStatus('reminder_delete')"
      :item="getModalData('reminder_delete')"
      @success="deletedReminder"
    />
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from "vue";
import ReminderItem from "./Item.vue";
import DeleteReminder from "../../Modals/DeleteReminder.vue";
import {
  getModalStatus,
  setModalStatus,
  getModalData,
  slideToggle,
  getLetters,
} from "@/utils/Helpers";
import store from "@/store";

const isPastEventsOpen = ref(false);
const props = defineProps({
  upcomingItems: {
    type: Array,
    required: true,
  },
  pastItems: {
    type: Array,
    required: true,
  },
  networkId: {
    type: Number,
    required: true,
  },
});

const togglePastEvents = () => {
  slideToggle(
    document.querySelector(".is-detail-reminders-body-add-future-block-past")
  );
  isPastEventsOpen.value = !isPastEventsOpen.value;
};

const deletedReminder = () => {
  store.dispatch("network/item", props.networkId).then(() => {
    setTimeout(() => {
      setModalStatus("reminder_delete", false);
    }, 1000);
  });
};
</script>
