<script>
import {createI18n} from "vue-i18n";

const preferences = JSON.parse(localStorage.getItem("preferences"));
const i18n = createI18n({
  locale: preferences?.language
      ? preferences.language.split("_")[0]
      : navigator.language.split("-")[0],
  fallbackLocale: "",
  allowComposition: true,
  messages: {
    tr: {
      validation: {
        required: "{label} zorunludur.",
        email: "{label} geçerli bir e-posta adresi olmalıdır.",
        min: "{label} en az {min} karakter olmalıdır.",
        max: "{label} en fazla {max} karakter olmalıdır.",
        unmatch_password: "Parolalar eşleşmiyor.",
        phone: "Girilen telefon numarası geçersiz.",
        not_type: "{label} geçersiz.",
        number: "{label} sayı olmalıdır.",
      },
      defines: {
        genders: {
          male: "Erkek",
          female: "Kadın",
        },
        phone_types: {
          mobile: "Mobil",
          home: "Ev",
          work: "İş",
          fax: "Faks",
          other: "Diğer",
        },
        more_types: {
          website: {
            label: "Website",
          },
          linkedin: {
            label: "Linkedin",
          },
          instagram: {
            label: "Instagram",
          },
          facebook: {
            label: "Facebook",
          },
          twitter: {
            label: "Twitter",
          },
          youtube: {
            label: "YouTube",
          },
          dribbble: {
            label: "Dribbble",
          },
          tiktok: {
            label: "Tiktok",
          },
          behance: {
            label: "Behance",
          },
          github: {
            label: "Github",
          },
          telegram: {
            label: "Telegram",
          },
          file: {
            label: "Dosya",
            placeholder:
                "Ağlarınızla paylaşmak istediğiniz belgenizi, özgeçmişinizi veya portföyünüzü ekleyebilirsiniz.",
          },
          date: {
            label: "Tarih",
          },
        },
      },
      misc: {
        individual_label: "Bireysel",
        corporate_label: "Kurumsal",
        no_results: "Sonuç bulunamadı.",
        error_occurred: "Bir hata oluştu.",
        id: "ID No",
        first_name: "Adınız",
        last_name: "Soyadınız",
        full_name: "Ad Soyad",
        identify_number: "T.C. Kimlik No",
        title: "Unvan",
        company: "Şirket Adı",
        tax_number: "Vergi No",
        tax_office: "Vergi Dairesi",
        message: "Mesaj",
        email: "E-posta",
        phone: "Telefon",
        address: "Adres",
        website: "Website",
        country: "Ülke",
        state: "Şehir",
        city: "İlçe",
        neighborhood: "Mahalle",
        street: "Sokak / Cadde",
        error_title: "Hay Aksi!",
        back: "Geriye Dön",
        cancel: "Vazgeç",
        submit: "Gönder",
        confirm: "Onayla",
        save: "Kaydet",
        optional: "İsteğe Bağlı",
        share: "Paylaş",
        edit: "Düzenle",
        delete: "Sil",
        saved: "Değişiklikler Kaydedildi",
        social_media: "Sosyal Medya",
        download_card: "Kartviziti İndir",
        visibility: "Görünürlük",
        get_started: "Hemen Başla",
        skip: "Atla",
        next: "İleri",
        prev: "Geri",
        done: "Tamam",
        premium_button_label: "Networkit Satın Al",
        remove_photo: "Fotoğrafı Kaldır",
        add_photo: "Fotoğraf Ekle",
        upload: "Yükle",
        logout: "Çıkış Yap",
        corporate: {
          email: "Şirket E-postası",
          phone: "Şirket Telefonu",
        },
        days: {
          monday: "Pazartesi",
          tuesday: "Salı",
          wednesday: "Çarşamba",
          thursday: "Perşembe",
          friday: "Cuma",
          saturday: "Cumartesi",
          sunday: "Pazar",
        },
        months: {
          january: "Ocak",
          february: "Şubat",
          march: "Mart",
          april: "Nisan",
          may: "Mayıs",
          june: "Haziran",
          july: "Temmuz",
          august: "Ağustos",
          september: "Eylül",
          october: "Ekim",
          november: "Kasım",
          december: "Aralık",
        },
        icons: "İkonlar",
      },
      auth: {
        common: {
          email: "E-posta",
          password: "Parola",
          password_confirmation: "Parola Tekrar",
          or: "ya da",
          google: "Google ile Giriş Yap",
          microsoft: "Microsoft ile Giriş Yap",
          create_account: "Yeni Hesap Oluştur",
          corporate: {
            email: "Şirket E-postası",
          },
        },
        login: {
          title: "Giriş Yap",
          button_label: "Giriş Yap",
          email: "E-posta veya ID No",
          forgot: "Şifreni mi unuttun?",
          not_registered: "Kayıtlı değil misin?",
          register: "Kaydol",
          corporate: {
            email: "Şirket E-postası veya ID no",
          },
        },
        register: {
          title: "Kaydol",
          button_label: "Kaydol",
          corporate: {
            title: "İletişime Geç",
            description:
                "Kurumsal hesap oluşturmak için formu doldurabilirsiniz. En kısa zamanda sizinle iletişime geçeceğiz",
            employee_count: "Çalışan Sayısı",
            button_label: "Talep oluştur",
            confirm: {
              text: "{email} mail adresine ait gelen kutusu ve spam kutularını kontrol ediniz.",
            },
          },
          already: "Zaten üye misin?",
          terms_text:
              "{text_1} {text_2}, {text_3}, {text_4} ve {text_5} kabul etmiş olursunuz.",
          terms_text_1: "Kaydol butonuna tıklayarak, Janus’ın",
          terms_text_2: "Üyelik Sözleşmesi",
          terms_text_3: "Gizlilik Politikasını ve Çerez Politikasını",
          terms_text_4: "Açık Rıza Metnini",
          terms_text_5: "KVKK Metnini",
          invite: {
            invalid: {
              title: "Geçersiz Davet Kodu",
              content: "Davet kodu geçersiz.",
            },
            expired: {
              title: "Geçersiz Davet Kodu",
              content: "Kullanmak istediğiniz davet kodunun süresi dolmuş.",
            },
            confirmed: {
              title: "Geçersiz Davet Kodu",
              content: "Kullanmak istediğiniz davet kodu zaten kullanılmış.",
            },
          },
        },
        forgot: {
          page_title: "Şifremi Unuttum",
          title: "Giriş yaparken sorun mu yaşıyorsun?",
          text: "Sisteme kaydolduğun e-posta adresini gir, biz de sana şifreni yenilemek için bir bağlantı gönderelim.",
          button_label: "Giriş Bağlantısı Gönder",
          sent: "Link gönderildi.",
          sent_text: "{email} adresine şifre yenileme linkin gönderildi.",
          resend: "Tekrar Gönder",
          not_found: "E-posta adresi bulunamadı.",
          corporate: {
            text: "Sisteme kaydolduğun şirket e-posta adresini gir, biz de sana şifreni yenilemek için bir bağlantı gönderelim.",
            not_found: "Şirket E-posta adresini bulamadık.",
          },
        },
        reset_password: {
          title: "Şifreni oluştur",
          button_label: "Şifreni oluştur",
          success_text:
              "Şifren başarıyla oluşturuldu. Yeni şifren ile giriş yapabilirsin.",
        },
        logout: {
          title: "Çıkış Yap",
          text: "Çıkış yapmak istediğinden emin misin?",
        },
        set_email: {
          page_title: "E-Posta adresi tanımlama",
          title: "E-Posta adresi tanımlama",
          text_1:
              "Hesabına erişmek için son bir adımın kaldı! Hesabınla ilgili tüm aktiviteleri gerçekleştirmen için bir e-posta adresi tanımlaman gerekiyor.",
          text_2:
              "Tanımlayacağın e-posta adresi ile hesabının şifresini değiştirmek, giriş yapmak gibi birçok fonksiyonu gerçekleştirmede kullanabileceksin.",
          already: "Bu e-posta adresi zaten kullanılıyor.",
          button_label: "E-posta adresini tanımla",
        },
      },
      public_profile: {
        basic_info: "Temel Bilgiler",
        additional_info: "Ek Bilgiler",
        corporate_info: "Şirket Bilgileri",
        download_card: "Kartviziti İndir",
        share_info: "Bilgilerini İlet",
        create_profile: "Janus Kart satın almak için tıkla",
        download_modal: {
          info: "{name} adlı kullanıcının bilgilerini hızlıca kaydedebilmek için:",
          list_item_1: "Aşağıdaki 'Kartviziti İndir' butonuna tıkla.",
          list_item_2: "Açılan ekranda dosya indirmeye izin ver.",
          list_item_3:
              "İndirilen dosyayı açıp telefonunun rehber uygulamasına kaydet.",
          ios: {
            list_item_1: "Aşağıdaki 'Kartviziti İndir' butonuna tıkla.",
            list_item_2:
                "Açılan rehber ekranında aşağı kaydır ve 'Yeni Kişi Yarat' butonuna tıklayarak rehbere kaydet.",
          },
        },
        share_modal: {
          title: "Bilgilerini İlet",
          info: "Networking karşılıklı yapılan bir etkinliktir. Sağlıklı bir networking yapabilmek için bilgilerini {name} ile paylaşmalısın.",
          card_downloaded: "Kartvizit indirildi!",
          add_more: "Daha fazla ekle",
          terms_text:
              "{text_1} {text_2}, {text_3}, {text_4} ve {text_5} kabul etmiş olursunuz.",
          terms_text_1: "Paylaş butonuna tıklayarak, Janus’ın",
          terms_text_2: "Üyelik Sözleşmesi",
          terms_text_3: "Gizlilik Politikasını ve Çerez Politikasını",
          terms_text_4: "Açık Rıza Metnini",
          terms_text_5: "KVKK Metnini",
        },
        register_modal: {
          info_1:
              "{name} adlı kullanıcının kartvizitini indirerek sadece bugünkü bilgilere sahip oldun.",
          info_2:
              "Kullanıcıların güncel bilgilerine sahip olmak için hadi sen de Janus’ta dijital kartvizitini oluştur!",
          register: "Janus’a Kaydol",
        },
      },
      profile: {
        create_page_title: "Profil Oluşturma",
        edit_page_title: "Profili Düzenle",
        view_page_title: "Dijital Kartvizit",
        view_page_title_corporate: "Kurumsal Kartvizit Profili",
        add_profile_photo: "Profil Fotoğrafı Ekle",
        add_company_logo: "Şirket Logosu Ekle",
        add: "Profil Ekle",
        addMore: "Daha fazla ekle",
        background_color: "Arkaplan Rengi",
        background_color_description:
            "Dijital kartvizitini paylaşacağın kişiler tarafından görünecek",
        corporate_profile_alert:
            "Kurumsal kartvizit profilinizde yapacağınız düzenlemeler, şirketinize ait tüm kartvizitlerin bilgilerini etkileyecektir.",
        corporate_main: "Şirket Profili",
        placeholders: {
          name: "Bireysel",
          first_name: "Sierra",
          last_name: "Garcia",
          title: "CEO",
          company: "Janus",
          email: "mail{'@'}usejanus.com",
          phone: "+905070000000",
        },
        name: "Kartvizit İsmi",
        company_name: "Şirket Adı",
        title: "Unvan",
        description: "Hakkında",
        description_placeholder:
            "Bu alanda şirketinizi tanıtan veya fatura bilgileri gibi paydaşlarınızla sürekli paylaşmanız gereken bilgilerinizi ekleyebilirsiniz.",
        link_text: "Link metni (linkine isim ekleyebilirsin)",
        visibility: {
          title: "Kartvizit Görünürlüğü",
          text: "Kartvizit bilgilerinizi yönetin; arama motorları üzerinden gelen ve oturum açmayan kişilerin görmesine izin vereceğiniz bilgileri belirleyin.",
        },
        address: {
          description:
              "Harita uygulamalarının adresinizi daha doğru bir şekilde bulması için posta kodunuzu da eklemeniz önemlidir.",
        },
        limit_premium_text:
            "Ekstra profil eklemek için “NetworKit Paketi” satın almalısın.",
        phone_limit_premium_text:
            "Ekstra telefon eklemek için “NetworKit Paketi” satın almalısın.",
        more_item_premium_text:
            "<span>Dosya ve Tarih</span> özelliklerinden yararlanabilmek için “NetworKit Paketi” satın almalısın.",
        share: {
          email: "E-posta",
          qr: "QR Kod",
          qr_text: "{qr_text_plain}, {qr_text_download} veya {qr_text_link}",
          qr_text_plain: "QR kodunu tarat",
          qr_text_link: "Profilin Linkini Paylaş",
          qr_text_download: "İndir.",
          copied: "Link Kopyalandı!",
          limit_text: "*Tek seferde en fazla 10 kişiyle paylaşabilirsin",
          info: "Profilini kimlerle paylaşmak istersin?",
          success_text:
              "Profilin paylaşmak istediklerine e-posta yoluyla iletildi!",
        },
        preview: {
          text_1:
              "Profil bilgilerini her değiştirdiğinde e-posta imzan ve sanal arka planın güncellenir.",
          text_2:
              "Hadi sana özel hazırlanan güncel e-posta imzanı e-posta adresine ekle ve ağını genişlet! Böylece bağlantıların güncel dijital kartvizitini görüntüleyebilir ve kendi bilgilerini de seninle paylaşabilir.",
          button_label: "Anladım",
        },
        trash: {
          text: "Dijital Kartvizitin ücretsiz pakette oluşturabileceğin tek kartvizittir. Kartvizitini silersen profil oluşturma adımına baştan başlayacaksın.",
          confirm_text: "Kartvizitini silmek istediğine emin misin?",
        },
        cancel: {
          text: "Profil bilgilerini kaydetmeden çıkmak istediğine emin misin?",
          back: "Geri Dön",
          confirm: "Evet, eminim",
        },
      },
      email_signature: {
        page_title: "E-posta İmzası",
        head_title: "E-posta İmzası",
        premium_text:
            "E-posta imzanı oluşturmak için “NetworKit Paketi” satın almalısın.",
        page_description:
            "Sana özel hazırlanan mail imzanı oluşturarak networkünü güçlendir!",
        add: "E-posta İmzası Ekle",
        copy: "İmzanı Kopyala",
        description:
            "İşlemlerini tamamlayabilmen için önce imzanı kopyalamalısın.",
        notice: "İmzanı kopyaladığına emin misin?",
        photo_logo: "Fotoğraf + Logo",
        logo: "Logo",
        text: "Metin",
        creating_text: "İmzanız oluşturuluyor...",
        created_text: "İmzanız oluşturuldu!",
        tabs: {
          title: "E-posta İmzası Detayları",
          tab_1_title: "Mail imzası arka planı ve biçimi",
          tab_2_title: "Banner",
          tab_3_title: "Metin Alanı",
        },
        noAccess: {
          text_1: "E-posta imzası detaylarını sadece admin düzenleyebilir.",
          text_2:
              "Sol bölümde admin tarafından hazırlanan imzanı e-posta hesabına ekleyebilirsin!",
        },
        visibility: {
          title: "Mail İmzası Görünürlüğü",
          text: "Mail imzanızı yönetin; mail imzanızı görüntüleyen kişilerin görmesine izin vereceğiniz bilgileri belirleyin.",
          banner: "Banner",
          disclaimer: "Metin Alanı",
        },
        add_image: "Görsel Ekle",
        format: "Format",
        add_image_description:
            "Çerçeve içinde kalan alan ({size}) yüklenecektir. Kartvizitini dikey ya da yatay formatta ve görünmesini istediğin alanları kırpma maskesi ile ayarlayabilirsin.",
        hubspot: {
          title: "Hubspot’a E-posta İmzası Ekle",
          button_label: "HubSpot’a Git",
          description:
            "Kişiselleştirilmiş bir imza eklemek için hesabınızda HubSpot CRM'nin kurulu olması ve satış erişimine sahip olmanız gerekir.",
          step_1_text: "İmzanızın HTML' ni kopyalayın:",
          step_1_button: "İmzanı Kopyala",
          step_2_text: "HubSpot hesabınıza giriş yapın",
          step_3_text: "HubSpot hesabınızda, ana gezinme çubuğundaki ayarlar simgesine tıklayın.",
          step_4_text: "Sol kenar çubuğu menüsünde <strong>Genel</strong> seçeneğine gidin ardından <strong>E-posta</strong> sekmesine tıklayın",
          step_5_text: "Yapılandırma bölümündeki, <strong>İmzayı düzenle</strong> seçeneğini seçin",
          step_6_text: "Sağ üstteki HTML seçeneğini seçin ve kodu metin kutusuna yapıştırın.",
          step_7_text: "İmzanızı kaydedin ve e-posta yazma ekranında imzanızı seçin.",
        },
        gmail: {
          title: "Gmail’e E-posta İmzası Ekle",
          button_label: "Gmail’e Git",
          description:
            "Kişiselleştirilmiş bir imza eklemek için, hesabınızda Gmail'in yüklü olması gerekir.",
          step_1_text: "İmzanızın HTML' ni kopyalayın:",
          step_1_button: "İmzanı Kopyala",
          step_2_text: "Gmail hesabınıza giriş yapın",
          step_3_text: "Ayarlar gidin ve kendi mail adresinize tıklayın",
          step_4_text: "İmza ayarları bölümüne gidin Mobil İmza seçeneğini aktifleştirin.",
          step_5_text: "Kopyaladığınız imzayı metin kutusuna yapıştırın",
        },
        outlook: {
          title: "Outlook’a E-posta İmzası Ekle",
          button_label: "Outlook’a Git",
          description:
            "Kişiselleştirilmiş bir imza eklemek için, hesabınızda Outlook yüklü olması gerekir.",
          step_1_text: "İmzanızın HTML' ni kopyalayın:",
          step_1_button: "İmzanı Kopyala",
          step_2_text: "Outlook hesabınıza giriş yapın",
          step_3_text: "Ayarlar ikonuna tıklayın ve E-posta imzası bölümüne gidin",
          step_4_text: "İmza ekle adlı check box’ı işaretleyin",
          step_5_text: "Web üzerindeki Outlook’taki imzamı kullan ya da farklı bir imza kullan seçeneğini seçin",
          step_6_text: "Açılan metin kutusuna kopyaladığınız kodu yapıştırın",
          step_7_text: "Kaydetmek için sağ üstteki onay ikonuna tıklayın",
        },
        apple: {
          title: "Apple Mail’e E-posta İmzası Ekle",
          button_label: "Apple Mail’e Git",
          description:
            "Kişiselleştirilmiş bir imza eklemek için, hesabınızda Apple Mail kurulu olmalıdır.",
          step_1_text: "Copy your signature's HTML:",
          step_1_button: "Copy Signature",
          step_2_text: "Log in to your Apple account",
          step_3_text: "Click the settings icon and go to the Email signature section",
          step_4_text: "Check the box labeled Add signature",
          step_5_text: "Select Use my signature from the web or use a different signature",
          step_6_text: "Paste the copied code into the text box that appears",
          step_7_text: "Click the confirm icon in the top right to save",
        },
        yahoo: {
          title: "Yahoo Mail’e E-posta İmzası Ekle",
          button_label: "Yahoo Mail’e Git",
          description:
            "Kişiselleştirilmiş bir imza eklemek için, hesabınızda Yahoo Mail kurulu olmalıdır.",
          step_1_text: "Copy your signature's HTML:",
          step_1_button: "Copy Signature",
          step_2_text: "Log in to your Yahoo account",
          step_3_text: "Click the settings icon and go to the Email signature section",
          step_4_text: "Check the box labeled Add signature",
          step_5_text: "Select Use my signature from the web or use a different signature",
          step_6_text: "Paste the copied code into the text box that appears",
          step_7_text: "Click the confirm icon in the top right to save",
        },
      },
      bg: {
        page_title: "Sanal Arka Plan",
        head_title: "Sanal Arka Plan",
        premium_text:
            "Sanal arka planı oluşturmak için “NetworKit Paketi” satın almalısın.",
        download: {
          title: "Sanal Arka Plan İndirildi!",
          description:
              "Şimdi sanal arka planını Zoom, Microsoft Teams gibi çevrimiçi platformlara ekleyebilirsin.",
          how_button_label: "Sanal Arka Plan Nasıl Eklenir?",
          button_label: "Sanal Arka Planı İndir",
          text: "Dijital kartvizitinin QR kodu sanal arka planında bulunur. Bu kodu kim tararsa, Dijital Kartvizitini görüntüleyebilir ve indirebilir.",
          new: "Yeni Sanal Arka Plan Oluştur",
        },
        upload: {
          image_button_label: "Görsel Yükle",
          button_label: "Sanal Arka Plan Yükle",
          description:
              "Çerçeve içindeki alan (1920x1080 px) yüklenecektir. Kırpma maskesi ile görüntülemek istediğiniz alanları ayarlayabilirsiniz.",
        },
        done: {
          title: "Tebrikler!",
          text_1: "Yeni sanal arka plan yüklendi.",
          text_2: "Yüklediğin yeni sanal arka planı tüm üyeler kullanabilecek.",
          button_label: "Yeni Sanal Arka Plan Ekle",
        },
        new: {
          title: "Yeni bir sanal arka plan eklendi!",
          text_1: "Haydi sen de dene.",
          button_label: "Tamam",
        },
        mobile: {
          bold_text: "masaüstü veya tabletinden giriş yapabilirsin.",
          text_1:
              "Üzgünüz! Sanal Arka Plan özelliği telefonda kullanıma uygun değildir. Bu özelliği kullanabilmek için uygulamaya {bold_text}",
          text_2:
              "Toplantılarında daha akılda kalıcı tanışmalar yapabilmeni sağlayacak Sanal Toplantı Arka Planı'yla ilgili blog yazımızı inceleyebilirsin!",
          button_label: "Blog Yazılarına Git",
        },
      },
      stats: {
        title: "İstatistikler",
        premium_text:
            "İstatistikleri görüntülemek için “NetworKit Paketi” satın almalısın.",
        select_date: "Tarih Seç",
        last_week_text: "Geçen Haftadan Beri",
        no_data_text: "Gösterecek veri bulunamadı.",
        view: "Görüntülenme",
        download: "İndirilme",
        date: {
          today: "Bugün",
          yesterday: "Dün",
          last_7_days: "Son 7 Gün",
          last_30_days: "Son 30 Gün",
          this_month: "Bu Ay",
          last_month: "Geçen Ay",
          this_year: "Bu Yıl",
          last_year: "Geçen Yıl",
        },
        tabs: {
          tab_1: {
            title: "NetworKit Toplam",
            sub_tabs: {
              tab_1: {
                title: "Toplam Kartvizit Görüntülenme",
                chart_title: "Görünteleme Grafiği",
                description:
                    "Kartvizit Görüntülenme, networkit ürünleri üzerinden gelen toplam ziyaret sayısını gösterir.",
              },
              tab_2: {
                title: "Toplam Kartvizit İndirilme",
                chart_title: "İndirme Grafiği",
                description:
                    "Kartvizit İndirilme, networkit ürünleri üzerinden gelen toplam kartvizit indirilme sayısını gösterir.",
              },
              tab_3: {
                title: "Verimlilik Oranı",
                chart_title: "Verimlilik Grafiği",
                description:
                    "Verimlilik Oranı, networkit ürünleri üzerinden gelen toplam kartvizit indirilme sayısının toplam ziyaret sayısına oranıdır.",
              },
            },
          },
          tab_2: {
            title: "Janus Profil (Kart)",
            sub_tabs: {
              tab_1: {
                title: "Janus Kart Görüntülenme",
                chart_title: "Görünteleme Grafiği",
                description:
                    "Kartvizit Görüntülenme, networkit ürünleri üzerinden gelen toplam ziyaret sayısını gösterir.",
              },
              tab_2: {
                title: "Janus Kart İndirilme",
                chart_title: "İndirme Grafiği",
                description:
                    "Kartvizit İndirilme, networkit ürünleri üzerinden gelen toplam kartvizit indirilme sayısını gösterir.",
              },
              tab_3: {
                title: "Verimlilik Oranı",
                chart_title: "Verimlilik Grafiği",
                description:
                    "Verimlilik Oranı, networkit ürünleri üzerinden gelen toplam kartvizit indirilme sayısının toplam ziyaret sayısına oranıdır.",
              },
            },
          },
          tab_3: {
            title: "E-Posta İmzası",
            sub_tabs: {
              tab_1: {
                title: "E-Posta İmzası Görüntülenme",
                chart_title: "Görünteleme Grafiği",
                description:
                    "E-Posta İmzası Görüntülenme, networkit ürünleri üzerinden gelen toplam ziyaret sayısını gösterir.",
              },
              tab_2: {
                title: "E-Posta İmzası İndirilme",
                chart_title: "İndirme Grafiği",
                description:
                    "E-Posta İmzası İndirilme, networkit ürünleri üzerinden gelen toplam e-posta imzası indirilme sayısını gösterir.",
              },
              tab_3: {
                title: "Verimlilik Oranı",
                chart_title: "Verimlilik Grafiği",
                description:
                    "Verimlilik Oranı, networkit ürünleri üzerinden gelen toplam e-posta imzası indirilme sayısının toplam ziyaret sayısına oranıdır.",
              },
            },
          },
          tab_4: {
            title: "Sanal Arka Plan",
            sub_tabs: {
              tab_1: {
                title: "Sanal Arka Plan Görüntülenme",
                chart_title: "Görünteleme Grafiği",
                description:
                    "Sanal Arka Plan Görüntülenme, networkit ürünleri üzerinden gelen toplam ziyaret sayısını gösterir.",
              },
              tab_2: {
                title: "Sanal Arka Plan İndirilme",
                chart_title: "İndirme Grafiği",
                description:
                    "Sanal Arka Plan İndirilme, networkit ürünleri üzerinden gelen toplam sanal arka plan indirilme sayısını gösterir.",
              },
              tab_3: {
                title: "Verimlilik Oranı",
                chart_title: "Verimlilik Grafiği",
                description:
                    "Verimlilik Oranı, networkit ürünleri üzerinden gelen toplam sanal arka plan indirilme sayısının toplam ziyaret sayısına oranıdır.",
              },
            },
          },
        },
        contribution: {
          title: "Doğaya Katkı",
          description:
              "*Etkileşime giren her bir kartvizitinin sağladığı katkı hesaplanır",
          liter: "Litre",
          item_1_title: "Kurtarılan Oksijen Miktarı",
          item_2_title: "Harcanan Enerji Miktarı",
          item_3_title: "Kurtarılan Su Miktarı",
          item_4_title: "Engellenen Karbondioksit Salınım Miktarı",
        },
        search: {
          options: {
            teams: "Tüm  Takımlar",
            members: "Tüm Üyeler",
          },
          teams: {
            empty: {
              title: "Henüz bir takım oluşturmadın",
              create: "Takım Oluştur",
            },
            member_count: "{count} Üye",
            created_at_text: "{date} tarihinde kuruldu!",
          },
          members: {
            name: "İsim",
            title: "Ünvan",
          },
          register_date_text: "{date} tarihinden beri Janus üyesi!",
          view_profile: "Profili Görüntüle",
          placeholder_members: "Üye Ara",
          placeholder_teams: "Takım Ara",
        },
        locations: {
          title: "Lokasyonlar",
          location: "Lokasyon",
          value: "Etkileşim Sayısı",
          view_more: "Daha Fazla Göster",
        },
        interests: {
          title: "İlgi Alanları",
          view_more: "Daha Fazla Göster",
          interest: "İlgi Alanı",
          value: "Etkileşim Sayısı",
        },
        views: {
          description:
              "Dijital kartvizitinin kaç kişi tarafından yeni veya tekrar görüntülenmiş olduğunu haftalık olarak görebilirsin.",
          tab_1: "Görüntüleyen",
          tab_2: "Yeni Görüntüleyen",
          tab_3: "Tekrar Görüntüleyen",
        },
        devices: {
          title: "Cihazlar",
        },
        genders: {
          title: "Cinsiyetler",
        },
        age: {
          title: "Yaş Aralığı",
        },
      },
      sidebar: {
        installation_progress: "Kurulum İlerlemesi",
        last_n_steps: "Son {n} Adım",
        complete_profile: "Profilini tamamla!",
        menu_item_digital_card: "Dijital Kartvizit",
        menu_item_stats: "İstatistikler",
        profile_dot: "Görünümü Düzenle",
        menu_item_email_signature: "E-Posta İmzası",
        menu_item_virtual_background: "Sanal Arka Plan",
        menu_item_members: "Üyeler",
        menu_item_network: "Network Havuzu",
        menu_item_notifications: "Bildirimler",
        menu_item_settings: "Ayarlar",
        menu_item_support: "Destek Merkezi",
        menu_item_logout: "Çıkış Yap",
      },
      team: {
        title: "Takımlar",
        add_team_button_label: "Takım Ekle",
        confirm: "Onayla",
        add: {
          button_label: "Takım Ekle",
          placeholder: "Design Takımı",
        },
        invite: {
          title: "Ekip Arkadaşlarını Davet Et",
          text: "Organizasyonda bulunan kişilerin mail adreslerini girerek dijital kartvizitlerini oluşturmalarına yardımcı olabilirsin.",
          copy_link: "Linki Kopyala",
          invite: "Gönder",
          success_text_1: "Tebrikler!",
          success_text_2: "Davetiyelerin e-posta yoluyla iletildi!",
          limit_error: {
            title: "Davet Gönderme Limiti Aşıldı",
            text: "Davet gönderme limitini aştığın için yeni davet gönderemezsin.",
          },
        },
        membership: {
          label: "Abonelik Yönetimi",
          free: "Ücretsiz",
          corporate: "Kurumsal",
          waiting: "Onay Bekliyor",
          cancel: {
            text: "adlı üyelerin <strong>kurumsal</strong> aboneliğini iptal etmek istediğine emin misin?",
            button_label: "Evet",
            success: {
              title: "Kurumsal abonelik iptal talebiniz için üzgünüz.",
              text: "Seçtiğiniz üyeler abonelik süreleri dolduktan sonra Ücretsiz Janus hesaplarını kullanmaya devam edecekler.",
            },
          },
          upgrade: {
            text: "adlı üyeleri <strong>kurumsal</strong> aboneliğe taşımak istediğine emin misin?",
            button_label: "Evet",
            success: {
              title: "Kurumsal abonelik talebiniz bize iletildi!",
              text: "En kısa sürede talebiniz ile ilgili teklifimizi e-posta adresinize ileteceğiz.",
            },
          },
        },
        role_assign: {
          label: "Üyelere Rol Ata",
          owner: "Sahip",
          admin: "Yönetici",
          member: "Üye",
        },
        team_assign: {
          label: "Üyelere Takım Ata",
          confirm: "Onayla",
          search_placeholder: "Takım Ata",
        },
        reset: {
          label: "Üye Bilgilerini Sıfırla",
        },
        delete: {
          title: "Takımı Sil",
          text: "{name} adlı takımı silmek istediğine emin misin?",
        },
        add_members: {
          title: "Takıma Ekleyeceğiniz Üyeleri Seçin",
          add: "Ekle",
          skip: "Daha Sonra",
          search_placeholder: "İsim veya Ünvan Ara",
        },
        remove_members: {
          label: "Üyeleri Takımdan Kaldır",
          text: "Seçili üyeleri takımınızdan silmek istediğinize emin misiniz?",
        },
        reset_members: {
          label: "Üye Bilgilerini Sıfırla",
          text: "Seçili üyelerin bilgilerini sıfırlamak istediğinize emin misiniz?",
        },
        filter: {
          title: "Filtreler",
          description: "Sıralama Düzeni",
          asc: "A'dan Z'ye",
          desc: "Z'den A'ya",
          order_by: {
            name: "İsim",
            id: "ID No",
          },
        },
        members: {
          title: "Üyeler",
          no_team: "Takım Yok",
          empty: {
            title: "Üye yok",
            text: "Üyeleri davet etmek için ‘’+’’ butonuna tıklayabilirsin.",
          },
          search: {
            placeholder: "Üye, takım, ID no ara...",
          },
          columns: {
            all: "Tümü",
            name: "İsim",
            membership: "Abonelik",
            id: "ID No",
            role: "Rol",
            team: "Takım",
          },
        },
        empty: {
          title: "Takım Yok",
          text: "Takım oluşturmak için ‘’Takım Ekle’’ butonuna tıklayın.",
        },
        empty_team: {
          title: "Üye Yok",
          text: "Bu takımda henüz üye yok, üyeleri davet etmek için ‘’+’’ butonuna tıklayın.",
        },
      },
      network: {
        title: "Network Havuzu",
        tabs: {
          card: "Kartvizit",
          details: "Detaylar",
          activities: "Aktiviteler",
        },
        groups: {
          all: "Tüm Gruplar",
          create_button_label: "Grup Oluştur",
          edit: {
            title: "Grup İsmini Düzenle",
            button_label: "Düzenle",
          },
          create: {
            title: "Grup Oluştur",
            button_label: "Oluştur",
            placeholder: "Grup İsmi",
          },
          delete: {
            title: "Grubu Sil",
            text: "{name} adlı grubu silmek istediğine emin misin?",
          },
          empty: {
            title: "Grup Yok",
            text: "Grup oluşturmak için ‘’Grup Ekle’’ seçeneğine tıkla.",
            text_2:
                "Grup oluşturmak için network havuzuna bağlantı eklemelisin.",
          },
          add_networks: {
            title: "Gruba Ekleyeceğin Bağlantıları Seç",
            skip: "Daha Sonra",
            add: "Ekle",
            search_placeholder: "İsim veya Ünvan Ara",
          },
        },
        export: {
          title: "Bağlantılarını Dışa Aktar",
          text: "Dışa aktarılan bağlantıların {email} adresine e-posta ile gönderilecektir.",
          button_label: "Dışa Aktar",
        },
        empty: {
          text_1: "Yeni bir bağlantı eklemek için",
          text_2: "butonuna basabilirsin.",
        },
        columns: {
          all: "Tümü",
          name: "İsim",
          title: "Ünvan",
          company: "Şirket",
          location: "Lokasyon",
          tags: "Etiketler",
          date: "Tarih",
          added: "Ekleyen",
        },
        import: {
          title: "Bağlantılarını İçe Aktar",
          text: "Diğer platformlardaki bağlantılarını kolayca Janus Network Havuzu’na aktarabilirsin!",
          option_excel: "Excel İçer Aktar",
          button_label: "İçe Aktar",
          choose_file: "Dosya Seç",
          choose_file_description: "Bir csv dosyası seçin.",
          excel: {
            title: "Excel’den İçe Aktar",
            download_sample_file: "Örnek excel dosyasını indir",
            step_1:
                "Lütfen eklemek istediğiniz kişlerin bilgilerini aşağıdaki excel dosyasına giriniz. Bu dosyada ilk satırda yer alan tüm sütunlar kişi hakkında eklenebilecek bilgilerin başlıklarını ifade eder. Her satıra yeni bir kişi ekleyin. Her kişi için bilgi başlıklarını doldurmak zorunda değilsiniz. Doldurmak istemediğiniz alanları boş bırakabilirsiniz.",
            step_2:
                "Kişileri eklemeyi bitirdikten sonra excel dosyanızı  farklı kaydete tıklayarak ve dosya türünü .csv formatında seçerek kaydediniz.",
            step_3:
                "Kaydettiğiniz dosyayı aşağıdaki butonu tıklayarak yükleyiniz.",
          },
          being: {
            title: "Bağlantıların içeri aktarılıyor...",
          },
          fail: {
            text: "{text_1}, {text_2}",
            text_1:
                "Bağlantıların network havuzunuza aktarılamadı. Dosyanı kontrol edip tekrar deneyebilirsin. Doğru dosya formatını indirmek için",
            text_2: "tıklayabilirsin.",
          },
          success: {
            text: "Bağlantıların network havuzuna başarıyla aktarıldı.",
          },
        },
        search: {
          placeholder: "İsim, unvan, şirket ara...",
        },
        remove_from_group: {
          text: "Seçili bağlantıları grubunuzdan silmek istediğinize emin misiniz?",
        },
        remove: {
          content_single:
              "{name} adlı bağlantını Network Havuzu’ndan silmek istiyor musun?",
          content_plural:
              "{names} adlı bağlantıları Network Havuzu’ndan silmek istiyor musun?",
          description:
              "Bağlantı sadece senin Network Havuzu'ndan silinir. Ortak havuzda admin tarafından görüntülenebilir.",
          description_admin:
              "Bağlantı sadece şirketin ortak Network Havuzu'ndan silinir. Bağlantıyı ekleyen ekip üyesinden silinmez.",
        },
        add_to_group: {
          button_label: "Gruba Ekle",
        },
        filter: {
          title: "Sırala",
          order: {
            asc: "A'dan Z'ye",
            desc: "Z'den A'ya",
          },
          order_by: {
            title: "Ünvan",
            name: "İsim",
            company: "Şirket",
            location: "Lokasyon",
            created_at: "Eklenme Tarihi",
          },
        },
        tags: {
          description:
              "{name} adlı bağlantına dilediğin kadar etiket ekleyebilirsin. Etiketler kişiye özeldir ve bunları bir tek sen görebilirsin.",
          empty_text:
              "Henüz etiket eklemedin. Not oluşturmak istiyorsan üstteki (+) butonuna tıklayabilirsin.",
          label: "Etiketler",
          edit_tags: "Etiketleri Düzenle",
          input_placeholder: "Etiket İsmi",
          recommended: "Önerilenler",
        },
        notes: {
          form: {
            title: "Başlık",
            content: "Açıklama",
          },
          delete_all: "Tümünü Sil",
          archive_all: "Tümünü Arşivle",
          view_archive: "Arşivi Görüntüle",
          description:
              "{name} adlı bağlantın ile ilgili hatırlamak istediklerini bu alana not edebilirsin.",
          empty_text:
              "Henüz bir not oluşturmadın. Not oluşturmak istiyorsan üstteki (+) butonuna tıklayabilirsin.",
          label: "Notlar",
          delete: {
            description:
                "{name} bağlantın ile ilgili notunu gerçekten silmek istiyor musun?",
            text: "‘Sil’ butonuna tıklarsan notunu tekrar göremeyeceksin.",
          },
          archive: {
            description:
                "{name} bağlantın ile ilgili notunu gerçekten arşivlemek istiyor musun?",
            button_label: "Evet, Arşivle",
          },
          unarchive: {
            description:
                "{name} bağlantın ile ilgili notunu arşivden çıkarmak istiyor musun?",
            button_label: "Evet",
          },
        },
        reminders: {
          label: "Anımsatıcılar",
          description:
              "{name} adlı bağlantın ile ilgili hatırlamak istediklerini bu alana not edebilirsin.",
          empty_text:
              "Henüz bir anımsatıcı oluşturmadın. Anımsatıcı oluşturmak istiyorsan üstteki (+) butonuna veya bu alana tıklayabilirsin.",
          delete_all: "Tümünü Sil",
          archive_all: "Tümünü Arşivle",
          view_archive: "Arşivi Görüntüle",
          date: "Tarih",
          time: "Saat",
          place: "Yer",
          reminder: "Hatırlat",
          priority: "Öncelik",
          past: "Geçmiş",
          add_guests: "Davetli Ekle",
          notify: {
            text: "Anımsatıcı ile ilgili detay bilgileri e-posta ile davetlilere göndermek istiyor musun?",
            dont_notify: "Gönderme",
            notify: "Gönder",
          },
          slots: {
            three_hours: "3 Saat İçinde",
            tomorrow: "Yarın",
            next_week: "Gelecek Hafta",
            one_month: "1 Ay içinde",
            three_months: "3 Ay içinde",
          },
          recurring: {
            every_day: "Her Gün",
            every_week: "Her Hafta",
            every_month: "Her Ay",
            every_year: "Her Yıl",
            never: "Tekrarlanmaz",
          },
          places: {
            online: "Online Toplantı",
            offline: "Yüz Yüze",
          },
          reminders: {
            five_minutes: "5 Dakika Önce",
            fifteen_minutes: "15 Dakika Önce",
            twenty_minutes: "20 Dakika Önce",
            thirty_minutes: "30 Dakika Önce",
            one_hour: "1 Saat Önce",
          },
          delete: {
            description:
                "{name} bağlantın ile ilgili anımsatıcını gerçekten silmek istiyor musun?",
            text: "‘Sil’ butonuna tıklarsan anımsatıcını tekrar göremeyeceksin.",
          },
          unarchive: {
            description:
                "{name} bağlantın ile ilgili anımsatıcını arşivden çıkarmak istiyor musun?",
            button_label: "Evet",
          },
        },
        stay_touch: {
          title: "Ne sıklıkla iletişimde kalmak istiyorsun?",
          options: {
            weekly: {
              label: "Haftalık",
              description: "",
              display_text: "Haftalık, {day}",
            },
            monthly: {
              label: "Aylık",
              description: "",
              display_text: "Her ayın {day}. günü",
            },
            quarterly: {
              label: "3 Aylık",
              description: "",
              display_text: "Her çeyreğin {day}. günü",
            },
            yearly: {
              label: "Yıllık",
              description: "",
              display_text: "Her yılın {month} ayının {day}. günü",
            },
            auto: {
              label: "Otomatik",
              description: "Janus zamanı senin adına seçer.",
            },
            never: {
              label: "Hiçbir Zaman",
              description: "İletişimde kalmak istemiyorum.",
              display_text: "Hiçbir zaman",
            },
          },
        },
        lead: "Bağlantı Formu",
        manuel: "Manuel",
        add_link: "Bağlantı Ekle",
        add_multiple_links: "Çoklu Bağlantı Ekle",
      },
      archived: {
        title: "Arşivlenenler",
        notes: {
          title: "Notlar",
          description:
              "Bağlantıların ile ilgili arşivlenen notlarını bu alanda görebilir dilediğin notları geri alabilirsin.",
          empty_text: "Henüz arşivlenen bir notun görünmüyor.",
        },
        reminders: {
          title: "Anımsatıcılar",
          description:
              "Bağlantıların ile ilgili arşivlenen anımsatıcılarını bu alanda görebilir dilediğin anımsatıcıları geri alabilirsin.",
          empty_text: "Henüz arşivlenen bir anımsatıcın görünmüyor.",
        },
        delete_all: "Tümünü Sil",
        unarchive_all: "Tümünü Arşivden Çıkar",
      },
      settings: {
        title: "Hesap Ayarları",
        edit_account: {
          account_name_title: "Hesap ismin ne?",
          account_name_description:
              "*Profesyonel, iş , kişisel vb. şekilde adlandırabilirsin.",
          add_profile_photo: "Profil Fotoğrafı Ekle",
        },
        preferences: {
          title: "Site Tercihleri",
          language: "Dil",
          language_text: "Janus’ta kullanacağınız dili seçin",
        },
        archived: {
          title: "Arşivlenenler",
        },
        match: {
          title: "Janus Kart",
          content_title: "Janus Kart’ımı Profilim ile Eşleştir",
          content_text:
              "Janus Kart’ın ile profilini eşleştirmek istiyorsan kartının arka yüzünde bulunan (JNXXXX-XXXX) şeklindeki ID numarayı aşağıdaki butona tıkladığında çıkan kutucuğa yazman yeterli olacaktır.",
          step_1: "Janus Kart’ının arka yüzünü çevir.",
          step_2: "JNXXXX-XXXX şeklindeki kodu bir yere not al.",
          step_3: "Eşleştir butonuna tıkla.",
          step_4: "Profilini seç ve numarayı kutucuğa yaz.",
          button_label: "Eşleştir",
          modal: {
            title: "Janus Kart’ımı Profilim ile Eşleştir",
            text: "Janus Kart’ını eşleştirmek istediğin profilini seç ve JNXXXX-XXXX şeklindeki ID numarayı kutucuğa yaz.",
            profiles: "Profiller",
            id: "ID Numara",
            button_label: "Eşleştir",
            success_button_label: "Talebinizi Aldık",
            confirm: {
              text: "Hali hazırdaki dijital kartvizitinizi, elinizdeki Janus Kart ile eşleştirmek istediğinize emin misiniz? Bu işlem gerçekleştikten sonra seçili olan dijital kartvizitiniz eşleştirmek istediğiniz kartvizite aktarılır.<strong>Mevcut dijital kartvizitiniz sıfırlanır ve bu işlem geri alınamaz.</strong>",
            },
            already_matched: {
              try_again_button_label: "Tekrar Dene",
              button_label: "İnceleme Talebi Oluştur",
              success_button_label: "Talebini Aldık",
            },
          },
        },
        account_management: {
          title: "Hesap Yönetimi",
          email: {
            title: "E-posta adresini değiştir",
            current_emails: "Mevcut E-posta Adresleri",
            new_email: "Yeni e-posta adresi ekle",
            enter_new_email: "Yeni e-posta adresini gir",
            add: "Ekle",
          },
          password: {
            title: "Şifreni değiştir",
            current_password: "Mevcut Şifre",
            new_password: "Yeni Şifre",
            confirm_new_password: "Yeni Şifreyi Onayla",
            change: "Şifreyi Değiştir",
            success_text: "Şifreniz başarıyla oluşturuldu!",
          },
          freeze_account: {
            title: "Hesabını Dondur",
            text_1:
                "Geçici bir süre Janus profilini ve aktivitelerini durdurmak istiyorsan hesabını dondurabilirsin.",
            text_2:
                "Bize hesabını neden dondurmak istediğini söyle. (isteğe bağlı)",
            other: "Diğer",
            button_label: "Hesabımı Dondur",
            other_placeholder: "Hesabını dondurma sebebini ekleyebilirsin.",
            success_text:
                "Hesabını dondurma talebini aldık. İşlemlerine devam etmek için e-posta adresini kontrol etmeyi unutma.",
          },
          delete_account: {
            title: "Hesabını Sil",
            button_label: "Hesabımı Sil",
            text: "{text1} {text2} {text3}",
            text_1: "Hesabını silersen",
            text_2:
                "hesapla ilişkili tüm Janus profillerini, istatistik ve verilerini kalıcı olarak",
            text_3: "kaybedeceksin.",
            confirm_password_text:
                "Güvenliğin için bu değişikliği yapmadan önce şifreni girmelisin. Hesabın {day} gün içinde silinecektir. Bu süre içinde hesabını yeniden aktif etmek için mevcut bilgilerin ile giriş yapabilirsin.",
            success_text:
                "Hesabını silme talebini aldık. İşlemlerine devam etmek için e-posta adresini kontrol etmeyi unutma.",
            agree: "Evet, onaylıyorum",
          },
          confirm_password: {
            text: "Güvenliğin için, bu değişikliği yapmadan önce şifreni girmelisin.",
            placeholder: "Şifreni gir",
          },
          billing_information: {
            title: "Fatura Bilgileri",
            individual: "Bireysel",
            corporate: "Kurumsal",
          },
        },
        packages: {
          title: "Paketler",
          monthly: "Aylık",
          yearly: "Yıllık",
          current_package_text: "Şu anda bu paketi kullanıyorsun.",
          choose: "Seç",
          cancel: "Aboneliğimi İptal Et",
          buy: "Satın Al",
          total: "Toplam",
          billing_not_active:
              "İşleme devam edebilmek için fatura bilgilerinizi girmelisiniz.",
          free: {
            title: "Ücretsiz Paket",
            features: {
              title: "Ücretsiz paket özellikleri",
              item_1: "Temel Dijital Kartvizit Profiline sahip ol",
              item_2:
                  "QR Kodu, mail, sms veya link yoluyla kartvizitini paylaş",
              item_3: "Profilinin İstatistiklerini görüntüle",
            },
          },
          card: {
            title: "Özel İndirimini Kaçırma!",
            content:
                "Networkit paketine sahipsin. Bu sayede Janus Kart'a %{discount} indirimle sahip olabilirsin. Satın alma adımında hesabının mail adresini kartla eşle ve indirimden faydalan!",
            subtitle: "Janus Kart",
          },
          plus: {
            title: "NetworKit Plus",
            content:
                "Networkit paketini ve Janus Kart’ı aynı anda alarak indirimli fiyattan yararlanın.",
            item_1_title: "NetworKit Paketi",
            item_2_title: "Janus Kart",
          },
          pairing: {
            title: "Janus Kart’ını Profilinle Eşleştirmeyi Unutma!",
            content:
                "Aşağıdaki ID numaranı kopyalayıp Janus Kart satın alım sayfasında ilgili alana ekleyebilirsin. Böylece Dijital kartvizit profilini Janus Kartın ile eşleştirmiş olursun.",
            copy: "Kopyala",
            copied: "ID numaran kopyalandı!",
            continue: "Devam Et",
          },
        },
      },
      membership: {
        success: {
          title: "Teşekkürler!",
          text: "Ödemeniz başarıyla alındı. Hesabınızı güncellemek için sayfayı yenileyin.",
          button_label: "Tamam",
        },
        error: {
          title: "Hata!",
          text: "Ödemeniz alınırken bir hata oluştu. Lütfen tekrar deneyin.",
          button_label: "Tekrar Dene",
        },
        cancel: {
          title: "Networking Deneyimin Nasıl Değişecek?",
          items: {
            1: "<span>Sanal Arka Plan</span> ve <span>E-posta İmzası</span> özelliğinden yararlanamayacaksın.",
            2: "<span>Gelişmiş Dijital Kartvizit</span> Profilinden faydalanamayacaksın.",
            3: "<span>Network istatistiklerini</span> artık detaylı inceleyemeyeceksin.",
          },
          continue: "İptal işlemi için devam et",
          finish: "İptal işlemini tamamla",
          confirm_password_description: "İptal işlemi için şifreni girmelisin.",
          confirm_password_content:
              "Paket iptali, geçerli faturalama döneminin biteceği {expire_date} tarihinde geçerli olacak. Paketini istediğin zaman yeniden başlatabilirsin.",
          confirmed_title: "Aboneliğini İptal Ettik",
          confirmed_text:
              "E-posta onayı {email} adresine gönderilecek. {expire_date} tarihine kadar paketini kullanmaya devam edebilirsin.",
          confirmed_questions_title: "Paketini neden iptal ettin?",
          confirmed_questions_placeholder:
              "Paketini iptal etme sebebini ekleyebilirsin.",
          success_title: "Teşekkürler!",
          success_text:
              "Seni NetworKit kullanıcısı olarak görmek güzeldi, umarız yakında geri dönersin.",
          success_button_label: "Anlaştık",
        },
      },
      notifications: {
        title: "Bildirimler",
        empty_text_1: "Şu anda bildirim yok!",
        empty_text_2: "Gelecek bildirimlerini buradan görüntüleyebilirsin.",
        show_more: "Daha Fazla Göster",
      },
      support: {
        title: "Yardım Merkezi",
        form_title: "İletişim Desteği",
        form: {
          email: "E-Posta",
          message: "Mesaj",
        },
      },
    },
    en: {
      validation: {
        required: "{label} is required.",
        email: "{label} must be a valid email address.",
        min: "{label} must be at least {min} characters.",
        max: "{label} must be at most {max} characters.",
        unmatch_password: "Passwords do not match.",
        phone: "The entered phone number is invalid.",
        not_type: "{label} is invalid.",
        number: "{label} must be a number.",
      },
      defines: {
        genders: {
          male: "Male",
          female: "Female",
        },
        phone_types: {
          mobile: "Mobile",
          home: "Home",
          work: "Work",
          fax: "Fax",
          other: "Other",
        },
        more_types: {
          website: {
            label: "Website",
          },
          linkedin: {
            label: "LinkedIn",
          },
          instagram: {
            label: "Instagram",
          },
          facebook: {
            label: "Facebook",
          },
          twitter: {
            label: "Twitter",
          },
          youtube: {
            label: "YouTube",
          },
          dribbble: {
            label: "Dribbble",
          },
          tiktok: {
            label: "TikTok",
          },
          behance: {
            label: "Behance",
          },
          github: {
            label: "GitHub",
          },
          telegram: {
            label: "Telegram",
          },
          file: {
            label: "File",
            placeholder:
                "You can add the document, resume, or portfolio you want to share with your networks.",
          },
          date: {
            label: "Date",
          },
        },
      },
      misc: {
        individual_label: "Individual",
        corporate_label: "Corporate",
        no_results: "No results found.",
        error_occurred: "An error occurred.",
        id: "ID No",
        first_name: "Your Name",
        last_name: "Your Surname",
        full_name: "Full Name",
        identify_number: "T.C. Identification No",
        title: "Title",
        company: "Company Name",
        tax_number: "Tax No",
        tax_office: "Tax Office",
        message: "Message",
        email: "E-mail",
        phone: "Phone",
        address: "Address",
        website: "Website",
        country: "Country",
        state: "State",
        city: "District",
        neighborhood: "Neighborhood",
        street: "Street / Avenue",
        error_title: "Oops!",
        back: "Go Back",
        cancel: "Cancel",
        submit: "Submit",
        confirm: "Confirm",
        save: "Save",
        optional: "Optional",
        share: "Share",
        edit: "Edit",
        delete: "Delete",
        saved: "Changes Saved",
        social_media: "Social Media",
        download_card: "Download Business Card",
        visibility: "Visibility",
        get_started: "Get Started",
        skip: "Skip",
        next: "Next",
        prev: "Previous",
        done: "Done",
        premium_button_label: "Buy NetworKit",
        remove_photo: "Remove Photo",
        add_photo: "Add Photo",
        upload: "Upload",
        logout: "Log Out",
        corporate: {
          email: "Corporate E-mail",
          phone: "Corporate Phone",
        },
        days: {
          monday: "Monday",
          tuesday: "Tuesday",
          wednesday: "Wednesday",
          thursday: "Thursday",
          friday: "Friday",
          saturday: "Saturday",
          sunday: "Sunday",
        },
        months: {
          january: "January",
          february: "February",
          march: "March",
          april: "April",
          may: "May",
          june: "June",
          july: "July",
          august: "August",
          september: "September",
          october: "October",
          november: "November",
          december: "December",
        },
        icons: "Icons",
      },
      auth: {
        common: {
          email: "E-mail",
          password: "Password",
          password_confirmation: "Confirm Password",
          or: "or",
          google: "Login with Google",
          microsoft: "Login with Microsoft",
          create_account: "Create New Account",
          corporate: {
            email: "Corporate E-mail",
          },
        },
        login: {
          title: "Login",
          button_label: "Login",
          email: "E-mail or ID No",
          forgot: "Did you forget your password?",
          not_registered: "Not registered?",
          register: "Register",
          corporate: {
            email: "Corporate E-mail or ID no",
          },
        },
        register: {
          title: "Sign Up",
          button_label: "Sign Up",
          corporate: {
            title: "Contact",
            description:
                "You can fill out the form to create a corporate account. We will contact you as soon as possible.",
            employee_count: "Employee Count",
            button_label: "Create Request",
            confirm: {
              text: "Check the inbox and spam folders associated with {email}.",
            },
          },
          already: "Already a member?",
          terms_text:
              "By clicking the sign-up button, you accept {text_1}, {text_2}, {text_3}, {text_4}, and {text_5}.",
          terms_text_1: "Janus's",
          terms_text_2: "Membership Agreement",
          terms_text_3: "Privacy and Cookie Policy",
          terms_text_4: "Open Consent Text",
          terms_text_5: "KVKK Text",
          invite: {
            invalid: {
              title: "Invalid Invitation Code",
              content: "The invitation code is invalid.",
            },
            expired: {
              title: "Invalid Invitation Code",
              content: "The invitation code you are trying to use has expired.",
            },
            confirmed: {
              title: "Invalid Invitation Code",
              content:
                  "The invitation code you are trying to use has already been used.",
            },
          },
        },
        forgot: {
          page_title: "I Forgot My Password",
          title: "Having trouble logging in?",
          text: "Enter the e-mail address you signed up with, and we'll send you a link to reset your password.",
          button_label: "Send Login Link",
          sent: "Link sent.",
          sent_text: "A password reset link has been sent to {email}.",
          resend: "Resend",
          not_found: "E-mail address not found.",
          corporate: {
            text: "Enter the corporate e-mail address you signed up with, and we'll send you a link to reset your password.",
            not_found: "We couldn't find the Corporate E-mail address.",
          },
        },
        reset_password: {
          title: "Create your password",
          button_label: "Create your password",
          success_text:
              "Your password has been successfully created. You can now log in with your new password.",
        },
        logout: {
          title: "Log Out",
          text: "Are you sure you want to log out?",
        },
        set_email: {
          page_title: "Define E-Mail Address",
          title: "Define E-Mail Address",
          text_1:
              "There's just one more step to access your account! You need to define an e-mail address to perform all activities related to your account.",
          text_2:
              "You can use the e-mail address you define to change your password, log in, and perform many other functions.",
          already: "This e-mail address is already in use.",
          button_label: "Define E-mail Address",
        },
      },
      public_profile: {
        basic_info: "Basic Information",
        additional_info: "Additional Information",
        corporate_info: "Corporate Information",
        download_card: "Download Business Card",
        share_info: "Share Information",
        create_profile: "Click to buy Janus Card",
        download_modal: {
          info: "To quickly save the user's information named {name}:",
          list_item_1: "Click the 'Download Business Card' button below.",
          list_item_2: "Allow file download on the pop-up screen.",
          list_item_3:
              "Open the downloaded file and save it to your phone's contacts app.",
          ios: {
            list_item_1: "Click the 'Download Business Card' button below.",
            list_item_2:
                "On the opened contacts screen, scroll down and click the 'Create New Contact' button to save to the contacts.",
          },
        },
        share_modal: {
          title: "Share Information",
          info: "Networking is a mutual activity. For healthy networking, you should share your information with {name}.",
          card_downloaded: "Business card downloaded!",
          add_more: "Add more",
          terms_text:
              "By clicking the share button, you accept {text_1}, {text_2}, {text_3}, {text_4}, and {text_5}.",
          terms_text_1: "Janus's",
          terms_text_2: "Membership Agreement",
          terms_text_3: "Privacy and Cookie Policy",
          terms_text_4: "Open Consent Text",
          terms_text_5: "KVKK Text",
        },
        register_modal: {
          info_1:
              "By downloading the business card of the user named {name}, you only have access to today's information.",
          info_2:
              "To have up-to-date information of the users, let's create your digital business card on Janus too!",
          register: "Sign Up to Janus",
        },
      },
      profile: {
        create_page_title: "Create Profile",
        edit_page_title: "Edit Profile",
        view_page_title: "Digital Business Card",
        view_page_title_corporate: "Corporate Business Card Profile",
        add_profile_photo: "Add Profile Photo",
        add_company_logo: "Add Company Logo",
        add: "Add Profile",
        addMore: "Add more",
        background_color: "Background Color",
        background_color_description:
            "The digital business card will be visible to the people you share it with.",
        corporate_profile_alert:
            "The changes you make on your corporate business card profile will affect all business cards associated with your company.",
        corporate_main: "Company Profile",
        placeholders: {
          name: "Individual",
          first_name: "Sierra",
          last_name: "Garcia",
          title: "CEO",
          company: "Janus",
          email: "usejanus.com",
          phone: "+905070000000",
        },
        name: "Business Card Name",
        company_name: "Company Name",
        title: "Title",
        description: "About",
        description_placeholder:
            "In this field, you can add information about your company or billing details that you need to share regularly with your stakeholders.",
        link_text: "Link text (you can name your link)",
        visibility: {
          title: "Business Card Visibility",
          text: "Manage your business card information; decide which information you allow to be seen by people coming from search engines and non-logged-in users.",
        },
        address: {
          description:
              "It's important to include your zip code so that map applications can find your address more accurately.",
        },
        limit_premium_text:
            "To add extra profiles, you must buy the 'NetworKit Package'.",
        phone_limit_premium_text:
            "To add extra phones, you must buy the 'NetworKit Package'.",
        more_item_premium_text:
            "To benefit from <span>File and Date</span> features, you must buy the 'NetworKit Package'.",
        share: {
          email: "E-mail",
          qr: "QR Code",
          qr_text: "{qr_text_plain}, {qr_text_download} or {qr_text_link}",
          qr_text_plain: "Scan the QR code",
          qr_text_link: "Share the Profile Link",
          qr_text_download: "Download.",
          copied: "Link Copied!",
          limit_text: "*You can share with a maximum of 10 people at a time",
          info: "Who do you want to share your profile with?",
          success_text:
              "Your profile has been emailed to those you want to share with!",
        },
        preview: {
          text_1:
              "Every time you change your profile information, your email signature and virtual background are updated.",
          text_2:
              "Add your up-to-date email signature to your email address and expand your network! This way, your connections can view and share their updated digital business card information with you.",
          button_label: "Got it",
        },
        trash: {
          text: "Your Digital Business Card is the only card you can create in the free package. If you delete your business card, you will have to start from the profile creation step again.",
          confirm_text: "Are you sure you want to delete your business card?",
        },
        cancel: {
          text: "Are you sure you want to exit without saving your profile information?",
          back: "Go Back",
          confirm: "Yes, I'm sure",
        },
      },
      email_signature: {
        page_title: "Email Signature",
        head_title: "Email Signature",
        premium_text:
            "To create your email signature, you must buy the 'NetworKit Package'.",
        page_description:
            "Strengthen your network by creating your personalized email signature!",
        add: "Add Email Signature",
        copy: "Copy Your Signature",
        description:
            "You must copy your signature to complete your transactions.",
        notice: "Are you sure you copied your signature?",
        photo_logo: "Photo + Logo",
        logo: "Logo",
        text: "Text",
        creating_text: "Your signature is being created...",
        created_text: "Your signature has been created!",
        tabs: {
          title: "Email Signature Details",
          tab_1_title: "Mail signature background and format",
          tab_2_title: "Banner",
          tab_3_title: "Text Area",
        },
        noAccess: {
          text_1: "Only the admin can edit the email signature details.",
          text_2:
              "On the left, you can add the signature prepared by the admin to your email account!",
        },
        visibility: {
          title: "Mail Signature Visibility",
          text: "Manage your mail signature; decide which information you allow to be seen by people viewing your mail signature.",
          banner: "Banner",
          disclaimer: "Text Area",
        },
        add_image: "Add Image",
        format: "Format",
        add_image_description:
            "The area within the frame ({size}) will be uploaded. You can set the business card in portrait or landscape format and adjust the areas you want to see with the cropping mask.",
        hubspot: {
          title: "Add Email Signature to HubSpot",
          button_label: "Go to HubSpot",
          description:
            "To add a personalized signature, you must have HubSpot CRM installed on your account and have sales access.",
          step_1_text: "Copy your signature's HTML:",
          step_1_button: "Copy Signature",
          step_2_text: "Log in to your HubSpot account",
          step_3_text: "In your HubSpot account, click the settings icon in the main navigation bar.",
          step_4_text: "In the left sidebar menu, go to <strong>General</strong> then click the <strong>Email</strong> tab.",
          step_5_text: "In the Configuration section, select the <strong>Edit Signature</strong> option.",
          step_6_text: "Select the HTML option in the top right and paste the code into the text box.",
          step_7_text: "Click the Save button to save your signature.",
        },
        gmail: {
          title: "Add Email Signature to Gmail",
          button_label: "Go to Gmail",
          description:
            "To add a personalized signature, you must have Gmail installed on your account.",
          step_1_text: "Copy your signature's HTML:",
          step_1_button: "Copy Signature",
          step_2_text: "Log in to your Gmail account",
          step_3_text: "Go to Settings and click on your email address",
          step_4_text: "Go to the Signature settings section and enable the Mobile Signature option",
          step_5_text: "Paste the copied signature into the text box",
        },
        outlook: {
          title: "Add Email Signature to Outlook",
          button_label: "Go to Outlook",
          description:
            "To add a personalized signature, you must have Outlook installed on your account.",
             step_1_text: "Copy your signature's HTML:",
            step_1_button: "Copy Signature",
            step_2_text: "Log in to your Outlook account",
            step_3_text: "Click the settings icon and go to the Email signature section",
            step_4_text: "Check the box labeled Add signature",
            step_5_text: "Select Use my signature from the web or use a different signature",
            step_6_text: "Paste the copied code into the text box that appears",
            step_7_text: "Click the confirm icon in the top right to save",
        },
        apple: {
          title: "Add Email Signature to Apple Mail",
          button_label: "Go to Apple Mail",
          description:
            "To add a personalized signature, you must have Apple Mail installed on your account.",
          step_1_text: "Copy your signature's HTML:",
          step_1_button: "Copy Signature",
          step_2_text: "Log in to your Apple account",
          step_3_text: "Click the settings icon and go to the Email signature section",
          step_4_text: "Check the box labeled Add signature",
          step_5_text: "Select Use my signature from the web or use a different signature",
          step_6_text: "Paste the copied code into the text box that appears",
          step_7_text: "Click the confirm icon in the top right to save",
        },
        yahoo: {
          title: "Add Email Signature to Yahoo Mail",
          button_label: "Go to Yahoo Mail",
          description:
            "To add a personalized signature, you must have Yahoo Mail installed on your account.",
          step_1_text: "Copy your signature's HTML:",
          step_1_button: "Copy Signature",
          step_2_text: "Log in to your Yahoo account",
          step_3_text: "Click the settings icon and go to the Email signature section",
          step_4_text: "Check the box labeled Add signature",
          step_5_text: "Select Use my signature from the web or use a different signature",
          step_6_text: "Paste the copied code into the text box that appears",
          step_7_text: "Click the confirm icon in the top right to save",
        },
      },
      bg: {
        page_title: "Virtual Background",
        head_title: "Virtual Background",
        premium_text:
            "To create a virtual background, you must buy the 'NetworKit Package'.",
        download: {
          title: "Virtual Background Downloaded!",
          description:
              "Now you can add your virtual background to online platforms like Zoom, Microsoft Teams, etc.",
          how_button_label: "How to Add a Virtual Background?",
          button_label: "Download Virtual Background",
          text: "The QR code of your digital business card is located on your virtual background. Anyone who scans this code can view and download your Digital Business Card.",
          new: "Create New Virtual Background",
        },
        upload: {
          image_button_label: "Upload Image",
          button_label: "Upload Virtual Background",
          description:
              "The area within the frame (1920x1080 px) will be uploaded. You can set the areas you want to see with the cropping mask.",
        },
        done: {
          title: "Congratulations!",
          text_1: "Your new virtual background has been uploaded.",
          text_2:
              "The new virtual background you uploaded can be used by all members.",
          button_label: "Add New Virtual Background",
        },
        new: {
          title: "A new virtual background has been added!",
          text_1: "Give it a try.",
          button_label: "Okay",
        },
        mobile: {
          bold_text: "you can log in from a desktop or tablet.",
          text_1:
              "Sorry! The Virtual Background feature is not suitable for use on the phone. To use this feature, you can log into the app from a {bold_text}",
          text_2:
              "You can check out our blog post about the Virtual Meeting Background that will make you more memorable in meetings!",
          button_label: "Go to Blog Posts",
        },
      },
      stats: {
        title: "Statistics",
        premium_text:
            "To view statistics, you must buy the 'NetworKit Package'.",
        select_date: "Select Date",
        last_week_text: "Since Last Week",
        no_data_text: "No data to display.",
        view: "View",
        download: "Download",
        date: {
          today: "Today",
          yesterday: "Yesterday",
          last_7_days: "Last 7 Days",
          last_30_days: "Last 30 Days",
          this_month: "This Month",
          last_month: "Last Month",
          this_year: "This Year",
          last_year: "Last Year",
        },
        tabs: {
          tab_1: {
            title: "NetworKit Total",
            sub_tabs: {
              tab_1: {
                title: "Total Business Card Views",
                chart_title: "Views Chart",
                description:
                    "Business Card Views show the total number of visits from NetworKit products.",
              },
              tab_2: {
                title: "Total Business Card Downloads",
                chart_title: "Downloads Chart",
                description:
                    "Business Card Downloads show the total number of business card downloads from NetworKit products.",
              },
              tab_3: {
                title: "Efficiency Ratio",
                chart_title: "Efficiency Chart",
                description:
                    "The Efficiency Ratio is the ratio of total business card downloads to total visits from NetworKit products.",
              },
            },
          },
          tab_2: {
            title: "Janus Profile (Card)",
            sub_tabs: {
              tab_1: {
                title: "Janus Card Views",
                chart_title: "Views Chart",
                description:
                    "Business Card Views show the total number of visits from NetworKit products.",
              },
              tab_2: {
                title: "Janus Card Downloads",
                chart_title: "Downloads Chart",
                description:
                    "Business Card Downloads show the total number of business card downloads from NetworKit products.",
              },
              tab_3: {
                title: "Efficiency Ratio",
                chart_title: "Efficiency Chart",
                description:
                    "The Efficiency Ratio is the ratio of total business card downloads to total visits from NetworKit products.",
              },
            },
          },
          tab_3: {
            title: "Email Signature",
            sub_tabs: {
              tab_1: {
                title: "Email Signature Views",
                chart_title: "Views Chart",
                description:
                    "Email Signature Views show the total number of visits from NetworKit products.",
              },
              tab_2: {
                title: "Email Signature Downloads",
                chart_title: "Downloads Chart",
                description:
                    "Email Signature Downloads show the total number of email signature downloads from NetworKit products.",
              },
              tab_3: {
                title: "Efficiency Ratio",
                chart_title: "Efficiency Chart",
                description:
                    "The Efficiency Ratio is the ratio of total email signature downloads to total visits from NetworKit products.",
              },
            },
          },
          tab_4: {
            title: "Virtual Background",
            sub_tabs: {
              tab_1: {
                title: "Virtual Background Views",
                chart_title: "Views Chart",
                description:
                    "Virtual Background Views show the total number of visits from NetworKit products.",
              },
              tab_2: {
                title: "Virtual Background Downloads",
                chart_title: "Downloads Chart",
                description:
                    "Virtual Background Downloads show the total number of virtual background downloads from NetworKit products.",
              },
              tab_3: {
                title: "Efficiency Ratio",
                chart_title: "Efficiency Chart",
                description:
                    "The Efficiency Ratio is the ratio of total virtual background downloads to total visits from NetworKit products.",
              },
            },
          },
        },
        contribution: {
          title: "Contribution to Nature",
          description:
              "*The contribution of each business card interaction is calculated",
          liter: "Liter",
          item_1_title: "Amount of Oxygen Saved",
          item_2_title: "Amount of Energy Spent",
          item_3_title: "Amount of Water Saved",
          item_4_title: "Amount of Carbon Dioxide Emission Prevented",
        },
        search: {
          options: {
            teams: "All Teams",
            members: "All Members",
          },
          teams: {
            empty: {
              title: "You have not created a team yet",
              create: "Create Team",
            },
            member_count: "{count} Member",
            created_at_text: "Created on {date}!",
          },
          members: {
            name: "Name",
            title: "Title",
          },
          register_date_text: "Member of Janus since {date}!",
          view_profile: "View Profile",
          placeholder_members: "Search Member Name",
          placeholder_teams: "Search Team Name",
        },
        locations: {
          title: "Locations",
          location: "Location",
          value: "Interactions",
          view_more: "Show More",
        },
        interests: {
          title: "Interests",
          view_more: "Show More",
          interest: "Interest",
          value: "Interactions",
        },
        views: {
          description:
              "You can see how many people have viewed your digital business card anew or again on a weekly basis.",
          tab_1: "Viewer",
          tab_2: "New Viewer",
          tab_3: "Repeat Viewer",
        },
        devices: {
          title: "Devices",
        },
        genders: {
          title: "Genders",
        },
        age: {
          title: "Age Range",
        },
      },
      sidebar: {
        installation_progress: "Installation Progress",
        last_n_steps: "Last {n} Steps",
        complete_profile: "Complete your profile!",
        menu_item_digital_card: "Digital Business Card",
        menu_item_stats: "Statistics",
        profile_dot: "Edit Appearance",
        menu_item_email_signature: "Email Signature",
        menu_item_virtual_background: "Virtual Background",
        menu_item_members: "Members",
        menu_item_network: "Network Pool",
        menu_item_notifications: "Notifications",
        menu_item_settings: "Settings",
        menu_item_support: "Support Center",
        menu_item_logout: "Log Out",
      },
      team: {
        title: "Teams",
        add_team_button_label: "Add Team",
        confirm: "Confirm",
        add: {
          button_label: "Add Team",
          placeholder: "Design Team",
        },
        invite: {
          title: "Invite Teammates",
          text: "You can help create digital business cards for people in the organization by entering their email addresses.",
          copy_link: "Copy Link",
          invite: "Send",
          success_text_1: "Congratulations!",
          success_text_2: "The invitations have been emailed!",
          limit_error: {
            title: "Invitation Sending Limit Exceeded",
            text: "You have exceeded your invitation sending limit and cannot send new invitations.",
          },
        },
        membership: {
          label: "Membership Management",
          free: "Free",
          corporate: "Corporate",
          waiting: "Waiting",
          cancel: {
            text: "are you sure you want to cancel the <strong>corporate</strong> membership of the selected members?",
            button_label: "Yes",
            success: {
              title:
                  "We have received your corporate membership cancellation request!",
              text: "Your selected members will be removed from the corporate membership at the end of the current billing period.",
            },
          },
          upgrade: {
            text: "are you sure you want to upgrade the selected members to <strong>corporate</strong> membership?",
            button_label: "Yes",
            success: {
              title: "We have received your corporate membership request!",
              text: "We will contact you as soon as possible.",
            },
          },
        },
        role_assign: {
          label: "Assign Roles to Members",
          owner: "Owner",
          admin: "Administrator",
          member: "Member",
        },
        team_assign: {
          label: "Assign Team to Members",
          confirm: "Confirm",
          search_placeholder: "Assign Team",
        },
        reset: {
          label: "Reset Member Information",
        },
        delete: {
          title: "Delete Team",
          text: "Are you sure you want to delete the team named {name}?",
        },
        add_members: {
          title: "Select Members to Add to the Team",
          add: "Add",
          skip: "Later",
          search_placeholder: "Search Name or Title",
        },
        remove_members: {
          label: "Remove Members from Team",
          text: "Are you sure you want to delete the selected members from your team?",
        },
        reset_members: {
          label: "Reset Member Information",
          text: "Are you sure you want to reset the information of the selected members?",
        },
        filter: {
          title: "Filters",
          description: "Sorting Order",
          asc: "A to Z",
          desc: "Z to A",
          order_by: {
            name: "Name",
            id: "ID No",
          },
        },
        members: {
          title: "Members",
          no_team: "No Team",
          empty: {
            title: "No members",
            text: "You can invite members by clicking the ‘’+’’ button.",
          },
          search: {
            placeholder: "Search member, team, ID no...",
          },
          columns: {
            all: "All",
            name: "Name",
            membership: "Membership",
            id: "ID No",
            role: "Role",
            team: "Team",
          },
        },
        empty: {
          title: "No Team",
          text: "Click the ‘’Add Team’’ button to create a team.",
        },
        empty_team: {
          title: "No Member",
          text: "There are no members in this team yet, click the ‘’+’’ button to invite members.",
        },
      },
      network: {
        title: "Network Pool",
        tabs: {
          card: "Business Card",
          details: "Details",
          activities: "Activities",
        },
        groups: {
          all: "All Groups",
          create_button_label: "Create Group",
          edit: {
            title: "Edit Group Name",
            button_label: "Edit",
          },
          create: {
            title: "Create Group",
            button_label: "Create",
            placeholder: "Group Name",
          },
          delete: {
            title: "Delete Group",
            text: "Are you sure you want to delete the group named {name}?",
          },
          empty: {
            title: "No Group",
            text: "Click ‘’Add Group’’ to create a group.",
            text_2:
                "To create a group, you must add a connection to the network pool.",
          },
          add_networks: {
            title: "Select Connections to Add to the Group",
            skip: "Later",
            add: "Add",
            search_placeholder: "Search Name or Title",
          },
        },
        export: {
          title: "Export Your Connections",
          text: "The exported connections will be sent to {email} via email.",
          button_label: "Export",
        },
        empty: {
          text_1: "To add a new connection, you can press the",
          text_2: "button.",
        },
        columns: {
          all: "All",
          name: "Name",
          title: "Title",
          company: "Company",
          location: "Location",
          tags: "Tags",
          date: "Date",
          added: "Added by",
        },
        import: {
          title: "Import Your Connections",
          text: "You can easily transfer your connections from other platforms to the Janus Network Pool!",
          option_excel: "Import from Excel",
          button_label: "Import",
          choose_file: "Choose File",
          choose_file_description: "Select a csv file.",
          excel: {
            title: "Import from Excel",
            download_sample_file: "Download sample excel file",
            step_1:
                "Please enter the information of the people you want to add in the following excel file. In this file, all columns in the first row represent the titles of information that can be added about the person. Add a new person in each row. You don't have to fill in the information titles for each person. You can leave the fields you do not want to fill in empty.",
            step_2:
                "After adding the people, save your excel file by clicking save as and selecting the file type as .csv format.",
            step_3: "Upload the file you saved by clicking the button below.",
          },
          being: {
            title: "Your connections are being imported...",
          },
          fail: {
            text: "{text_1}, {text_2}",
            text_1:
                "Your connections could not be transferred to your network pool. Check your file and try again. You can click to download the correct file format.",
            text_2: "click here.",
          },
          success: {
            text: "Your connections have been successfully transferred to your network pool.",
          },
        },
        search: {
          placeholder: "Search name, title, company...",
        },
        remove_from_group: {
          text: "Are you sure you want to delete the selected connections from your group?",
        },
        remove: {
          content_single:
              "Do you want to delete the connection named {name} from your Network Pool?",
          content_plural:
              "Do you want to delete the connections named {names} from your Network Pool?",
          description:
              "The connection will only be deleted from your Network Pool. It can still be viewed by the admin in the common pool.",
          description_admin:
              "The connection will only be deleted from the company's common Network Pool. It will not be deleted from the team member who added it.",
        },
        add_to_group: {
          button_label: "Add to Group",
        },
        filter: {
          title: "Sort",
          order: {
            asc: "A to Z",
            desc: "Z to A",
          },
          order_by: {
            title: "Title",
            name: "Name",
            company: "Company",
            location: "Location",
            created_at: "Added Date",
          },
        },
        tags: {
          description:
              "You can add as many tags as you want to the connection named {name}. Tags are personal and only you can see them.",
          empty_text:
              "You have not added any tags yet. If you want to create a note, you can click on the (+) button above.",
          label: "Tags",
          edit_tags: "Edit Tags",
          input_placeholder: "Tag Name",
          recommended: "Recommended",
        },
        notes: {
          form: {
            title: "Title",
            content: "Description",
          },
          delete_all: "Delete All",
          archive_all: "Archive All",
          view_archive: "View Archive",
          description:
              "You can make notes here about the connection that you want to remember.",
          empty_text:
              "You haven't created a note yet. If you want to create a note, you can click on the (+) button or this area above.",
          label: "Notes",
          delete: {
            description:
                "Do you really want to delete the note about your connection {name}?",
            text: "If you click 'Delete', you will not be able to see your note again.",
          },
          archive: {
            description:
                "Do you really want to archive the note about your connection {name}?",
            button_label: "Yes, Archive",
          },
          unarchive: {
            description:
                "Do you want to unarchive the note about your connection {name}?",
            button_label: "Yes",
          },
        },
        reminders: {
          label: "Reminders",
          description:
              "You can make notes here about the connection that you want to remember.",
          empty_text:
              "You haven't created a reminder yet. If you want to create a reminder, you can click on the (+) button or this area above.",
          delete_all: "Delete All",
          archive_all: "Archive All",
          view_archive: "View Archive",
          date: "Date",
          time: "Time",
          place: "Place",
          reminder: "Reminder",
          priority: "Priority",
          past: "Past",
          add_guests: "Add Guests",
          notify: {
            text: "Do you want to send a notification to your guests about the reminder you created?",
            dont_notify: "Don't Send",
            notify: "Send",
          },
          slots: {
            three_hours: "Within 3 Hours",
            tomorrow: "Tomorrow",
            next_week: "Next Week",
            one_month: "Within 1 Month",
            three_months: "Within 3 Months",
          },
          recurring: {
            every_day: "Every Day",
            every_week: "Every Week",
            every_month: "Every Month",
            every_year: "Every Year",
            never: "Never Repeats",
          },
          places: {
            online: "Online Meeting",
            offline: "Face-to-Face",
          },
          reminders: {
            five_minutes: "5 Minutes Before",
            fifteen_minutes: "15 Minutes Before",
            twenty_minutes: "20 Minutes Before",
            thirty_minutes: "30 Minutes Before",
            one_hour: "1 Hour Before",
          },
          delete: {
            description:
                "Do you really want to delete the reminder about your connection {name}?",
            text: "If you click 'Delete', you will not be able to see your reminder again.",
          },
          unarchive: {
            description:
                "Do you want to unarchive the reminder about your connection {name}?",
            button_label: "Yes",
          },
        },
        stay_touch: {
          title: "How often do you want to stay in touch?",
          options: {
            weekly: {
              label: "Weekly",
              description: "",
              display_text: "Weekly, {day}",
            },
            monthly: {
              label: "Monthly",
              description: "",
              display_text: "Every month's {day}. day",
            },
            quarterly: {
              label: "Quarterly",
              description: "",
              display_text: "Every quarter's {day}. day",
            },
            yearly: {
              label: "Yearly",
              description: "",
              display_text: "Every year's {month} month's {day}. day",
            },
            auto: {
              label: "Automatic",
              description: "Janus will choose the time on your behalf.",
            },
            never: {
              label: "Never",
              description: "I don't want to stay in touch.",
              display_text: "Never",
            },
          },
        },
        lead: "Connection Form",
        manuel: "Manual",
        add_link: "Add Link",
        add_multiple_links: "Add Multiple Links",
      },
      archived: {
        title: "Archived",
        notes: {
          title: "Notes",
          description:
              "You can see the archived notes about your connections here and you can restore any notes you want.",
          empty_text: "You don't seem to have any archived notes yet.",
        },
        reminders: {
          title: "Reminders",
          description:
              "You can see the archived reminders about your connections here and you can restore any reminders you want.",
          empty_text: "You don't seem to have any archived reminders yet.",
        },
        delete_all: "Delete All",
        unarchive_all: "Unarchive All",
      },
      settings: {
        title: "Account Settings",
        edit_account: {
          account_name_title: "What is your account name?",
          account_name_description:
              "*You can name it professional, business, personal, etc.",
          add_profile_photo: "Add Profile Photo",
        },
        preferences: {
          title: "Site Preferences",
          language: "Language",
          language_text: "Select the language you will use in Janus",
        },
        archived: {
          title: "Archived",
        },
        match: {
          title: "Janus Card",
          content_title: "Match My Janus Card with My Profile",
          content_text:
              "If you want to match your Janus Card with your profile, just enter the ID number found on the back of the card (JNXXXX-XXXX) into the box that appears when you click the button below.",
          step_1: "Turn the back of your Janus Card.",
          step_2: "Take note of the code in the form of JNXXXX-XXXX.",
          step_3: "Click the Match button.",
          step_4: "Select your profile and enter the number into the box.",
          button_label: "Match",
          modal: {
            title: "Match My Janus Card with My Profile",
            text: "Select the profile you want to match your Janus Card with and write the ID number in the form of JNXXXX-XXXX into the box.",
            profiles: "Profiles",
            id: "ID Number",
            button_label: "Match",
            success_button_label: "We Received Your Request",
            confirm: {
              text: "Are you sure you want to match your current digital business card with the Janus Card you already have? Once this is done, your currently selected digital business card will be transferred to the business card you want to match.<strong>Your current digital business card will be reset and this action cannot be undone.</strong>",
            },
            already_matched: {
              try_again_button_label: "Try Again",
              button_label: "Create Review Request",
              success_button_label: "We Received Your Request",
            },
          },
        },
        account_management: {
          title: "Account Management",
          email: {
            title: "Change your e-mail address",
            current_emails: "Current E-mail Addresses",
            new_email: "Add a new e-mail address",
            enter_new_email: "Enter your new e-mail address",
            add: "Add",
          },
          password: {
            title: "Change your password",
            current_password: "Current Password",
            new_password: "New Password",
            confirm_new_password: "Confirm New Password",
            change: "Change Password",
            success_text: "Your password has been successfully created!",
          },
          freeze_account: {
            title: "Freeze My Account",
            text_1:
                "If you want to temporarily pause your Janus profile and activities, you can freeze your account.",
            text_2: "Tell us why you want to freeze your account. (optional)",
            other: "Other",
            button_label: "Freeze My Account",
            other_placeholder:
                "You can add the reason for freezing your account.",
            success_text:
                "We have received your request to freeze your account. Don't forget to check your email to continue your transactions.",
          },
          delete_account: {
            title: "Delete My Account",
            button_label: "Delete My Account",
            text: "{text1} {text2} {text3}",
            text_1: "If you delete your account, you will permanently lose",
            text_2:
                "all Janus profiles, statistics, and data associated with the account",
            text_3: ".",
            confirm_password_text:
                "For your security, you must enter your password before making this change. Your account will be deleted within {day} days. During this period, you can reactivate your account by logging in with your current information.",
            success_text:
                "We have received your request to delete your account. Don't forget to check your email to continue your transactions.",
            agree: "Yes, I confirm",
          },
          confirm_password: {
            text: "For your security, you must enter your password before making this change.",
            placeholder: "Enter your password",
          },
          billing_information: {
            title: "Billing Information",
            individual: "Individual",
            corporate: "Corporate",
          },
        },
        packages: {
          title: "Packages",
          monthly: "Monthly",
          yearly: "Yearly",
          current_package_text: "You are currently using this package.",
          choose: "Choose",
          cancel: "Cancel My Subscription",
          buy: "Buy",
          total: "Total",
          billing_not_active:
              "You must enter your billing information to proceed.",
          free: {
            title: "Free Package",
            features: {
              title: "Free package features",
              item_1: "Have a Basic Digital Business Card Profile",
              item_2:
                  "Share your business card via QR code, mail, SMS, or link",
              item_3: "View your Profile Statistics",
            },
          },
          card: {
            title: "Don't Miss Your Special Discount!",
            content:
                "You have the Networkit package. This allows you to get the Janus Card with %{discount} discount. Match your account's email address during the purchasing step and benefit from the discount!",
            subtitle: "Janus Card",
          },
          plus: {
            title: "NetworKit Plus",
            content:
                "Benefit from the discounted price by getting the Networkit package and Janus Card at the same time.",
            item_1_title: "NetworKit Package",
            item_2_title: "Janus Card",
          },
          pairing: {
            title: "Don't Forget to Match Your Janus Card with Your Profile!",
            content:
                "You can copy the ID number below and add it to the relevant field on the Janus Card purchase page. Thus, you will have matched your digital business card profile with your Janus Card.",
            copy: "Copy",
            copied: "Your ID number has been copied!",
            continue: "Continue",
          },
        },
      },
      membership: {
        success: {
          title: "Thank You!",
          text: "Your payment has been successfully received. Refresh the page to update your account.",
          button_label: "Okay",
        },
        error: {
          title: "Error!",
          text: "An error occurred while receiving your payment. Please try again.",
          button_label: "Try Again",
        },
        cancel: {
          title: "How Will Your Networking Experience Change?",
          items: {
            1: "<span>Virtual Background</span> and <span>Email Signature</span> features will no longer be available to you.",
            2: "You will not be able to benefit from the <span>Advanced Digital Business Card</span> Profile.",
            3: "You will no longer be able to view your <span>network statistics</span> in detail.",
          },
          continue: "Continue with the cancellation process",
          finish: "Complete the cancellation process",
          confirm_password_description:
              "You must enter your password to proceed with the cancellation.",
          confirm_password_content:
              "The package cancellation will take effect on {expire_date}, when the current billing period ends. You can restart your package at any time.",
          confirmed_title: "We've Cancelled Your Subscription",
          confirmed_text:
              "Email confirmation will be sent to {email}. You can continue to use your package until {expire_date}.",
          confirmed_questions_title: "Why did you cancel your package?",
          confirmed_questions_placeholder:
              "You can add the reason for canceling your package.",
          success_title: "Thank You!",
          success_text:
              "It was nice to see you as a NetworKit user, we hope you return soon.",
          success_button_label: "Understood",
        },
      },
      notifications: {
        title: "Notifications",
        empty_text_1: "There are no notifications right now!",
        empty_text_2: "You can view upcoming notifications here.",
        show_more: "Show More",
      },
      support: {
        title: "Support Center",
        form_title: "Contact Support",
        form: {
          email: "E-Mail",
          message: "Message",
        },
      },
    },
  },
});

export default i18n;
</script>
