<template>
  <div class="members-body-content">
    <div class="members-body-content-item there-are-members">
      <div class="members-body-content-item-block">
        <div class="members-body-content-item-block-head">
          <template v-if="currentWorkspace?.role !== 'member'">
            <div class="members-body-content-item-block-head-checkbox">
              <div class="form-item-checkbox">
                <input
                  id="checkbox"
                  type="checkbox"
                  v-model="isSelectAll"
                  @change="selectAllMembers"
                />
                <label for="checkbox">{{
                  $t("team.members.columns.all")
                }}</label>
              </div>
            </div>

            <Bar :is-active="selectedMembers.length" :mode="props.barMode" />
          </template>
        </div>
        <div class="members-body-content-item-block-list">
          <div class="members-body-content-item-block-list-head">
            <div
              class="members-body-content-item-block-list-head-name"
              @click="setOrderBy('name')"
              v-if="!props.excludeColumns.includes('name')"
            >
              <span
                :class="[
                  {
                    ascending:
                      selectedOrderBy == 'name' && selectedOrder == 'asc',
                    descending:
                      selectedOrderBy == 'name' && selectedOrder == 'desc',
                  },
                ]"
              >
                {{ $t("team.members.columns.name") }}</span
              >
            </div>
            <div
              class="members-body-content-item-block-list-head-subscription"
              v-if="!props.excludeColumns.includes('membership')"
            >
              <span class="no-sorting">
                {{ $t("team.members.columns.membership") }}
              </span>
            </div>
            <div
              class="members-body-content-item-block-list-head-team"
              v-if="!props.excludeColumns.includes('teams')"
            >
              <span class="no-sorting">{{
                $t("team.members.columns.team")
              }}</span>
            </div>
            <div
              class="members-body-content-item-block-list-head-id"
              @click="setOrderBy('id')"
              v-if="!props.excludeColumns.includes('id')"
            >
              <span
                :class="[
                  {
                    ascending:
                      selectedOrderBy == 'id' && selectedOrder == 'asc',
                    descending:
                      selectedOrderBy == 'id' && selectedOrder == 'desc',
                  },
                ]"
              >
                {{ $t("team.members.columns.id") }}</span
              >
            </div>
            <div
              class="members-body-content-item-block-list-head-role"
              v-if="!props.excludeColumns.includes('role')"
            >
              <span class="no-sorting">{{
                $t("team.members.columns.role")
              }}</span>
            </div>
          </div>
          <div class="members-body-content-item-block-list-content">
            <ListItem
              v-for="member in notEmptyMembers"
              :key="member.id"
              :item="member"
              :exclude-columns="props.excludeColumns"
            />
          </div>
          <div
            class="members-body-content-item-block-list-content"
            v-if="emptyMembers.length"
          >
            <ListItem
              v-for="member in emptyMembers"
              :key="member.id"
              :item="member"
              :exclude-columns="props.excludeColumns"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, defineProps } from "vue";
import store from "@/store";
import ListItem from "./ListItem.vue";
import Bar from "../Common/Bar.vue";

const selectedOrderBy = computed(() => store.getters["team/selectedOrderBy"]);
const selectedOrder = computed(() => store.getters["team/selectedOrder"]);

const currentWorkspace = computed(() => store.getters["account/workspace"]);
const isSelectAll = ref(false);
const props = defineProps({
  members: {
    type: Array,
    required: false,
  },
  excludeColumns: {
    type: Array,
    required: false,
    default: () => [],
  },
  barMode: {
    type: String,
    required: false,
    default: "members",
  },
});

const selectedMembers = computed(() => {
  return store.getters["team/selectedMembers"];
});

const members = computed(() => {
  return props.members ? props.members : store.getters["member/items"];
});

const notEmptyMembers = computed(() => {
  return members.value.filter((member) => member.email !== "");
});

const emptyMembers = computed(() => {
  return members.value.filter((member) => member.email === "");
});

const selectAllMembers = () => {
  if (
    selectedMembers.value.length === members.value.length &&
    !isSelectAll.value
  ) {
    store.dispatch("team/removeAllSelectedMembers");
  } else {
    store.dispatch("team/selectAllMembers", members.value);
  }
};

const setOrderBy = (orderBy) => {
  store.commit("team/setSelectedOrderBy", orderBy);
  setOrder();
};

const setOrder = () => {
  const currentOrder = store.getters["team/selectedOrder"];

  store.commit(
    "team/setSelectedOrder",
    currentOrder === "asc" ? "desc" : "asc"
  );
};

onMounted(() => {
  if (!props.members) {
    // store.dispatch("member/items");
  }
});
</script>
