<template>
  <div
    :class="[
      'profile-creation-body-content-item',
      { 'file-area': item.value.type === 'file' },
    ]"
  >
    <div class="profile-creation-body-content-item-label">
      <span>{{ item.value.label }}</span>
    </div>
    <div class="profile-creation-body-content-item-form-block">
      <div class="profile-creation-body-content-item-form-block-item">
        <div class="form-item-icon" v-if="item.value.type !== 'file'">
          <span class="h-40">
            <img :src="item.value.icon" :alt="item.value.label" />
          </span>
          <Field
            :name="
              item.value.type !== 'date'
                ? `more_items[${props.idx}].url`
                : `more_items[${props.idx}].date`
            "
            :errors="props.errors"
            ref="input"
            :placeholder="item.value.placeholder"
            :mask="item.value.mask"
          >
            <a
              class="form-item-action icon-cansel-gray"
              href="javascript:;"
              @click="clearInput"
            ></a>
          </Field>
        </div>
        <div
          class="profile-creation-body-content-item-form-block-item-file"
          v-else
        >
          <div
            class="dropzone dz-clickable"
            id="more-item-file"
            ref="moreItemFile"
          >
            <div class="dropzone-image-edit">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V9"
                  stroke="#167FFC"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7 14L12 9L17 14"
                  stroke="#167FFC"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 9V21"
                  stroke="#167FFC"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="dz-message" data-dz-message="">
              <p>
                {{ item.value.placeholder }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="profile-creation-body-content-item-form-block-item">
        <div class="form-item h-40">
          <Field
            :name="`more_items[${props.idx}].text`"
            :errors="props.errors"
            :placeholder="$t('profile.link_text')"
          />
        </div>
      </div>
      <div
        class="profile-creation-body-content-item-form-block-item"
        v-if="error"
      >
        <div class="form-item-error-text">
          {{ error }}
        </div>
      </div>
    </div>
    <div class="delete-item-button" @click="deleteItem">
      <button type="button">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 12H19"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, onMounted, ref } from "vue";
import Field from "../inputs/Field.vue";
import store from "@/store";
import Dropzone from "dropzone";
import authHeader from "@/services/AuthHeader";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  idx: {
    type: Number,
    required: true,
  },
  setFieldValue: {
    type: Function,
    required: true,
  },
  errors: {
    type: Object,
    required: true,
  },
  remove: {
    type: Function,
    required: true,
  },
});

const error = computed(() => {
  return props.errors[`more_items[${props.idx}].url`];
});

const deleteItem = () => {
  props.remove(props.idx);
};

const clearInput = () => {
  props.setFieldValue(`more_items[${props.idx}].url`, "");
};

const moreItemFile = ref(null);

onMounted(() => {
  const moreType = store.getters["defines/get_more_type"](
    props.item.value.type
  );

  props.item.value = {
    ...props.item.value,
    ...moreType,
  };

  // dropzone
  if (moreItemFile.value) {
    let dropzone = new Dropzone(moreItemFile.value, {
      url: process.env.VUE_APP_API_DOMAIN + "/misc/upload_file",
      headers: authHeader(),
      maxFiles: 1,
      maxThumbnailFilesize: 1,
      parallelUploads: 1,
      uploadMultiple: false,
      acceptedFiles: "image/*,application/pdf",
      addRemoveLinks: false,
      init: function () {
        const self = this;
        const file = props.item.value.file;

        if (file) {
          const fileMockFile = {
            name: file.filename,
            size: file.filesize,
            type: file.mime_type,
          };

          self.emit("addedfile", fileMockFile);
          self.emit("success", fileMockFile);
          store.commit("profile/set_file_input", file.unique_id);

        }
      },
      success: function (file, response) {
        if (response) {
          const uniqueId = response.uniq_id;

          store.commit("profile/set_file_input", uniqueId);
        }
      },
    });
  }
});
</script>