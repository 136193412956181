<template>
  <div :class="['email-signature-network-content is-show', tab.colorClass]">
    <a
      href="javascript:;"
      class="close-network-content"
      @click="setModalStatus('email_service', false)"
    >
      <img src="/img/static/email-signature-popup-close-icon.svg" />
    </a>
    <div class="title">
      <h5>
        {{ tab.title }}
      </h5>
    </div>
    <div class="email-signature-network-content-comment">
      <p>
        {{ tab.description }}
      </p>
    </div>
    <div class="email-signature-network-content-swiper">
      <Slides :active-tab="tab" :is-modal="true"/>
      <div class="email-signature-network-content-swiper-footer">
        <div class="swiper-btn button-prev">
          <i>
            <svg
              width="12"
              height="22"
              viewBox="0 0 12 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 20L1.5 11L10.5 2"
                stroke="#E3E3E3"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </i>
        </div>
        <div class="swiper-btn button-next">
          <i>
            <svg
              width="13"
              height="22"
              viewBox="0 0 13 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 2L11 11L2 20"
                stroke="#E3E3E3"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </i>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="email-signature-network-content-btn">
      <a
        href="javascript:;"
        class="btn btn-sm btn-round-4 btn-400"
        @click="goToCopy"
      >
        {{ tab.buttonLabel }}
      </a>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed, defineProps } from "vue";
import domtoimage from "dom-to-image";
import useProfile from "@/composables/profile";
import store from "@/store";
import Slides from "../Slides.vue";
import { MembershipLevelEnum } from "@/enums";
import {
  setModalStatus,
  getModalData,
  copyClipboardElement,
  getProxyURL,
  getWithProxyURL,
} from "@/utils/Helpers";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const modalData = getModalData("email_service");
const tab = ref(modalData.tab);
const emailSignatureSrc = ref(null);
const me = computed(() => store.getters["account/me"]);
const goToCopy = () => {
  setModalStatus("email_service", false);
  setModalStatus("email_copy", true, tab.value);
};

const copy = () => {
  copyClipboardElement("email-copy-view");
};

</script>
