<template>
  <div class="statistics-block-head-search d-block" v-if="members && teams">
    <div class="statistics-search">
      <div class="statistics-search-input">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
            stroke="#979797"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.5 17.5L13.875 13.875"
            stroke="#979797"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <input
          type="text"
          :placeholder="
            selectedOption.key === 'members'
              ? $t('stats.search.placeholder_members')
              : $t('stats.search.placeholder_teams')
          "
          @input="search"
          ref="searchInput"
        />
      </div>
      <div
        class="statistics-search-dropdown"
        @click="toggleDropdown"
        v-click-away="onClickAway"
      >
        <ul>
          <li :class="{ 'is-show': isOpenDropdown }">
            {{ selectedOption.label }}
            <svg
              width="12"
              height="7"
              viewBox="0 0 12 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L6 6L11 1"
                stroke="#979797"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <ul v-if="dropdownOptions">
              <li
                v-for="option in dropdownOptions"
                :key="option.key"
                @click="selectOption(option)"
              >
                {{ option.label }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <Members
      v-if="canOpenBlock && selectedOption.key === 'members'"
      :items="filteredItems"
    />
    <Teams
      v-if="canOpenBlock && selectedOption.key === 'teams'"
      :items="filteredItems"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import store from "@/store";
import { getLetters } from "@/utils/Helpers";
import i18n from "@/utils/i18n.vue";
import Members from "./Search/Members";
import Teams from "./Search/Teams";

const dropdownOptions = [
  {
    key: "members",
    label: i18n.global.t("stats.search.options.members"),
  },
  {
    key: "teams",
    label: i18n.global.t("stats.search.options.teams"),
  },
];

const searchInput = ref(null);
const isOpenDropdown = ref(false);
const selectedOption = ref(dropdownOptions[0]);
const canOpenBlock = ref(false);
const filteredItems = ref([]);
const membersSalt = ref([]);
const teamsSalt = ref([]);
const isOpenDetail = ref(null);
const detailType = ref(null);
const selectedItem = computed(() => store.getters["stats/selectedItem"]);

const members = computed(() => {
  const members = store.getters["member/items"];

  if (
    (members && members.length && !membersSalt.value) ||
    !membersSalt.value.length
  ) {
    membersSalt.value = members;
  }

  return members;
});

const teams = computed(() => {
  const teams = store.getters["team/items"];

  if (teams.length && !teamsSalt.value) {
    teamsSalt.value = teams;
  }

  return teams;
});

const toggleDropdown = () => {
  isOpenDropdown.value = !isOpenDropdown.value;
};

const selectOption = (option) => {
  selectedOption.value = option;

  search();
};

const search = () => {
  const value = searchInput.value.value;
  const selected = selectedOption.value.key;

  if (value.length) {
    canOpenBlock.value = true;
  } else {
    canOpenBlock.value = false;
  }

  if (selectedOption.value.key === "members") {
    filteredItems.value = membersSalt.value.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
  } else {
    filteredItems.value = teams.value.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
  }
};

const onClickAway = () => {
  isOpenDropdown.value = false;
};
</script>
