<template>
  <div class="spinner-container" v-if="!item.acf || loading">
    <div class="loading-spinner"></div>
  </div>
  <template v-else>
    <metainfo>
      <template v-slot:title="{ content }">
        {{ displayName() }}
      </template>
    </metainfo>
    <ThirdLayout class="main-business-card-view">
      <div class="business-card-view">
        <div class="business-card-view-content">
          <div
            :class="[
              'business-card-view-content-head',
              {
                'is-profile-img-none': !profilePhoto,
                'is-full-img-none': !profilePhoto && !companyLogo,
              },
            ]"
          >
            <div
              class="business-card-view-content-head-profile"
              v-if="profilePhoto"
            >
              <img
                :src="profilePhoto.url"
                :alt="displayName()"
                id="profilePhoto"
              />
            </div>
            <div
              class="business-card-view-content-head-colleague"
              v-if="companyLogo"
            >
              <img :src="companyLogo.url" :alt="item.acf.company_name" />
            </div>
            <div class="business-card-view-content-head-info">
              <div class="business-card-view-content-head-info-title">
                <h4>{{ displayName() }}</h4>
              </div>
              <div class="business-card-view-content-head-info-colleague">
                <h5>
                  {{ getValue("company_name") }}
                </h5>
                <span>
                  {{ getValue("title") }}
                </span>
              </div>
            </div>
            <div
              class="business-card-view-content-head-description"
              v-if="getValue('description')"
              v-html="getValue('description')"
            ></div>
            <div class="business-card-view-content-head-buttons">
              <div class="business-card-view-content-head-download">
                <button type="button" @click="downloadCard">
                  {{ $t("public_profile.download_card") }}
                </button>
              </div>
              <div class="business-card-view-content-head-download share-info">
                <button
                  type="button"
                  @click="
                    setModalStatus('public_profile_share', true, {
                      display_name: displayName(),
                      item,
                    })
                  "
                >
                  {{ $t("public_profile.share_info") }}
                </button>
              </div>
            </div>
          </div>
          <div class="business-card-view-content-body">
            <b-tabs content-class="mt-3">
              <b-tab active>
                <template #title>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
                      stroke="#979797"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
                      stroke="#979797"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {{ $t("public_profile.basic_info") }}
                </template>
                <div class="business-card-profile-content-body-info">
                  <template v-if="phones && preferences.visibility.phone">
                    <div
                      class="business-card-profile-content-body-info-item is-fax"
                      v-for="(phone, index) in phones.filter(
                        (item) => item.type == 'self'
                      )"
                      :key="index"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22 16.92V19.92C22.0011 20.1985 21.9441 20.4741 21.8325 20.7293C21.7209 20.9845 21.5573 21.2136 21.3521 21.4018C21.1468 21.5901 20.9046 21.7335 20.6407 21.8227C20.3769 21.9119 20.0974 21.945 19.82 21.92C16.7428 21.5856 13.787 20.5341 11.19 18.85C8.77382 17.3146 6.72533 15.2661 5.18999 12.85C3.49997 10.2412 2.44824 7.27097 2.11999 4.17997C2.095 3.90344 2.12787 3.62474 2.21649 3.3616C2.30512 3.09846 2.44756 2.85666 2.63476 2.6516C2.82196 2.44653 3.0498 2.28268 3.30379 2.1705C3.55777 2.05831 3.83233 2.00024 4.10999 1.99997H7.10999C7.5953 1.9952 8.06579 2.16705 8.43376 2.48351C8.80173 2.79996 9.04207 3.23942 9.10999 3.71997C9.23662 4.68004 9.47144 5.6227 9.80999 6.52997C9.94454 6.8879 9.97366 7.27689 9.8939 7.65086C9.81415 8.02482 9.62886 8.36809 9.35999 8.63998L8.08999 9.90997C9.51355 12.4135 11.5864 14.4864 14.09 15.91L15.36 14.64C15.6319 14.3711 15.9751 14.1858 16.3491 14.1061C16.7231 14.0263 17.1121 14.0554 17.47 14.19C18.3773 14.5285 19.3199 14.7634 20.28 14.89C20.7658 14.9585 21.2094 15.2032 21.5265 15.5775C21.8437 15.9518 22.0122 16.4296 22 16.92Z"
                          stroke="#167FFC"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <span>{{ phone.phone_type.label }}</span>
                      <a :href="'tel:' + phone.phone_number">
                        {{ phone.phone_number }}
                      </a>
                    </div>
                  </template>

                  <template v-if="emails.length">
                    <div
                      class="business-card-profile-content-body-info-item is-mail"
                      v-for="(item, index) in emails.filter(
                        (item) => item.type == 'self'
                      )"
                      :key="index"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
                          stroke="#167FFC"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M22 6L12 13L2 6"
                          stroke="#167FFC"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                    </div>
                  </template>

                  <template v-if="websites">
                    <div
                      class="business-card-profile-content-body-info-item is-website"
                      v-for="(websiteItem, index) in websites.filter(
                        (item) => item.type == 'self'
                      )"
                      :key="index"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#167FFC"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M2 12H22"
                          stroke="#167FFC"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z"
                          stroke="#167FFC"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <a :href="websiteItem.url" target="_blank">
                        {{ websiteItem.link_text || websiteItem.url }}
                      </a>
                    </div>
                  </template>

                  <div
                    class="business-card-profile-content-body-info-item is-adress"
                    v-if="address && preferences.visibility.address"
                  >
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style="width: 28px; height: 28px"
                    >
                      <path
                        d="M24.5 11.667C24.5 19.8337 14 26.8337 14 26.8337C14 26.8337 3.5 19.8337 3.5 11.667C3.5 8.88222 4.60625 6.2115 6.57538 4.24237C8.54451 2.27324 11.2152 1.16699 14 1.16699C16.7848 1.16699 19.4555 2.27324 21.4246 4.24237C23.3938 6.2115 24.5 8.88222 24.5 11.667Z"
                        stroke="#167FFC"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14 15.167C15.933 15.167 17.5 13.6 17.5 11.667C17.5 9.734 15.933 8.16699 14 8.16699C12.067 8.16699 10.5 9.734 10.5 11.667C10.5 13.6 12.067 15.167 14 15.167Z"
                        stroke="#167FFC"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <a
                      :href="'https://maps.google.com/?q=' + address"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>{{ address }}</span>
                    </a>
                  </div>
                </div>
              </b-tab>
              <b-tab
                v-if="
                  profileType === 'corporate' &&
                  (corporatePhones.length ||
                    corporateEmails.length ||
                    corporateWebsites.length)
                "
              >
                <template #title>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                      stroke="#979797"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2 12H22"
                      stroke="#979797"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z"
                      stroke="#979797"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {{ $t("public_profile.corporate_info") }}
                </template>
                <div class="business-card-profile-content-body-info">
                  <template v-if="phones && preferences.visibility.phone">
                    <div
                      class="business-card-profile-content-body-info-item is-fax"
                      v-for="(phone, index) in corporatePhones"
                      :key="index"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22 16.92V19.92C22.0011 20.1985 21.9441 20.4741 21.8325 20.7293C21.7209 20.9845 21.5573 21.2136 21.3521 21.4018C21.1468 21.5901 20.9046 21.7335 20.6407 21.8227C20.3769 21.9119 20.0974 21.945 19.82 21.92C16.7428 21.5856 13.787 20.5341 11.19 18.85C8.77382 17.3146 6.72533 15.2661 5.18999 12.85C3.49997 10.2412 2.44824 7.27097 2.11999 4.17997C2.095 3.90344 2.12787 3.62474 2.21649 3.3616C2.30512 3.09846 2.44756 2.85666 2.63476 2.6516C2.82196 2.44653 3.0498 2.28268 3.30379 2.1705C3.55777 2.05831 3.83233 2.00024 4.10999 1.99997H7.10999C7.5953 1.9952 8.06579 2.16705 8.43376 2.48351C8.80173 2.79996 9.04207 3.23942 9.10999 3.71997C9.23662 4.68004 9.47144 5.6227 9.80999 6.52997C9.94454 6.8879 9.97366 7.27689 9.8939 7.65086C9.81415 8.02482 9.62886 8.36809 9.35999 8.63998L8.08999 9.90997C9.51355 12.4135 11.5864 14.4864 14.09 15.91L15.36 14.64C15.6319 14.3711 15.9751 14.1858 16.3491 14.1061C16.7231 14.0263 17.1121 14.0554 17.47 14.19C18.3773 14.5285 19.3199 14.7634 20.28 14.89C20.7658 14.9585 21.2094 15.2032 21.5265 15.5775C21.8437 15.9518 22.0122 16.4296 22 16.92Z"
                          stroke="#167FFC"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <span>{{ phone.phone_type.label }}</span>
                      <a :href="'tel:' + phone.phone_number">
                        {{ phone.phone_number }}
                      </a>
                    </div>
                  </template>

                  <template v-if="emails.length">
                    <div
                      class="business-card-profile-content-body-info-item is-mail"
                      v-for="(item, index) in corporateEmails"
                      :key="index"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
                          stroke="#167FFC"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M22 6L12 13L2 6"
                          stroke="#167FFC"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <a :href="`mailto:${item.email}`">{{ item.email }}</a>
                    </div>
                  </template>

                  <template v-if="websites">
                    <div
                      class="business-card-profile-content-body-info-item is-website"
                      v-for="(websiteItem, index) in corporateWebsites"
                      :key="index"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#167FFC"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M2 12H22"
                          stroke="#167FFC"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z"
                          stroke="#167FFC"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <a :href="websiteItem.url" target="_blank">
                        {{ websiteItem.link_text || websiteItem.url }}
                      </a>
                    </div>
                  </template>

                  <template v-if="true">
                    <div
                      v-for="item in corporateSocialMedias"
                      :key="item.social_media_type"
                      :class="[
                        'business-card-profile-content-body-social-item',
                        'is-' + item.social_media_type,
                      ]"
                    >
                      <template
                        v-if="
                          !['date', 'file'].includes(item.social_media_type)
                        "
                      >
                        <a :href="item.url" target="_blank">
                          <i
                            :class="['svg-color-state-' + item.svg_color_state]"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              :content="item.type_label"
                              v-tippy
                              v-html="item.svg_code"
                            ></svg>
                          </i>

                          {{ item.link_text }}
                        </a>
                      </template>

                      <template v-else-if="item.social_media_type === 'date'">
                        <a>
                          <i
                            :class="['svg-color-state-' + item.svg_color_state]"
                          >
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              :content="item.type_label"
                              v-tippy
                              v-html="item.svg_code"
                            ></svg>
                          </i>
                          {{ item.date }}
                          <span v-if="item.link_text"
                            >({{ item.link_text }})</span
                          >
                        </a>
                      </template>

                      <template v-else>
                        <a :href="item.file.url" target="_blank">
                          <i
                            :class="['svg-color-state-' + item.svg_color_state]"
                          >
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              :content="item.type_label"
                              v-tippy
                              v-html="item.svg_code"
                            ></svg>
                          </i>

                          {{ item.link_text || item.type_label }}
                        </a>
                      </template>
                    </div>
                  </template>
                </div>
              </b-tab>
              <b-tab
                v-if="
                  individualSocialMedias?.length && preferences.visibility.social_media
                "
              >
                <template #title>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                      stroke="#979797"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2 12H22"
                      stroke="#979797"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z"
                      stroke="#979797"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  {{ $t("public_profile.additional_info") }}
                </template>
                <div class="business-card-profile-content-body-social">
                  <div
                    v-for="item in individualSocialMedias"
                    :key="item.social_media_type"
                    :class="[
                      'business-card-profile-content-body-social-item',
                      'is-' + item.social_media_type,
                    ]"
                  >
                    <template
                      v-if="!['date', 'file'].includes(item.social_media_type)"
                    >
                      <a :href="item.url" target="_blank">
                        <i :class="['svg-color-state-' + item.svg_color_state]">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            :content="item.type_label"
                            v-tippy
                            v-html="item.svg_code"
                          ></svg>
                        </i>

                        {{ item.link_text }}
                      </a>
                    </template>

                    <template v-else-if="item.social_media_type === 'date'">
                      <a>
                        <i :class="['svg-color-state-' + item.svg_color_state]">
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            :content="item.type_label"
                            v-tippy
                            v-html="item.svg_code"
                          ></svg>
                        </i>
                        {{ item.date }}
                        <span v-if="item.link_text"
                          >({{ item.link_text }})</span
                        >
                      </a>
                    </template>

                    <template v-else>
                      <a :href="item.file.url" target="_blank">
                        <i :class="['svg-color-state-' + item.svg_color_state]">
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            :content="item.type_label"
                            v-tippy
                            v-html="item.svg_code"
                          ></svg>
                        </i>

                        {{ item.link_text || item.type_label }}
                      </a>
                    </template>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
          <Download v-if="getModalStatus('public_profile_download')" />
          <Share v-if="getModalStatus('public_profile_share')" />
          <Register v-if="getModalStatus('public_profile_register')" />
        </div>
        <div
          class="business-card-view-register"
          v-if="item.acf.workspace_settings.show_brand"
        >
            <button type="button" @click="navigate">
              {{ $t("public_profile.create_profile") }}
            </button>
        </div>
      </div>
    </ThirdLayout>
  </template>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import ThirdLayout from "@/layouts/ThirdLayout.vue";
import { useRoute, useRouter } from "vue-router";
import { getModalStatus, setModalStatus } from "@/utils/Helpers";
import store from "@/store";
import useProfile from "@/composables/profile";
import Download from "@/components/Public/Profile/Modals/Download.vue";
import Share from "@/components/Public/Profile/Modals/Share.vue";
import Register from "@/components/Public/Profile/Modals/Register.vue";
import { useMeta } from "vue-meta";
import { event } from "vue-gtag";

const loading = ref(true);
const item = computed(() => store.getters["misc/profile"]);

const {
  getValue,
  displayName,
  workspaceBackgroundColor,
  websites,
  phones,
  address,
  socialMedias,
  profilePhoto,
  companyLogo,
  preferences,
  emails,
  createVCard,
  profileType,
} = useProfile({
  getterName: "misc/profile",
  actionName: "misc/getProfile",
  applyWsRules: true,
});

const route = useRoute();
const router = useRouter();
const displayNameVar = ref("");
const backgroundColor = computed(() => {
  const defaultColor = "#167ffc";

  if (item.value?.id) {
    return workspaceBackgroundColor.value || defaultColor;
  }

  return defaultColor;
});

const backgroundColorOpacity = computed(() => {
  const color = backgroundColor.value;

  if (color) {
    return color + "2e";
  }

  return color;
});

const corporateWebsites = computed(() => {
  return websites.value.filter((item) => item.type == "corporate");
});
const corporateSocialMedias = computed(() => {
  return socialMedias.value.filter((item) => item.type == "corporate");
});
const corporatePhones = computed(() => {
  return phones.value.filter((item) => item.type == "corporate");
});
const corporateEmails = computed(() => {
  return emails.value.filter((item) => item.type == "corporate");
});

const individualSocialMedias = computed(() => {
  return socialMedias.value.filter((item) => item.type == "self");
});

const navigate = () => {
  window.location.href = "https://usejanus.com/janus-kart/";
};

const downloadCard = () => {
  const { profileId, profileSlug } = route.params;
  const { utm_medium, utm_source, utm_term } = route.query;
  let profileSlugOnlyInt = profileSlug.replace(/\D/g, "");
  profileSlugOnlyInt = parseInt(profileSlugOnlyInt);

  event("janus_card", {
    card_id: profileId,
    card_user_id: profileSlugOnlyInt,
    source: utm_source || "card",
    action_type: "download",
  });

  createVCard();
};

useMeta({
  title: "",
});

onMounted(() => {
  const { profileId, profileSlug } = route.params;
  const { utm_medium, utm_source, utm_term } = route.query;
  let profileSlugOnlyInt = profileSlug.replace(/\D/g, "");
  profileSlugOnlyInt = parseInt(profileSlugOnlyInt);

  store.dispatch("misc/options").then(() => {
    store
      .dispatch("misc/getProfile", profileId)
      .then((response) => {
        displayNameVar.value = displayName();

        if(profileSlugOnlyInt !== response?.acf?.user) {
          router.push({ name: "login" });
        }

        if (response?.redirect_to) {
          window.location.href = response.redirect_to;
        } else {
          loading.value = false;
        }

        event("janus_card", {
          card_id: profileId,
          card_user_id: profileSlugOnlyInt,
          source: utm_source || "card",
          action_type: utm_medium || "view",
        });
      })
      .catch(() => {
        router.push({ name: "login" });
      });
  });
});
</script>

<style>
.business-card-view-content-body .nav-tabs .nav-item .nav-link.active {
  border-color: v-bind(backgroundColor) !important;
  color: v-bind(backgroundColor) !important;
}

.business-card-view-content-body .nav-tabs .nav-item .nav-link.active svg path {
  stroke: v-bind(backgroundColor) !important;
}

.business-card-profile-content-body-info-item svg path {
  stroke: v-bind(backgroundColor) !important;
}

.business-card-profile-content-body-social-item
  i.svg-color-state-fill
  svg
  path:first-child {
  fill: v-bind(backgroundColor) !important;
}

.business-card-profile-content-body-social-item
  i.svg-color-state-stroke
  svg
  path {
  stroke: v-bind(backgroundColor) !important;
}

.business-card-profile-content-body-social-item svg {
  width: 24px;
  height: 20px;
  margin-right: 25px;
}

.business-card-view::after {
  background-color: v-bind(backgroundColor) !important;
}

.business-card-profile-content-body-info-item.is-fax span {
  color: v-bind(backgroundColor) !important;
}

.business-card-view-register button {
  background-color: v-bind(backgroundColor) !important;
}

.business-card-view-content-head-download button {
  background: linear-gradient(
    180deg,
    v-bind(backgroundColor) 0%,
    v-bind(backgroundColor) 100%
  ) !important;
}

.business-card-view-content-head-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.business-card-view-content-head-download.share-info button {
  margin-left: 10px;
  background: v-bind(backgroundColorOpacity) !important;
  color: v-bind(backgroundColor) !important;
}
</style>
