<template>
  <router-view :key="route.fullPath"></router-view>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import store from "@/store";

const route = useRoute();
const router = useRouter();

onMounted(() => {
  const status = store.getters["auth/status"];

  if (!status.login) {
    return;
  }

  store.dispatch("account/me").then((response) => {
    const type = response.current_workspace.type;
    const role = response.current_workspace.role;

    store.commit("account/is_loading", false);
    store.commit("common/apply_mode", {
      type,
      role,
    });
  });

  store.dispatch("misc/options");
  store.dispatch("profiles/items");

  setTimeout(() => {
    store.dispatch("account/status").then((response) => {
      if (!response.status) {
        store.dispatch("auth/logout");

        router.push({ name: "login" });
      }
    });
  }, 10000);
});
</script>
