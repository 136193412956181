<template>
  <div class="reset-password-icon">
    <img src="/img/static/icon-unlock.svg" alt="" />
  </div>
  <div class="reset-password-title">
    <h4>
      {{ $t("auth.forgot.title") }}
    </h4>
  </div>
  <div class="reset-password-text">
    <p>
      {{ $t(getI18n("auth.forgot.text")) }}
    </p>
  </div>
  <div class="reset-password-form reset-password-form-group">
    <Form @submit="submit" :validation-schema="schema" v-slot="{ errors }">
      <Field
        name="email"
        :errors="errors"
        :placeholder="$t(getI18n('auth.common.email'))"
      />
      <div class="reset-password-form-send-btn">
        <Button
          :state="state"
          class="btn btn-md btn-full btn-primary-blue btn-round-8 btn-500"
        >
          {{ $t("auth.forgot.button_label") }}
        </Button>
      </div>
      <div class="reset-password-form-line">
        <span>{{ $t("auth.common.or") }}</span>
      </div>
      <div class="reset-password-form-footer">
        <router-link to="/sign-up">
          {{ $t("auth.common.create_account") }}
        </router-link>
      </div>
    </Form>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import store from "@/store";
import { useRouter, useRoute } from "vue-router";
import { Form, Field as VField } from "vee-validate";
import * as yup from "yup";
import Field from "@/components/inputs/Field";
import Button from "@/components/inputs/Button";
import { getI18n } from "@/utils/Helpers";
import i18n from "@/utils/i18n.vue";

const emit = defineEmits(["success", "error"]);

const schema = yup.object({
  email: yup
    .string()
    .required()
    .email()
    .label(i18n.global.t(getI18n("auth.common.email"))),
});

const router = useRouter();
const state = ref("default");
const errorMessage = ref("");
const mode = store.getters["common/mode"];
const submit = (values) => {
  state.value = "loading";
  store.dispatch("auth/resetPasswordEmail", values).then(
    () => {
      state.value = "success";
      errorMessage.value = "";

      setTimeout(() => {
        emit("success", values.email);
      }, 1000);
    },
    (error) => {
      emit("error");
    }
  );
};
</script>
