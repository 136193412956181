<template>
  <div
    v-if="item"
    :class="[
      'business-card-profile is-show',
      { 'is-profile-image-none': !profilePhoto && !companyLogo },
    ]"
  >
    <div class="business-card-profile-head">
      <template v-if="isMainCorporateProfile">
        <div class="business-card-profile-head-image" v-if="companyLogo">
          <img :src="companyLogo.url" :alt="item.acf.company_name" />
        </div>
      </template>
      <template v-else>
        <div class="business-card-profile-head-image" v-if="profilePhoto">
          <img :src="profilePhoto.url" :alt="displayName()" />
        </div>
        <div class="business-card-profile-head-illustration" v-if="companyLogo">
          <img :src="companyLogo.url" :alt="item.acf.company_name" />
        </div>
      </template>
    </div>
    <div class="business-card-profile-content">
      <div class="business-card-profile-content-head">
        <div class="business-card-profile-content-head-user">
          <div class="business-card-profile-content-head-user-title">
            <h3>{{ displayName() }}</h3>
          </div>
          <div class="business-card-profile-content-head-user-colleague">
            <h4>{{ getValue("title") }}</h4>
          </div>
          <div class="business-card-profile-content-head-user-works">
            <h4>{{ companyName }}</h4>
          </div>
        </div>
        <div
          class="business-card-profile-content-head-description"
          v-if="description"
        >
          <p>
            {{ description }}
          </p>
        </div>
      </div>

      <div class="business-card-profile-content-body">
        <div class="business-card-profile-content-body-info">
          <template v-if="phones">
            <div
              class="business-card-profile-content-body-info-item is-fax"
              v-for="(phone, index) in phones"
              :key="index"
            >
              <img
                src="/img/static/icon-phone.svg"
                alt=""
                :content="phone.phone_type.label"
                v-tippy
              />
              <span>{{ phone.phone_type.label }}</span>
              <a :href="'tel:' + phone.phone_number">
                {{ phone.phone_number }}
              </a>
            </div>
          </template>
          <template v-if="emails.length">
            <div
              class="business-card-profile-content-body-info-item is-mail"
              v-for="(item, index) in emails"
              :key="index"
            >
              <img
                src="/img/static/icon-mail.svg"
                alt=""
                :content="$t('misc.email')"
                v-tippy
              />
              <a :href="`mailto:${item.email}`">{{ item.email }}</a>
            </div>
          </template>
          <template v-if="websites">
            <div
              class="business-card-profile-content-body-info-item is-website"
              v-for="(websiteItem, index) in websites"
              :key="index"
            >
              <img
                src="/img/static/icon-globe-s.svg"
                :alt="websiteItem.link_text"
                :content="$t('misc.website')"
                v-tippy
              />
              <a :href="websiteItem.url" target="_blank">
                {{ websiteItem.link_text || websiteItem.url }}
              </a>
            </div>
          </template>

          <div
            class="business-card-profile-content-body-info-item is-file"
            v-if="file && isMainCorporateProfile"
          >
            <img
              src="/img/static/icon-s-file-tiny.svg"
              :alt="file.link_text"
              :content="file.type_label"
              v-tippy
            />
            <a :href="file?.file?.url" target="_blank">
              {{ file.link_text || file.type_label }}
            </a>
          </div>
          <div
            class="business-card-profile-content-body-info-item is-adress"
            v-if="address"
          >
            <img
              src="/img/static/icon-map-pin.svg"
              alt=""
              :content="$t('misc.address')"
              v-tippy
            />
            <a
              :href="'https://maps.google.com/?q=' + address"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>{{ address }}</span>
            </a>
          </div>
        </div>
        <div
          class="business-card-profile-content-body-social"
          v-if="socialMedias"
        >
          <div
            v-for="item in socialMedias"
            :key="item.social_media_type"
            :class="[
              'business-card-profile-content-body-social-item',
              'is-' + item.social_media_type,
              {
                'd-none':
                  item.social_media_type === 'file' && isMainCorporateProfile,
              },
            ]"
          >
            <template v-if="!['date', 'file'].includes(item.social_media_type)">
              <a :href="item.url" target="_blank">
                <img
                  :src="`/img/static/icon-s-${item.social_media_type}.svg`"
                  :alt="item.link_text"
                  :content="item.type_label"
                  v-tippy
                />
                {{ item.link_text }}
              </a>
            </template>

            <template v-else-if="item.social_media_type === 'date'">
              <a>
                <img
                  :src="`/img/static/icon-s-${item.social_media_type}.svg`"
                  :alt="item.link_text"
                  :content="item.type_label"
                  v-tippy
                />
                {{ item.date }}
                <span v-if="item.link_text">({{ item.link_text }})</span>
              </a>
            </template>

            <template v-else>
              <a :href="item.file.url" target="_blank">
                <img
                  :src="`/img/static/icon-s-${item.social_media_type}.svg`"
                  :alt="item.link_text"
                  :content="item.type_label"
                  v-tippy
                />
                {{ item.link_text }}
              </a>
            </template>
          </div>
        </div>
      </div>

      <div class="business-card-profile-content-buttons">
        <div
          class="business-card-profile-content-buttons-item is-edit"
          :content="$t('profile.edit_page_title')"
          v-tippy
          v-if="props.actions.includes('edit')"
        >
          <button
            class="btn btn-icon btn-sm btn-primary-blue btn-round-8"
            href="javascript:;"
            @click="edit"
          >
            <i>
              <svg
                width="20"
                height="19"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 18H19"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M14.5 1.49998C14.8978 1.10216 15.4374 0.878662 16 0.878662C16.2786 0.878662 16.5544 0.933532 16.8118 1.04014C17.0692 1.14674 17.303 1.303 17.5 1.49998C17.697 1.69697 17.8532 1.93082 17.9598 2.18819C18.0665 2.44556 18.1213 2.72141 18.1213 2.99998C18.1213 3.27856 18.0665 3.55441 17.9598 3.81178C17.8532 4.06915 17.697 4.303 17.5 4.49998L5 17L1 18L2 14L14.5 1.49998Z"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </i>
          </button>
        </div>
        <div
          class="business-card-profile-content-buttons-item is-visibility"
          @click="setModalStatus('profile_visibility', true)"
          :content="$t('profile.visibility.title')"
          v-tippy
          v-if="props.actions.includes('visibility')"
        >
          <a
            class="btn btn-icon btn-sm btn-primary-blue btn-round-8"
            href="javascript:;"
          >
            <i>
              <svg
                width="26"
                height="20"
                viewBox="0 0 26 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.08337 9.99992C1.08337 9.99992 5.41671 1.33325 13 1.33325C20.5834 1.33325 24.9167 9.99992 24.9167 9.99992C24.9167 9.99992 20.5834 18.6666 13 18.6666C5.41671 18.6666 1.08337 9.99992 1.08337 9.99992Z"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M13 13.2501C14.7949 13.2501 16.25 11.795 16.25 10.0001C16.25 8.2052 14.7949 6.75012 13 6.75012C11.2051 6.75012 9.75 8.2052 9.75 10.0001C9.75 11.795 11.2051 13.2501 13 13.2501Z"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </i>
          </a>
        </div>
        <div
          class="business-card-profile-content-buttons-item is-trash"
          @click="setModalStatus('profile_trash', true)"
          :content="$t('misc.delete')"
          v-if="
            (!isMainCorporateProfile && props.actions.includes('trash')) &&
            (workspace.type === 'corporate' && me.first_profile_id != item.id) ||
            (workspace.type === 'individual')
          "
          v-tippy
        >
          <button
            type="button"
            class="btn btn-icon btn-sm btn-primary-blue btn-round-8"
            href="javascript:;"
          >
            <i>
              <svg
                width="22"
                height="24"
                viewBox="0 0 22 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.25 5.5H3.41667H20.75"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M6.66666 5.49996V3.33329C6.66666 2.75866 6.89493 2.20756 7.30126 1.80123C7.70759 1.3949 8.25869 1.16663 8.83332 1.16663H13.1667C13.7413 1.16663 14.2924 1.3949 14.6987 1.80123C15.1051 2.20756 15.3333 2.75866 15.3333 3.33329V5.49996M18.5833 5.49996V20.6666C18.5833 21.2413 18.3551 21.7924 17.9487 22.1987C17.5424 22.605 16.9913 22.8333 16.4167 22.8333H5.58332C5.00869 22.8333 4.45759 22.605 4.05126 22.1987C3.64493 21.7924 3.41666 21.2413 3.41666 20.6666V5.49996H18.5833Z"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </i>
          </button>
        </div>
      </div>

      <TrashModal v-if="getModalStatus('profile_trash')" :item="item" />

      <VisibilityModal
        v-if="getModalStatus('profile_visibility')"
        :data="visibility"
        :id="item.id"
      />
    </div>
    <div class="business-card-profile-close">
      <button
        type="button"
        @click="close"
        v-if="props.actions.includes('close')"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24 8L8 24"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M8 8L24 24"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      </button>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, onUnmounted, computed, defineProps } from "vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import useProfile from "@/composables/profile";
import { setModalStatus, getModalStatus, getModalData } from "@/utils/Helpers";
import VisibilityModal from "@/components/Profile/Modals/Visibility.vue";
import TrashModal from "@/components/Profile/Modals/Trash.vue";

const route = useRoute();
const router = useRouter();
const visibility = ref({});
const data = getModalData(props.modalName);
const props = defineProps({
  id: {
    type: Number,
    required: false,
  },
  actions: {
    type: Array,
    required: false,
    default: () => ["edit", "visibility", "trash"],
  },
  isModal: {
    type: Boolean,
    required: false,
    default: false,
  },
  isApplyWsRules: {
    type: Boolean,
    default: true,
    required: false,
  },
  modalName: {
    type: String,
    required: false,
    default: "member_view",
  },
  editModalName: {
    type: String,
    required: false,
    default: "member_edit",
  },
  item: {
    type: Object,
    required: false,
    default: false,
  },
  storeGetKey: {
    type: String,
    required: false,
    default: "profiles/item",
  },
  storeDispatchKey: {
    type: String,
    required: false,
    default: "profiles/get",
  },
});
const me = computed(() => store.getters["account/me"]);
const workspace = computed(() => store.getters["account/workspace"]);
const {
  getValue,
  displayName,
  companyName,
  description,
  emails,
  website,
  websites,
  phones,
  address,
  socialMedias,
  profileType,
  isMainCorporateProfile,
  profilePhoto,
  companyLogo,
  file,
} = useProfile({
  getterName: props.storeGetKey,
  actionName: props.storeDispatchKey,
  applyWsRules: props.isApplyWsRules,
});

let item = computed(() => {
  const item = store.getters[props.storeGetKey];

  if (item && item?.acf?.preferences) {
    visibility.value = item.acf.preferences.visibility;
  }

  return item;
});

const edit = () => {
  if (props.isModal) {
    setModalStatus(props.modalName, false);
    setModalStatus(props.editModalName, true, item.value);
  } else {
    router.push({
      name: "profile-edit",
      params: {
        id: item.value.id,
      },
    });
  }
};

// onmounted
onMounted(() => {
  if (!item.value) {
    const id = props.item?.id ? props.item.id : route.params.id;
    store.dispatch(props.storeDispatchKey, id).then(() => {
      if (data?.loaded()) {
        data?.loaded()();
      }
    });
  }
});

const close = () => {
  setModalStatus(props.modalName, false);

  store.commit("profiles/setItem", null);
};
</script>
