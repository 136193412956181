<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>

  <SecondLayout>
    <section class="login" id="login-page">
      <div class="login-contain">
        <div class="login-main">
          <div class="login-left-side">
            <div class="login-left-side-main">
              <div class="login-left-side-title">
                <h1>{{ t("auth.login.title") }}</h1>
              </div>
              <div class="login-left-side-tab" v-if="appVersion === 3">
                <div class="login-left-side-tab-block">
                  <ul
                    class="nav nav-tabs"
                    id="login-left-side-tab-block-tab"
                    role="tablist"
                    v-if="modes"
                  >
                    <li class="nav-item" v-for="mode in modes" :key="mode">
                      <a
                        class="nav-link active"
                        role="tab"
                        @click="changeMode(mode)"
                      >
                        {{ t(`misc.${mode}_label`) }}
                      </a>
                    </li>
                    <div class="login-left-side-tab-block-base">
                      <div class="login-left-side-tab-block-base-active"></div>
                    </div>
                  </ul>
                </div>
              </div>
              <div class="login-left-side-form">
                <Form
                  @submit="submit"
                  :validation-schema="schema"
                  v-slot="{ errors }"
                >
                  <template v-if="selectedMode === modes.individual">
                    <Field
                      :errors="errors"
                      name="username"
                      :placeholder="t('auth.login.email')"
                    />

                    <div
                      :class="[
                        'form-item h-40',
                        errors.password ? 'is-error' : '',
                      ]"
                    >
                      <VField
                        v-slot="{ field }"
                        name="password"
                        :errors="errors"
                      >
                        <PasswordInput
                          :bind-value="field"
                          :placeholder="$t('auth.common.password')"
                        />
                      </VField>
                    </div>

                    <div class="form-item-error-text" v-if="errors.password">
                      {{ errors.password }}
                    </div>
                  </template>

                  <template v-else>
                    <Field
                      :errors="errors"
                      name="username"
                      :placeholder="t('auth.login.corporate.email')"
                    />

                    <div
                      :class="[
                        'form-item h-40',
                        errors.password ? 'is-error' : '',
                      ]"
                    >
                      <VField
                        v-slot="{ field }"
                        name="password"
                        :errors="errors"
                      >
                        <PasswordInput
                          :bind-value="field"
                          :placeholder="$t('auth.common.password')"
                        />
                      </VField>
                    </div>

                    <div class="form-item-error-text" v-if="errors.password">
                      {{ errors.password }}
                    </div>
                  </template>

                  <div class="login-left-side-form-reset-password">
                    <router-link to="/forgot">
                      {{ t("auth.login.forgot") }}
                    </router-link>
                  </div>
                  <div class="login-left-side-form-send-btn">
                    <Button
                      :state="buttonState"
                      class="btn btn-md btn-primary-blue btn-round-8 btn-500"
                    >
                      {{ t("auth.login.button_label") }}
                    </Button>
                  </div>
                  <div
                    class="login-left-side-form-error-text"
                    v-if="errorMessage"
                  >
                    <p>
                      {{ errorMessage }}
                    </p>
                  </div>
                  <OtherLogin />
                  <div class="login-left-side-form-footer">
                    <p>
                      {{ t("auth.login.not_registered") }}
                      <router-link to="/sign-up">
                        {{ t("auth.login.register") }}
                      </router-link>
                    </p>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div
            class="login-right-side"
            style="background-image: url(/img/static/login-right-side-bg.jpg)"
          >
            <figure>
              <img src="/img/dynamic/login-right-side-figure.png" alt="" />
            </figure>
          </div>

          <div
            class="login-right-side is-corporate"
            style="
              background-image: url(/img/static/login-right-side-bg-corporate-login.jpg);
            "
          >
            <figure>
              <img src="/img/dynamic/login-right-side-figure-corporate.png" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  </SecondLayout>
</template>

<script setup>
// Main
import { ref, onMounted, computed } from "vue";
import store from "@/store";
import { useMeta } from "vue-meta";
import { useI18n } from "vue-i18n";
import { Form, Field as VField } from "vee-validate";
import * as yup from "yup";
import { useRouter } from "vue-router";
import useAuth from "@/composables/auth";
import i18n from "@/utils/i18n.vue";

// Components
import SecondLayout from "@/layouts/SecondLayout.vue";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import Button from "@/components/inputs/Button.vue";
import OtherLogin from "@/components/OtherLogin.vue";
import Field from "@/components/inputs/Field.vue";

//meta
useMeta({
  title: i18n.global.t("auth.login.title"),
});

// Variables
const { t } = useI18n();
const schema = yup.object({
  username: yup
    .string()
    .required()
    .label(i18n.global.t("auth.login.email"))
    .trim(),
  password: yup
    .string()
    .required()
    .min(4)
    .label(i18n.global.t("auth.common.password"))
    .trim(),
});

const appVersion = computed(() => store.getters["common/app_version"]);
const modes = store.getters["common/modes"];
const selectedMode = ref(modes.individual);
const router = useRouter();
const buttonState = ref("default");
const errorMessage = computed(() => store.getters["auth/error_message"]);
const { login } = useAuth({ buttonState, router });

const submit = (values) => {
  values.login_type = "default";

  login(values);
};

const changeMode = (mode) => {
  const index = Object.values(modes).indexOf(mode);
  selectedMode.value = mode;

  const clickedTab =
    document.querySelectorAll(".nav-link")[index].parentElement;
  const tabBaseActive = document.querySelector(
    ".login-left-side-tab-block-base-active"
  );

  const clickedWidth = clickedTab.offsetWidth;
  const clickedOffsetLeft = clickedTab.offsetLeft;

  tabBaseActive.style.left = clickedOffsetLeft + 2 + "px";
  tabBaseActive.style.width = clickedWidth - 4 + "px";

  store.commit("common/apply_mode", { type: mode });

  localStorage.setItem("mode", mode);
};

onMounted(() => {
  if (appVersion.value === 3) {
    const mode = store.getters["common/mode"];
    changeMode(mode);
  }

  store.dispatch("auth/clearErrorMessage");
  store.dispatch("auth/logout");
});
</script>
