<template>
  <div class="profile-creation-body-upload is-show">
    <div class="profile-creation-body-upload-block">
      <div class="profile-creation-body-upload-block-image">
        <img
          :src="selectedImageSrc"
          ref="profilePhoto"
          id="profile-creation-body-upload-block-image-content"
        />
      </div>
      <div class="profile-creation-body-upload-block-content">
        <cropper-zoom
          v-if="cropper"
          :cropper="cropper"
          :image-container="profilePhoto"
        />
        <div class="profile-creation-body-upload-block-content-button">
          <a
            class="btn btn-sm btn-white btn-round-4 btn-400 give-up"
            href="javascript:;"
            @click="cancel"
          >
            {{ $t("misc.cancel") }}
          </a>
          <Button
            type="button"
            class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
            href="javascript:;"
            :state="state"
            @click="upload"
          >
            {{ $t("misc.upload") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, onMounted, ref } from "vue";
import { setModalStatus, getModalData } from "@/utils/Helpers";
import Button from "@/components/inputs/Button.vue";
import Cropper from "cropperjs";
import store from "@/store";
import "cropperjs/dist/cropper.css";
import cropperZoom from "@/components/Common/CropperZoom.vue";

const state = ref("default");
const cropper = ref(null);
const profilePhoto = ref(null);
const modalData = getModalData("profile_photo_upload");
const selectedImageSrc = ref(null);

const upload = () => {
  state.value = "loading";

  const canvas = cropper.value.getCroppedCanvas();
  const image = canvas.toDataURL("image/png");
  const formData = new FormData();

  canvas.toBlob(function (blob) {
    const dropzoone = modalData.dropzone;

    // remove the old file
    dropzoone.removeFile(dropzoone.files[0]);

    blob.cropped = true;

    // add the new file
    dropzoone.addFile(blob);

    // close the modal
    setModalStatus("profile_photo_upload", false);

    // upload the file
    dropzoone.processQueue();
  });
};

const cancel = () => {
  const dropzoone = modalData.dropzone;

  // remove the old file
  dropzoone.removeFile(dropzoone.files[0]);

  setModalStatus("profile_photo_upload", false);
};

onMounted(() => {
  selectedImageSrc.value = URL.createObjectURL(modalData.file);
  setTimeout(() => {
    var minAspectRatio = 0.5;
    var maxAspectRatio = 1.5;

    cropper.value = new Cropper(profilePhoto.value, {
      cropBoxMovable: false,
      dragMode: "move",
      viewMode: 1,
      // aspectRatio: 1 / 1,
      // viewMode: 3,
    });
  }, 100);
});
</script>
