<template>
  <div class="link-note-delete is-show">
    <div class="link-note-delete-content">
      <p>
        <i18n-t keypath="network.notes.archive.description">
          <template v-slot:name>
            <span>{{ props.item.network.title }}</span>
          </template>
        </i18n-t>
      </p>
    </div>
    <div class="link-note-delete-button">
      <button
        type="button"
        class="btn btn-sm btn-white btn-round-4 btn-400"
        @click="setModalStatus('note_archive', false)"
      >
        {{ $t("misc.cancel") }}
      </button>
      <Button
        type="button"
        class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
        :state="state"
        @click="archive"
      >
        {{ $t("network.notes.archive.button_label") }}
      </Button>
    </div>
    <div class="link-note-delete-close">
      <button type="button" @click="setModalStatus('note_archive', false)">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 6L6 18"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6 6L18 18"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed, defineProps } from "vue";
import store from "@/store";
import Button from "@/components/inputs/Button.vue";
import { getModalStatus, setModalStatus } from "@/utils/Helpers";

const state = ref("default");
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const archive = () => {
  state.value = "loading";
  store
    .dispatch("network/updateNote", {
      networkId: props.item.network.id,
      noteId: props.item.id,
      is_archived: true,
    })
    .then(() => {
      store.dispatch("network/item", props.item.network.id).then(() => {
        state.value = "success";
        setTimeout(() => {
          setModalStatus("note_archive", false);
        }, 1000);
      });
    })
    .catch(() => {
      state.value = "default";
    });
};
</script>
