<template>
  <div class="statistics-block-head-chart">
    <div class="tab-content" id="statisticsValueTabContent">
      <div
        class="tab-pane fade show active"
        id="business-card-display"
        role="tabpanel"
        aria-labelledby="business-card-display-tab"
      >
        <div class="statistics-block-head-chart-item">
          <Loading type="skeleton" v-if="isLoading" />
          <div class="statistics-block-head-chart-item-label">
            <h3>{{ activeTab.name }}</h3>
            <h4>{{ activeSubTab.chart_title }}</h4>
            <button :id="activeSubTab.legendId" class="legend-container">
              <div :class="[activeSubTab.legendId + '-block']">
                <i>
                  <svg
                    width="31"
                    height="10"
                    viewBox="0 0 31 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 5H31" :stroke="activeTab.color" />
                    <circle
                      cx="16"
                      cy="5"
                      r="4.5"
                      :fill="activeTab.color"
                      :stroke="activeTab.color"
                    />
                  </svg>
                </i>
                <i v-if="activeSubTab.id === 'conversion'">
                  <svg
                    width="31"
                    height="10"
                    viewBox="0 0 31 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M0 5H31" :stroke="activeTab.color" />
                    <circle
                      cx="16"
                      cy="5"
                      r="4.5"
                      :fill="activeTab.color"
                      :stroke="activeTab.color"
                    />
                  </svg>
                </i>
              </div>
              <div
                class="legend-container-total-data"
                v-if="activeSubTab.id === 'conversion'"
              >
                <span>
                  <!-- %{{ stats[activeTab.id].summary.conversion || 0 }} -->
                </span>
                <p>Total Verim</p>
              </div>
            </button>
          </div>
          <Chart />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import store from "@/store";
import Chart from "./Chart.vue";
import Loading from "../Common/Loading.vue";

const isLoading = computed(() => store.getters["stats/isLoading"]);

const activeTab = computed(() => store.getters["stats/activeTab"]);
const activeSubTab = computed(() => store.getters["stats/activeSubTab"]);
const stats = computed(() => {
  const stats = store.getters["stats/get"];

  return stats;
});
</script>
