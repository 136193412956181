import ExportService from "@/services/modules/ExportService";

export const exportStore = {
  namespaced: true,
  state: {},
  actions: {
    create({ commit }, payload) {
      return ExportService.create(payload).then(
        (item) => {
          return Promise.resolve(item);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {},
  getters: {},
};
