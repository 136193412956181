import AuthService from "../services/modules/AuthService";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: {
    login: user ? true : false,
    user: user,
    error_message: "",
  },
  errorMessage: null,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);

          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");

          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      commit("logout");
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    resetPasswordEmail({ commit }, email) {
      return AuthService.resetPasswordEmail(email).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          commit("resetPasswordFailure", error);
          return Promise.reject(error);
        }
      );
    },
    resetPassword({ commit }, payload) {
      return AuthService.resetPassword(payload).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    resetPasswordTokenCheck({ commit }, payload) {
      return AuthService.resetPasswordTokenCheck(payload).then(
        (response) => {
          return Promise.resolve(response.data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    clearErrorMessage({ commit }) {
      commit("error_message", "");
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.login = true;
      state.user = user;

      localStorage.setItem("user", JSON.stringify(user));
    },
    loginFailure(state) {
      state.login = false;
      state.user = null;
    },
    logout(state) {
      state.login = false;
      state.user = null;

      localStorage.removeItem("user");
      localStorage.removeItem("preferences");
    },
    resetPasswordFailure(state, error) {
      state.errorMessage = error.response.data.data.message;
    },
    error_message(state, message) {
      state.error_message = message;
    },
  },
  getters: {
    status: (state) => {
      return state;
    },
    error_message: (state) => {
      return state.error_message;
    },
  },
};
