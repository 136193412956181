<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>

  <MainLayout :title="title">
    <b-skeleton
      v-if="!item || !workspace"
      class="profile-action-skeleton"
    ></b-skeleton>
    <div class="digital-profile-block" v-if="item && workspace">
      <View />
      <Share :data="item" />
      <LimitModal v-if="getModalStatus('profile_premium')" />
    </div>
  </MainLayout>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import MainLayout from "@/layouts/MainLayout.vue";
import store from "@/store";
import LimitModal from "@/components/Profile/Modals/Limit.vue";
import { setModalStatus, getModalStatus } from "@/utils/Helpers";
import { useRoute } from "vue-router";
import { useMeta } from "vue-meta";
import i18n from "@/utils/i18n.vue";
import View from "@/components/Profile/View.vue";
// components
import Share from "@/components/Profile/Share.vue";

//meta
useMeta({
  title: i18n.global.t("profile.view_page_title"),
});

const title = ref("");
const route = useRoute();
const workspace = computed(() => {
  const workspace = store.getters["account/workspace"];
  if (workspace?.type) {
    title.value =
      workspace.type === "corporate" &&
      item.value.acf.is_main_corporate_profile
        ? i18n.global.t("profile.view_page_title_corporate")
        : i18n.global.t("profile.view_page_title");
  }

  return workspace;
});

let item = computed(() => {
  const item = store.getters["profiles/item"];

  return item;
});

onMounted(() => {
  store.commit("profiles/get", null);
  store.dispatch("profiles/get", route.params.id);
});
</script>
