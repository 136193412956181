<template>
  <div class="see-more-popup-container is-show">
    <div class="see-more-popup-container-inner">
      <div class="see-more-popup">
        <div class="popup-head">
          <h6>{{ $t("stats.locations.title") }}</h6>
          <a
            href="javascript:;"
            title="Close"
            class="close-popup"
            @click="setModalStatus('stats_locations', false)"
          >
            <img src="/img/static/close-icon.svg" alt="" />
          </a>
        </div>
        <table>
          <thead>
            <tr>
              <th>{{ $t("stats.locations.location") }}</th>
              <th>{{ $t("stats.locations.value") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ item.label }}</td>
              <td>{{ item.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";
import { setModalStatus } from "@/utils/Helpers";

const items = ref({});
const props = defineProps({
  items: {
    type: Object,
    required: true,
  },
});

onMounted(() => {
  items.value = props.items.labels.map((item, index) => {
    return {
      label: item,
      value: props.items.values[index],
    };
  });
});

</script>
