<template>
  <div class="business-card-profile-content-visibility is-show">
    <div
      :class="[
        'business-card-profile-content-visibility-head',
        { 'is-save': isSaved },
      ]"
    >
      <span>{{ $t("misc.saved") }}</span>
      <h5>{{ $t("profile.visibility.title") }}</h5>
      <p>
        {{ $t("profile.visibility.text") }}
      </p>
      <div
        class="business-card-profile-content-visibility-head-close"
        @click="setModalStatus('profile_visibility', false)"
      >
        <button type="button">
          <img src="/img/static/icon-x.svg" alt="" />
        </button>
      </div>
    </div>
    <div class="business-card-profile-content-visibility-options">
      <div class="business-card-profile-content-visibility-options-item">
        <span>{{ $t("misc.phone") }}</span>
        <div class="form-item-switch">
          <input type="checkbox" v-model="visibility.phone" />
        </div>
      </div>
      <div class="business-card-profile-content-visibility-options-item">
        <span>{{ $t("misc.address") }}</span>
        <div class="form-item-switch">
          <input type="checkbox" v-model="visibility.address" />
        </div>
      </div>
      <div class="business-card-profile-content-visibility-options-item">
        <span>{{ $t("misc.social_media") }}</span>
        <div class="form-item-switch">
          <input type="checkbox" v-model="visibility.social_media" />
        </div>
      </div>
    </div>
    <div class="business-card-profile-content-visibility-buttons">
      <a
        class="btn btn-sm btn-white btn-round-4 btn-400"
        href="javascript:;"
        @click="setModalStatus('profile_visibility', false)"
      >
        {{ $t("misc.cancel") }}
      </a>
      <Button
        class="btn btn-sm btn-primary-blue btn-round-4 btn-400 save-button"
        :state="state"
        @click="updateVisibility"
      >
        {{ $t("misc.save") }}
      </Button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps } from "vue";
import { setModalStatus } from "@/utils/Helpers";
import Button from "@/components/inputs/Button.vue";
import store from "@/store";

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  data: {
    type: Object,
    required: true,
  },
});

const visibility = ref(props.data);
const isSaved = ref(false);
const state = ref("default");

const updateVisibility = () => {
  state.value = "loading";
  store
    .dispatch("profiles/updateVisibility", {
      id: props.id,
      visibility: visibility.value,
    })
    .then(() => {
      state.value = "success";
      // isSaved.value = true;
    });
};
</script>