import InvitationService from "@/services/modules/InvitationService";

export const invitation = {
  namespaced: true,
  state: {},
  actions: {
    check({ commit }, payload) {
      return InvitationService.check(payload).then(
        (item) => {
          return Promise.resolve(item);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {},
  getters: {},
};
