<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>

  <MainLayout
    :title="$t('profile.edit_page_title')"
    container-class="profile-creation"
    class="main-profile-creation"
  >
    <b-skeleton v-if="!item" class="profile-action-skeleton"></b-skeleton>
    <Page :item="item" v-if="item" />
  </MainLayout>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import MainLayout from "@/layouts/MainLayout.vue";
import Page from "@/components/Profile/Page";
import store from "@/store";
import { useRoute } from "vue-router";
import { useMeta } from "vue-meta";
import i18n from "@/utils/i18n.vue";

//meta
useMeta({
  title: i18n.global.t("profile.edit_page_title"),
});

// variables
const route = useRoute();
let item = computed(() => store.getters["profiles/item"]);

onMounted(() => {
  // set null to item
  store.commit("profiles/get", null);
  store.dispatch("profiles/get", route.params.id);
});
</script>
