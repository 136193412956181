import { axios } from "@/services/axios";
import AuthHeader from "../AuthHeader";

export const list = (payload) => {
  return axios
    .get("network", {
      headers: AuthHeader(),
      params: payload,
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const item = (id) => {
  return axios
    .get("network/" + id, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const groups = (payload) => {
  return axios
    .get("network/groups", {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const create = (payload) => {
  return axios
    .post("network", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const update = (payload) => {
  return axios
    .put("network/" + payload.id, payload.data, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const remove = (payload) => {
  return axios
    .post("network/remove", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const createGroup = (payload) => {
  return axios
    .post("network/groups", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const updateGroup = (payload) => {
  return axios
    .put("network/groups/" + payload.id, payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const deleteGroup = (id) => {
  return axios
    .delete("network/groups/" + id, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const addMembersToGroup = (payload) => {
  return axios
    .post("network/groups/add-networks", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const removeNetworksFromGroup = (payload) => {
  return axios
    .post("network/groups/remove-networks", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const importNetworks = (payload) => {
  return axios
    .post("network/import", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const updateTags = (id, tags) => {
  return axios
    .put(
      "network/" + id + "/tags",
      { tags },
      {
        headers: AuthHeader(),
      }
    )
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getNotes = (payload) => {
  return axios
    .get("network/notes", {
      headers: AuthHeader(),
      params: payload,
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const createNote = (id, payload) => {
  return axios
    .post("network/" + id + "/notes", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteNote = (networkId, id) => {
  return axios
    .delete("network/" + networkId + "/notes/" + id, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const updateNote = (networkId, id, payload) => {
  return axios
    .put("network/" + networkId + "/notes/" + id, payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteAllNotes = (payload) => {
  return axios
    .delete("network/notes/", {
      headers: AuthHeader(),
      data: payload,
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const archiveAllNotes = (payload) => {
  return axios
    .post("network/archive_all_notes", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getReminders = (payload) => {
  return axios
    .get("network/reminders", {
      headers: AuthHeader(),
      params: payload,
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const createReminder = (id, payload) => {
  return axios
    .post("network/" + id + "/reminders", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const updateReminder = (networkId, id, payload) => {
  return axios
    .put("network/" + networkId + "/reminders/" + id, payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const notifyReminder = (networkId, id, payload) => {
  return axios
    .post("network/" + networkId + "/reminders/" + id + "/notify", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteReminder = (networkId, id) => {
  return axios
    .delete("network/" + networkId + "/reminders/" + id, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const deleteAllReminders = (payload) => {
  return axios
    .delete("network/reminders/", {
      headers: AuthHeader(),
      data: payload,
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const archiveAllReminders = (payload) => {
  return axios
    .post("network/archive_all_reminders", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const getActivities = (payload) => {
  return axios
    .get("network/activities", {
      headers: AuthHeader(),
      params: payload,
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const updateStayTouch = (networkId, payload) => {
  return axios
    .post("network/" + networkId + "/stay-touch", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export default {
  list,
  item,
  create,
  update,
  remove,
  groups,
  createGroup,
  updateGroup,
  deleteGroup,
  addMembersToGroup,
  removeNetworksFromGroup,
  importNetworks,
  updateTags,
  getNotes,
  createNote,
  deleteNote,
  updateNote,
  deleteAllNotes,
  archiveAllNotes,
  getReminders,
  createReminder,
  updateReminder,
  notifyReminder,
  deleteReminder,
  deleteAllReminders,
  archiveAllReminders,
  getActivities,
  updateStayTouch,
};
