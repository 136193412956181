<template>
  <div class="network-pool-body-head">
    <div class="network-pool-body-head-checkbox">
      <div class="form-item-checkbox">
        <input
          id="checkbox"
          type="checkbox"
          @change="selectAllNetworks"
          ref="checkboxRef"
          :checked="selectedNetworks.length === networks.length"
        />
        <label for="checkbox">Tümü</label>
      </div>
    </div>
    <div
      :class="[
        'network-pool-body-head-settings',
        { 'is-active': props.isActive },
      ]"
    >
      <div class="network-pool-body-head-settings-block">
        <Groups />
        <Remove />
      </div>
      <div class="network-pool-body-head-settings-block">
        <Export />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, ref } from "vue";
import store from "@/store";
import Groups from "./Bar/Groups.vue";
import Remove from "./Bar/Remove.vue";
import Export from "./Bar/Export.vue";

const checkboxRef = ref(null);

const props = defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
});

const networks = computed(() => {
  return store.getters["network/items"];
});

const selectedNetworks = computed(() => {
  const selectedNetworks = store.getters["network/selectedNetworks"];

  return selectedNetworks;
});

const selectAllNetworks = () => {
  const value = checkboxRef.value.checked;

  if (!value) {
    store.dispatch("network/removeAllSelectedNetworks");
  } else {
    store.dispatch("network/selectAllNetworks", networks.value);
  }
};
</script>
