<template>
  <div
    :class="[
      'form-item',
      { 'is-about': !accountCan.populate_description_field },
    ]"
    v-if="accountCan"
  >
    <textarea
      :placeholder="$t('profile.description_placeholder')"
      v-on:input="onInput"
      :value="modelValue"
    ></textarea>
    <div
      class="about-us-area-locked"
      v-if="!accountCan.populate_description_field"
    >
      <router-link
        :to="{ name: 'settings' }"
        class="btn btn-sm btn-primary-blue btn-round-4 btn-400"
      >
        {{ $t("misc.premium_button_label") }}
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, computed, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import store from "@/store";
import { MembershipLevelEnum } from "@/enums";

const accountCan = computed(() => store.getters["account/can"]);

const props = defineProps({
  modelValue: String,
});

const emit = defineEmits(["update:modelValue"]);

const onInput = (e) => {
  const value = e.target.value;
  emit("update:modelValue", value);
};
</script>
