<template>
  <div class="delete-link is-show">
    <div class="delete-link-block">
      <div class="delete-link-block-head">
        <div class="delete-link-block-head-image">
          <img src="/img/static/delete-link-image.png" alt="" />
        </div>
        <div
          class="delete-link-block-head-description"
          v-if="selectedNetworks.length === 1"
        >
          <p>
            {{
              $t("network.remove.content_single", {
                name: selectedNetworks[0].full_name,
              })
            }}
          </p>
        </div>
        <div
          class="delete-link-block-head-description"
          v-else-if="selectedNetworks.length > 1"
        >
          <p>
            {{
              $t("network.remove.content_plural", {
                names: selectedNetworks
                  .map((item) => item.full_name)
                  .join(", "),
              })
            }}
          </p>
        </div>
      </div>
      <div class="delete-link-block-body">
        <div class="delete-link-block-body-description">
          <p v-if="workspace.role === 'member'">
            <span>! !</span> {{ $t("network.remove.description") }}
          </p>
          <p v-else>
            <span>! !</span> {{ $t("network.remove.description_admin") }}
          </p>
        </div>
        <div class="delete-link-block-body-button">
          <button
            @click="setModalStatus('network_remove', false)"
            type="button"
            class="btn btn-sm btn-white btn-round-4 btn-400"
          >
            {{ $t("misc.cancel") }}
          </button>
          <Button
            @click="removeNetworks"
            type="button"
            class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
            :state="state"
          >
            {{ $t("misc.delete") }}
          </Button>
        </div>
      </div>
      <div class="delete-link-block-close">
        <button type="button" @click="setModalStatus('network_remove', false)">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed, ref } from "vue";
import store from "@/store";
import { setModalStatus, getModalStatus } from "@/utils/Helpers";
import Button from "@/components/inputs/Button.vue";

const workspace = computed(() => store.getters["account/workspace"]);
const state = ref("default");
const selectedNetworks = computed(
  () => store.getters["network/selectedNetworks"]
);

const removeNetworks = () => {
  state.value = "loading";
  const ids = selectedNetworks.value.map((item) => item.id);

  store
    .dispatch("network/remove", {
      networks: ids,
    })
    .then(() => {
      store.dispatch("network/items").then(() => {
        setModalStatus("network_remove", false);
        state.value = "success";
      });
    });
};
</script>
