import { axios } from "@/services/axios";
import AuthHeader from "../AuthHeader";

export const form = (payload) => {
  return axios
    .post("payment/form", payload,{
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default {
  form,
};
