<template>
  <button
    type="button"
    :class="['is-role is-' + props.role.key]"
    @mouseover="toggleRoleArea"
    @mouseout="toggleRoleArea"
  >
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="is-admin-icon"
    >
      <path
        d="M12.6667 14V12.6667C12.6667 11.9594 12.3857 11.2811 11.8856 10.781C11.3855 10.281 10.7072 10 10 10H4.66667C3.95942 10 3.28115 10.281 2.78105 10.781C2.28095 11.2811 2 11.9594 2 12.6667V14"
        stroke="#6C489E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.33268 7.33333C8.80544 7.33333 9.99935 6.13943 9.99935 4.66667C9.99935 3.19391 8.80544 2 7.33268 2C5.85992 2 4.66602 3.19391 4.66602 4.66667C4.66602 6.13943 5.85992 7.33333 7.33268 7.33333Z"
        stroke="#6C489E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="is-member-icon"
    >
      <g clip-path="url(#clip0_13973_100838)">
        <path
          d="M15.333 14.0002V12.6669C15.3326 12.0761 15.1359 11.5021 14.7739 11.0351C14.4119 10.5682 13.9051 10.2346 13.333 10.0869"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.3337 14V12.6667C11.3337 11.9594 11.0527 11.2811 10.5526 10.781C10.0525 10.281 9.37424 10 8.66699 10H3.33366C2.62641 10 1.94814 10.281 1.44804 10.781C0.947944 11.2811 0.666992 11.9594 0.666992 12.6667V14"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.667 2.08691C11.2406 2.23378 11.749 2.56738 12.1121 3.03512C12.4752 3.50286 12.6722 4.07813 12.6722 4.67025C12.6722 5.26236 12.4752 5.83763 12.1121 6.30537C11.749 6.77311 11.2406 7.10671 10.667 7.25358"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.99967 7.33333C7.47243 7.33333 8.66634 6.13943 8.66634 4.66667C8.66634 3.19391 7.47243 2 5.99967 2C4.52692 2 3.33301 3.19391 3.33301 4.66667C3.33301 6.13943 4.52692 7.33333 5.99967 7.33333Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_13973_100838">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="is-default-icon"
    >
      <g clip-path="url(#clip0_13973_100848)">
        <path
          d="M15.333 14.0002V12.6669C15.3326 12.0761 15.1359 11.5021 14.7739 11.0351C14.4119 10.5682 13.9051 10.2346 13.333 10.0869"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M11.3337 14V12.6667C11.3337 11.9594 11.0527 11.2811 10.5526 10.781C10.0525 10.281 9.37424 10 8.66699 10H3.33366C2.62641 10 1.94814 10.281 1.44804 10.781C0.947944 11.2811 0.666992 11.9594 0.666992 12.6667V14"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.667 2.08691C11.2406 2.23378 11.749 2.56738 12.1121 3.03512C12.4752 3.50286 12.6722 4.07813 12.6722 4.67025C12.6722 5.26236 12.4752 5.83763 12.1121 6.30537C11.749 6.77311 11.2406 7.10671 10.667 7.25358"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.99967 7.33333C7.47243 7.33333 8.66634 6.13943 8.66634 4.66667C8.66634 3.19391 7.47243 2 5.99967 2C4.52692 2 3.33301 3.19391 3.33301 4.66667C3.33301 6.13943 4.52692 7.33333 5.99967 7.33333Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_13973_100848">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <div :class="['is-role-detail', { 'is-show': isShowRoleArea }]">
      <div class="is-role-detail-block">
        <div class="is-role-detail-block-head">
          <div :class="['is-role-detail-block-head-icon is-' + props.role.key]">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="is-admin-icon"
            >
              <path
                d="M12.6667 14V12.6667C12.6667 11.9594 12.3857 11.2811 11.8856 10.781C11.3855 10.281 10.7072 10 10 10H4.66667C3.95942 10 3.28115 10.281 2.78105 10.781C2.28095 11.2811 2 11.9594 2 12.6667V14"
                stroke="#6C489E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M7.33268 7.33333C8.80544 7.33333 9.99935 6.13943 9.99935 4.66667C9.99935 3.19391 8.80544 2 7.33268 2C5.85992 2 4.66602 3.19391 4.66602 4.66667C4.66602 6.13943 5.85992 7.33333 7.33268 7.33333Z"
                stroke="#6C489E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="is-member-icon"
            >
              <g clip-path="url(#clip0_13973_100838)">
                <path
                  d="M15.333 14.0002V12.6669C15.3326 12.0761 15.1359 11.5021 14.7739 11.0351C14.4119 10.5682 13.9051 10.2346 13.333 10.0869"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M11.3337 14V12.6667C11.3337 11.9594 11.0527 11.2811 10.5526 10.781C10.0525 10.281 9.37424 10 8.66699 10H3.33366C2.62641 10 1.94814 10.281 1.44804 10.781C0.947944 11.2811 0.666992 11.9594 0.666992 12.6667V14"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M10.667 2.08691C11.2406 2.23378 11.749 2.56738 12.1121 3.03512C12.4752 3.50286 12.6722 4.07813 12.6722 4.67025C12.6722 5.26236 12.4752 5.83763 12.1121 6.30537C11.749 6.77311 11.2406 7.10671 10.667 7.25358"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M5.99967 7.33333C7.47243 7.33333 8.66634 6.13943 8.66634 4.66667C8.66634 3.19391 7.47243 2 5.99967 2C4.52692 2 3.33301 3.19391 3.33301 4.66667C3.33301 6.13943 4.52692 7.33333 5.99967 7.33333Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_13973_100838">
                  <rect width="16" height="16" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="is-default-icon"
            >
              <g clip-path="url(#clip0_13973_100848)">
                <path
                  d="M15.333 14.0002V12.6669C15.3326 12.0761 15.1359 11.5021 14.7739 11.0351C14.4119 10.5682 13.9051 10.2346 13.333 10.0869"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M11.3337 14V12.6667C11.3337 11.9594 11.0527 11.2811 10.5526 10.781C10.0525 10.281 9.37424 10 8.66699 10H3.33366C2.62641 10 1.94814 10.281 1.44804 10.781C0.947944 11.2811 0.666992 11.9594 0.666992 12.6667V14"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M10.667 2.08691C11.2406 2.23378 11.749 2.56738 12.1121 3.03512C12.4752 3.50286 12.6722 4.07813 12.6722 4.67025C12.6722 5.26236 12.4752 5.83763 12.1121 6.30537C11.749 6.77311 11.2406 7.10671 10.667 7.25358"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M5.99967 7.33333C7.47243 7.33333 8.66634 6.13943 8.66634 4.66667C8.66634 3.19391 7.47243 2 5.99967 2C4.52692 2 3.33301 3.19391 3.33301 4.66667C3.33301 6.13943 4.52692 7.33333 5.99967 7.33333Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_13973_100848">
                  <rect width="16" height="16" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="is-role-detail-block-head-label">
            <h6>
              {{ props.role.label }}
            </h6>
          </div>
        </div>
        <div
          class="is-role-detail-block-body"
          v-if="props.role.key === 'admin'"
        >
          <ul>
            <li>Ekip üyelerini görüntüleme</li>
            <li>Ekip üyesi ekleme</li>
            <li>Ekip üyesini çıkarma</li>
            <li>Takım kurma</li>
            <li>Takım üyelerini görüntüleme</li>
            <li>Takıma üye ekleme, çıkarma</li>
          </ul>
        </div>
        <div
          class="is-role-detail-block-body"
          v-if="props.role.key === 'member'"
        >
          <ul>
            <li>Ekip üyelerini görüntüleme</li>
            <li>Takım üyelerini görüntüleme</li>
            <li>Takımları görüntüleme</li>
          </ul>
        </div>
      </div>
    </div>
  </button>
</template>

<script setup>
import { ref, defineProps, onMounted } from "vue";

const props = defineProps({
  role: {
    type: String,
    required: true,
  },
});
const isShowRoleArea = ref(false);
const toggleRoleArea = () => {
  if (props.role.key !== "default") {
    isShowRoleArea.value = !isShowRoleArea.value;
  }
};

onMounted(() => {
  props.role.key = props.role.key === "owner" ? "admin" : props.role.key;
});
</script>
