<template>
  <div class="email-signature-block" v-if="item">
    <div class="email-signature-row" id="email-signature-row">
      <div class="email-signature-col">
        <div class="email-signature-col-inner">
          <EmailTemplate
            :item="item"
            :visibility="visibility"
            :layout-mode-buttons="false"
            :view-mode-buttons="false"
          >
            <template v-slot:after-email-template>
              <div
                class="mail-banner"
                v-if="selectedBanner?.banner && visibility.banner"
              >
                <img :src="selectedBanner.banner.url" />
              </div>
              <div
                class="mail-disclaimer"
                v-if="text && visibility.disclaimer_text"
              >
                <div class="mail-disclaimer-inner">
                  <p v-html="text" style="white-space: pre-line"></p>
                </div>
              </div>
            </template>
          </EmailTemplate>
        </div>
      </div>
      <div class="email-signature-col">
        <div class="email-signature-col-inner">
          <Right />
        </div>
      </div>
    </div>
    <CopyModal v-if="getModalStatus('email_copy')" @copy="copy" :item="item" />
    <CopyView v-if="getModalStatus('email_copy_view')" :item="item" />
    <Service v-if="getModalStatus('email_service')" :item="item" />
    <AddPattern v-if="getModalStatus('email_add_pattern')" />
    <div id="email-copy-view" class="d-none">
      <a :href="item.urls.email.view">
        <img :src="generatedImageURL" />

        <p
          style="
            margin-top: 10px;
            margin-bottom: 10px;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            line-height: 16px;
            color: #545454;
            text-align: center;
          "
        >
          E-posta imzamı görüntüleyemiyor musunuz? Buraya tıklayın.
        </p>

        <p
          style="
            margin-top: 10px;
            margin-bottom: 10px;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            line-height: 16px;
            color: #545454;
            text-align: center;
          "
        >
          If you can't see my email signature, click here.
        </p>
      </a>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import useProfile from "@/composables/profile";
import store from "@/store";
import { getModalStatus, copyClipboardElement } from "@/utils/Helpers";

import Right from "./Right.vue";
import EmailTemplate from "../EmailTemplate.vue";
import CopyModal from "@/components/Email/Modals/Copy.vue";
import CopyView from "@/components/Email/CopyView.vue";
import Service from "@/components/Email/Modals/Service.vue";
import AddPattern from "@/components/Email/Modals/AddPattern";
import { useMeta } from "vue-meta";
import i18n from "@/utils/i18n.vue";

//meta
useMeta({
  title: i18n.global.t("email_signature.page_title"),
});

const item = computed(() => {
  const item = store.getters["profiles/item"];

  if (item) {
    visibility.value = item.acf.preferences.email_visibility;
  }

  return item;
});

const generatedImageURL = computed(
  () => store.getters["email/generatedImageURL"]
);
const selectedBanner = computed(() => store.getters["email/banner"]);
const visibility = ref({});
const isShowCopyView = ref(false);
const text = computed(() => {
  return store.getters["email/text"];
});
const copy = () => {
  isShowCopyView.value = true;
  setTimeout(() => {
    copyClipboardElement("email-copy-view");
    isShowCopyView.value = false;
  }, 200);
};
</script>
