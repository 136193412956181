import StatsService from "@/services/modules/StatsService";
import i18n from "@/utils/i18n.vue";

export const stats = {
  namespaced: true,
  state: {
    get: [],
    contributions: {},
    tabs: [
      {
        id: "networkit",
        name: i18n.global.t("stats.tabs.tab_1.title"),
        color: "#167FFC",
        colorName: "is-blue",
        subTabs: [
          {
            id: "view",
            name: i18n.global.t("stats.tabs.tab_1.sub_tabs.tab_1.title"),
            chart_title: i18n.global.t(
              "stats.tabs.tab_1.sub_tabs.tab_1.chart_title"
            ),
            legendId: "legend-container",
            tooltip_text: i18n.global.t(
              "stats.tabs.tab_1.sub_tabs.tab_1.description"
            ),
          },
          {
            id: "download",
            name: i18n.global.t("stats.tabs.tab_1.sub_tabs.tab_2.title"),
            chart_title: i18n.global.t(
              "stats.tabs.tab_1.sub_tabs.tab_2.chart_title"
            ),
            legendId: "legend-container-second",
            tooltip_text: i18n.global.t(
              "stats.tabs.tab_1.sub_tabs.tab_2.description"
            ),
          },
          {
            id: "conversion",
            name: i18n.global.t("stats.tabs.tab_1.sub_tabs.tab_3.title"),
            chart_title: i18n.global.t(
              "stats.tabs.tab_1.sub_tabs.tab_3.chart_title"
            ),
            legendId: "legend-container-third",
            tooltip_text: i18n.global.t(
              "stats.tabs.tab_1.sub_tabs.tab_3.description"
            ),
          },
        ],
      },
      {
        id: "card",
        name: i18n.global.t("stats.tabs.tab_2.title"),
        color: "#6C489E",
        colorName: "is-purple",
        subTabs: [
          {
            id: "view",
            name: i18n.global.t("stats.tabs.tab_2.sub_tabs.tab_1.title"),
            chart_title: i18n.global.t(
              "stats.tabs.tab_2.sub_tabs.tab_1.chart_title"
            ),
            legendId: "legend-container",
            tooltip_text: i18n.global.t(
              "stats.tabs.tab_2.sub_tabs.tab_1.description"
            ),
          },
          {
            id: "download",
            name: i18n.global.t("stats.tabs.tab_2.sub_tabs.tab_2.title"),
            chart_title: i18n.global.t(
              "stats.tabs.tab_2.sub_tabs.tab_2.chart_title"
            ),
            legendId: "legend-container-second",
            tooltip_text: i18n.global.t(
              "stats.tabs.tab_2.sub_tabs.tab_2.description"
            ),
          },
          {
            id: "conversion",
            name: i18n.global.t("stats.tabs.tab_2.sub_tabs.tab_3.title"),
            chart_title: i18n.global.t(
              "stats.tabs.tab_2.sub_tabs.tab_3.chart_title"
            ),
            legendId: "legend-container-third",
            tooltip_text: i18n.global.t(
              "stats.tabs.tab_2.sub_tabs.tab_3.description"
            ),
          },
        ],
      },
      {
        id: "email",
        name: i18n.global.t("stats.tabs.tab_3.title"),
        color: "#FCB20E",
        colorName: "is-yellow",
        subTabs: [
          {
            id: "view",
            name: i18n.global.t("stats.tabs.tab_3.sub_tabs.tab_1.title"),
            chart_title: i18n.global.t(
              "stats.tabs.tab_3.sub_tabs.tab_1.chart_title"
            ),
            legendId: "legend-container",
            tooltip_text: i18n.global.t(
              "stats.tabs.tab_3.sub_tabs.tab_1.description"
            ),
          },
          {
            id: "download",
            name: i18n.global.t("stats.tabs.tab_3.sub_tabs.tab_2.title"),
            chart_title: i18n.global.t(
              "stats.tabs.tab_3.sub_tabs.tab_2.chart_title"
            ),
            legendId: "legend-container-second",
            tooltip_text: i18n.global.t(
              "stats.tabs.tab_3.sub_tabs.tab_2.description"
            ),
          },
          {
            id: "conversion",
            name: i18n.global.t("stats.tabs.tab_3.sub_tabs.tab_3.title"),
            chart_title: i18n.global.t(
              "stats.tabs.tab_3.sub_tabs.tab_3.chart_title"
            ),
            legendId: "legend-container-third",
            tooltip_text: i18n.global.t(
              "stats.tabs.tab_3.sub_tabs.tab_3.description"
            ),
          },
        ],
      },
      {
        id: "bg",
        name: i18n.global.t("stats.tabs.tab_4.title"),
        color: "#E8146D",
        colorName: "is-pink",
        subTabs: [
          {
            id: "view",
            name: i18n.global.t("stats.tabs.tab_4.sub_tabs.tab_1.title"),
            chart_title: i18n.global.t(
              "stats.tabs.tab_4.sub_tabs.tab_1.chart_title"
            ),
            legendId: "legend-container",
            tooltip_text: i18n.global.t(
              "stats.tabs.tab_4.sub_tabs.tab_1.description"
            ),
          },
          {
            id: "download",
            name: i18n.global.t("stats.tabs.tab_4.sub_tabs.tab_2.title"),
            chart_title: i18n.global.t(
              "stats.tabs.tab_4.sub_tabs.tab_2.chart_title"
            ),
            legendId: "legend-container-second",
            tooltip_text: i18n.global.t(
              "stats.tabs.tab_4.sub_tabs.tab_2.description"
            ),
          },
          {
            id: "conversion",
            name: i18n.global.t("stats.tabs.tab_4.sub_tabs.tab_3.title"),
            chart_title: i18n.global.t(
              "stats.tabs.tab_4.sub_tabs.tab_3.chart_title"
            ),
            legendId: "legend-container-third",
            tooltip_text: i18n.global.t(
              "stats.tabs.tab_4.sub_tabs.tab_3.description"
            ),
          },
        ],
      },
    ],
    activeTab: 0,
    activeSubTab: 0,
    selectedItem: null,
    isLoading: true,
    selectedDates: {
      start: null,
      end: null,
    },
  },
  actions: {
    get({ commit }, payload = {}) {
      return StatsService.get(payload).then(
        (data) => {
          commit("get", data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    contributions({ commit }) {
      return StatsService.contributions().then(
        (data) => {
          commit("contributions", data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    get(state, data) {
      state.get = data;
    },
    activeTab(state, data) {
      state.activeTab = data;
    },
    activeSubTab(state, data) {
      state.activeSubTab = data;
    },
    contributions(state, data) {
      state.contributions = data;
    },
    setSelectedItem(state, data) {
      state.selectedItem = data;
    },
    setIsLoading(state, data) {
      state.isLoading = data;
    },
    setSelectedDates(state, data) {
      state.selectedDates = data;
    },
  },
  getters: {
    get: (state) => {
      return state.get;
    },
    contributions: (state) => {
      return state.contributions;
    },
    tabs: (state) => {
      return state.tabs;
    },
    activeTab: (state) => {
      return state.tabs[state.activeTab];
    },
    subTabs: (state) => {
      return state.tabs[state.activeTab].subTabs;
    },
    activeSubTab: (state) => {
      return state.tabs[state.activeTab].subTabs[state.activeSubTab];
    },
    isLoading: (state) => {
      return state.isLoading;
    },
    selectedDates: (state) => {
      return state.selectedDates;
    },
    statsFiltered: (state, getters) => {
      const stats = getters.get;
      const statsStatus = stats.success;
      const statsData = stats.data;
      const filtered = [];
      const activeTab = getters.activeTab;
      const activeSubTab = getters.activeSubTab;
      const filteredByParentTab = statsData?.[activeTab.id];

      if (statsStatus) {
        if (activeSubTab.id === "conversion") {
          filtered.push({
            id: "view",
            data: filteredByParentTab?.view,
            ...filteredByParentTab,
          });
          filtered.push({
            id: "download",
            data: filteredByParentTab?.download,
            ...filteredByParentTab,
          });
        } else {
          filtered.push({
            id: activeSubTab.id,
            data: filteredByParentTab?.[activeSubTab.id],
            summary: filteredByParentTab?.summary,
            ...filteredByParentTab,
          });
        }
      }

      return filtered;
    },
    selectedItem: (state) => {
      return state.selectedItem;
    },
  },
};
