<template>
  <div class="profile-creation-head">
    <div class="profile-creation-head-title">
      <h1>
        {{ props.title }}
      </h1>
    </div>
    <div class="profile-creation-head-nav">
      <div class="profile-creation-head-nav-block">
        <div
          v-if="
            props.addProfile &&
            workspace &&
            !(workspace.type === 'corporate' && workspace.role === 'owner')
          "
          :class="[
            'profile-creation-head-nav-item is-profile-add',
            { 'is-active': !activeId },
          ]"
        >
          <a @click="addProfile" href="javascript:void(0)">
            <div class="profile-creation-head-nav-item-content">
              <div class="profile-creation-head-nav-item-content-bg">
                <img src="/img/static/icon-plus.svg" alt="" />
              </div>
              <div class="profile-creation-head-nav-item-content-title">
                <span>
                  {{ $t("profile.add") }}
                </span>
              </div>
            </div>
          </a>
        </div>
        <template v-if="!items">
          <ItemSkeleton v-for="n in 5" :key="n" />
        </template>
        <div
          v-else
          :class="[
            'profile-creation-head-nav-item',
            `is-${item.acf.color}`,
            { 'is-active': activeId == item.id, 'corporate-profile': !item.is_self },
          ]"
          v-for="item in items"
          :key="item.id"
        >
          <router-link
            :to="{ name: `${itemRoutePath}-view`, params: { id: item.id } }"
          >
            <div class="profile-creation-head-nav-item-content">
              <div class="profile-creation-head-nav-item-content-bg"></div>
              <div class="profile-creation-head-nav-item-content-title">
                <span>
                  {{ item.title }}
                </span>
              </div>
              <div
                class="profile-creation-head-nav-item-content-profile"
                v-if="!item.is_self"
              >
                <span> {{ $t("profile.corporate_main") }} </span>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import store from "@/store";
import { MembershipLevelEnum } from "@/enums";
import ItemSkeleton from "./ItemSkeleton.vue";
import { setModalStatus } from "@/utils/Helpers";

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const bgTypes = ["is-individual", "is-job", "is-email", "is-telephone"];
const accountCan = computed(() => store.getters["account/can"]);
const workspace = computed(() => store.getters["account/workspace"]);
const activeId = computed(() => route.params.id);
const itemRoutePath = ref("profile");

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  addProfile: {
    type: Boolean,
    default: true,
  },
});

const items = computed(() => {
  const items = store.getters["profiles/items"];

  return items;
});

const addProfile = () => {
  if (!accountCan.value.create_profile) {
    setModalStatus("profile_premium", true);
  } else {
    router.push({ name: "profile-create" });
  }
};

onMounted(() => {
  const path = route.path.split("/")[3];
  itemRoutePath.value = path;

  if (!items.value) {
    store.dispatch("profiles/items");
  }
});
</script>
