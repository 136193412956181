import { createRouter, createWebHistory } from "vue-router";
import VueBodyClass from "vue-body-class";
import store from "@/store";

// Pages
import Login from "@/pages/Auth/Login.vue";
import Forgot from "@/pages/Auth/Forgot.vue";
import PasswordReset from "@/pages/Auth/PasswordReset.vue";
import Register from "@/pages/Auth/Register.vue";
import SetEmail from "@/pages/Auth/SetEmail.vue";
import Onboarding from "@/pages/Onboarding/index.vue";
import ProfileCreate from "@/pages/Profile/Create.vue";
import ProfileEdit from "@/pages/Profile/Edit.vue";
import ProfileView from "@/pages/Profile/View.vue";
import EmailView from "@/pages/Email/View.vue";
import BgView from "@/pages/VirtualBackground/View.vue";
import Stats from "@/pages/Stats/Index.vue";
import Settings from "@/pages/Settings/Index.vue";
import Notifications from "@/pages/Notifications/Index.vue";
import Support from "@/pages/Support/Index.vue";
import Members from "@/pages/Members/Index.vue";
import Network from "@/pages/Network/Index.vue";
import Teams from "@/pages/Teams/Index.vue";
import TeamView from "@/pages/Teams/View.vue";

// Archived
import Notes from "@/pages/Archived/Notes/Index.vue";
import Reminders from "@/pages/Archived/Reminders/Index.vue";

// Public Pages
import ProfilePublicView from "@/pages/Public/Profile/View.vue";

// Middlewares
import auth from "@/middlewares/auth";
import isAccess from "@/middlewares/isAccess";
import profileRedirect from "@/middlewares/profileRedirect";

const routes = [
  {
    name: "login",
    path: "/login",
    component: Login,
    meta: { bodyClass: "body-login body-second-version" },
  },
  {
    name: "forgot",
    path: "/forgot",
    component: Forgot,
    meta: { bodyClass: "body-reset-password body-second-version" },
  },
  {
    name: "password-reset",
    path: "/password-reset/:token",
    component: PasswordReset,
    beforeEnter: [
      function (to, from, next) {
        // const token = to.params.token;
        // if (token) {
        //   store
        //     .dispatch("auth/resetPasswordTokenCheck", { token })
        //     .then(() => {
        //       next();
        //     })
        //     .catch(() => {
        //       router.push({ name: "login" });
        //     });
        // } else {
        //   next({ name: "login" });
        // }

        next();
      },
    ],
    meta: { bodyClass: "body-reset-password body-second-version" },
  },
  {
    name: "set-email",
    path: "/set-email",
    component: SetEmail,
    meta: { bodyClass: "body-reset-password body-second-version" },
    beforeEnter: [
      auth,
      function (to, from, next) {
        const auth = store.getters["auth/status"];
        if (auth.user.user_email) {
          next({
            name: "profile-main",
            params: {
              workspace: auth.user.last_workspace,
            },
          });
        } else {
          next();
        }
      },
    ],
  },
  {
    name: "signup",
    path: "/sign-up",
    component: Register,
    meta: { bodyClass: "body-login body-second-version" },
  },
  {
    name: "onboarding",
    path: "/onboarding",
    component: Onboarding,
    meta: { bodyClass: "body-second-version body-onboarding" },
    beforeEnter: [
      auth,
      function (to, from, next) {
        const auth = store.getters["auth/status"];
        if (auth.user.onboarding) {
          next({
            name: "profile-main",
            params: {
              workspace: auth.user.last_workspace,
            },
          });
        } else {
          next();
        }
      },
    ],
  },
  {
    name: "workspace",
    path: "/w/:workspace",
    beforeEnter: [auth],
    children: [
      {
        name: "workspace-main",
        path: "",
        beforeEnter: [profileRedirect],
      },
      {
        name: "profile",
        path: "profile",
        beforeEnter: [auth],
        children: [
          {
            name: "profile-main",
            path: "",
            beforeEnter: [profileRedirect],
          },
          {
            name: "profile-create",
            path: "create",
            component: ProfileCreate,
            meta: {
              bodyClass:
                "body-first-version body-profile body-profile-creation",
            },
          },
          {
            name: "profile-edit",
            path: ":id/edit",
            component: ProfileEdit,
            meta: {
              bodyClass:
                "body-first-version body-profile body-profile-creation",
            },
          },
          {
            name: "profile-view",
            path: ":id/view",
            component: ProfileView,
            meta: {
              bodyClass: "body-digital-profile",
            },
          },
        ],
      },
      {
        name: "email",
        path: "email",
        beforeEnter: [auth, isAccess],
        children: [
          {
            name: "email-main",
            path: "",
            beforeEnter: [profileRedirect],
          },
          {
            name: "email-view",
            path: ":id/view",
            component: EmailView,
            meta: {
              bodyClass: "body-first-version body-email-signature",
            },
          },
        ],
      },
      {
        name: "bg",
        path: "bg",
        beforeEnter: [auth, isAccess],
        children: [
          {
            name: "bg-main",
            path: "",
            beforeEnter: [profileRedirect],
          },
          {
            name: "bg-view",
            path: ":id/view",
            component: BgView,
            meta: {
              bodyClass: "body-first-version body-virtual-background",
            },
          },
        ],
      },
      {
        name: "stats",
        path: "stats",
        beforeEnter: [auth],
        children: [
          {
            name: "stats-main",
            path: "",
            beforeEnter: [profileRedirect],
          },
          {
            name: "stats-view",
            path: ":id/view",
            component: Stats,
            meta: {
              bodyClass: "body-first-version body-email-signature",
            },
          },
        ],
      },
      {
        name: "teams",
        path: "teams",
        beforeEnter: [auth],
        children: [
          {
            name: "teams-main",
            path: "",
            component: Teams,
            meta: {
              bodyClass: "body-first-version body-teams",
            },
          },
          {
            name: "team-view",
            path: ":id",
            component: TeamView,
            meta: {
              bodyClass: "body-first-version body-teams-member",
            },
          },
        ],
      },
      {
        name: "members",
        path: "members",
        component: Members,
        beforeEnter: auth,
        meta: {
          bodyClass: "body-first-version body-members",
        },
      },
      {
        name: "network",
        path: "network",
        component: Network,
        beforeEnter: [auth],
        meta: {
          bodyClass: "body-first-version body-network-pool",
        },
      },
      {
        name: "notifications",
        path: "notifications",
        component: Notifications,
        beforeEnter: auth,
        meta: {
          bodyClass: "body-first-version body-notifications",
        },
      },
      {
        name: "settings",
        path: "settings",
        beforeEnter: auth,
        children: [
          {
            name: "settings-main",
            path: "",
            component: Settings,
            meta: {
              bodyClass: "body-settings",
            },
          },
          {
            name: "archived-notes",
            path: "archived/notes",
            component: Notes,
            meta: {
              bodyClass: "body-first-version body-archived",
            },
          },
          {
            name: "archived-reminders",
            path: "archived/reminders",
            component: Reminders,
            meta: {
              bodyClass: "body-first-version body-archived",
            },
          },
        ],
      },
      {
        name: "support",
        path: "support",
        component: Support,
        beforeEnter: auth,
        meta: {
          bodyClass: "body-first-version body-help-center",
        },
      },
    ],
  },
  {
    name: "logout",
    path: "/logout",
    component: Stats,
    beforeEnter: (to, from, next) => {
      store.dispatch("auth/logout");
      next({ name: "login" });
    },
    meta: {
      bodyClass: "body-first-version body-statistics",
    },
  },
  {
    name: "profile-public-view",
    path: "/profile/:profileSlug-:profileId(\\d+)",
    component: ProfilePublicView,
    meta: {
      bodyClass: "body-first-version body-business-card-view",
    },
  },
  {
    // except one page, all other pages will be redirected to login page
    path: "/:catchAll(.*)",
    redirect: { name: "login" },
    beforeEnter: (to, from, next) => {
      const auth = stor;
    },
  },
];

const vueBodyClass = new VueBodyClass(routes);

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const me = store.getters["account/me"];

  if (me && me.current_workspace) {
    const type = me.current_workspace.type;
    const role = me.current_workspace.role;

    setTimeout(() => {
      store.commit("common/apply_mode", { type, role });
    }, 1);
  }

  vueBodyClass.guard(to, next);
});

router.beforeEach((to, from, next) => {
  setTimeout(() => {
    store.commit("common/set_sidebar_status", false);
  }, 300);

  const activeModals = store.getters["common/active_modals"];
  const toName = to.name;
  const excludeNames = [
    "email-main",
    "email-view",
    "bg-main",
    "bg-view",
    "stats-main",
    "stats-view",
  ];
  // if (to.name.includes("main") && from.name.includes("view")) {
  //   return next();
  // }
  if (activeModals.length && excludeNames.indexOf(toName) === -1) {
    store.commit("common/close_all_modals");
  }

  next();
});

export default router;
