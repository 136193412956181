import PaymentService from "../services/modules/PaymentService";

export const payment = {
  namespaced: true,
  state: {
    list: [],
  },
  actions: {
    form({ commit }, payload) {
      return PaymentService.form(payload).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {},
  getters: {},
};
