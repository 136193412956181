import { axios } from "@/services/axios";
import AuthHeader from "../AuthHeader";

export const list = (payload) => {
  return axios
    .get("members", {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const assignRole = (payload) => {
  return axios
    .post("members/assign-role", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const changeMembership = (payload) => {
  return axios
    .post("members/change-membership", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const reset = (payload) => {
  return axios
    .post("members/reset", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default {
  list,
  assignRole,
  changeMembership,
  reset,
};
