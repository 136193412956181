<template>
  <div class="settings-accordion-item accordion-item">
    <div
      class="settings-accordion-item-btn accordion-header"
      id="packages-heading"
      data-bs-toggle="collapse"
      data-bs-target="#packages"
      aria-expanded="false"
      aria-controls="packages"
    >
      <h2 class="accordion-button">
        {{ $t("settings.packages.title") }}
      </h2>
    </div>
    <div
      id="packages"
      class="settings-accordion-item-content accordion-collapse collapse"
      data-bs-parent="#settings-accordions"
      aria-labelledby="packages-heading"
    >
      <div class="accordion-body">
        <div
          class="settings-accordion-item-content-block"
          v-if="packages.length"
        >
          <div class="settings-accordion-item-content-block-switch">
            <div class="settings-accordion-item-content-block-switch-title">
              <span>
                {{ $t("settings.packages.monthly") }}
              </span>
            </div>
            <div class="settings-accordion-item-content-block-switch-toggle">
              <input
                type="checkbox"
                id="check-toggle-settings"
                @change="togglePeriod"
              />
              <label for="check-toggle-settings"></label>
            </div>
            <div class="settings-accordion-item-content-block-switch-title">
              <span>
                {{ $t("settings.packages.yearly") }}
              </span>
            </div>
          </div>
          <div
            class="settings-accordion-item-content-block-item is-monthly"
            v-if="currentPackage"
          >
            <div class="settings-accordion-item-content-block-item-head">
              <div class="settings-accordion-item-content-block-item-head-card">
                <div
                  class="settings-accordion-item-content-block-item-head-card-content"
                >
                  <div
                    class="settings-accordion-item-content-block-item-head-card-content-title"
                  >
                    <h5>{{ currentPackage.name }}</h5>
                  </div>
                  <div
                    class="settings-accordion-item-content-block-item-head-card-content-price"
                  >
                    <span v-if="!currentPackage.isFree"
                      >{{ packagePrice(currentPackage.id) }} ₺</span
                    >
                    <span v-if="!currentPackage.isFree"
                      >/ {{ periodName }}</span
                    >
                  </div>
                  <div
                    class="settings-accordion-item-content-block-item-head-card-content-available"
                  >
                    <span>
                      {{ $t("settings.packages.current_package_text") }}
                    </span>
                  </div>
                </div>
                <div
                  class="settings-accordion-item-content-block-item-head-card-button"
                >
                  <button type="button">
                    {{ $t("settings.packages.choose") }}
                  </button>
                  <button
                    type="button"
                    class="membership-cancel"
                    v-if="!currentPackage.isFree"
                    @click="cancelMembership"
                  >
                    {{ $t("settings.packages.cancel") }}
                  </button>
                </div>
              </div>
              <div
                class="settings-accordion-item-content-block-item-head-list"
                v-if="otherPackage && isFree"
              >
                <div
                  class="settings-accordion-item-content-block-item-head-list-title"
                >
                  <h5>{{ otherPackage.name }}</h5>
                </div>
                <div
                  class="settings-accordion-item-content-block-item-head-list-price"
                >
                  <span v-if="!otherPackage.isFree"
                    >{{ packagePrice(otherPackage.id) }} ₺</span
                  >
                  <span v-if="!otherPackage.isFree">/ {{ periodName }}</span>
                </div>
                <div
                  class="settings-accordion-item-content-block-item-head-list-block"
                >
                  <div
                    class="settings-accordion-item-content-block-item-head-list-block-title"
                  >
                    <span>{{ otherPackage.features.title }}</span>
                  </div>
                  <div
                    class="settings-accordion-item-content-block-item-head-list-block-item"
                    v-for="item in otherPackage.features.items"
                    :key="item"
                  >
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.8332 4L6.49984 11.3333L3.1665 8"
                        stroke="#545454"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <span>
                      {{ item.feature }}
                    </span>
                  </div>
                </div>
                <div
                  class="settings-accordion-item-content-block-item-head-list-button"
                >
                  <Button
                    type="button"
                    @click="chosePackage"
                    :state="buttonState"
                    class="btn btn-md btn-primary-blue btn-round-8 btn-500"
                  >
                    {{ $t("settings.packages.choose") }}
                  </Button>
                </div>
              </div>
            </div>
            <div
              class="settings-accordion-item-content-block-item-discount"
              v-if="!isFree"
            >
              <div
                class="settings-accordion-item-content-block-item-discount-head"
              >
                <h5>
                  {{ $t("settings.packages.card.title") }}
                </h5>
                <p>
                  {{ $t("settings.packages.card.content", {
                    discount: `%${options.janus_card_discount}`,
                  }) }}
                </p>
              </div>
              <div
                class="settings-accordion-item-content-block-item-discount-price"
              >
                <div
                  class="settings-accordion-item-content-block-item-discount-price-title"
                >
                  <span>{{ $t("settings.packages.card.subtitle") }}</span>
                </div>
                <div
                  class="settings-accordion-item-content-block-item-discount-price-sub"
                >
                  <span>{{ options.janus_card_regular_price }} ₺</span>
                </div>
                <div
                  class="settings-accordion-item-content-block-item-discount-price-main"
                >
                  <span v-html="options.janus_card_sale_price"></span>
                </div>
                <div
                  class="settings-accordion-item-content-block-item-discount-price-button"
                >
                  <button
                    type="button"
                    class="settings-accordion-item-content-block-item-discount-price-button-buy"
                    @click="buyCard"
                  >
                    {{ $t("settings.packages.buy") }}
                  </button>
                </div>
              </div>
            </div>

            <Pairing v-if="getModalStatus('settings_package_pairing')" />
          </div>
          <div
            class="settings-accordion-item-content-block-item-plus"
            v-if="isFree && false"
          >
            <div class="settings-accordion-item-content-block-item-plus-title">
              <h5>
                {{ $t("settings.packages.plus.title") }}
              </h5>
              <p>
                {{ $t("settings.packages.plus.content") }}
              </p>
            </div>
            <div
              class="settings-accordion-item-content-block-item-plus-packets"
            >
              <div
                class="settings-accordion-item-content-block-item-plus-packets-item"
              >
                <div
                  class="settings-accordion-item-content-block-item-plus-packets-item-label"
                >
                  <span>
                    {{ $t("settings.packages.plus.item_1_title") }}
                  </span>
                </div>
                <div
                  class="settings-accordion-item-content-block-item-plus-packets-item-price"
                >
                  <span>{{ packagePrice(otherPackage.id) }} ₺</span>
                </div>
              </div>
              <div
                class="settings-accordion-item-content-block-item-plus-packets-plus"
              >
                <span>+</span>
              </div>
              <div
                class="settings-accordion-item-content-block-item-plus-packets-item"
              >
                <div
                  class="settings-accordion-item-content-block-item-plus-packets-item-label"
                >
                  <span>
                    {{ $t("settings.packages.plus.item_2_title") }}
                  </span>
                </div>
                <div
                  class="settings-accordion-item-content-block-item-plus-packets-item-price"
                >
                  <span>{{ options.janus_card_regular_price }} ₺</span>
                </div>
              </div>
            </div>
            <div class="settings-accordion-item-content-block-item-plus-total">
              <div
                class="settings-accordion-item-content-block-item-plus-total-label"
              >
                <span>
                  {{ $t("settings.packages.total") }}
                </span>
              </div>
              <div
                class="settings-accordion-item-content-block-item-plus-total-discount"
              >
                <span
                  >{{
                    packagePrice(otherPackage.id) +
                    parseInt(options.janus_card_regular_price)
                  }}
                  ₺</span
                >
              </div>
              <div
                class="settings-accordion-item-content-block-item-plus-total-price"
              >
                <span>{{ nPlusTotalPrice() }} ₺</span>
              </div>
            </div>
            <div class="settings-accordion-item-content-block-item-plus-button">
              <button
                type="button"
                class="settings-accordion-item-content-block-item-discount-price-button-buy"
                @click="buyCard"
              >
                {{ $t("settings.packages.buy") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import store from "@/store";
import Button from "@/components/inputs/Button";
import { MembershipLevelEnum } from "@/enums";
import { setModalStatus, getModalStatus } from "@/utils/Helpers";
import Pairing from "@/components/Settings/Modals/Pairing";
import { useToast } from "bootstrap-vue-3";
import i18n from "@/utils/i18n.vue";

const formContent = ref(null);
const toast = useToast();

const periods = [
  {
    slug: "monthly",
    name: i18n.global.t("settings.packages.monthly"),
  },
  {
    slug: "yearly",
    name: i18n.global.t("settings.packages.yearly"),
  },
];

const buttonState = ref("default");
const selectedPeriod = ref("yearly");
const freePackageMock = {
  id: 1,
  name: i18n.global.t("settings.packages.free.title"),
  price: 0,
  isFree: true,
  features: {
    title: i18n.global.t("settings.packages.free.features.title"),
    items: [
      {
        feature: i18n.global.t("settings.packages.free.features.item_1"),
      },
      {
        feature: i18n.global.t("settings.packages.free.features.item_2"),
      },
      {
        feature: i18n.global.t("settings.packages.free.features.item_3"),
      },
    ],
  },
  variations: [],
};
const packages = computed(() => store.getters["product/list"]);
const me = computed(() => store.getters["account/me"]);
const options = computed(() => store.getters["misc/options"]);
const membershipLevel = computed(() => me.value.membership_level);

const isFree = computed(
  () => membershipLevel.value === MembershipLevelEnum.FREE
);

const currentPackage = computed(() => {
  if (isFree.value) {
    return freePackageMock;
  }

  return packages.value[0];
});

const otherPackage = computed(() => {
  if (isFree.value && packages.value.length) {
    return packages.value[0];
  }

  return freePackageMock;
});

const togglePeriod = (event) => {
  const isChecked = event.target.checked;
  selectedPeriod.value = isChecked ? "monthly" : "yearly";
};

const periodName = computed(() => {
  const period = periods.find((p) => p.slug === selectedPeriod.value);
  return period.name;
});

const packagePrice = (packageId) => {
  const packageItem = packages.value.find((p) => p.id === packageId);
  const variant = packageItem.variations.find(
    (v) => v.type === selectedPeriod.value
  );

  const price = variant.price;

  // return paseInt(price);
  return price;
};

const chosePackage = () => {
  const billingStatus = me.value.billing.status;
  const chosenPackage = otherPackage.value;
  const period = selectedPeriod.value;
  const chosenPackageByPeriod = chosenPackage.variations.find(
    (v) => v.type === period
  );

  if (!billingStatus) {
    toast.show({
      title: i18n.global.t("misc.error_occurred"),
      body: i18n.global.t("settings.packages.billing_not_active"),
      autoHideDelay: 5000,
    });
    return;
  }

  buttonState.value = "loading";

  store
    .dispatch("payment/form", {
      product_id: chosenPackageByPeriod.id,
    })
    .then((response) => {
      localStorage.setItem("show_payment_status_modal", true);

      const status = response.status;
      const message = response.message;

      if (status) {
        const html = response.data.checkout_form_content;
        const script = document.createElement("script");
        script.innerHTML = html;

        document.body.appendChild(script);
      } else {
        setModalStatus("settings_payment_status", true, {
          message,
        });
      }

      buttonState.value = "default";
    });
};

const nPlusTotalPrice = () => {
  const cardPrice = options.value.janus_card_regular_price;
  const packagePriceVariable = packagePrice(otherPackage.value.id);
  const discountPercent = 20;
  const salePrice = packagePriceVariable + parseInt(cardPrice);

  const discountPrice = salePrice - (salePrice * discountPercent) / 100;

  return discountPrice;
};

const buyCard = () => {
  setModalStatus("settings_package_pairing", true, {
    buy_page: options.value.janus_card_buy_page,
  });
};

const cancelMembership = () => {
  setModalStatus("settings_membership_cancel", true);
};

onMounted(() => {
  store.dispatch("misc/options");

  if (me.value?.membership_period_type) {
    selectedPeriod.value = me.value.membership_period_type;
  }
});
</script>
