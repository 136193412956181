<template>
  <Bar :is-active="selectedNetworks.length" />
  <div class="network-pool-body-content">
    <div class="network-pool-body-content-head">
      <div
        class="network-pool-body-content-head-name"
        @click="setOrderBy('full_name')"
      >
        <span
          :class="[
            {
              ascending:
                selectedOrderBy == 'full_name' && selectedOrder == 'asc',
              descending:
                selectedOrderBy == 'full_name' && selectedOrder == 'desc',
            },
          ]"
        >
          {{ $t("network.columns.name") }}
        </span>
      </div>
      <div
        class="network-pool-body-content-head-title"
        @click="setOrderBy('title')"
      >
        <span
          :class="[
            {
              ascending: selectedOrderBy == 'title' && selectedOrder == 'asc',
              descending: selectedOrderBy == 'title' && selectedOrder == 'desc',
            },
          ]"
        >
          {{ $t("network.columns.title") }}
        </span>
      </div>
      <div
        class="network-pool-body-content-head-company"
        @click="setOrderBy('company_name')"
      >
        <span
          :class="[
            {
              ascending:
                selectedOrderBy == 'company_name' && selectedOrder == 'asc',
              descending:
                selectedOrderBy == 'company_name' && selectedOrder == 'desc',
            },
          ]"
        >
          {{ $t("network.columns.company") }}
        </span>
      </div>
      <div
        class="network-pool-body-content-head-location"
        @click="setOrderBy('location')"
      >
        <span
          :class="[
            {
              ascending:
                selectedOrderBy == 'location' && selectedOrder == 'asc',
              descending:
                selectedOrderBy == 'location' && selectedOrder == 'desc',
            },
          ]"
        >
          {{ $t("network.columns.location") }}
        </span>
      </div>
      <div class="network-pool-body-content-head-label">
        <span>
          {{ $t("network.columns.tags") }}
        </span>
      </div>
      <div
        class="network-pool-body-content-head-date"
        @click="setOrderBy('created_at')"
      >
        <span
          :class="[
            {
              ascending:
                selectedOrderBy == 'created_at' && selectedOrder == 'asc',
              descending:
                selectedOrderBy == 'created_at' && selectedOrder == 'desc',
            },
          ]"
        >
          {{ $t("network.columns.date") }}
        </span>
      </div>
      <div :class="['network-pool-body-content-head-adding']">
        <span class="no-sorting">
          {{ $t("network.columns.added") }}
        </span>
      </div>
    </div>
    <div class="network-pool-body-content-item no-connection">
      <div class="network-pool-body-content-item-block">
        <div class="network-pool-body-content-item-block-image">
          <img
            src="/img/static/members-body-content-item-block-image.png"
            alt=""
          />
        </div>
        <div class="network-pool-body-content-item-block-description">
          <p>
            Yeni bir bağlantı eklemek için
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="11.5" cy="11.5" r="11.5" fill="white" />
              <rect x="10.5" y="5.5" width="1.5" height="12.5" fill="#167FFC" />
              <rect
                x="5"
                y="12.5"
                width="1.5"
                height="12.5"
                transform="rotate(-90 5 12.5)"
                fill="#167FFC"
              />
            </svg>
            butonuna basabilirsin.
          </p>
        </div>
      </div>
    </div>
    <div class="network-pool-body-content-item there-are-connection">
      <div class="network-pool-body-content-item-block">
        <ListItem v-for="item in items" :key="item.name" :item="item" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import store from "@/store";
import ListItem from "./ListItem.vue";
import Bar from "@/components/Network/Bar.vue";

const selectedOrderBy = computed(
  () => store.getters["network/selectedOrderBy"]
);
const selectedOrder = computed(() => store.getters["network/selectedOrder"]);
const currentWorkspace = store.getters["account/workspace"];
const items = computed(() => store.getters["network/items"]);
const selectedNetworks = computed(() => {
  return store.getters["network/selectedNetworks"];
});

const setOrderBy = (orderBy) => {
  store.commit("network/setSelectedOrderBy", orderBy);
  setOrder();
};

const setOrder = () => {
  const currentOrder = store.getters["network/selectedOrder"];

  store.commit(
    "network/setSelectedOrder",
    currentOrder === "asc" ? "desc" : "asc"
  );
};
</script>
