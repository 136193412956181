<template>
  <div class="statistics-picker-date"
       v-if="me?.id"
  >
    <div class="statistics-picker-date-label">
      <litepie-datepicker
        trigger="open-datepicker"
        v-model="dateValue"
        :formatter="dateFormatter"
        :shortcuts="shortcuts"
      ></litepie-datepicker>

      <button type="button">
        <span id="open-datepicker">{{ pickerText }}</span>
        <svg
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.9583 4.83398H6.04167C4.70698 4.83398 3.625 5.91596 3.625 7.25065V24.1673C3.625 25.502 4.70698 26.584 6.04167 26.584H22.9583C24.293 26.584 25.375 25.502 25.375 24.1673V7.25065C25.375 5.91596 24.293 4.83398 22.9583 4.83398Z"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M19.3359 2.41602V7.24935"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.66406 2.41602V7.24935"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.625 12.084H25.375"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import store from "@/store";
import i18n from "@/utils/i18n";
import { it } from "date-fns/locale";
import { useRoute, useRouter } from "vue-router";
import * as dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
dayjs.extend(weekday);

const pickerText = ref(
    dayjs().weekday(1).format("YYYY-MM-DD") +
    " - " +
    dayjs().weekday(7).format("YYYY-MM-DD")
);

const me = computed(() => {
  const me = store.getters["account/me"];

  if (me) {
    pickerText.value = me.register_date + " - " + dayjs().format("YYYY-MM-DD");

    return me;
  }
});
const route = useRoute();
const dateFormatter = {
  date: "YYYY-MM-DD ",
  month: "MMM",
};

const dateValue = ref([]);
const shortcuts = () => {
  return [
    {
      label: i18n.global.t("stats.date.today"),
      atClick: () => {
        const date = new Date();
        return [
          dayjs(date).format("YYYY-MM-DD"),
          dayjs(date).format("YYYY-MM-DD"),
        ];
      },
    },
    {
      label: i18n.global.t("stats.date.yesterday"),
      atClick: () => {
        const date = new Date();
        return [
          dayjs(date).subtract(1, "day").format("YYYY-MM-DD"),
          dayjs(date).subtract(1, "day").format("YYYY-MM-DD"),
        ];
      },
    },
    {
      label: i18n.global.t("stats.date.last_7_days"),
      atClick: () => {
        const date = new Date();
        return [
          dayjs(date).subtract(7, "day").format("YYYY-MM-DD"),
          dayjs(date).format("YYYY-MM-DD"),
        ];
      },
    },
    {
      label: i18n.global.t("stats.date.last_30_days"),
      atClick: () => {
        const date = new Date();
        return [
          dayjs(date).subtract(30, "day").format("YYYY-MM-DD"),
          dayjs(date).format("YYYY-MM-DD"),
        ];
      },
    },
    {
      label: i18n.global.t("stats.date.this_month"),
      atClick: () => {
        const date = new Date();
        return [
          dayjs(date).startOf("month").format("YYYY-MM-DD"),
          dayjs(date).endOf("month").format("YYYY-MM-DD"),
        ];
      },
    },
    {
      label: i18n.global.t("stats.date.last_month"),
      atClick: () => {
        const date = new Date();
        return [
          dayjs(date)
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD"),
          dayjs(date).subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
        ];
      },
    },
    {
      label: i18n.global.t("stats.date.this_year"),
      atClick: () => {
        const date = new Date();
        return [
          dayjs(date).startOf("year").format("YYYY-MM-DD"),
          dayjs(date).endOf("year").format("YYYY-MM-DD"),
        ];
      },
    },
    {
      label: i18n.global.t("stats.date.last_year"),
      atClick: () => {
        const date = new Date();
        return [
          dayjs(date).subtract(1, "year").startOf("year").format("YYYY-MM-DD"),
          dayjs(date).subtract(1, "year").endOf("year").format("YYYY-MM-DD"),
        ];
      },
    },
  ];
};

const { id } = route.params;

watch(
  dateValue,
  (val) => {
    store.commit("stats/setIsLoading", true);
    store.commit("stats/setSelectedDates", {
      start: val[0],
      end: val[1],
    });
    store
      .dispatch("stats/get", {
        start_date: val[0],
        end_date: val[1],
        profile_id: id,
      })
      .then(() => {
        store.commit("stats/setIsLoading", false);
      });

    pickerText.value = `${val[0]} - ${val[1]}`;
  },
  { deep: true }
);
</script>

<style>
@import "../../index.css";
</style>
