<template>
  <div class="email-signature-add-title">
    <h3>{{ $t("email_signature.add") }}</h3>
  </div>
  <div class="email-signature-add-buttons" v-if="tabs">
    <swiper
      class="email-signature-buttons-slider"
      :slides-per-view="5"
      :space-between="10"
      :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
      :breakpoints="{
        320: {
          slidesPerView: 'auto',
        },
      }"
    >
      <swiper-slide v-for="(tab, index) in tabs" :key="index">
        <a
          href="javascript:;"
          :class="[
            'btn btn-network btn-round-8 btn-500',
            tab.color,
            { 'is-active': activeTabIndex === index },
          ]"
          @click="activeTabIndex = index"
        >
          <img :src="`/img/static/icon-logo-${tab.id}.svg`" :alt="tab.name" />
          <span>{{ tab.name }}</span>
        </a>
      </swiper-slide>
    </swiper>
    <div class="email-signature-buttons-slider-buttons">
      <div class="swiper-button-next">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 18L15 12L9 6"
            stroke="#545454"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="swiper-button-prev">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 18L9 12L15 6"
            stroke="#545454"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>
  <div :class="['email-signature-network-content', activeTab.colorClass]">
    <div class="email-signature-network-content-prev-btn">
      <button
        type="button"
        class="btn btn-text btn-sm btn-secondary-orange btn-500"
      >
        <i data-position="left">
          <svg
            width="12"
            height="22"
            viewBox="0 0 12 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 20L1.5 11L10.5 2"
              stroke="#FF7A59"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </i>
        {{ activeTab.title }}
      </button>
    </div>
    <div class="email-signature-network-content-comment">
      <p>
        {{ activeTab.description }}
      </p>
    </div>
    <div class="email-signature-network-content-swiper">
      <Slides :active-tab="activeTab" />
      <div class="email-signature-network-content-swiper-footer">
        <div class="swiper-btn button-prev">
          <i>
            <svg
              width="12"
              height="22"
              viewBox="0 0 12 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.5 20L1.5 11L10.5 2"
                stroke="#E3E3E3"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </i>
        </div>
        <div class="swiper-btn button-next">
          <i>
            <svg
              width="13"
              height="22"
              viewBox="0 0 13 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 2L11 11L2 20"
                stroke="#E3E3E3"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </i>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="email-signature-network-content-btn">
      <a
        href="javascript:;"
        class="btn btn-sm btn-round-4 btn-400"
        @click="openCopyModal"
      >
        {{ activeTab.buttonLabel }}
      </a>
    </div>
  </div>
  <VisibilityModal v-if="getModalStatus('email_visibility')" />
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import Slides from "../Slides.vue";
import { getModalStatus, setModalStatus } from "@/utils/Helpers";
import VisibilityModal from "@/components/Email/Modals/Visibility";
import i18n from "@/utils/i18n";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import store from "@/store";
SwiperCore.use([Navigation, Pagination, A11y]);

const activeTabIndex = ref(0);
const tabs = computed(() => store.getters["email/services"]);
const activeTab = computed(() => tabs.value[activeTabIndex.value]);

const openCopyModal = () => {
  setModalStatus("email_copy_view", true, {
    showCopyActions: false,
    done: () => {
      setModalStatus("email_copy_view", false);
      setModalStatus("email_copy", true, activeTab.value);
    },
  });
};
</script>
