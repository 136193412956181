<template>
  <div
    :class="[
      'network-pool-body-content-item-block-item',
      { 'is-checked': isChecked() },
    ]"
  >
    <div class="network-pool-body-content-item-block-item-name">
      <div class="form-item-checkbox">
        <input
          type="checkbox"
          @change="onChange"
          :checked="isChecked()"
          :id="`network-${item.id}`"
        />
        <label :for="`network-${item.id}`">
          {{ props.item.name }}
        </label>
      </div>
      <div
        class="network-pool-body-content-item-block-item-name-image is-purple"
      >
        <img v-if="item.profile_photo" :src="item.profile_photo.url" alt="" />
        <span v-else>
          {{ getLetters(item.full_name) }}
        </span>
      </div>
      <div
        class="network-pool-body-content-item-block-item-name-label cursor-pointer"
        @click="view"
      >
        <span>
          {{ item.full_name }}
        </span>
      </div>
    </div>
    <div class="network-pool-body-content-item-block-item-title">
      <span> {{ item.title }} </span>
    </div>
    <div class="network-pool-body-content-item-block-item-company">
      <span>{{ item.company_name }}</span>
    </div>
    <div class="network-pool-body-content-item-block-item-location">
      <span>
        {{ item.location ?? "-" }}
      </span>
    </div>
    <div class="network-pool-body-content-item-block-item-label">
      <template v-if="item.tags.length">
        <div class="network-pool-body-content-item-block-item-label-group">
          <div
            class="network-pool-body-content-item-block-item-label-group-block"
          >
            <div
              v-for="tag in item.tags"
              :key="tag.id"
              :class="[
                'network-pool-body-content-item-block-item-label-group-item is-' +
                  tag.color,
              ]"
            >
              {{ tag.label }}
            </div>
          </div>
          <div
            class="network-pool-body-content-item-block-item-label-group-icon"
            @click="tagsPopupStatus = !tagsPopupStatus"
          >
            <button type="button">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 14.5H2V10.5"
                  stroke="#167FFC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M2 14.4987L6.66667 9.83203"
                  stroke="#167FFC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M10 2.5H14V6.5"
                  stroke="#167FFC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M13.9997 2.5L9.33301 7.16667"
                  stroke="#167FFC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </button>
          </div>
        </div>
        <div :class="['network-pool-body-content-item-block-item-label-content', {'is-show': tagsPopupStatus}]">
          <div
            v-for="tag in item.tags"
            :key="tag.id"
            :class="[
              'network-pool-body-content-item-block-item-label-group-item is-' +
                tag.color,
            ]"
          >
            {{ tag.label }}
          </div>

          <div
            class="network-pool-body-content-item-block-item-label-content-close"
            @click="tagsPopupStatus = !tagsPopupStatus"
          >
            <button type="button">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 4L4 12"
                  stroke="#979797"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M4 4L12 12"
                  stroke="#979797"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </template>
      <span v-else>-</span>
    </div>
    <div class="network-pool-body-content-item-block-item-date">
      <span>{{ item.created_at }}</span>
    </div>
    <div
      class="network-pool-body-content-item-block-item-detail"
      v-if="currentWorkspace?.role === 'member'"
    >
      <button type="button" @click="view">
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 14.5L11 8.5L5 2.5"
            stroke="#B1B1B1"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
    <div
      v-if="currentWorkspace?.role !== 'member'"
      :class="['network-pool-body-content-item-block-item-adding']"
    >
      <span>
        {{ item.added_by || "-" }}
      </span>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { setModalStatus, getLetters } from "@/utils/Helpers";
import store from "@/store";

const currentWorkspace = store.getters["account/workspace"];
const router = useRouter();
const dropdownStatus = ref(false);
const tagsPopupStatus = ref(false);
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const toggleDropdown = () => {
  dropdownStatus.value = !dropdownStatus.value;
};

const onChange = (e) => {
  const value = e.target.value;
  const isCheck = e.target.checked;

  if (isCheck) {
    store.dispatch("network/addNetwork", props.item);
  } else {
    store.dispatch("network/removeNetwork", props.item);
  }
};

const isChecked = () => {
  return store.getters["network/isSelectedNetwork"](props.item);
};

const view = () => {
  store.dispatch("network/item", props.item.id).then(() => {
    setModalStatus("network_view", true, { id: props.item.id });
  });
};
</script>
