import BackgroundService from "@/services/modules/BackgroundService";

export const background = {
  namespaced: true,
  state: {
    list: {},
  },
  actions: {
    list({ commit }) {
      return BackgroundService.list().then(
        (data) => {
          commit("list", data.data.items);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    upload({ commit }, payload) {
      return BackgroundService.upload(payload).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    generate({ commit }, payload) {
      return BackgroundService.generate(payload).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    delete({ commit }, id) {
      return BackgroundService.deleteBackground(id).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    list(state, data) {
      state.list = data;
    },
  },
  getters: {
    list: (state) => {
      return state.list;
    },
  },
};
