<template>
  <div
    class="email-signature-visibility-btn"
    v-if="props.visibilityButton && item"
  >
    <a
      href="javascript:;"
      class="btn btn-text btn-sm btn-primary-blue btn-400"
      @click="setModalStatus('email_visibility', true)"
    >
      <i data-position="left">
        <svg
          width="24"
          height="19"
          viewBox="0 0 24 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 9.41177C1 9.41177 5 1 12 1C19 1 23 9.41177 23 9.41177C23 9.41177 19 17.8235 12 17.8235C5 17.8235 1 9.41177 1 9.41177Z"
            stroke="#167FFC"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.0009 12.6473C13.7877 12.6473 15.2362 11.1989 15.2362 9.41205C15.2362 7.62525 13.7877 6.17676 12.0009 6.17676C10.2141 6.17676 8.76562 7.62525 8.76562 9.41205C8.76562 11.1989 10.2141 12.6473 12.0009 12.6473Z"
            stroke="#167FFC"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </i>
      {{ $t("misc.visibility") }}
    </a>
  </div>

  <div class="email-signature-mail-template" v-if="selectedImage?.image">
    <slot name="before-email-template"></slot>

    <div id="email-signature-mail-template-copy">
      <div
        id="email-signature-mail-template"
        :class="[
          'mail-template',
          {
            'is-row-view': selectedImage.layout_mode == 'horizontal',
            'is-column-view': selectedImage.layout_mode == 'vertical',
          },
        ]"
        :style="{
          backgroundImage: `url(${selectedImage.image.url})`,
        }"
      >
        <a :href="urls.card.download" target="_blank">
          <div class="mail-template-main">
            <div
              class="mail-template-pictures"
              v-if="selectedViewMode !== 'text'"
            >
              <div
                class="mail-template-pictures-user"
                v-if="profilePhoto && selectedViewMode === 'full'"
              >
                <img :src="profilePhoto.url" :alt="displayName()" />
              </div>
              <div class="mail-template-pictures-logo" v-if="companyLogo">
                <img :src="companyLogo.url" :alt="getValue('company_name')" />
              </div>
            </div>
            <div class="mail-template-head">
              <div class="mail-template-head-name">
                <p>{{ displayName() }}</p>
              </div>
              <div class="mail-template-head-title">
                <p>{{ getValue("title") }}</p>
              </div>
              <div class="mail-template-head-company">
                <p>{{ getValue("company_name") }}</p>
              </div>
            </div>
            <div class="mail-template-info">
              <ul>
                <template v-if="phones">
                  <li v-for="(phone, index) in phonesList" :key="index">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22 16.92V19.92C22.0011 20.1985 21.9441 20.4741 21.8325 20.7293C21.7209 20.9845 21.5573 21.2136 21.3521 21.4018C21.1468 21.5901 20.9046 21.7335 20.6407 21.8227C20.3769 21.9119 20.0974 21.945 19.82 21.92C16.7428 21.5856 13.787 20.5341 11.19 18.85C8.77382 17.3146 6.72533 15.2661 5.18999 12.85C3.49997 10.2412 2.44824 7.27097 2.11999 4.17997C2.095 3.90344 2.12787 3.62474 2.21649 3.3616C2.30512 3.09846 2.44756 2.85666 2.63476 2.6516C2.82196 2.44653 3.0498 2.28268 3.30379 2.1705C3.55777 2.05831 3.83233 2.00024 4.10999 1.99997H7.10999C7.5953 1.9952 8.06579 2.16705 8.43376 2.48351C8.80173 2.79996 9.04207 3.23942 9.10999 3.71997C9.23662 4.68004 9.47144 5.6227 9.80999 6.52997C9.94454 6.8879 9.97366 7.27689 9.8939 7.65086C9.81415 8.02482 9.62886 8.36809 9.35999 8.63998L8.08999 9.90997C9.51355 12.4135 11.5864 14.4864 14.09 15.91L15.36 14.64C15.6319 14.3711 15.9751 14.1858 16.3491 14.1061C16.7231 14.0263 17.1121 14.0554 17.47 14.19C18.3773 14.5285 19.3199 14.7634 20.28 14.89C20.7658 14.9585 21.2094 15.2032 21.5265 15.5775C21.8437 15.9518 22.0122 16.4296 22 16.92Z"
                        stroke="#167FFC"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <span>{{ phone.phone_type.label }}</span>
                    <p :href="`tel:${phone.phone_number}`">
                      {{ phone.phone_number }}
                    </p>
                  </li>
                </template>

                <template v-if="emails.length">
                  <li v-for="(item, index) in emailsList" :key="index">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
                        stroke="#167FFC"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M22 6L12 13L2 6"
                        stroke="#167FFC"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <p :href="`mailto:${item.email}`">
                      {{ item.email }}
                    </p>
                  </li>
                </template>

                <template v-if="websites.length">
                  <li v-for="(website, index) in websitesList" :key="index">
                    <svg
                      width="14"
                      height="12"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                        stroke="#167FFC"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2 12H22"
                        stroke="#167FFC"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z"
                        stroke="#167FFC"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p :href="website.url" target="_blank">
                      {{ website.link_text || website.url }}
                    </p>
                  </li>
                </template>

                <li v-if="address && showAddress">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.5 11.667C24.5 19.8337 14 26.8337 14 26.8337C14 26.8337 3.5 19.8337 3.5 11.667C3.5 8.88222 4.60625 6.2115 6.57538 4.24237C8.54451 2.27324 11.2152 1.16699 14 1.16699C16.7848 1.16699 19.4555 2.27324 21.4246 4.24237C23.3938 6.2115 24.5 8.88222 24.5 11.667Z"
                      stroke="#167FFC"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M14 15.167C15.933 15.167 17.5 13.6 17.5 11.667C17.5 9.734 15.933 8.16699 14 8.16699C12.067 8.16699 10.5 9.734 10.5 11.667C10.5 13.6 12.067 15.167 14 15.167Z"
                      stroke="#167FFC"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                  <p href="javascript:;">{{ address }}</p>
                </li>
              </ul>
            </div>
            <div class="mail-template-social" v-if="socialMedias">
              <ul>
                <li
                  v-for="(item, index) in socialMediasList"
                  :key="index"
                  :class="item.social_media_type"
                >
                  <i v-html="item.email_svg_code"></i>
                </li>
              </ul>
            </div>
            <div class="mail-template-download-btn">
              <span> {{ $t("misc.download_card") }} </span>
            </div>
          </div>
        </a>
      </div>
      <slot name="after-email-template"></slot>
    </div>
    <div
      class="email-signature-mail-template-type-btn"
      v-if="props.layoutModeButtons"
    >
      <button
        type="button"
        :class="[
          'mail-template-row',
          { 'is-active': selectedLayoutMode == 'horizontal' },
        ]"
        @click="changelayoutMode('horizontal')"
      >
        <i>
          <svg
            width="23"
            height="13"
            fill="none"
            viewBox="0 0 23 13"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="22"
              height="12"
              rx="2.5"
              stroke="#6C489E"
            />
          </svg>
        </i>
      </button>
      <button
        type="button"
        :class="[
          'mail-template-column',
          { 'is-active': selectedLayoutMode == 'vertical' },
        ]"
        @click="changelayoutMode('vertical')"
      >
        <i>
          <svg
            width="14"
            height="19"
            fill="none"
            viewBox="0 0 14 19"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="13.5"
              y="0.5"
              width="18"
              height="13"
              rx="2.5"
              transform="rotate(90 13.5 0.5)"
            />
          </svg>
        </i>
      </button>
    </div>
    <div
      class="email-signature-mail-template-version-btn"
      v-if="viewModes && props.viewModeButtons"
    >
      <button
        type="button"
        v-for="viewMode in viewModes"
        :key="viewMode.id"
        :class="[
          'mail-template-is-full-view btn btn-xs btn-round-4 btn-400',
          {
            'btn-secondary-purple': selectedViewMode === viewMode.id,
            'btn-secondary-purple-outline': selectedViewMode !== viewMode.id,
          },
        ]"
        @click="changeViewMode(viewMode.id)"
      >
        {{ viewMode.label }}
      </button>
    </div>
  </div>
</template>

<style>
#email-signature-mail-template-copy {
  width: 100%;
  max-width: 464px;
  line-height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#email-signature-mail-template {
  line-height: 1.15 !important;
}
.is-border-none {
  box-shadow: none !important;
}

.mail-template-download-btn span {
  background-color: v-bind(backgroundColor) !important;
}
.business-card-view-content-body .nav-tabs .nav-item .nav-link.active svg path {
  stroke: v-bind(backgroundColor) !important;
}

.mail-template-social ul li:not(.file, .date) svg path {
  fill: v-bind(backgroundColor) !important;
}
.mail-template-social ul li:is(.file, .date, .youtube) svg path {
  stroke: v-bind(backgroundColor) !important;
}

.mail-template-social ul li.youtube svg path:last-child {
  fill: #fff !important;
}

.mail-template-info ul li svg path {
  stroke: v-bind(backgroundColor) !important;
}

.mail-template-info ul li span {
  color: v-bind(backgroundColor) !important;
}

.business-card-profile-content-body-social-item
  i.svg-color-state-fill
  svg
  path:first-child {
  fill: v-bind(backgroundColor) !important;
}

.business-card-profile-content-body-social-item
  i.svg-color-state-stroke
  svg
  path {
  stroke: v-bind(backgroundColor) !important;
}
</style>

<script setup>
import { ref, onMounted, computed, defineProps, watch } from "vue";
import useProfile from "@/composables/profile";
import store from "@/store";
import {
  getModalStatus,
  setModalStatus,
  copyClipboardElement,
} from "@/utils/Helpers";

const props = defineProps({
  item: {
    type: Object,
    required: true,
    default: () => {},
  },
  visibility: {
    type: Object,
    required: true,
    default: () => {},
  },
  visibilityButton: {
    type: Boolean,
    default: true,
  },
  viewModeButtons: {
    type: Boolean,
    default: true,
  },
  layoutModeButtons: {
    type: Boolean,
    default: true,
  },
});

const workspaceVisibility = ref({});
const visibility = computed(() => props.visibility);
const selectedLayoutMode = computed(() => store.getters["email/layoutMode"]);
const selectedViewMode = computed(() => store.getters["email/viewMode"]);
const selectedImage = computed(() => store.getters["email/image"]);
const viewModes = store.getters["email/viewModes"];
const currentWorkspace = computed(() => {
  const workspace = store.getters["account/workspace"];

  return workspace;
});
const emailsList = ref([]);
const phonesList = ref([]);
const websitesList = ref([]);
const socialMediasList = ref([]);
const showAddress = ref(true);
const backgroundColor = computed(() => {
  const defaultColor = "#167ffc";

  if (item.value?.id) {
    return workspaceBackgroundColor.value || defaultColor;
  }

  return defaultColor;
});

const item = computed(() => {
  const item = store.getters["profiles/item"];

  if (item) {
    if (item.acf?.workspace_settings?.preferences) {
      workspaceVisibility.value =
        item.acf?.workspace_settings?.preferences.email_visibility;
    }
  }

  return item;
});

const changelayoutMode = (mode) => {
  store.commit("email/setLayoutMode", mode);
};

const changeViewMode = (mode) => {
  store.commit("email/setViewMode", mode);
};

const {
  getValue,
  displayName,
  website,
  phones,
  address,
  addressType,
  socialMedias,
  emails,
  websites,
  profilePhoto,
  companyLogo,
  urls,
  workspaceBackgroundColor,
} = useProfile();

const socialMediasUnique = (socialMedias) => {
  return socialMedias.filter(
    (item, index, self) =>
      index ===
      self.findIndex((t) => t.social_media_type === item.social_media_type)
  );
};

watch(
  () => visibility.value,
  (value) => {
    const emailsArr = [];
    const phonesArr = [];
    const websiteArr = [];
    const socialMediasArr = [];

    const timeOutSecond = workspaceVisibility.value ? 0 : 1000;
    setTimeout(() => {
      if (!emailsArr.length) {
        if (!workspaceVisibility.value.email) {
          emails.value.map((item) => {
            if (item.type === "self") {
              emailsArr.push(item);
            }
          });
        } else {
          emails.value.map((item) => {
            emailsArr.push(item);
          });
        }
      }

      if (!phonesArr.length) {
        if (!workspaceVisibility.value.phone) {
          phones.value.map((item) => {
            if (item.type === "self") {
              phonesArr.push(item);
            }
          });
        } else {
          phones.value.map((item) => {
            phonesArr.push(item);
          });
        }
      }

      if (!websiteArr.length) {
        if (!workspaceVisibility.value.website) {
          websites.value.map((item) => {
            if (item.type === "self") {
              websiteArr.push(item);
            }
          });
        } else {
          websites.value.map((item) => {
            websiteArr.push(item);
          });
        }
      }

      if (!socialMediasArr.length) {
        if (!workspaceVisibility.value.social_media) {
          socialMedias.value.map((item) => {
            if (item.type === "self") {
              socialMediasArr.push(item);
            }
          });
        } else {
          socialMedias.value.map((item) => {
            socialMediasArr.push(item);
          });
        }
      }

      if (!workspaceVisibility.value.address) {
        showAddress.value = false;
      } else {
        showAddress.value = true;
      }

      if (value.email) {
        emailsList.value = emailsArr;
      } else {
        emailsList.value = emailsArr.filter((item) => item.type !== "self");
      }

      if (value.phone) {
        phonesList.value = phonesArr;
      } else {
        phonesList.value = phonesArr.filter((item) => item.type !== "self");
      }

      if (value.website) {
        websitesList.value = websiteArr;
      } else {
        websitesList.value = websiteArr.filter((item) => item.type !== "self");
      }

      if (value.social_media) {
        socialMediasList.value = socialMediasUnique(socialMediasArr);
      } else {
        socialMediasList.value = socialMediasUnique(
          socialMediasArr.filter((item) => item.type !== "self")
        );
      }

      if (value.address) {
        if (addressType.value === "self") {
          showAddress.value = true;
        }
      } else {
        if (addressType.value === "self") {
          showAddress.value = false;
        }
      }
    }, timeOutSecond);
  },
  { immediate: true, deep: true }
);

onMounted(() => {
  if (currentWorkspace.value.type === "corporate") {
    store.commit(
      "email/setWillCopySelector",
      "email-signature-mail-template-copy"
    );
  } else {
    store.commit("email/setImage", {
      image: {
        url: "/img/static/mail-template-bg-row.png",
      },
      layout_mode: "horizontal",
    });
  }
});
</script>
