<template>
  <div class="is-detail-label">
    <div class="is-detail-label-head">
      <div class="is-detail-label-head-content">
        <div class="is-detail-label-head-content-title">
          <div class="is-detail-label-head-content-title-icon">
            <i>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.1587 11.1763L11.1837 17.1513C11.0289 17.3063 10.8451 17.4292 10.6427 17.5131C10.4404 17.5969 10.2235 17.6401 10.0045 17.6401C9.78547 17.6401 9.56859 17.5969 9.36626 17.5131C9.16393 17.4292 8.98011 17.3063 8.82533 17.1513L1.66699 10.0013V1.66797H10.0003L17.1587 8.8263C17.4691 9.13857 17.6433 9.56099 17.6433 10.0013C17.6433 10.4416 17.4691 10.864 17.1587 11.1763V11.1763Z"
                  stroke="#167FFC"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.83301 5.83203H5.84191"
                  stroke="#167FFC"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </i>
          </div>
          <div class="is-detail-label-head-content-title-text">
            <h3>
              {{ $t("network.tags.label") }}
            </h3>
          </div>
          <div class="is-detail-label-head-content-title-add">
            <button
              type="button"
              aria-controls="sheet"
              @click="changeViewMode('add')"
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 5.83203V22.1654"
                  stroke="#167FFC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.83301 14H22.1663"
                  stroke="#167FFC"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
        <div
          :class="[
            'is-detail-label-head-content-edit',
            { 'is-active': isDropdownShow },
          ]"
        >
          <button
            type="button"
            :class="[
              'is-detail-label-head-content-edit-button',
              { 'is-active': isDropdownShow },
            ]"
            @click="isDropdownShow = !isDropdownShow"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19Z"
                stroke="#707070"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                stroke="#707070"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11 5C11 5.55228 11.4477 6 12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5Z"
                stroke="#707070"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <div class="is-detail-label-head-content-edit-content">
            <button
              type="button"
              class="is-detail-label-head-content-edit-content-button"
              @click="changeViewMode('edit')"
            >
              {{ $t("network.tags.edit_tags") }}
            </button>
          </div>
        </div>
      </div>
      <div class="is-detail-label-head-description">
        <p>
          {{
            $t("network.tags.description", {
              name: displayName(),
            })
          }}
        </p>
      </div>
    </div>
    <div class="is-detail-label-body">
      <div
        class="is-detail-label-body-empty"
        v-if="!item.acf.tags.length && view === 'default'"
      >
        <p>
          {{ $t("network.tags.empty_text") }}
        </p>
      </div>
      <div
        :class="[
          'is-detail-label-body-edit',
          { 'is-show': view !== 'default', 'is-delete': view === 'edit' },
        ]"
        aria-hidden="true"
        role="dialog"
      >
        <div class="is-detail-label-body-edit-head">
          <div class="is-detail-label-body-edit-head-tag">
            <div class="is-detail-label-body-edit-head-tag-list">
              <span
                :class="`is-detail-label-body-edit-head-tag-list-item is-${tag.color}`"
                v-for="(tag, index) in item.acf.tags"
                :key="index"
              >
                {{ tag.label }}
                <button
                  class="is-detail-label-body-edit-head-tag-list-item-close"
                  @click="deleteTag(index)"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.5 4.5L4.5 13.5"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M4.5 4.5L13.5 13.5"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </button>
              </span>
              <input
                v-if="view !== 'edit'"
                :class="[
                  'is-detail-label-body-edit-head-tag-list-input is-' +
                    selectedTagColor,
                  {
                    'is-centered': isTyping,
                  },
                ]"
                type="text"
                :placeholder="$t('network.tags.input_placeholder')"
                style="width: 90px"
                @keyup="typingTag"
                @keyup.enter="addTag"
                @keydown.backspace="backspaceTag"
              />
            </div>
          </div>
          <div class="is-detail-label-body-edit-head-controls">
            <button type="button" class="is-delete" @click="clearInput">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 19L5 6M18 6L5 19"
                  stroke="#979797"
                  stroke-width="2.2"
                  stroke-linecap="round"
                />
              </svg>
            </button>
            <button type="button" class="is-checked" @click="addTag">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.48276 16.9303L5.13336 12.515C4.899 12.2771 4.58113 12.1435 4.24969 12.1435C3.91825 12.1435 3.60039 12.2771 3.36603 12.515C3.13166 12.7529 3 13.0756 3 13.4121C3 13.5787 3.03232 13.7436 3.09513 13.8976C3.15793 14.0515 3.24998 14.1913 3.36603 14.3091L8.60536 19.6278C9.0942 20.1241 9.88386 20.1241 10.3727 19.6278L23.634 6.16568C23.8683 5.92776 24 5.60508 24 5.26862C24 4.93216 23.8683 4.60948 23.634 4.37157C23.3996 4.13366 23.0817 4 22.7503 4C22.4189 4 22.101 4.13366 21.8666 4.37157L9.48276 16.9303Z"
                  fill="#36A549"
                />
              </svg>
            </button>
          </div>
        </div>
        <div class="is-detail-label-body-edit-color" v-if="avaliableTagColors">
          <button
            type="button"
            :class="[
              'is-' + color,
              { 'is-active': color === selectedTagColor },
            ]"
            v-for="color in avaliableTagColors"
            :key="color"
            @click="selectedTagColor = color"
          ></button>
        </div>
        <div class="is-detail-label-body-edit-button">
          <button
            type="button"
            class="btn btn-sm btn-white btn-round-4 btn-400 is-cancel"
            @click="cancel"
          >
            {{ $t("misc.cancel") }}
          </button>
          <Button
            type="button"
            class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400 is-save"
            :state="state"
            @click="save"
          >
            {{ $t("misc.save") }}
          </Button>
        </div>
        <div class="is-detail-label-body-edit-recommended">
          <div class="is-detail-label-body-edit-recommended-title">
            <h5>
              {{ $t("network.tags.recommended") }}
            </h5>
          </div>
          <div
            class="is-detail-label-body-edit-recommended-buttons"
            v-if="options.default_tags"
          >
            <button
              type="button"
              :class="'is-' + tag.color"
              v-for="tag in options.default_tags"
              :key="tag"
              @click="addTag(tag)"
            >
              {{ tag.label }}
              <i>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.10742 3.85547V14.3555"
                    stroke="#E3AA7E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.85693 9.10547H14.3569"
                    stroke="#E3AA7E"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </i>
            </button>
          </div>
        </div>
      </div>
      <div
        class="is-detail-label-body-add"
        v-if="item.acf.tags.length && view === 'default'"
      >
        <div class="is-detail-label-body-add-block">
          <div
            :class="`is-detail-label-body-add-block-item is-${item.color}`"
            v-for="(item, index) in item.acf.tags"
            :key="index"
          >
            <span>{{ item.label }}</span>
          </div>

          <div class="is-detail-label-body-add-block-button">
            <button type="button" @click="changeViewMode('add')">
              <svg
                width="40"
                height="30"
                viewBox="0 0 40 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 9.16797V20.8346"
                  stroke="#CDCDCD"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.167 15H25.8337"
                  stroke="#CDCDCD"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="29"
                  rx="3.5"
                  stroke="#CDCDCD"
                  stroke-dasharray="8 8"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed, defineProps } from "vue";
import store from "@/store";
import useProfile from "@/composables/profile";
import Button from "@/components/inputs/Button.vue";

const avaliableTagColors = ["purple", "pink", "orange", "green", "red", "blue"];
const state = ref("default");
const view = ref("default");
const isDropdownShow = ref(false);
const selectedTagColor = ref("purple");
const isTyping = ref(false);
const { displayName } = useProfile({
  getterName: "network/item",
  actionName: "network/item",
  applyWsRules: false,
});

const saltRecommendedTags = [];
const options = computed(() => {
  const options = store.getters["misc/options"];

  if (options?.default_tags && !saltRecommendedTags.length) {
    options.default_tags.map((tag) => {
      saltRecommendedTags.push(tag);
    });
  }

  return options;
});

let tags = [];
let item = computed(() => {
  const item = store.getters["network/item"];

  if (item && item?.acf?.preferences) {
    visibility.value = item.acf.preferences.visibility;
  }

  if (item.acf.tags.length && !tags.length) {
    item.acf.tags.map((tag) => {
      tags.push(tag);
    });
  }

  if (!item.acf.tags) {
    item.acf.tags = [];
  }

  return item;
});

const deleteTag = (index) => {
  item.value.acf.tags.splice(index, 1);
};

const changeViewMode = (mode) => {
  isDropdownShow.value = false;
  view.value = mode;
};

const cancel = () => {
  view.value = "default";

  item.value.acf.tags = [];

  tags.map((tag) => {
    item.value.acf.tags.push(tag);
  });
};

const save = () => {
  state.value = "loading";
  tags = [];

  item.value.acf.tags.map((tag) => {
    tags.push(tag);
  });

  store
    .dispatch("network/updateTags", {
      id: item.value.id,
      tags: item.value.acf.tags,
    })
    .then(() => {
      store.dispatch("network/items").then(() => {
        state.value = "success";

        setTimeout(() => {
          view.value = "default";
        }, 1000);
      });
    });
};

const addTag = (tag = {}) => {
  const input = document.querySelector(
    ".is-detail-label-body-edit-head-tag-list-input"
  );

  if (tag?.label) {
    item.value.acf.tags.push({
      label: tag.label,
      color: tag.color,
    });

    clearInput();

    filterRecommendedTags("");

    return;
  }

  const value = input.value;

  if (value.length > 0) {
    item.value.acf.tags.push({
      label: value,
      color: selectedTagColor.value,
    });

    clearInput();
    filterRecommendedTags("");
  }
};

const backspaceTag = (event) => {
  if (event.target.value === "") {
    item.value.acf.tags.pop();
    clearInput();
  }
};

const clearInput = () => {
  const input = document.querySelector(
    ".is-detail-label-body-edit-head-tag-list-input"
  );

  input.value = "";
  input.focus();

  adjustTagInputWidth(true);
  isTyping.value = false;
};

const typingTag = (event) => {
  const value = event.target.value;

  if (value.length > 0) {
    isTyping.value = true;
  } else {
    isTyping.value = false;
  }

  filterRecommendedTags(value);

  adjustTagInputWidth();
};

const filterRecommendedTags = (q) => {
  const tags = saltRecommendedTags.filter((tag) => {
    return tag.label.toLowerCase().includes(q.toLowerCase());
  });

  options.value.default_tags = tags;
};

const adjustTagInputWidth = (isForceToDefault = false) => {
  const input = document.querySelector(
    ".is-detail-label-body-edit-head-tag-list-input"
  );

  if (isForceToDefault) {
    input.style.width = "90px";
    return;
  }

  const characters = input.value.split("");
  let totalWidth = 19;

  characters.forEach((character) => {
    const tempSpan = document.createElement("span");
    tempSpan.style.visibility = "hidden";
    tempSpan.style.whiteSpace = "nowrap";
    tempSpan.style.position = "absolute";
    tempSpan.style.display = "inline-block";
    tempSpan.textContent = character;
    document.body.appendChild(tempSpan);

    const characterWidth = tempSpan.offsetWidth;
    totalWidth += characterWidth;

    document.body.removeChild(tempSpan);
  });

  input.style.width = totalWidth + "px";
};
</script>
