<template>
  <section class="email-signature-copy-modal is-show mh-auto" style="position: fixed;">
    <template v-if="isLoading">
      <div class="email-signature-copy-modal-main-title">
        <h4>{{ $t("email_signature.creating_text") }}</h4>
      </div>
      <div class="spinner-container" style="position: unset">
        <div class="loading-spinner"></div>
      </div>
    </template>
    <template v-else>
      <div class="email-signature-copy-modal-main-title">
        <h4>{{ $t("email_signature.created_text") }}</h4>
      </div>
      <div class="email-signature-copy-modal-main-buttons">
        <Button
          class="btn btn-md btn-primary-blue btn-round-4 btn-500"
          @click="copy"
        >
          {{ $t("email_signature.copy") }}
        </Button>
      </div>
    </template>
  </section>
</template>

<script setup>
import { ref, onMounted, computed, defineProps } from "vue";
import domtoimage from "dom-to-image";
import store from "@/store";
import { MembershipLevelEnum } from "@/enums";
import {
  setModalStatus,
  getModalData,
  copyClipboardElement,
  getProxyURL,
  getWithProxyURL,
} from "@/utils/Helpers";

const willCopySelector = computed(
  () => store.getters["email/willCopySelector"]
);
const isLoading = ref(true);
const data = getModalData("email_copy_view");
const me = computed(() => store.getters["account/me"]);
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const generate = () => {
  const formData = new FormData();
  const generateImageContainer = document.getElementById(
    willCopySelector.value
  );
  const addBorderClassContainer = document.getElementById(
    "email-signature-mail-template"
  );

  if (me.value.membership_level === MembershipLevelEnum.FREE) {
    setModalStatus("page_premium", true);
    return;
  }

  // add proxy url to all images in generateImageContainer
  const images = generateImageContainer.querySelectorAll("img");
  const elements = generateImageContainer.querySelectorAll("*");

  images.forEach((image) => {
    image.src = getWithProxyURL(image.src);
  });

  elements.forEach((element) => {
    const backgroundImage = element.style.backgroundImage;

    if (backgroundImage) {
      // is background image url remote or local
      const urlType = backgroundImage.includes("http") ? "remote" : "local";

      if (urlType === "remote") {
        element.style.backgroundImage = backgroundImage.replace(
          /url\("(.*?)"\)/g,
          (match, p1) => {
            return `url(${getWithProxyURL(p1)})`;
          }
        );
      }
    }
  });

  addBorderClassContainer.classList.add("is-border-none");

  setTimeout(() => {
    domtoimage
      .toBlob(generateImageContainer, {
        width: generateImageContainer.clientWidth * 1.2,
        height: generateImageContainer.clientHeight * 1.2,
        style: {
          transform: "scale(" + 1.2 + ")",
          transformOrigin: "top left",
        },
      })
      .then(function (blob) {
        formData.append("file", blob, "image.png");

        store
          .dispatch("profiles/addEmailImage", {
            file: formData.get("file"),
            id: props.item.id,
            type: "signature",
          })
          .then((response) => {
            const url = response.data.url;
            store.commit("email/setGeneratedImageURL", url);

            setTimeout(() => {
              images.forEach((image) => {
                image.src = image.src.replace(getProxyURL(), "");
              });

              elements.forEach((element) => {
                const backgroundImage = element.style.backgroundImage;

                if (backgroundImage) {
                  element.style.backgroundImage = backgroundImage.replace(
                    /url\("(.*?)"\)/g,
                    (match, p1) => {
                      return `url(${p1.replace(getProxyURL(), "")})`;
                    }
                  );
                }
              });

              if (data.showCopyActions) {
                isLoading.value = false;
              } else {
                data.done();
              }

              addBorderClassContainer.classList.remove("is-border-none");
            }, 100);
          });
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  }, 1000);
};

const copy = () => {
  copyClipboardElement("email-copy-view");

  data.done();
};

onMounted(() => {
  generate();
});
</script>

<style>
.mh-auto {
  min-height: auto !important;
}
</style>
