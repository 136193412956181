import { axios } from "@/services/axios";
import AuthHeader from "../AuthHeader";

export const me = (payload) => {
  return axios
    .get("accounts/me", {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const confirmPassword = (payload) => {
  return axios
    .post("accounts/confirm_password", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const update = (payload) => {
  return axios
    .put("accounts/me", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const changePassword = (payload) => {
  return axios
    .put("accounts/change_password", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const updatePreferences = (payload) => {
  return axios
    .put("accounts/preferences", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const getEmails = (payload) => {
  return axios
    .get("accounts/emails", {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const addEmail = (payload) => {
  return axios
    .post("accounts/emails", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
const setAccount = (payload) => {
  return axios
    .post("accounts/set_account", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const sendRequest = (payload) => {
  return axios
    .post("accounts/send_request", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const disableRequest = (payload) => {
  return axios
    .post("accounts/disable_request", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const deleteRequest = (payload) => {
  return axios
    .post("accounts/delete_request", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const membershipCancelRequest = (payload) => {
  return axios
    .post("accounts/membership_cancel_request", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const status = (payload) => {
  return axios
    .get("accounts/status", {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const createOrder = (payload) => {
  return axios
    .post("accounts/create_order", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default {
  me,
  confirmPassword,
  update,
  changePassword,
  updatePreferences,
  getEmails,
  addEmail,
  setAccount,
  sendRequest,
  disableRequest,
  deleteRequest,
  membershipCancelRequest,
  status,
  createOrder,
};
