<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <div class="toast-block">
    <b-container
      :toast="{ root: true }"
      fluid="sm"
      position="position-fixed"
    ></b-container>
  </div>

  <main-layout :head="false" class="main-network-pool">
    <div class="network-pool">
      <Head />
      <Search />

      <b-skeleton v-if="isLoading" class="profile-action-skeleton"></b-skeleton>
      <div class="network-pool-body" v-else>
        <Empty v-if="!items?.length" />
        <List v-else />
      </div>
      <AddButton />
      <DeleteModal v-if="getModalStatus('network_remove')" />
      <ImportModal v-if="getModalStatus('network_import')" />
      <ExportModal v-if="getModalStatus('network_export')" />
      <CreateGroup />
      <DeleteGroupModal v-if="getModalStatus('group_delete')" />
      <RemoveFromGroup v-if="getModalStatus('remove_from_group')" />
      <GroupAction v-if="getModalStatus('group_action')" />
      <GroupNetworksModal v-if="getModalStatus('group_networks')" />
      <Action
        v-if="getModalStatus('network_action')"
        :item="getModalData('network_action')"
      />
      <View
        v-if="getModalStatus('network_view')"
        :item="getModalData('network_view')"
        :is-apply-ws-rules="false"
        store-get-key="network/item"
        store-dispatch-key="network/item"
        modal-name="network_view"
        edit-modal-name="network_action"
        :actions="['edit', 'close']"
        :is-modal="true"
      />
    </div>
  </main-layout>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { getModalStatus, getModalData } from "@/utils/Helpers";
import store from "@/store";
import MainLayout from "@/layouts/MainLayout.vue";
import { useMeta } from "vue-meta";
import i18n from "@/utils/i18n";

import Head from "@/components/Network/Head.vue";
import Search from "@/components/Network/Search.vue";
import Empty from "@/components/Network/Empty.vue";
import List from "@/components/Network/List.vue";
import AddButton from "@/components/Network/AddButton.vue";
import DeleteModal from "@/components/Network/Modals/Delete.vue";
import ImportModal from "@/components/Network/Modals/Import.vue";
import ExportModal from "@/components/Network/Modals/Export.vue";
import DeleteGroupModal from "@/components/Network/Modals/DeleteGroup.vue";
import CreateGroup from "@/components/Network/Modals/CreateGroup.vue";
import GroupAction from "@/components/Network/Modals/GroupAction.vue";
import GroupNetworksModal from "@/components/Network/Modals/GroupNetworks.vue";
import Action from "@/components/Network/Modals/Action.vue";
import View from "@/components/Network/Modals/View.vue";
import RemoveFromGroup from "@/components/Network/Modals/RemoveFromGroup.vue";

const items = computed(() => store.getters["network/items"]);
const isLoading = computed(() => store.getters["network/isLoading"]);

useMeta({
  title: i18n.global.t("network.title"),
});

onMounted(() => {
  store.dispatch("network/items").then(() => {
    store.dispatch("network/setLoading", false);
  });
});
</script>
