<template>
  <div class="email-detail-actions">
    <div class="email-detail-actions-inner">
      <div class="change-text-container">
        <textarea v-model="text" class="form-control" rows="5" @input="input" />
        <div class="char-state">
          <span class="current-char">{{ chracterCount }}</span>
          <span class="char-limit">{{ characterLimit }}</span>
          karakter
        </div>
      </div>
      <div class="save-container">
        <Button
          :title="$t('misc.save')"
          @click="save"
          :state="state"
          class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
        >
          {{ $t("misc.save") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import Button from "@/components/inputs/Button.vue";
import store from "@/store";

const state = ref("default");
const text = ref("The content of this email is confidential and intended for the recipient specified in message only. It is strictly forbidden to share any part of this message with any third party, without a written consent of the sender. If you received this message by mistake, please reply to this message and follow with its deletion, so that we can ensure such a mistake does not occur in the future.");
const chracterCount = ref(0);
const characterLimit = 5000;
const item = computed(() => {
  const item = store.getters["profiles/item"];

  return item;
});

const input = () => {
  store.commit("email/setText", text.value);

  if (text.value.length > characterLimit) {
    text.value = text.value.substring(0, characterLimit);
  }

  chracterCount.value = text.value.length;
};

const save = () => {
  state.value = "loading";
  store
    .dispatch("profiles/updatePreferences", {
      id: item.value.id,
      email_settings: {
        text: text.value,
      },
    })
    .then(() => {
      state.value = "success";
    });
};

onMounted(() => {
  store.commit("email/setText", text.value);
  const settings = item.value.acf.preferences?.email_settings;

  if (settings?.text) {
    text.value = settings.text;
    chracterCount.value = text.value.length;
    store.commit("email/setText", settings.text);
  }
});
</script>
