<template>
  <div class="statistics-member is-show">
    <div :class="['statistics-member-block', { 'is-hide': isOpenDetail }]">
      <div class="statistics-member-block-head">
        <div class="statistics-member-block-head-name">
          <span>
            {{ $t("stats.search.members.name") }}
          </span>
        </div>
        <div class="statistics-member-block-head-title">
          <span>
            {{ $t("stats.search.members.title") }}
          </span>
        </div>
      </div>
      <div class="statistics-member-block-body">
        <div
          class="statistics-member-block-body-item"
          v-for="member in props.items"
          :key="member.id"
        >
          <div class="statistics-member-block-body-item-name">
            <div class="statistics-member-block-body-item-name-image is-purple">
              <span>{{ getLetters(member.first_name) }}</span>
            </div>
            <div class="statistics-member-block-body-item-name-label">
              <span>{{ member.name }}</span>
            </div>
          </div>
          <div class="statistics-member-block-body-item-title">
            <div class="statistics-member-block-body-item-title-label">
              <span>{{ member.profile.title }}</span>
            </div>
            <div class="statistics-member-block-body-item-title-button">
              <button type="button" @click="choseItem(member, 'member')">
                <svg
                  width="7"
                  height="16"
                  viewBox="0 0 7 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1.5L6 7.675L1 14.5"
                    stroke="#979797"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="selectedItem"
      :class="['statistics-member-detail', { 'is-show': isOpenDetail }]"
    >
      <div class="statistics-member-detail-head">
        <div
          class="statistics-member-detail-head-image"
          v-if="selectedItem.profile.photo"
        >
          <img :src="selectedItem.profile.photo" :alt="selectedItem.name" />
        </div>
        <div
          class="statistics-member-block-body-item-name-image is-purple statistics-member-detail-head-image"
          v-else
        >
          <span>{{ getLetters(selectedItem.first_name) }}</span>
        </div>

        <div class="statistics-member-detail-head-profile">
          <a :href="selectedItem.profile.url" target="_blank">
            {{ $t("stats.search.view_profile") }}
          </a>
        </div>
      </div>
      <div class="statistics-member-detail-body">
        <div class="statistics-member-detail-body-content">
          <div class="statistics-member-detail-body-content-title">
            <span>
              {{ selectedItem.name }}
            </span>
          </div>
          <div class="statistics-member-detail-body-content-role">
            <span>{{ selectedItem.profile.title }}</span>
          </div>
          <div
            class="statistics-member-detail-body-content-team"
            v-if="selectedItem.teams"
          >
            <span v-for="team in selectedItem.teams" :key="team.id">
              {{ team.name }}
            </span>
          </div>
        </div>
        <div class="statistics-member-detail-body-detail">
          <span>{{
            $t("stats.search.register_date_text", {
              date: selectedItem.created_at,
            })
          }}</span>
        </div>
      </div>
      <div class="statistics-member-detail-close">
        <button type="button" @click="removeChosenItem">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#979797"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M6 6L18 18"
              stroke="#979797"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from "vue";
import store from "@/store";
import { getLetters } from "@/utils/Helpers";

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
});

const isOpenDetail = ref(null);
const detailType = ref(null);
const selectedItem = computed(() => store.getters["stats/selectedItem"]);

const choseItem = (item, type) => {
  store.commit("stats/setSelectedItem", item);

  isOpenDetail.value = true;
  detailType.value = type;

  store.commit("stats/setIsLoading", true);

  store
    .dispatch("stats/get", {
      member_id: selectedItem.value.id,
    })
    .then(() => {
      store.commit("stats/setIsLoading", false);
    });
};

const removeChosenItem = () => {
  store.commit("stats/setSelectedItem", null);

  isOpenDetail.value = false;
  detailType.value = null;

  store.commit("stats/setIsLoading", true);

  store.dispatch("stats/get").then(() => {
    store.commit("stats/setIsLoading", false);
  });
};
</script>
