<template>
  <Header />
  <main :class="[props.class, { 'sidebar-is-active': sidebarStatus }]">
    <div class="wrapper">
      <Sidebar />
      <div
        :class="[
          'wrapper-content',
          {
            'is-free-package':
              me.membership_level === MembershipLevelEnum.FREE &&
              getModalStatus('page_premium'),
          },
        ]"
      >
        <div
          :class="[
            props.containerClass,
            {
              'is-free-package':
                me.membership_level === MembershipLevelEnum.FREE,
            },
          ]"
        >
          <Head :title="props.title" v-if="props.head" />
          <slot></slot>
        </div>
        <PagePremium v-if="getModalStatus('page_premium')" />
      </div>
    </div>
  </main>
</template>

<script setup>
import { defineProps, computed } from "vue";
import Sidebar from "../components/Sidebar.vue";
import Head from "../components/Profile/Head.vue";
import Header from "@/components/Header";
import store from "@/store";
import { MembershipLevelEnum } from "@/enums";
import { getModalStatus } from "@/utils/Helpers";
import PagePremium from "@/components/Common/Modals/PagePremium.vue";

const sidebarStatus = computed(() => store.getters["common/sidebar_status"]);
const me = computed(() => store.getters["account/me"]);

const props = defineProps({
  class: {
    type: String,
    default: "",
    required: false,
  },
  containerClass: {
    type: String,
    default: "digital-profile",
    required: false,
  },
  title: {
    type: String,
    default: "",
  },
  head: {
    type: Boolean,
    default: true,
  },
});
</script>
