<template>
  <div class="export-your-links is-show">
    <div class="export-your-links-block">
      <div class="export-your-links-block-content">
        <h4>
          {{ $t("network.export.title") }}
        </h4>
        <p>
          {{
            $t("network.export.text", {
              email: me.email,
            })
          }}
        </p>
      </div>
      <div class="export-your-links-block-button">
        <button
          type="button"
          class="btn btn-sm btn-white btn-round-4 btn-400"
          @click="setModalStatus('network_export', false)"
        >
          {{ $t("misc.cancel") }}
        </button>
        <Button
          type="button"
          class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
          :state="state"
          @click="exportNetworks"
        >
          {{ $t("network.export.button_label") }}
        </Button>
      </div>
      <div
        class="export-your-links-block-close"
        @click="setModalStatus('network_export', false)"
      >
        <button type="button">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { setModalStatus, getModalStatus } from "@/utils/Helpers";
import store from "@/store";
import Button from "@/components/inputs/Button.vue";

const state = ref("default");
const me = computed(() => store.getters["account/me"]);

const exportNetworks = async () => {
  state.value = "loading";
  store.dispatch("exportStore/create", { type: "networks" }).then(() => {
    state.value = "success";

    setTimeout(() => {
      setModalStatus("network_export", false);
    }, 1000);
  });
};
</script>
