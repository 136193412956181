<template>
  <div class="members-body-content is-empty">
    <div class="members-body-content-item no-members">
      <div class="members-body-content-item-block">
        <div class="members-body-content-item-block-head">
          <h5>
            {{ $t("team.members.empty.title") }}
          </h5>
          <p>
            {{ $t("team.members.empty.text") }}
          </p>
        </div>
        <div class="members-body-content-item-block-image">
          <img
            src="/img/static/members-body-content-item-block-image.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="members-body-content-item there-are-members">
      <div class="members-body-content-item-block">
        <div class="members-body-content-item-block-head">
          <div class="members-body-content-item-block-head-checkbox">
            <div class="form-item-checkbox">
              <input id="checkbox" type="checkbox" />
              <label for="checkbox">Tümü</label>
            </div>
          </div>
          <div class="members-body-content-item-block-head-settings">
            <div class="members-body-content-item-block-head-settings-item">
              <button type="button">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 11.5799V12.4999C21.9988 14.6563 21.3005 16.7545 20.0093 18.4817C18.7182 20.2088 16.9033 21.4723 14.8354 22.0838C12.7674 22.6952 10.5573 22.6218 8.53447 21.8744C6.51168 21.1271 4.78465 19.746 3.61096 17.9369C2.43727 16.1279 1.87979 13.9879 2.02168 11.8362C2.16356 9.68443 2.99721 7.63619 4.39828 5.99694C5.79935 4.35768 7.69279 3.21525 9.79619 2.74001C11.8996 2.26477 14.1003 2.4822 16.07 3.35986"
                    stroke="#E3E3E3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22 4.5L12 14.51L9 11.51"
                    stroke="#E3E3E3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div class="members-body-content-item-block-head-settings-item">
              <button type="button">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_14215_90155)">
                    <path
                      d="M16.5 21.5V19.5C16.5 18.4391 16.0786 17.4217 15.3284 16.6716C14.5783 15.9214 13.5609 15.5 12.5 15.5H5.5C4.43913 15.5 3.42172 15.9214 2.67157 16.6716C1.92143 17.4217 1.5 18.4391 1.5 19.5V21.5"
                      stroke="#E3E3E3"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.5 8.5V14.5"
                      stroke="#E3E3E3"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M23.5 11.5H17.5"
                      stroke="#E3E3E3"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9 11.5C11.2091 11.5 13 9.70914 13 7.5C13 5.29086 11.2091 3.5 9 3.5C6.79086 3.5 5 5.29086 5 7.5C5 9.70914 6.79086 11.5 9 11.5Z"
                      stroke="#E3E3E3"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_14215_90155">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0.5 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
            <div class="members-body-content-item-block-head-settings-item">
              <button type="button">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 4.5V10.5H7"
                    stroke="#E3E3E3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.51 15.5004C4.15839 17.3408 5.38734 18.9206 7.01166 20.0018C8.63598 21.083 10.5677 21.607 12.5157 21.4949C14.4637 21.3828 16.3226 20.6406 17.8121 19.3802C19.3017 18.1198 20.3413 16.4094 20.7742 14.5068C21.2072 12.6042 21.0101 10.6124 20.2126 8.83154C19.4152 7.05068 18.0605 5.57723 16.3528 4.6332C14.6451 3.68917 12.6769 3.3257 10.7447 3.59755C8.81245 3.86941 7.02091 4.76186 5.64 6.14044L1 10.5004"
                    stroke="#E3E3E3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="members-body-content-item-block-list">
          <div class="members-body-content-item-block-list-head">
            <div class="members-body-content-item-block-list-head-name">
              <span>İsim</span>
            </div>
            <div class="members-body-content-item-block-list-head-team">
              <span>Takım</span>
            </div>
            <div class="members-body-content-item-block-list-head-id">
              <span>ID No</span>
            </div>
            <div class="members-body-content-item-block-list-head-role">
              <span>Rol</span>
            </div>
          </div>
          <div class="members-body-content-item-block-list-content">
            <div class="members-body-content-item-block-list-content-item">
              <div
                class="members-body-content-item-block-list-content-item-name"
              >
                <div class="form-item-checkbox">
                  <input id="name-1" type="checkbox" />
                  <label for="name-1">Ali Şahin</label>
                </div>
              </div>
              <div
                class="members-body-content-item-block-list-content-item-team is-soft-grey"
              >
                <span>Takım yok</span>
              </div>
              <div class="members-body-content-item-block-list-content-item-id">
                <span>1044</span>
              </div>
              <div
                class="members-body-content-item-block-list-content-item-role"
              >
                <button type="button" class="is-role is-admin">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="is-admin-icon"
                  >
                    <path
                      d="M12.6667 14V12.6667C12.6667 11.9594 12.3857 11.2811 11.8856 10.781C11.3855 10.281 10.7072 10 10 10H4.66667C3.95942 10 3.28115 10.281 2.78105 10.781C2.28095 11.2811 2 11.9594 2 12.6667V14"
                      stroke="#6C489E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.33268 7.33333C8.80544 7.33333 9.99935 6.13943 9.99935 4.66667C9.99935 3.19391 8.80544 2 7.33268 2C5.85992 2 4.66602 3.19391 4.66602 4.66667C4.66602 6.13943 5.85992 7.33333 7.33268 7.33333Z"
                      stroke="#6C489E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="is-member-icon"
                  >
                    <g clip-path="url(#clip0_13973_100838)">
                      <path
                        d="M15.333 14.0002V12.6669C15.3326 12.0761 15.1359 11.5021 14.7739 11.0351C14.4119 10.5682 13.9051 10.2346 13.333 10.0869"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.3337 14V12.6667C11.3337 11.9594 11.0527 11.2811 10.5526 10.781C10.0525 10.281 9.37424 10 8.66699 10H3.33366C2.62641 10 1.94814 10.281 1.44804 10.781C0.947944 11.2811 0.666992 11.9594 0.666992 12.6667V14"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.667 2.08691C11.2406 2.23378 11.749 2.56738 12.1121 3.03512C12.4752 3.50286 12.6722 4.07813 12.6722 4.67025C12.6722 5.26236 12.4752 5.83763 12.1121 6.30537C11.749 6.77311 11.2406 7.10671 10.667 7.25358"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.99967 7.33333C7.47243 7.33333 8.66634 6.13943 8.66634 4.66667C8.66634 3.19391 7.47243 2 5.99967 2C4.52692 2 3.33301 3.19391 3.33301 4.66667C3.33301 6.13943 4.52692 7.33333 5.99967 7.33333Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_13973_100838">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="is-default-icon"
                  >
                    <g clip-path="url(#clip0_13973_100848)">
                      <path
                        d="M15.333 14.0002V12.6669C15.3326 12.0761 15.1359 11.5021 14.7739 11.0351C14.4119 10.5682 13.9051 10.2346 13.333 10.0869"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.3337 14V12.6667C11.3337 11.9594 11.0527 11.2811 10.5526 10.781C10.0525 10.281 9.37424 10 8.66699 10H3.33366C2.62641 10 1.94814 10.281 1.44804 10.781C0.947944 11.2811 0.666992 11.9594 0.666992 12.6667V14"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.667 2.08691C11.2406 2.23378 11.749 2.56738 12.1121 3.03512C12.4752 3.50286 12.6722 4.07813 12.6722 4.67025C12.6722 5.26236 12.4752 5.83763 12.1121 6.30537C11.749 6.77311 11.2406 7.10671 10.667 7.25358"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.99967 7.33333C7.47243 7.33333 8.66634 6.13943 8.66634 4.66667C8.66634 3.19391 7.47243 2 5.99967 2C4.52692 2 3.33301 3.19391 3.33301 4.66667C3.33301 6.13943 4.52692 7.33333 5.99967 7.33333Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_13973_100848">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                <button type="button" class="is-profile">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 18.5L15 12.5L9 6.5"
                      stroke="#B1B1B1"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div class="members-body-content-item-block-list-content-item">
              <div
                class="members-body-content-item-block-list-content-item-name"
              >
                <div class="form-item-checkbox">
                  <input id="name-2" type="checkbox" />
                  <label for="name-2">Aytaç Tombul</label>
                </div>
              </div>
              <div
                class="members-body-content-item-block-list-content-item-team is-soft-grey"
              >
                <span>Takım yok</span>
              </div>
              <div class="members-body-content-item-block-list-content-item-id">
                <span>1036</span>
              </div>
              <div
                class="members-body-content-item-block-list-content-item-role"
              >
                <button type="button" class="is-role is-member">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="is-admin-icon"
                  >
                    <path
                      d="M12.6667 14V12.6667C12.6667 11.9594 12.3857 11.2811 11.8856 10.781C11.3855 10.281 10.7072 10 10 10H4.66667C3.95942 10 3.28115 10.281 2.78105 10.781C2.28095 11.2811 2 11.9594 2 12.6667V14"
                      stroke="#6C489E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.33268 7.33333C8.80544 7.33333 9.99935 6.13943 9.99935 4.66667C9.99935 3.19391 8.80544 2 7.33268 2C5.85992 2 4.66602 3.19391 4.66602 4.66667C4.66602 6.13943 5.85992 7.33333 7.33268 7.33333Z"
                      stroke="#6C489E"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="is-member-icon"
                  >
                    <g clip-path="url(#clip0_13973_100838)">
                      <path
                        d="M15.333 14.0002V12.6669C15.3326 12.0761 15.1359 11.5021 14.7739 11.0351C14.4119 10.5682 13.9051 10.2346 13.333 10.0869"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.3337 14V12.6667C11.3337 11.9594 11.0527 11.2811 10.5526 10.781C10.0525 10.281 9.37424 10 8.66699 10H3.33366C2.62641 10 1.94814 10.281 1.44804 10.781C0.947944 11.2811 0.666992 11.9594 0.666992 12.6667V14"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.667 2.08691C11.2406 2.23378 11.749 2.56738 12.1121 3.03512C12.4752 3.50286 12.6722 4.07813 12.6722 4.67025C12.6722 5.26236 12.4752 5.83763 12.1121 6.30537C11.749 6.77311 11.2406 7.10671 10.667 7.25358"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.99967 7.33333C7.47243 7.33333 8.66634 6.13943 8.66634 4.66667C8.66634 3.19391 7.47243 2 5.99967 2C4.52692 2 3.33301 3.19391 3.33301 4.66667C3.33301 6.13943 4.52692 7.33333 5.99967 7.33333Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_13973_100838">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="is-default-icon"
                  >
                    <g clip-path="url(#clip0_13973_100848)">
                      <path
                        d="M15.333 14.0002V12.6669C15.3326 12.0761 15.1359 11.5021 14.7739 11.0351C14.4119 10.5682 13.9051 10.2346 13.333 10.0869"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.3337 14V12.6667C11.3337 11.9594 11.0527 11.2811 10.5526 10.781C10.0525 10.281 9.37424 10 8.66699 10H3.33366C2.62641 10 1.94814 10.281 1.44804 10.781C0.947944 11.2811 0.666992 11.9594 0.666992 12.6667V14"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.667 2.08691C11.2406 2.23378 11.749 2.56738 12.1121 3.03512C12.4752 3.50286 12.6722 4.07813 12.6722 4.67025C12.6722 5.26236 12.4752 5.83763 12.1121 6.30537C11.749 6.77311 11.2406 7.10671 10.667 7.25358"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.99967 7.33333C7.47243 7.33333 8.66634 6.13943 8.66634 4.66667C8.66634 3.19391 7.47243 2 5.99967 2C4.52692 2 3.33301 3.19391 3.33301 4.66667C3.33301 6.13943 4.52692 7.33333 5.99967 7.33333Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_13973_100848">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                <button type="button" class="is-profile">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 18.5L15 12.5L9 6.5"
                      stroke="#B1B1B1"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
