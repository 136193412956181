<template>
  <section class="reset-password-error">
    <div class="reset-password-error-main">
      <div class="reset-password-error-title">
        <h4>{{ $t("misc.error_title") }}</h4>
      </div>
      <div class="reset-password-error-text">
        <p>
          {{ $t(getI18n("auth.forgot.not_found")) }}
        </p>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted } from "vue";
import store from "@/store";
import { useMeta } from "vue-meta";
import { getI18n } from "@/utils/Helpers";

// Components
import SecondLayout from "@/layouts/SecondLayout.vue";

onMounted(() => {
  store.commit("common/apply_mode");
});
</script>
