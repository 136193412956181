<template>
  <div
    :class="[
      'stay-in-touch',
      {
        'is-show': step === 1,
      },
    ]"
  >
    <div class="stay-in-touch-block">
      <div class="stay-in-touch-block-head">
        <div class="stay-in-touch-block-head-label">
          <i>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.8 10.75H18L10.2 22V13.2538L5 13.25L12.8 2V10.75Z"
                stroke="#167FFC"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </i>
          <span>
            {{ $t("network.stay_touch.title") }}
          </span>
        </div>
        <div class="stay-in-touch-block-head-person">
          <img
            :src="profilePhoto.url"
            :alt="displayName()"
            v-if="profilePhoto"
          />
          <span>
            {{ displayName() }}
          </span>
        </div>
      </div>
      <Loading v-if="isLoading" />
      <div class="stay-in-touch-block-body" v-if="options && !isLoading">
        <div
          class="stay-in-touch-block-body-item"
          v-for="option in options"
          :key="option.key"
        >
          <button type="button" @click="choseOption(option)">
            {{ option.label }}
          </button>
          <span v-if="option.description">{{ option.description }}</span>
        </div>
      </div>
      <div class="stay-in-touch-block-close">
        <button
          type="button"
          @click="setModalStatus('network_stay_touch', false)"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div
    :class="[
      'choose-to-stay-in-touch',
      {
        'is-show': step === 2,
      },
    ]"
  >
    <div class="choose-to-stay-in-touch-block">
      <div class="choose-to-stay-in-touch-block-head">
        <div class="choose-to-stay-in-touch-block-head-back">
          <button type="button" @click="step = 1">
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.5083 12.6234L16.6883 18.4434L22.5083 24.2634C22.6471 24.4022 22.7573 24.5671 22.8324 24.7485C22.9076 24.93 22.9463 25.1245 22.9463 25.3209C22.9463 25.5172 22.9076 25.7117 22.8324 25.8932C22.7573 26.0746 22.6471 26.2395 22.5083 26.3784C22.3694 26.5172 22.2045 26.6274 22.0231 26.7025C21.8416 26.7777 21.6472 26.8164 21.4508 26.8164C21.2544 26.8164 21.0599 26.7777 20.8784 26.7025C20.697 26.6274 20.5321 26.5172 20.3933 26.3784L13.5083 19.4934C13.3692 19.3546 13.2589 19.1898 13.1836 19.0083C13.1083 18.8268 13.0696 18.6323 13.0696 18.4359C13.0696 18.2394 13.1083 18.0449 13.1836 17.8634C13.2589 17.682 13.3692 17.5171 13.5083 17.3784L20.3933 10.4934C20.532 10.3543 20.6969 10.244 20.8783 10.1687C21.0598 10.0934 21.2543 10.0547 21.4508 10.0547C21.6472 10.0547 21.8417 10.0934 22.0232 10.1687C22.2047 10.244 22.3695 10.3543 22.5083 10.4934C23.0783 11.0784 23.0933 12.0384 22.5083 12.6234Z"
                fill="#979797"
              ></path>
            </svg>
          </button>
        </div>
        <div class="choose-to-stay-in-touch-block-head-person">
          <img
            :src="profilePhoto.url"
            :alt="displayName()"
            v-if="profilePhoto"
          />
          <span>
            {{ displayName() }}
          </span>
        </div>
        <div class="choose-to-stay-in-touch-block-head-time">
          <span>{{ selectedOption?.label }}</span>
        </div>
      </div>
      <div class="choose-to-stay-in-touch-block-body">
        <Weekly v-if="selectedOption?.key === 'weekly'" />
        <Monthly v-if="selectedOption?.key === 'monthly'" />
        <Quarterly v-if="selectedOption?.key === 'quarterly'" />
        <Yearly v-if="selectedOption?.key === 'yearly'" />
      </div>
      <div class="choose-to-stay-in-touch-block-button">
        <button
          type="button"
          class="btn btn-sm btn-white btn-round-4 btn-400 is-cancel"
          @click="setModalStatus('network_stay_touch', false)"
        >
          {{ $t("misc.cancel") }}
        </button>
        <Button
          type="button"
          class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400 is-save"
          :state="state"
          @click="save"
        >
          {{ $t("misc.save") }}
        </Button>
      </div>
      <div class="choose-to-stay-in-touch-block-close">
        <button
          type="button"
          @click="setModalStatus('network_stay_touch', false)"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M6 6L18 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed, defineProps } from "vue";
import store from "@/store";
import { setModalStatus } from "@/utils/Helpers";
import useProfile from "@/composables/profile";
import i18n from "@/utils/i18n.vue";
import Weekly from "../View/StayTouch/Weekly.vue";
import Monthly from "../View/StayTouch/Monthly.vue";
import Quarterly from "../View/StayTouch/Quarterly";
import Yearly from "../View/StayTouch/Yearly.vue";
import Button from "@/components/inputs/Button.vue";
import Loading from "@/components/Common/Loading.vue";

const isLoading = ref(false);
const stayTouch = computed(() => store.getters["network/stayTouch"]);
const state = ref("default");
const selectedOption = ref(null);
const step = ref(1);
let item = computed(() => {
  const item = store.getters["network/item"];

  if (item && item?.acf?.preferences) {
    visibility.value = item.acf.preferences.visibility;
  }

  return item;
});

const {
  getValue,
  displayName,
  companyName,
  description,
  emails,
  website,
  websites,
  phones,
  address,
  socialMedias,
  profileType,
  isMainCorporateProfile,
  profilePhoto,
  companyLogo,
  file,
} = useProfile({
  getterName: "network/item",
  actionName: "network/item",
  applyWsRules: false,
});

const options = store.getters["network/stayTouchOptions"];

const choseOption = (option) => {
  if (option.key === "auto" || option.key === "never") {
    isLoading.value = true;

    store
      .dispatch("network/updateStayTouch", {
        network_id: item.value.id,
        type: option.key,
      })
      .then(() => {
        store.dispatch("network/item", item.value.id).then(() => {
          isLoading.value = false;
          setModalStatus("network_stay_touch", false);
        });
      });

    return;
  }

  selectedOption.value = option;
  step.value = 2;
};

const save = () => {
  state.value = "loading";
  store
    .dispatch("network/updateStayTouch", {
      network_id: item.value.id,
      ...stayTouch.value,
    })
    .then(() => {
      store.dispatch("network/item", item.value.id).then(() => {
        state.value = "success";
      });
    });
};
</script>
