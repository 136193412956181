<template>
  <div :class="['sidebar', { 'is-show': sidebarStatus }]">
    <div class="sidebar-navigation">
      <div class="sidebar-navigation-profile">
        <div class="sidebar-navigation-profile-icon" @click="closeSidebar">
          <button type="button">
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.25 8.75C5.25 8.28587 5.43437 7.84075 5.76256 7.51256C6.09075 7.18437 6.53587 7 7 7H28C28.4641 7 28.9092 7.18437 29.2374 7.51256C29.5656 7.84075 29.75 8.28587 29.75 8.75C29.75 9.21413 29.5656 9.65925 29.2374 9.98744C28.9092 10.3156 28.4641 10.5 28 10.5H7C6.53587 10.5 6.09075 10.3156 5.76256 9.98744C5.43437 9.65925 5.25 9.21413 5.25 8.75Z"
                fill="#545454"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.25 17.5C5.25 17.0359 5.43437 16.5908 5.76256 16.2626C6.09075 15.9344 6.53587 15.75 7 15.75H28C28.4641 15.75 28.9092 15.9344 29.2374 16.2626C29.5656 16.5908 29.75 17.0359 29.75 17.5C29.75 17.9641 29.5656 18.4092 29.2374 18.7374C28.9092 19.0656 28.4641 19.25 28 19.25H7C6.53587 19.25 6.09075 19.0656 5.76256 18.7374C5.43437 18.4092 5.25 17.9641 5.25 17.5Z"
                fill="#545454"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.25 26.25C5.25 25.7859 5.43437 25.3408 5.76256 25.0126C6.09075 24.6844 6.53587 24.5 7 24.5H28C28.4641 24.5 28.9092 24.6844 29.2374 25.0126C29.5656 25.3408 29.75 25.7859 29.75 26.25C29.75 26.7141 29.5656 27.1592 29.2374 27.4874C28.9092 27.8156 28.4641 28 28 28H7C6.53587 28 6.09075 27.8156 5.76256 27.4874C5.43437 27.1592 5.25 26.7141 5.25 26.25Z"
                fill="#545454"
              />
            </svg>
          </button>
        </div>
        <template v-if="me.workspaces">
          <div
            class="sidebar-navigation-profile-image"
            v-for="workspace in me.workspaces"
            :key="workspace.id"
            @click="switchWorkspace({ id: workspace.id, slug: workspace.slug })"
            v-tippy="{ content: workspace.name, placement: 'right' }"
          >
            <a v-if="workspace.logo?.url">
              <img :src="workspace.logo.url" :alt="workspace.name" />
            </a>
            <a v-else>
              {{ getLetters(workspace.name) }}
            </a>
          </div>
        </template>
      </div>
      <div class="sidebar-navigation-buttons">
        <router-link
          :to="{ name: 'support' }"
          class="support-center-button"
          :content="$t('sidebar.menu_item_support')"
          v-tippy
        >
          <img src="../dist/img/static/icon-help-circle.svg" alt="" />
        </router-link>
        <router-link
          :to="{ name: 'settings-main' }"
          class="settings-button"
          :content="$t('sidebar.menu_item_settings')"
          v-tippy
        >
          <img src="../dist/img/static/icon-settings.svg" alt="" />
        </router-link>
        <a
          href="javascript:void(0)"
          class="log-out-button"
          :content="$t('sidebar.menu_item_logout')"
          v-tippy
          @click="logoutButtonClicked"
        >
          <img src="../dist/img/static/icon-log-out.svg" alt="" />
        </a>
      </div>
    </div>
    <div class="sidebar-menu">
      <div class="sidebar-menu-head is-installation">
        <div class="sidebar-menu-head-logo">
          <img src="../dist/img/static/blue-logo-s.png" alt="" />
        </div>
        <div
          v-if="me.id && currentWorkspace.id"
          :class="[
            'sidebar-menu-head-profile',
            { 'is-premium': me.membership_level !== MembershipLevelEnum.FREE },
          ]"
        >
          <div class="sidebar-menu-head-profile-title">
            <h4>
              {{ currentWorkspace.name }}
            </h4>
            <span v-if="me.membership_level !== MembershipLevelEnum.FREE">
              <img src="../dist/img/static/icon-jam-crown.svg" alt="" />
              Premium
            </span>
          </div>
          <div
            class="sidebar-menu-head-profile-more"
            v-if="currentWorkspace?.role !== 'member'"
          >
            <button type="button" @click="toggleEditMenu">
              <img src="../dist/img/static/icon-more-horizontal.svg" alt="" />
            </button>
            <div
              :class="[
                'sidebar-menu-head-profile-more-edit',
                { 'is-show': editMenuStatus },
              ]"
            >
              <a href="javascript:;" @click="openEditPopup">{{ $t("sidebar.profile_dot") }}</a>
            </div>
          </div>
        </div>

        <div
          class="sidebar-menu-head-installation is-50"
          v-if="!me.profile_count"
        >
          <div class="sidebar-menu-head-installation-progress">
            <router-link :to="{ name: 'profile-create' }">
              {{ $t("sidebar.installation_progress") }}
              <img src="../dist/img/static/icon-chevron-right.svg" alt="" />
            </router-link>
          </div>
          <div class="sidebar-menu-head-installation-step">
            <span>
              {{ $t("sidebar.last_n_steps", { n: 1 }) }}:
              {{ $t("sidebar.complete_profile") }}
            </span>
          </div>
        </div>
      </div>
      <div class="sidebar-menu-list">
        <div class="sidebar-menu-list-item">
          <router-link :to="{ name: 'profile-main' }">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 21.4941V19.4941C20 18.4333 19.5786 17.4159 18.8284 16.6657C18.0783 15.9156 17.0609 15.4941 16 15.4941H8C6.93913 15.4941 5.92172 15.9156 5.17157 16.6657C4.42143 17.4159 4 18.4333 4 19.4941V21.4941"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 11.4941C14.2091 11.4941 16 9.70328 16 7.49414C16 5.285 14.2091 3.49414 12 3.49414C9.79086 3.49414 8 5.285 8 7.49414C8 9.70328 9.79086 11.4941 12 11.4941Z"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {{ $t("sidebar.menu_item_digital_card") }}
          </router-link>
        </div>
        <div class="sidebar-menu-list-item">
          <router-link :to="{ name: 'stats-main' }">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23 6L13.5 15.5L8.5 10.5L1 18"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17 6H23V12"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            {{ $t("sidebar.menu_item_stats") }}
          </router-link>
        </div>
        <div class="sidebar-menu-list-item">
          <router-link :to="{ name: 'email-main' }">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 19.4941L19 12.4941L22 15.4941L15 22.4941L12 19.4941Z"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18 13.4941L16.5 5.99414L2 2.49414L5.5 16.9941L13 18.4941L18 13.4941Z"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2 2.49414L9.586 10.0801"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11 13.4941C12.1046 13.4941 13 12.5987 13 11.4941C13 10.3896 12.1046 9.49414 11 9.49414C9.89543 9.49414 9 10.3896 9 11.4941C9 12.5987 9.89543 13.4941 11 13.4941Z"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {{ $t("sidebar.menu_item_email_signature") }}
          </router-link>
        </div>
        <div class="sidebar-menu-list-item">
          <router-link :to="{ name: 'bg-main' }">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 3.49414H5C3.89543 3.49414 3 4.38957 3 5.49414V19.4941C3 20.5987 3.89543 21.4941 5 21.4941H19C20.1046 21.4941 21 20.5987 21 19.4941V5.49414C21 4.38957 20.1046 3.49414 19 3.49414Z"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21 15.4941L16 10.4941L5 21.4941"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.5 10.4941C9.32843 10.4941 10 9.82257 10 8.99414C10 8.16571 9.32843 7.49414 8.5 7.49414C7.67157 7.49414 7 8.16571 7 8.99414C7 9.82257 7.67157 10.4941 8.5 10.4941Z"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {{ $t("sidebar.menu_item_virtual_background") }}
          </router-link>
        </div>
        <div
          class="sidebar-menu-list-item"
          v-if="
            currentWorkspace?.type === 'corporate' &&
            currentWorkspace?.role !== 'member'
          "
        >
          <router-link :to="{ name: 'members' }">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M16 3.13C16.8604 3.35031 17.623 3.85071 18.1676 4.55232C18.7122 5.25392 19.0078 6.11683 19.0078 7.00501C19.0078 7.89318 18.7122 8.75609 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            {{ $t("sidebar.menu_item_members") }}
          </router-link>
        </div>
        <div
          class="sidebar-menu-list-item"
          v-if="currentWorkspace?.type === 'corporate'"
        >
          <router-link :to="{ name: 'network' }">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23 19V17.6371C22.9996 17.0332 22.8029 16.4465 22.4409 15.9692C22.0789 15.4919 21.5721 15.151 21 15"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M18 19V17.6667C18 16.9594 17.6839 16.2811 17.1213 15.781C16.5587 15.281 15.7956 15 15 15H9C8.20435 15 7.44129 15.281 6.87868 15.781C6.31607 16.2811 6 16.9594 6 17.6667V19"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M18 6C18.5721 6.14213 19.0792 6.46497 19.4413 6.91762C19.8034 7.37027 20 7.92699 20 8.5C20 9.07301 19.8034 9.62973 19.4413 10.0824C19.0792 10.535 18.5721 10.8579 18 11"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M12 11C13.6569 11 15 9.65685 15 8C15 6.34315 13.6569 5 12 5C10.3431 5 9 6.34315 9 8C9 9.65685 10.3431 11 12 11Z"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M1 19V17.6371C1.00044 17.0332 1.1971 16.4465 1.55909 15.9692C1.92108 15.4919 2.42791 15.151 3 15"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M6 6C5.42789 6.14213 4.9208 6.46497 4.55868 6.91762C4.19655 7.37027 4 7.92699 4 8.5C4 9.07301 4.19655 9.62973 4.55868 10.0824C4.9208 10.535 5.42789 10.8579 6 11"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
            {{ $t("sidebar.menu_item_network") }}
          </router-link>
        </div>
        <div class="sidebar-menu-list-item">
          <router-link :to="{ name: 'notifications' }">
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 8.49414C18 6.90284 17.3679 5.37672 16.2426 4.2515C15.1174 3.12628 13.5913 2.49414 12 2.49414C10.4087 2.49414 8.88258 3.12628 7.75736 4.2515C6.63214 5.37672 6 6.90284 6 8.49414C6 15.4941 3 17.4941 3 17.4941H21C21 17.4941 18 15.4941 18 8.49414Z"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.7295 21.4941C13.5537 21.7972 13.3014 22.0488 12.9978 22.2237C12.6941 22.3985 12.3499 22.4906 11.9995 22.4906C11.6492 22.4906 11.3049 22.3985 11.0013 22.2237C10.6977 22.0488 10.4453 21.7972 10.2695 21.4941"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {{ $t("sidebar.menu_item_notifications") }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <Logout v-if="getModalStatus('logout')" />
  <EditAccount v-if="getModalStatus('edit_account')" />
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import store from "@/store";
import { MembershipLevelEnum } from "@/enums";
import { getLetters, getModalStatus, setModalStatus } from "@/utils/Helpers";
import Logout from "@/components/Common/Modals/Logout";
import EditAccount from "@/components/Common/Modals/EditAccount.vue";

const editMenuStatus = ref(false);
const sidebarStatus = computed(() => store.getters["common/sidebar_status"]);
const me = computed(() => store.getters["account/me"]);
const currentWorkspace = computed(() => store.getters["account/workspace"]);
const router = useRouter();

const closeSidebar = () => {
  store.commit("common/set_sidebar_status", false);
};

const toggleEditMenu = () => {
  editMenuStatus.value = !editMenuStatus.value;
};

const openEditPopup = () => {
  toggleEditMenu();
  closeSidebar();
  setTimeout(() => {
    setModalStatus("edit_account", true);
  }, 100);
};

const logoutButtonClicked = () => {
  closeSidebar();
  setTimeout(() => {
    setModalStatus("logout", true);
  }, 100);
};

onMounted(() => {
  // store.dispatch("getProfile");
});

const switchWorkspace = (data) => {
  if (data.slug === me.value.last_workspace) {
    return;
  }

  store
    .dispatch("account/update", {
      last_workspace: data.id,
    })
    .then(() => {
      router.push({
        name: "profile-main",
        params: {
          workspace: data.slug,
        },
      });
    });
};
</script>
