<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>

  <MainLayout
    class="main-email-signature"
    :title="$t('email_signature.head_title')"
  >
    <b-skeleton v-if="!item?.id" class="profile-action-skeleton"></b-skeleton>
    <template v-else>
      <IndividualView v-if="currentWorkspace?.type === 'individual'" />
      <CorporateView v-if="currentWorkspace?.type === 'corporate'" />
    </template>
  </MainLayout>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import MainLayout from "@/layouts/MainLayout.vue";
import store from "@/store";
import { useRoute } from "vue-router";

import IndividualView from "@/components/Email/Individual/View.vue";
import CorporateView from "@/components/Email/Corporate/View.vue";
import { useMeta } from "vue-meta";
import i18n from "@/utils/i18n.vue";

const currentWorkspace = computed(() => {
  const workspace = store.getters["account/workspace"];

  return workspace;
});

const route = useRoute();
const item = computed(() => {
  const item = store.getters["profiles/item"];

  return item;
});

useMeta({
  title: i18n.global.t("email_signature.page_title"),
});

onMounted(() => {
  store.commit("profiles/get", null);
  store.dispatch("profiles/get", route.params.id);
});
</script>
