<template>
  <div class="profile-sharing">
    <div class="profile-sharing-widget">
      <b-tabs content-class="mt-3" @activate-tab="changedTab">
        <b-tab :title="$t('profile.share.qr')" active>
          <div class="tab-content-qr">
            <div class="tab-content-qr-head">
              <canvas id="qr-canvas" />
            </div>
            <div class="tab-content-qr-body">
              <div class="tab-content-qr-body-name">
                <span>{{ slug() }}</span>
              </div>
              <div class="tab-content-qr-body-link">
                <span>
                  <i18n-t keypath="profile.share.qr_text">
                    <template v-slot:qr_text_plain>
                      {{ $t("profile.share.qr_text_plain") }}
                    </template>
                    <template v-slot:qr_text_download>
                      <a href="javascript:;" @click="downloadQRCode">
                        {{ $t("profile.share.qr_text_download") }}
                      </a>
                    </template>
                    <template v-slot:qr_text_link>
                      <a href="javascript:;" @click="copyProfileUrl">
                        {{ $t("profile.share.qr_text_link") }}
                      </a>
                    </template>
                  </i18n-t>
                </span>
                <span class="is-copied" style="opacity: 1" v-if="isLinkCopied">
                  {{ $t("profile.share.copied") }}
                </span>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('profile.share.email')">
          <div :class="['tab-content-email', { 'd-none': isEmailShared }]">
            <div class="tab-content-email-label">
              <span>
                {{ $t("profile.share.info") }}
              </span>
            </div>
            <div class="tab-content-email-head">
              <div class="tab-content-email-head-list" v-if="emails">
                <span
                  class="tab-content-email-head-list-item"
                  v-for="(email, index) in emails"
                  :key="index"
                >
                  {{ email }}
                  <button
                    class="profile-sharing-delete-email"
                    @click="deleteEmail(index)"
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.2332 1L1.00001 10.9996"
                        stroke="#7B7B7B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M11.2332 11L1.00001 1.00038"
                        stroke="#7B7B7B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg></button
                ></span>
              </div>
              <div class="tab-content-email-head-input">
                <input
                  type="email"
                  name="email"
                  class="profile-sharing-enter-email-id"
                  :placeholder="$t('misc.email')"
                  @keyup.enter="addEmail"
                />
              </div>
            </div>
            <div class="tab-content-email-subtitle">
              <span>{{ $t("profile.share.limit_text") }}</span>
            </div>
            <div
              :class="[
                'tab-content-email-share',
                { 'is-shareable': isActiveEmailShare },
              ]"
            >
              <Button
                @click="shareProfile"
                :state="state"
                class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
              >
                {{ $t("misc.share") }}
              </Button>
            </div>
          </div>
          <div :class="['tab-content-forwarded', { 'd-block': isEmailShared }]">
            <div class="tab-content-forwarded-image">
              <img src="/img/static/forwarded-image.png" alt="" />
            </div>
            <div class="tab-content-forwarded-content">
              <span>
                {{ $t("profile.share.success_text") }}
              </span>
            </div>
          </div>
        </b-tab>
        <template #tabs-end>
          <div class="tab-base">
            <div class="tab-base-active"></div>
          </div>
        </template>
      </b-tabs>
    </div>
    <div
      class="profile-sharing-alert"
      v-if="workspace.role != 'member' && isMainCorporateProfile"
    >
      <div class="profile-sharing-alert-icon">
        <i>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
              stroke="#FCB20E"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M12 16H12.01"
              stroke="#FCB20E"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M12 8V12"
              stroke="#FCB20E"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </i>
      </div>
      <div class="profile-sharing-alert-description">
        <p>
          {{ $t("profile.corporate_profile_alert") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, defineProps } from "vue";
import { validateEmail, qrInit, qrDownload } from "@/utils/Helpers";
import store from "@/store";
import Button from "@/components/inputs/Button.vue";
import useProfile from "@/composables/profile";
import copy from "copy-to-clipboard";

const {
  getValue,
  displayName,
  slug,
  website,
  phones,
  address,
  socialMedias,
  profilePhoto,
  companyLogo,
  isMainCorporateProfile,
  urls,
} = useProfile();

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const emails = ref([]);
const isActiveEmailShare = ref(false);
const isEmailShared = ref(false);
const state = ref("default");
const isLinkCopied = ref(false);
const workspace = store.getters["account/workspace"];

onMounted(() => {
  const tabBaseActive = document.querySelector(".tab-base-active");
  const activeTab = document.querySelector(".nav-link.active").parentElement;
  const activeTabWidth = activeTab.offsetWidth;
  const activeTabPosition = activeTab.offsetLeft;

  tabBaseActive.style.left = activeTabPosition + 2 + "px";
  tabBaseActive.style.width = activeTabWidth - 4 + "px";

  qrInit(urls.value.card.view);
});

const changedTab = (newTabIndex, prevTabIndex) => {
  const clickedTab =
    document.querySelectorAll(".nav-link")[newTabIndex].parentElement;
  const tabBaseActive = document.querySelector(".tab-base-active");

  const clickedWidth = clickedTab.offsetWidth;
  const clickedOffsetLeft = clickedTab.offsetLeft;

  tabBaseActive.style.left = clickedOffsetLeft + 2 + "px";
  tabBaseActive.style.width = clickedWidth - 4 + "px";
};

const addEmail = (e) => {
  let email = e.target.value;
  email = email.trim();

  if (!validateEmail(email)) {
    return;
  }

  emails.value.push(email);
  e.target.value = "";
};

const deleteEmail = (index) => {
  emails.value.splice(index, 1);
};

const shareProfile = () => {
  if (emails.value.length === 0) {
    return;
  }

  state.value = "loading";

  store
    .dispatch("profiles/share", {
      id: props.data.id,
      type: "email",
      recipients: emails.value,
    })
    .then((res) => {
      if (res.status) {
        state.value = "success";
        setTimeout(() => {
          isEmailShared.value = true;
        }, 1000);
      }
    });
};

const downloadQRCode = () => {
  qrDownload();
};

const copyProfileUrl = () => {
  copy(urls.value.card.view);

  isLinkCopied.value = true;
};

watch(
  emails,
  (newVal) => {
    if (newVal.length > 0) {
      isActiveEmailShare.value = true;
    } else {
      isActiveEmailShare.value = false;
    }
  },
  { deep: true }
);
</script>
