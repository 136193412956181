<template>
  <div class="settings-modal settings-modal-email-change is-show">
    <a
      href="javascript:;"
      class="settings-modal-close"
      @click="setModalStatus('settings_change_email', false)"
    >
      <i>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 1L1 13"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 1L13 13"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </i>
    </a>
    <a
      href="javascript:;"
      class="settings-modal-prev btn btn-text btn-sm btn-primary-blue btn-500"
      @click="setModalStatus('settings_change_email', false)"
    >
      <i data-position="left">
        <svg
          width="7"
          height="15"
          viewBox="0 0 7 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.83749 14.5125C5.68809 14.513 5.54048 14.4799 5.4055 14.4158C5.27052 14.3516 5.15161 14.258 5.05749 14.1417L0.227488 8.13025C0.0804062 7.95097 0 7.7261 0 7.49403C0 7.26196 0.0804062 7.03709 0.227488 6.85781L5.22749 0.846319C5.39723 0.641712 5.64114 0.513041 5.90556 0.488615C6.16999 0.464188 6.43327 0.546007 6.63749 0.71607C6.8417 0.886134 6.97013 1.13051 6.99451 1.39545C7.01889 1.66038 6.93723 1.92416 6.76749 2.12877L2.29749 7.49904L6.61749 12.8693C6.73977 13.0164 6.81745 13.1955 6.84133 13.3854C6.86521 13.5753 6.83429 13.7681 6.75223 13.9409C6.67018 14.1138 6.54042 14.2595 6.37831 14.3608C6.2162 14.4621 6.02852 14.5147 5.83749 14.5125Z"
            fill="#167FFC"
          />
        </svg>
      </i>
      {{ $t("settings.account_management.email.title") }}
    </a>
    <div class="settings-modal-content" v-if="me.emails">
      <h4>{{ $t("settings.account_management.email.current_emails") }}</h4>
      <div
        class="form-item-radio"
        v-for="(item, index) in me.emails"
        :key="item.email"
      >
        <input
          :id="`email-${index}`"
          type="radio"
          name="email"
          :value="item.email"
          v-model="primaryEmail"
        />
        <label :for="`email-${index}`">{{ item.email }}</label>
      </div>
      <div class="settings-modal-email-change-add-email">
        <div
          class="settings-modal-email-change-add-email-btn"
          @click="isShowAddEmailForm = !isShowAddEmailForm"
        >
          {{ $t("settings.account_management.email.new_email") }}
          <i>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 1V15"
                stroke="#167FFC"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 8H15"
                stroke="#167FFC"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </i>
        </div>
        <Form
          @submit="addEmail"
          :validation-schema="schema"
          v-slot="{ errors }"
        >
          <div
            :class="[
              'settings-modal-email-change-add-email-form',
              { 'is-show': isShowAddEmailForm },
            ]"
          >
            <Field
              name="email"
              :errors="errors"
              :placeholder="
                $t('settings.account_management.email.enter_new_email')
              "
            />

            <Button
              type="submit"
              class="btn btn-lg btn-full btn-primary-blue btn-round-8 btn-500"
              :state="state"
            >
              {{ $t("settings.account_management.email.add") }}
            </Button>
          </div>
        </Form>
      </div>
      <div class="settings-modal-email-change-footer">
        <div class="settings-modal-email-change-footer-alert is-show">
          {{ responseMessage }}
        </div>
        <div class="settings-modal-email-change-footer-buttons">
          <a
            href="javascript:;"
            class="btn btn-sm btn-white btn-round-4 btn-400"
            @click="setModalStatus('settings_change_email', false)"
          >
            {{ $t("misc.cancel") }}
          </a>
          <Button
            class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
            :state="saveState"
            @click="saveForm"
          >
            {{ $t("misc.save") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import store from "@/store";
import { setModalStatus } from "@/utils/Helpers";
import { useField, Form } from "vee-validate";
import * as yup from "yup";
import Field from "@/components/inputs/Field";
import Button from "@/components/inputs/Button";
import i18n from "@/utils/i18n";

const schema = yup.object().shape({
  email: yup.string().email().required().label(i18n.global.t("misc.email")),
});

const me = computed(() => {
  const me = store.getters["account/me"];

  if(me.emails.length) {
    primaryEmail.value = me.emails.find((item) => item.is_primary).email;
  }

  return me;
});

// const emails = computed(() => {
//   const emails = store.getters["account/emails"];

//   if (emails.length) {
//     primaryEmail.value = emails.find((item) => item.is_primary).email;
//   }

//   return emails;
// });

const isShowAddEmailForm = ref(false);
const state = ref("default");
const saveState = ref("default");
const responseMessage = ref("");
const primaryEmail = ref("");
// const newEmail = ref("");

const addEmail = (values, actions) => {
  const email = values.email;
  state.value = "loading";
  responseMessage.value = "";
  store.dispatch("account/addEmail", { email }).then((response) => {
    if (response.status) {
      state.value = "success";
      store.dispatch("account/me");
      actions.setFieldValue("email", "");
    } else {
      state.value = "default";
      responseMessage.value = response.message;
    }
  });
};

const saveForm = () => {
  saveState.value = "loading";
  store
    .dispatch("account/update", { user_email: primaryEmail.value })
    .then((response) => {
      if (response.status) {
        saveState.value = "success";
        store.dispatch("account/me");
      }
    });
};

onMounted(() => {
  // store.dispatch("account/getEmails");
});
</script>
