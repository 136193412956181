<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <div class="toast-block">
    <b-container
      :toast="{ root: true }"
      fluid="sm"
      position="position-fixed"
    ></b-container>
  </div>

  <main-layout :head="false" class="main-members">
    <div class="members">
      <Head mode="teams" />
      <b-skeleton v-if="isLoading" class="profile-action-skeleton"></b-skeleton>
      <template v-else>
        <Add v-if="currentWorkspace?.role !== 'member'" />

        <div class="members-body">
          <Empty v-if="!items?.length" />
          <List v-else />
        </div>
      </template>
      <InviteButton v-if="currentWorkspace?.role !== 'member'" />
      <InviteModal
        v-if="
          getModalStatus('member_invite') && currentWorkspace?.role !== 'member'
        "
      />
      <AddModal v-if="getModalStatus('team_add_member')" />
    </div>

    <DeleteTeamModal v-if="getModalStatus('delete_team')" />
  </main-layout>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { getModalStatus, getModalData } from "@/utils/Helpers";
import store from "@/store";
import MainLayout from "@/layouts/MainLayout.vue";
import { useMeta } from "vue-meta";
import i18n from "@/utils/i18n";
import Head from "@/components/Team/Common/Head.vue";
import Add from "@/components/Team/Add.vue";
import Empty from "@/components/Team/Empty.vue";
import List from "@/components/Team/List.vue";
import InviteButton from "@/components/Team/Common/InviteButton.vue";
import InviteModal from "@/components/Team/Common/Modals/Invite.vue";
import AddModal from "@/components/Team/Modals/Add.vue";
import DeleteTeamModal from "@/components/Team/Modals/Delete";

useMeta({
  title: i18n.global.t("team.title"),
});

const isLoading = ref(true);
const currentWorkspace = computed(() => store.getters["account/workspace"]);
const dropdownStatus = ref(false);
const items = computed(() => store.getters["team/items"]);

const toggleDropdown = () => {
  dropdownStatus.value = !dropdownStatus.value;
};

onMounted(() => {
  store.dispatch("team/items").then(() => {
    isLoading.value = false;
  });
});
</script>
