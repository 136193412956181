<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>

  <Header />

  <main :class="['main-settings', { 'sidebar-is-active': sidebarStatus }]">
    <div class="toast-block">
      <b-container
        :toast="{ root: true }"
        fluid="sm"
        position="position-fixed"
      ></b-container>
    </div>

    <div class="wrapper">
      <Sidebar />
      <div class="wrapper-content">
        <div class="settings">
          <div class="settings-head">
            <div class="settings-head-title">
              <h1>{{ $t("settings.title") }}</h1>
            </div>
          </div>

          <b-skeleton
            class="profile-action-skeleton"
            v-if="!me.id || !packages.length || !languages?.data.length"
          ></b-skeleton>

          <div class="settings-block" v-else>
            <div class="settings-accordion accordion" id="settings-accordions">
              <Preferences />
              <Billing v-if="currentWorkspace.type === 'individual'" />
              <Packages v-if="currentWorkspace.type === 'individual'" />
              <AccountManagement />
              <Archived v-if="currentWorkspace.type === 'corporate'" />
              <Match v-if="currentWorkspace.type === 'individual'" />
            </div>
            <ChangePassword v-if="getModalStatus('settings_change_password')" />
            <ChangeEmail v-if="getModalStatus('settings_change_email')" />
            <FreezeAccount v-if="getModalStatus('settings_freeze_account')" />
            <ConfirmPassword
              v-if="getModalStatus('settings_confirm_password')"
            />
            <DeleteAccount v-if="getModalStatus('settings_delete_account')" />

            <ChangePasswordSuccess
              v-if="getModalStatus('settings_change_password_success')"
            />

            <FreezeAccountSuccess
              v-if="getModalStatus('settings_freeze_account_success')"
            />

            <DeleteAccountSuccess
              v-if="getModalStatus('settings_delete_account_success')"
            />

            <MembershipCancel
              v-if="getModalStatus('settings_membership_cancel')"
            />
            <PaymentStatus v-if="getModalStatus('settings_payment_status')" />
            <MatchCard v-if="getModalStatus('settings_match_card')" />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { onMounted, computed } from "vue";
import Header from "@/components/Header";
import Sidebar from "@/components/Sidebar.vue";
import Billing from "@/components/Settings/Billing.vue";
import Preferences from "@/components/Settings/Preferences.vue";
import Archived from "@/components/Settings/Archived.vue";
import Match from "@/components/Settings/Match.vue";
import AccountManagement from "@/components/Settings/AccountManagement";
import Packages from "../../components/Settings/Packages.vue";
import ChangeEmail from "@/components/Settings/Modals/ChangeEmail.vue";
import ChangePassword from "../../components/Settings/Modals/ChangePassword.vue";
import FreezeAccount from "../../components/Settings/Modals/FreezeAccount";
import ConfirmPassword from "../../components/Settings/Modals/ConfirmPassword";
import DeleteAccount from "../../components/Settings/Modals/DeleteAccount";
import ChangePasswordSuccess from "../../components/Settings/Modals/ChangePasswordSuccess.vue";
import FreezeAccountSuccess from "../../components/Settings/Modals/FreezeAccountSuccess.vue";
import DeleteAccountSuccess from "../../components/Settings/Modals/DeleteAccountSuccess.vue";
import { getModalStatus, setModalStatus } from "@/utils/Helpers";
import MembershipCancel from "@/components/Settings/Modals/MembershipCancel.vue";
import PaymentStatus from "@/components/Settings/Modals/PaymentStatus.vue";
import MatchCard from "@/components/Settings/Modals/MatchCard.vue";
import { useMeta } from "vue-meta";
import i18n from "@/utils/i18n.vue";
import store from "@/store";

const sidebarStatus = computed(() => store.getters["common/sidebar_status"]);
const me = computed(() => store.getters["account/me"]);
const packages = computed(() => store.getters["product/list"]);
const languages = computed(() => store.getters["misc/languages"]);
const currentWorkspace = computed(() => store.getters["account/workspace"]);

useMeta({
  title: i18n.global.t("settings.title"),
});

onMounted(() => {
  if (localStorage.getItem("show_payment_status_modal") == "true") {
    setModalStatus("settings_payment_status", true);
    localStorage.removeItem("show_payment_status_modal");
  }

  store.dispatch("product/list");
  store.dispatch("misc/languages");

  // click anywhere on body
  document.body.addEventListener("click", function (e) {
    const target = e.target;
    const tagName = target.tagName.toLowerCase();

    // if target is svg element
    if (tagName === "svg" || tagName === "path") {
      const parent = target.closest(".notranslate");

      if (parent) {
        localStorage.removeItem("show_payment_status_modal");

        window.location.reload();
      }
    }
  });
});
</script>
