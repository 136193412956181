<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <main-layout :head="false" class="main-members">
    <div class="members">
      <Head mode="members" />

      <Search />

      <b-skeleton v-if="isLoading" class="profile-action-skeleton"></b-skeleton>

      <div class="members-body" v-else>
        <Empty v-if="!members || !members.length" />
        <List v-else />
      </div>

      <InviteButton v-if="currentWorkspace?.role !== 'member'" />
      <InviteModal
        v-if="
          getModalStatus('member_invite') && currentWorkspace?.role !== 'member'
        "
      />
      <RemoveMemberModal v-if="getModalStatus('remove_member')" />
      <ResetMemberModal v-if="getModalStatus('reset_member')" />
      <UpgradeMemberModal v-if="getModalStatus('membership_upgrade')" />
      <UpgradeMemberSuccessModal v-if="getModalStatus('membership_upgrade_success')" />
      <CancelMemberModal v-if="getModalStatus('membership_cancel')" />
      <CancelMemberSuccessModal v-if="getModalStatus('membership_cancel_success')" />
      <View
        v-if="getModalStatus('member_view')"
        :item="getModalData('member_view')"
        :actions="['edit', 'close']"
        :is-modal="true"
      />
      <EditProfile
        v-if="getModalStatus('member_edit')"
        :item="getModalData('member_edit')"
        :is-modal="true"
        :show-corporate-fields="false"
      />
    </div>
  </main-layout>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import store from "@/store";
import MainLayout from "@/layouts/MainLayout.vue";
import { useMeta } from "vue-meta";
import i18n from "@/utils/i18n";
import Head from "@/components/Team/Common/Head.vue";
import Search from "@/components/Team/Members/Search.vue";
import Empty from "@/components/Team/Members/Empty.vue";
import List from "@/components/Team/Members/List.vue";
import InviteButton from "@/components/Team/Common/InviteButton.vue";
import InviteModal from "@/components/Team/Common/Modals/Invite.vue";
import RemoveMemberModal from "@/components/Team/Modals/RemoveMember.vue";
import ResetMemberModal from "@/components/Team/Members/Modals/Reset";
import UpgradeMemberModal from "@/components/Team/Members/Modals/Upgrade";
import UpgradeMemberSuccessModal from "@/components/Team/Members/Modals/UpgradeSuccess";
import CancelMemberModal from "@/components/Team/Members/Modals/Cancel";
import CancelMemberSuccessModal from "@/components/Team/Members/Modals/CancelSuccess";
import { getModalStatus, getModalData } from "@/utils/Helpers";
import View from "@/components/Profile/View.vue";
import EditProfile from "@/components/Profile/Page.vue";

const isLoading = ref(true);
const members = computed(() => store.getters["member/items"]);
const currentWorkspace = computed(() => store.getters["account/workspace"]);

useMeta({
  title: i18n.global.t("team.members.title"),
});

onMounted(() => {
  store.commit("profiles/setItem", null);

  store.dispatch("member/items").then(() => {
    isLoading.value = false;
  });
});
</script>
