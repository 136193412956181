import ProfileService from "@/services/modules/ProfileService";

export const profiles = {
  namespaced: true,
  items: [],
  item: {},
  actions: {
    items({ commit }) {
      return ProfileService.list().then(
        (response) => {
          commit("items", response.items);
          return Promise.resolve(response.items);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    create({ commit }, item) {
      return ProfileService.create(item).then(
        (item) => {
          commit("create", item);
          return Promise.resolve(item);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    get({ commit }, id) {
      return ProfileService.get(id).then(
        (item) => {
          commit("get", item);
          return Promise.resolve(item);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    update({ commit }, item) {
      return ProfileService.update(item.id, item.data).then(
        (item) => {
          return Promise.resolve(item);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    share({ commit }, item) {
      return ProfileService.share(item.id, item.type, item.recipients).then(
        (item) => {
          return Promise.resolve(item);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    delete({ commit }, id) {
      return ProfileService.destroy(id).then(
        (item) => {
          return Promise.resolve(item);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updateVisibility({ commit }, item) {
      return ProfileService.updateVisibility(item).then(
        (item) => {
          return Promise.resolve(item);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    updatePreferences({ commit }, item) {
      return ProfileService.updatePreferences(item).then(
        (item) => {
          return Promise.resolve(item);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    addEmailImage({ commit }, data) {
      return ProfileService.addEmailImage(data).then(
        (item) => {
          return Promise.resolve(item);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    deleteEmailImage({ commit }, data) {
      return ProfileService.deleteEmailImage(data).then(
        (item) => {
          return Promise.resolve(item);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    match({ commit }, data) {
      return ProfileService.match(data).then(
        (item) => {
          return Promise.resolve(item);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    items(state, items) {
      state.items = items;
    },
    get(state, item) {
      state.item = item;
    },
    setItem(state, item) {
      state.item = item;
    },
  },
  getters: {
    items: (state) => {
      return state.items;
    },
    item: (state) => {
      return state.item;
    },
  },
};
