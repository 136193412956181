<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <div class="spinner-container" v-if="isLoading">
    <div class="loading-spinner"></div>
  </div>
  <SecondLayout v-else>
    <section class="login" v-if="view === 'form'">
      <div class="login-contain">
        <div class="login-main">
          <div class="login-left-side">
            <div class="login-left-side-main">
              <div class="login-left-side-title">
                <h1>
                  {{
                    selectedMode === "individual"
                        ? t("auth.register.title")
                        : t("auth.register.corporate.title")
                  }}
                </h1>
              </div>
              <div
                  class="login-left-side-tab"
                  v-if="appVersion === 3 && showModeTabs"
              >
                <div class="login-left-side-tab-block">
                  <ul
                      class="nav nav-tabs"
                      id="login-left-side-tab-block-tab"
                      role="tablist"
                      v-if="modes"
                  >
                    <li class="nav-item" v-for="mode in modes" :key="mode">
                      <a
                          class="nav-link active"
                          role="tab"
                          @click="changeMode(mode)"
                      >
                        {{ t(`misc.${mode}_label`) }}
                      </a>
                    </li>
                    <div class="login-left-side-tab-block-base">
                      <div class="login-left-side-tab-block-base-active"></div>
                    </div>
                  </ul>
                </div>
              </div>
              <div class="login-left-side-form">
                <Form
                    @submit="submit"
                    :validation-schema="schema"
                    v-slot="{ errors }"
                    ref="form"
                >
                  <template v-if="selectedMode === modes.individual">
                    <Field
                        name="first_name"
                        :errors="errors"
                        :placeholder="$t('misc.first_name')"
                    />

                    <Field
                        name="last_name"
                        :errors="errors"
                        :placeholder="$t('misc.last_name')"
                    />

                    <Field
                        name="email"
                        :placeholder="$t('misc.email')"
                        :errors="errors"
                        :is-disabled="emailFieldIsDisabled"
                    />

                    <div class="header-second-version-contain-language form-item h-40">
                      <v-select
                          v-model="selectedLanguage"
                          :options="languages.data"
                          :placeholder="$t('settings.preferences.language')"
                          :label="$t('settings.preferences.language')"
                          :get-option-label="(option) => option.name"
                          :get-option-value="(option) => option.locale"
                          @input="change"
                      >
                        <template #no-options>
                          <div class="">
                            {{ $t("misc.no_results") }}
                          </div>
                        </template>
                      </v-select>
                    </div>


                    <div
                        :class="[
                        'form-item h-40',
                        errors.password ? 'is-error' : '',
                      ]"
                    >
                      <VField v-slot="{ field }" name="password">
                        <PasswordInput
                            :bind-value="field"
                            :placeholder="$t('auth.common.password')"
                        />
                      </VField>
                    </div>

                    <div class="form-item-error-text" v-if="errors.password">
                      {{ errors.password }}
                    </div>

                  </template>

                  <template v-else>
                    <p class="mb-4">
                      {{ $t("auth.register.corporate.description") }}
                    </p>

                    <Field
                        name="full_name"
                        :errors="errors"
                        :placeholder="$t('misc.full_name')"
                    />

                    <Field
                        name="company_name"
                        :errors="errors"
                        :placeholder="$t('misc.company')"
                    />

                    <Field
                        name="email"
                        :placeholder="$t('auth.common.corporate.email')"
                        :errors="errors"
                    />

                    <Field
                        name="phone"
                        :errors="errors"
                        :placeholder="$t('misc.phone')"
                        mask="+90 (###) ### ## ##"
                    />

                    <Field
                        name="employee_count"
                        :errors="errors"
                        :placeholder="
                        $t('auth.register.corporate.employee_count')
                      "
                    />
                  </template>

                  <div class="login-left-side-form-terms">
                    <i18n-t keypath="auth.register.terms_text" tag="p">
                      <template v-slot:text_1>
                        {{ $t("auth.register.terms_text_1") }}
                      </template>
                      <template v-slot:text_2>
                        <a
                            @click="
                            setModalStatus('auth_register_terms', true, {
                              type: 'membership',
                            })
                          "
                        >
                          {{ $t("auth.register.terms_text_2") }}
                        </a>
                      </template>
                      <template v-slot:text_3>
                        <a
                            @click="
                            setModalStatus('auth_register_terms', true, {
                              type: 'cookie',
                            })
                          "
                        >
                          {{ $t("auth.register.terms_text_3") }}
                        </a>
                      </template>
                      <template v-slot:text_4>
                        <a
                            @click="
                            setModalStatus('auth_register_terms', true, {
                              type: 'express_written_consent',
                            })
                          "
                        >
                          {{ $t("auth.register.terms_text_4") }}
                        </a>
                      </template>
                      <template v-slot:text_5>
                        <a
                            @click="
                            setModalStatus('auth_register_terms', true, {
                              type: 'personal_data',
                            })
                          "
                        >
                          {{ $t("auth.register.terms_text_5") }}
                        </a>
                      </template>
                    </i18n-t>
                  </div>
                  <div class="login-left-side-form-send-btn">
                    <Button
                        :state="buttonState"
                        class="btn btn-md btn-primary-blue btn-round-8 btn-500"
                    >
                      {{
                        selectedMode === modes.individual
                            ? $t("auth.register.button_label")
                            : $t("auth.register.corporate.button_label")
                      }}
                    </Button>
                  </div>
                  <div
                      class="login-left-side-form-error-text"
                      v-if="errorMessage"
                  >
                    <p>
                      {{ errorMessage }}
                    </p>
                  </div>

                  <OtherLogin v-if="selectedMode === modes.individual" />

                  <div class="login-left-side-form-footer">
                    <p>
                      {{ t("auth.register.already") }}
                      <router-link to="/login">
                        {{ t("auth.login.title") }}
                      </router-link>
                    </p>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div
              class="login-right-side"
              style="
              background-image: url(/img/static/login-right-side-bg-login-member.jpg);
            "
          >
            <figure>
              <img
                  src="/img/dynamic/login-right-side-figure-member.png"
                  alt=""
              />
            </figure>
          </div>
          <div
              class="login-right-side is-corporate"
              style="
              background-image: url(/img/static/login-right-side-bg-corporate.jpg);
            "
          >
            <figure>
              <img
                  src="/img/dynamic/login-right-side-figure-corporate.png"
                  alt=""
              />
            </figure>
          </div>
        </div>
      </div>
    </section>
    <Error :title="errorStrings.title" :content="errorStrings.content" v-else />
    <Terms v-if="getModalStatus('auth_register_terms')" />
    <CorporateConfirm
        v-if="getModalStatus('auth_register_corporate_confirm')"
    />
  </SecondLayout>
</template>

<style>
.form-item.header-second-version-contain-language {
  top: unset;
  right: unset;
}

.form-item.header-second-version-contain-language .v-select .vs__dropdown-toggle {
  width: 100%;
}
</style>

<script setup>
// Main
import { ref, onMounted, computed, watch } from "vue";
import store from "@/store";
import { useMeta } from "vue-meta";
import { useI18n } from "vue-i18n";
import { Form, Field as VField } from "vee-validate";
import * as yup from "yup";
import { useRouter, useRoute } from "vue-router";
import Field from "@/components/inputs/Field.vue";
import { getModalStatus, setModalStatus } from "@/utils/Helpers";
import useAuth from "@/composables/auth";

// Components
import SecondLayout from "@/layouts/SecondLayout.vue";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import Button from "../../components/inputs/Button.vue";
import OtherLogin from "@/components/OtherLogin.vue";
import Terms from "@/components/Auth/Modals/Terms.vue";
import CorporateConfirm from "@/components/Auth/Modals/CorporateConfirm.vue";
import i18n from "../../utils/i18n.vue";
import Error from "@/components/Auth/Error.vue";

//meta
useMeta({
  title: i18n.global.t("auth.register.title"),
});

const selectedLanguage = ref(null);
const languages = computed(() => {
  const languages = store.getters["misc/languages"]

  if (languages.data?.length > 0) {
    selectedLanguage.value = languages.data[0]
  }

  return languages
});


// Variables
const phoneRegExp =
    /^(\+90|0)?\s*\(?([0-9]{3})\)?\s*([0-9]{3})\s*([0-9]{2})\s*([0-9]{2})$/;
const showModeTabs = ref(true);
const emailFieldIsDisabled = ref(false);
const isLoading = ref(true);
const view = ref("form");
const errorStrings = ref({
  title: "",
  content: "",
});
const form = ref(null);
const modes = store.getters["common/modes"];
const selectedMode = ref(modes.individual);
const { t } = useI18n();
const schema = yup.object({
  full_name: yup.string().when([], {
    is: () => selectedMode.value === modes.corporate,
    then: yup.string().required().label(i18n.global.t("misc.full_name")).trim(),
    otherwise: yup.string().notRequired(),
  }),
  phone: yup.string().when([], {
    is: () => selectedMode.value === modes.corporate,
    then: yup
        .string()
        .required()
        .matches(phoneRegExp, i18n.global.t("validation.phone"))
        .label(i18n.global.t("misc.phone"))
        .trim(),
    otherwise: yup.string().notRequired(),
  }),
  company_name: yup.string().when([], {
    is: () => selectedMode.value === modes.corporate,
    then: yup.string().required().label(i18n.global.t("misc.company")).trim(),
    otherwise: yup.string().notRequired(),
  }),
  first_name: yup.string().when([], {
    is: () => selectedMode.value === modes.individual,
    then: yup
        .string()
        .required()
        .label(i18n.global.t("misc.first_name"))
        .trim(),
  }),
  last_name: yup.string().when([], {
    is: () => selectedMode.value === modes.individual,
    then: yup.string().required().label(i18n.global.t("misc.last_name")).trim(),
  }),
  email: yup
      .string()
      .required()
      .email()
      .label(i18n.global.t("misc.email"))
      .trim(),
  password: yup.string().when([], {
    is: () => selectedMode.value === modes.individual,
    then: yup
        .string()
        .required()
        .min(4)
        .label(i18n.global.t("auth.common.password"))
        .trim(),
  }),
  employee_count: yup.string().when([], {
    is: () => selectedMode.value === modes.corporate,
    then: yup
        .string()
        .required()
        .matches(/^[0-9]+$/, i18n.global.t("validation.number"))
        .label(i18n.global.t("auth.register.corporate.employee_count"))
        .trim(),
  }),
});

const router = useRouter();
const route = useRoute();

const buttonState = ref("default");
const errorMessage = computed(() => store.getters["auth/error_message"]);

const { register } = useAuth({ buttonState, router });
const appVersion = computed(() => store.getters["common/app_version"]);

const submit = (values) => {
  const params = route.query;

  values.register_type = "default";
  values.mode =
      selectedMode.value === modes.individual ? "individual" : "corporate_pre";

  if (params.token) {
    values.token = params.token;
  }

  if (selectedLanguage.value) {
    values.language = selectedLanguage.value.locale;
  }

  register(values);
};

const changeMode = (mode) => {
  const index = Object.values(modes).indexOf(mode);
  selectedMode.value = mode;

  const clickedTab =
      document.querySelectorAll(".nav-link")[index].parentElement;
  const tabBaseActive = document.querySelector(
      ".login-left-side-tab-block-base-active"
  );

  const clickedWidth = clickedTab.offsetWidth;
  const clickedOffsetLeft = clickedTab.offsetLeft;

  tabBaseActive.style.left = clickedOffsetLeft + 2 + "px";
  tabBaseActive.style.width = clickedWidth - 4 + "px";

  store.commit("common/apply_mode", { type: mode });

  localStorage.setItem("mode", mode);

  // reset all errors
  form.value.resetForm();

  // reset all fields
  // form.value.setValues(;
};

onMounted(() => {
  const params = route.query;
  const token = params.token;

  if (token) {
    store
        .dispatch("invitation/check", token)
        .then((response) => {
          const data = response.data;
          const email = data.email;
          const mode = "individual";
          isLoading.value = false;
          showModeTabs.value = false;

          store.commit("common/apply_mode", { type: mode });

          localStorage.setItem("mode", mode);

          setTimeout(() => {
            if (email) {
              emailFieldIsDisabled.value = true;
            }
            form.value.setValues({
              email: email,
            });
          }, 500);
        })
        .catch((error) => {
          document.body.classList.add("body-reset-password");

          const status = error.response.data.data.status;

          switch (status) {
            case "expired":
              errorStrings.value.title = t("auth.register.invite.expired.title");
              errorStrings.value.content = t(
                  "auth.register.invite.expired.content"
              );
              break;
            case "invalid":
              errorStrings.value.title = t("auth.register.invite.invalid.title");
              errorStrings.value.content = t(
                  "auth.register.invite.invalid.content"
              );
              break;
            case "confirmed":
              errorStrings.value.title = t(
                  "auth.register.invite.confirmed.title"
              );
              errorStrings.value.content = t(
                  "auth.register.invite.confirmed.content"
              );
              break;
          }

          isLoading.value = false;

          view.value = "error";
        });
  } else {
    isLoading.value = false;
  }

  store.dispatch("auth/clearErrorMessage");
  store.dispatch("misc/options");
});

watch(
    () => isLoading.value,
    (value) => {
      if (!value && view.value === "form") {
        const mode = store.getters["common/mode"];
        setTimeout(() => {
          changeMode(mode);
        }, 100);
      }
    }
);
</script>
