<template>
  <swiper
    :slides-per-view="1"
    :space-between="50"
    :rewind="true"
    :pagination="{
      clickable: true,
      el: '.swiper-pagination',
    }"
    :navigation="{
      nextEl: '.button-next',
      prevEl: '.button-prev',
    }"
  >
    <template v-if="props.activeTab.id === 'hubspot'">
      <swiper-slide>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            01
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>
              {{ $t("email_signature.hubspot.step_1_text") }}
              <br /><br />
              <a href="javascript:;" @click="copy"> {{ $t("email_signature.hubspot.step_1_button") }} </a>
            </p>
          </div>
        </div>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            02
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>{{ $t("email_signature.hubspot.step_2_text") }}</p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            03
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>
              {{ $t("email_signature.hubspot.step_3_text") }}
            </p>
          </div>
        </div>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            04
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>
              {{ $t("email_signature.hubspot.step_4_text") }}
            </p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            05
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>
              {{ $t("email_signature.hubspot.step_5_text") }}
            </p>
          </div>
        </div>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            06
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>
              {{ $t("email_signature.hubspot.step_6_text") }}
            </p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            07
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>{{ $t("email_signature.hubspot.step_7_text") }}</p>
          </div>
        </div>
      </swiper-slide>
    </template>

    <template v-else-if="props.activeTab.id === 'google'">
      <swiper-slide>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            01
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>
              {{ $t("email_signature.gmail.step_1_text") }}
              <br /><br />
              <a href="javascript:;" @click="copy"> {{ $t("email_signature.gmail.step_1_button") }} </a>
            </p>
          </div>
        </div>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            02
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>{{ $t("email_signature.gmail.step_2_text") }}</p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            03
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>{{ $t("email_signature.gmail.step_3_text") }}</p>
          </div>
        </div>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            04
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>
              {{ $t("email_signature.gmail.step_4_text") }}
            </p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            05
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>{{ $t("email_signature.gmail.step_5_text") }}</p>
          </div>
        </div>
      </swiper-slide>
    </template>

    <template v-else-if="props.activeTab.id === 'outlook'">
      <swiper-slide>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            01
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>
              {{ $t("email_signature.outlook.step_1_text") }}
              <br /><br />
              <a href="javascript:;" @click="copy"> {{ $t("email_signature.outlook.step_1_button") }} </a>
            </p>
          </div>
        </div>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            02
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>{{ $t("email_signature.outlook.step_2_text") }}</p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            03
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>{{ $t("email_signature.outlook.step_3_text") }}</p>
          </div>
        </div>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            04
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>{{ $t("email_signature.outlook.step_4_text") }}</p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            05
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>
              {{ $t("email_signature.outlook.step_5_text") }}
            </p>
          </div>
        </div>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            06
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>{{ $t("email_signature.outlook.step_6_text") }}</p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            07
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>{{ $t("email_signature.outlook.step_7_text") }}</p>
          </div>
        </div>
      </swiper-slide>
    </template>

    <template v-else-if="props.activeTab.id === 'apple'">
      <swiper-slide>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            01
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>
              {{ $t("email_signature.apple.step_1_text") }}
              <br /><br />
              <a href="javascript:;" @click="copy"> {{ $t("email_signature.apple.step_1_button") }} </a>
            </p>
          </div>
        </div>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            02
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>{{ $t("email_signature.apple.step_2_text") }}</p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            03
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>{{ $t("email_signature.apple.step_3_text") }}</p>
          </div>
        </div>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            04
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>{{ $t("email_signature.apple.step_4_text") }}</p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            05
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>
             {{ $t("email_signature.apple.step_5_text") }}
            </p>
          </div>
        </div>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            06
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>{{ $t("email_signature.apple.step_6_text") }}</p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            07
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>{{ $t("email_signature.apple.step_7_text") }}</p>
          </div>
        </div>
      </swiper-slide>
    </template>

    <template v-else-if="props.activeTab.id === 'yahoo'">
      <swiper-slide>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            01
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>
              {{ $t("email_signature.yahoo.step_1_text") }}
              <br /><br />
              <a href="javascript:;" @click="copy"> {{ $t("email_signature.yahoo.step_1_button") }} </a>
            </p>
          </div>
        </div>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            02
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>{{ $t("email_signature.yahoo.step_2_text") }}</p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            03
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>{{ $t("email_signature.yahoo.step_3_text") }}</p>
          </div>
        </div>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            04
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>{{ $t("email_signature.yahoo.step_4_text") }}</p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            05
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>
              {{ $t("email_signature.yahoo.step_5_text") }}
            </p>
          </div>
        </div>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            06
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>{{ $t("email_signature.yahoo.step_6_text") }}</p>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide>
        <div class="email-signature-network-content-swiper-item">
          <div class="email-signature-network-content-swiper-item-number">
            07
          </div>
          <div class="email-signature-network-content-swiper-item-content">
            <p>{{ $t("email_signature.yahoo.step_7_text") }}</p>
          </div>
        </div>
      </swiper-slide>
    </template>
  </swiper>
</template>

<script setup>
import { ref, onMounted, computed, defineProps } from "vue";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { setModalStatus, copyClipboardElement } from "@/utils/Helpers";
import copyEl from "copy-to-clipboard";

SwiperCore.use([Navigation, Pagination, A11y]);

const props = defineProps({
  activeTab: {
    type: Object,
    required: true,
  },
  isModal: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const copy = () => {
  if (props.isModal) {
    copyClipboardElement("email-copy-view");
  } else {
    setModalStatus("email_copy_view", true, {
      showCopyActions: true,
      done: () => {
        setModalStatus("email_copy_view", false);
      },
    });
  }
  // setModalStatus("email_service", false);
  // setModalStatus("email_copy", true, props.activeTab);
};
</script>
