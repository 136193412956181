<template>
  <div class="business-card-profile-content-trash is-show">
    <div class="business-card-profile-content-trash-head">
      <div class="business-card-profile-content-trash-head-description">
        <p v-if="props.item.has_card">
          Bu profil bir kartla ilişkilendirilmiştir. Bu yüzden silinemez.
        </p>

        <template v-else>
          <p v-if="me.membership_level === MembershipLevelEnum.FREE">
            {{ $t("profile.trash.text") }}
          </p>
          <p>{{ $t("profile.trash.confirm_text") }}</p>
        </template>
      </div>
      <div
        class="business-card-profile-content-trash-head-close"
        @click="setModalStatus('profile_trash', false)"
      >
        <button type="button">
          <img src="/img/static/icon-x.svg" alt="" />
        </button>
      </div>
    </div>
    <div class="business-card-profile-content-trash-buttons">
      <a href="javascript:;" class="is-regular" @click="deleteProfile" v-if="!props.item.has_card">
        {{ $t("misc.delete") }}
      </a>
      <a @click="pushEditProfile" href="javascript:;">
        {{ $t("misc.edit") }}
      </a>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, computed } from "vue";
import store from "@/store";
import { MembershipLevelEnum } from "@/enums";
import { setModalStatus } from "@/utils/Helpers";
import { useRouter } from "vue-router";

const me = computed(() => store.getters["account/me"]);
const router = useRouter();
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const deleteProfile = () => {
  store.dispatch("profiles/delete", props.item.id).then(() => {
    setModalStatus("profile_trash", false);

    store.dispatch("profiles/items");
    store.dispatch("account/me");

    router.push({
      name: "profile-create",
    });
  });
};

const pushEditProfile = () => {
  setModalStatus("profile_trash", false);
  router.push({
    name: "profile-edit",
    params: {
      id: props.item.id,
    },
  });
};
</script>
