<template>
  <div class="email-signature-visibility-modal is-show" v-if="item">
    <a
      href="javascript:;"
      class="email-signature-visibility-modal-close"
      @click="setModalStatus('email_visibility', false)"
    >
      <i>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 1L1 13"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 1L13 13"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </i>
    </a>
    <a
      href="javascript:;"
      @click="setModalStatus('email_visibility', false)"
      class="email-signature-visibility-modal-prev-btn btn btn-text btn-sm btn-primary-blue btn-500"
    >
      <i data-position="left">
        <svg
          width="8"
          height="14"
          viewBox="0 0 8 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 13L1 7L7 1"
            stroke="#167FFC"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </i>
      {{ $t("email_signature.visibility.title") }}
    </a>
    <div class="email-signature-visibility-modal-content">
      <div class="email-signature-visibility-modal-content-comment">
        <p>
          {{ $t("email_signature.visibility.text") }}
        </p>
      </div>
      <div class="email-signature-visibility-modal-content-form">
        <form action="" method="">
          <div class="form-item-switch">
            <label for="">
              <input
                type="checkbox"
                v-model="visibility.phone"
                :disabled="isDisabled('phone')"
              />
              <span> {{ $t("misc.phone") }}</span>
            </label>
          </div>
          <div class="form-item-switch">
            <label for="">
              <input
                type="checkbox"
                v-model="visibility.email"
                :disabled="isDisabled('email')"
              />
              <span>
                {{ $t("misc.email") }}
              </span>
            </label>
          </div>
          <div class="form-item-switch">
            <label for="">
              <input
                type="checkbox"
                v-model="visibility.address"
                :disabled="isDisabled('address')"
              />
              <span>
                {{ $t("misc.address") }}
              </span>
            </label>
          </div>
          <div class="form-item-switch">
            <label for="">
              <input
                type="checkbox"
                v-model="visibility.website"
                :disabled="isDisabled('website')"
              />
              <span>
                {{ $t("misc.website") }}
              </span>
            </label>
          </div>
          <div class="form-item-switch">
            <label for="">
              <input
                type="checkbox"
                v-model="visibility.social_media"
                :disabled="isDisabled('social_media')"
              />
              <span>
                {{ $t("misc.icons") }}
              </span>
            </label>
          </div>
          <template
            v-if="
              workspace.type === 'corporate' && workspace?.role !== 'member'
            "
          >
            <div class="form-item-switch">
              <label for="">
                <input type="checkbox" v-model="visibility.banner" />
                <span>
                  {{ $t("email_signature.visibility.banner") }}
                </span>
              </label>
            </div>
            <div class="form-item-switch">
              <label for="">
                <input type="checkbox" v-model="visibility.disclaimer_text" />
                <span>
                  {{ $t("email_signature.visibility.disclaimer") }}
                </span>
              </label>
            </div>
          </template>
        </form>
      </div>
      <div class="email-signature-visibility-modal-content-footer">
        <a
          class="btn btn-sm btn-white btn-round-4 btn-400"
          href="javascript:;"
          @click="cancel"
        >
          {{ $t("misc.cancel") }}
        </a>
        <Button
          class="btn btn-sm btn-primary-blue btn-round-4 btn-400"
          :state="state"
          @click="updateVisibility"
        >
          {{ $t("misc.save") }}
        </Button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { setModalStatus, getModalData } from "@/utils/Helpers";
import Button from "../../inputs/Button.vue";
import store from "@/store";

let visibilityInit = {};
let workspaceVisibilityInit = {};

const workspace = computed(() => store.getters["account/workspace"]);
const item = computed(() => {
  const item = store.getters["profiles/item"];

  if (item) {
    if (!Object.keys(visibilityInit).length) {
      // don't ref the object
      visibilityInit = { ...item.acf.preferences.email_visibility };
    }

    visibility.value = item.acf.preferences.email_visibility;

    if (item.acf.workspace_settings?.preferences?.email_visibility) {
      workspaceVisibilityInit.value =
        item.acf.workspace_settings.preferences.email_visibility;
    }
  }

  return item;
});

const visibility = ref({});
const state = ref("default");

const isDisabled = (field) => {
  return false;
  if (workspace.value.type === "corporate") {
    return workspaceVisibilityInit.value[field] === false;
  }

  return false;
};

const cancel = () => {
  const visibilityInitEntries = Object.entries(visibilityInit);

  visibilityInitEntries.forEach(([key, value]) => {
    visibility.value[key] = value;
  });

  setModalStatus("email_visibility", false);
};

const updateVisibility = () => {
  state.value = "loading";
  store
    .dispatch("profiles/updateVisibility", {
      id: item.value.id,
      email_visibility: visibility.value,
    })
    .then(() => {
      visibilityInit = { ...visibility.value };
      state.value = "success";
    });
};
</script>
