import LocationService from "@/services/modules/LocationService";

export const location = {
  namespaced: true,
  countries: [],
  states: [],
  cities: [],
  neighborhoods: [],
  streets: [],
  actions: {
    countries({ commit }) {
      return LocationService.countries().then(
        (data) => {
          commit("countries", data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    states({ commit }, payload) {
      return LocationService.states(payload).then(
        (data) => {
          commit("states", data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    cities({ commit }, payload) {
      return LocationService.cities(payload.country, payload.state).then(
        (data) => {
          commit("cities", data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    neighborhoods({ commit }, payload) {
      return LocationService.neighborhoods(
        payload.country,
        payload.state,
        payload.city
      ).then(
        (data) => {
          commit("neighborhoods", data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    streets({ commit }, payload) {
      return LocationService.streets(
        payload.country,
        payload.state,
        payload.city,
        payload.neighborhood
      ).then(
        (data) => {
          commit("streets", data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    countries(state, data) {
      state.countries = data;
    },
    states(state, data) {
      state.states = data;
    },
    cities(state, data) {
      state.cities = data;
    },
    neighborhoods(state, data) {
      state.neighborhoods = data;
    },
    streets(state, data) {
      state.streets = data;
    },
  },
  getters: {
    countries: (state) => {
      return state.countries;
    },
    states: (state) => {
      return state.states;
    },
    cities: (state) => {
      return state.cities;
    },
    neighborhoods: (state) => {
      return state.neighborhoods;
    },
    streets: (state) => {
      return state.streets;
    },
  },
};
