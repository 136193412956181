<template>
  <div class="profile-creation-body-content-business-card is-show">
    <div class="profile-creation-body-content-business-card-block" v-if="item">
      <div class="profile-creation-body-content-business-card-block-image">
        <div class="mail-template is-column-view">
          <a :href="urls.card.view" target="_blank">
            <div class="mail-template-main">
              <div class="mail-template-pictures">
                <div class="mail-template-pictures-user" v-if="profilePhoto">
                  <img :src="profilePhoto.url" :alt="displayName()" />
                </div>
                <div class="mail-template-pictures-logo" v-if="companyLogo">
                  <img :src="companyLogo.url" :alt="getValue('company_name')" />
                </div>
              </div>
              <div class="mail-template-head">
                <div class="mail-template-head-name">
                  <p>{{ displayName() }}</p>
                </div>
                <div class="mail-template-head-title">
                  <p>{{ getValue("title") }}</p>
                </div>
                <div class="mail-template-head-company">
                  <p>{{ getValue("company_name") }}</p>
                </div>
              </div>
              <div class="mail-template-info">
                <ul>
                  <template v-if="phones">
                    <li v-for="(phone, index) in phones" :key="index">
                      <img
                        src="/img/static/icon-mail-template-tel.svg"
                        alt=""
                      />
                      <span>{{ phone.phone_type.label }}</span>
                      <p :href="'tel:' + phone.phone_number">
                        {{ phone.phone_number }}
                      </p>
                    </li>
                  </template>
                  <li v-if="getValue('email')">
                    <img src="/img/static/icon-mail-template-mail.svg" alt="" />
                    <p :href="`mailto:${getValue('email')}`">
                      {{ getValue("email") }}
                    </p>
                  </li>
                  <li v-if="website">
                    <img
                      src="/img/static/icon-mail-template-global.svg"
                      alt=""
                    />
                    <p :href="website.url" target="_blank">
                      {{ website.link_text || website.url }}
                    </p>
                  </li>
                  <li v-if="address">
                    <img src="/img/static/icon-mail-template-map.svg" alt="" />
                    <p
                      :href="'https://maps.google.com/?q=' + address"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {{ address }}
                    </p>
                  </li>
                </ul>
              </div>
              <div class="mail-template-social" v-if="socialMedias">
                <ul>
                  <template
                    v-for="item in socialMedias"
                    :key="item.social_media_type"
                  >
                    <li
                      v-if="!['date', 'file'].includes(item.social_media_type)"
                    >
                      <img
                        :src="`/img/static/icon-mail-template-${item.social_media_type}.svg`"
                        :alt="item.social_media_type"
                      />
                    </li>

                    <li v-else-if="item.social_media_type === 'file'">
                      <img
                        :src="`/img/static/icon-mail-template-${item.social_media_type}.svg`"
                        :alt="item.social_media_type"
                      />
                    </li>
                  </template>
                </ul>
              </div>
              <div class="mail-template-download-btn">
                <span>{{ $t("misc.download_card") }}</span>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="profile-creation-body-content-business-card-block-content">
        <span>
          {{ $t("profile.preview.text_1") }}
        </span>
        <p>
          {{ $t("profile.preview.text_2") }}
        </p>
      </div>
      <div class="profile-creation-body-content-business-card-block-button">
        <router-link
          class="btn btn-lg btn-secondary-yellow btn-round-8 btn-400"
          :to="{ name: 'profile-main' }"
        >
          {{ $t("profile.preview.button_label") }}
        </router-link>
      </div>
      <div
        class="profile-creation-body-content-business-card-block-close"
        @click="close"
        v-if="props.showCloseButton"
      >
        <button type="button">
          <img src="/img/static/icon-x.svg" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, onMounted, computed } from "vue";
import store from "@/store";
import useProfile from "@/composables/profile";

const props = defineProps({
  id: {
    type: Number,
    default: false,
  },
  showCloseButton: {
    type: Boolean,
    default: true,
  },
});
const emit = defineEmits(["close"]);
const item = computed(() => store.getters["profiles/item"]);

const {
  getValue,
  displayName,
  website,
  phones,
  address,
  socialMedias,
  profilePhoto,
  companyLogo,
  urls,
} = useProfile();

const close = () => {
  emit("close");
};

onMounted(() => {
  store.dispatch("profiles/get", props.id);
});
</script>
