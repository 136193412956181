<template>
  <div
    :class="['statistics-block-head-value', activeParent.colorName]"
    v-if="stats.success"
  >
    <ul class="nav nav-tabs" id="statisticsValueTab" role="tablist" v-if="tabs">
      <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
        <a
          :class="['nav-link', { active: activeTab.id === tab.id }]"
          @click="changeTab(index)"
        >
          <Loading type="skeleton" v-if="isLoading" />
          <div
            :class="[
              'statistics-block-head-value-item',
              {
                'is-decline':
                  stats.data[activeParent.id][tab.id]?.last_week?.type ===
                  'down',
                'is-increase':
                  stats.data[activeParent.id][tab.id]?.last_week?.type === 'up',
              },
            ]"
          >
            <div class="statistics-block-head-value-item-label">
              <span>{{ tab.name }}</span>
              <button
                type="button"
                ref="statsTooltip"
                v-tippy="{
                  theme: 'stats-tooltip-content',
                  content: tab.tooltip_text,
                }"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_12603_14224)">
                    <path
                      d="M9.99935 18.3327C14.6017 18.3327 18.3327 14.6017 18.3327 9.99935C18.3327 5.39698 14.6017 1.66602 9.99935 1.66602C5.39698 1.66602 1.66602 5.39698 1.66602 9.99935C1.66602 14.6017 5.39698 18.3327 9.99935 18.3327Z"
                      stroke="#979797"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 13.3333V10"
                      stroke="#979797"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10 6.66602H10.0083"
                      stroke="#979797"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_12603_14224">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
            <div class="statistics-block-head-value-item-ratio">
              <span v-if="tab.id !== 'conversion'">
                {{ stats.data[activeParent.id]?.[tab.id]?.count || 0 }}
              </span>
              <span v-else>
                %{{ stats.data[activeParent.id]?.[tab.id]?.count || 0 }}
              </span>
            </div>
            <div
              class="statistics-block-head-value-item-weekly"
              v-if="tab.id !== 'conversion' && !selectedDates.start"
            >
              <div class="statistics-block-head-value-item-weekly-label">
                <span>
                  {{ $t("stats.last_week_text") }}
                </span>
              </div>
              <div class="statistics-block-head-value-item-weekly-icon">
                <span>
                  {{
                    stats.data[activeParent.id][tab.id].last_week.percantage ||
                    0
                  }}%
                </span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17 7L7 17"
                    stroke="#D63835"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17 17L7 17L7 7"
                    stroke="#D63835"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, h } from "vue";
import store from "@/store";
import { useTippy } from "vue-tippy";
import Loading from "@/components/Common/Loading.vue";
const isLoading = computed(() => store.getters["stats/isLoading"]);
const selectedDates = computed(() => store.getters["stats/selectedDates"]);

const stats = computed(() => {
  const stats = store.getters["stats/get"];

  return stats;
});

const activeParent = computed(() => store.getters["stats/activeTab"]);
const activeTab = computed(() => store.getters["stats/activeSubTab"]);
const tabs = computed(() => store.getters["stats/subTabs"]);
const statsTooltip = ref(null);

// useTippy(statsTooltip, {

// });

const changeTab = (index) => {
  store.commit("stats/activeSubTab", index);
};
</script>
