<template>
  <div class="members-search">
    <div class="members-search-teams-member">
      <input type="text" placeholder="" ref="input" :value="item.name" />
      <button
        type="button"
        @click="save"
        :class="['edit-btn', { 'btn-loader': isLoader }]"
      >
        <svg
          :class="[{ 'd-none': isLoader }]"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 3.0003C17.2626 2.73766 17.5744 2.52932 17.9176 2.38718C18.2608 2.24503 18.6286 2.17188 19 2.17188C19.3714 2.17187 19.7392 2.24503 20.0824 2.38718C20.4256 2.52932 20.7374 2.73766 21 3.0003C21.2626 3.26295 21.471 3.57475 21.6131 3.91791C21.7553 4.26107 21.8284 4.62887 21.8284 5.0003C21.8284 5.37174 21.7553 5.73953 21.6131 6.08269C21.471 6.42585 21.2626 6.73766 21 7.0003L7.5 20.5003L2 22.0003L3.5 16.5003L17 3.0003Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import store from "@/store";

const isLoader = ref(false);
const input = ref(null);
const item = computed(() => {
  const item = store.getters["team/item"];

  return item;
});

const save = () => {
  item.value.name = input.value.value;
  isLoader.value = true;
  store
    .dispatch("team/update", {
      id: item.value.id,
      name: input.value.value,
    })
    .then(() => {
      isLoader.value = false;
      store.dispatch("team/item", item.value.id);
    });
};
</script>
