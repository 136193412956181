<template>
  <div class="members-body-content-item-block-head-settings-item">
    <button
      type="button"
      class="role-assignment-button"
      @click="toggleRoleArea"
      :content="$t('team.role_assign.label')"
      v-tippy
      v-click-away="onClickAway"
    >
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 11.5799V12.4999C21.9988 14.6563 21.3005 16.7545 20.0093 18.4817C18.7182 20.2088 16.9033 21.4723 14.8354 22.0838C12.7674 22.6952 10.5573 22.6218 8.53447 21.8744C6.51168 21.1271 4.78465 19.746 3.61096 17.9369C2.43727 16.1279 1.87979 13.9879 2.02168 11.8362C2.16356 9.68443 2.99721 7.63619 4.39828 5.99694C5.79935 4.35768 7.69279 3.21525 9.79619 2.74001C11.8996 2.26477 14.1003 2.4822 16.07 3.35986"
          stroke="#E3E3E3"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22 4.5L12 14.51L9 11.51"
          stroke="#E3E3E3"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
    <div
      :class="[
        'members-body-content-item-block-head-settings-item-menu role-assignment',
        { 'is-show': isShow },
      ]"
    >
      <div
        class="members-body-content-item-block-head-settings-item-menu-block"
      >
        <div
          class="members-body-content-item-block-head-settings-item-menu-block-list"
        >
          <div
            class="members-body-content-item-block-head-settings-item-menu-block-list-item"
          >
            <div class="form-item-radio">
              <input
                id="role-admin"
                name="role"
                type="radio"
                value="admin"
                v-model="selectedRole"
              />
              <label for="role-admin">{{ $t("team.role_assign.admin") }}</label>
            </div>
          </div>
          <div
            class="members-body-content-item-block-head-settings-item-menu-block-list-item"
          >
            <div class="form-item-radio">
              <input
                id="role-member"
                name="role"
                type="radio"
                value="member"
                v-model="selectedRole"
              />
              <label for="role-member">{{
                $t("team.role_assign.member")
              }}</label>
            </div>
          </div>
        </div>
        <div
          class="members-body-content-item-block-head-settings-item-menu-block-button"
        >
          <Button
            type="button"
            class="btn btn-sm btn-primary-blue btn-round-4 btn-400"
            @click="save"
            :state="state"
          >
            {{ $t("team.confirm") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, defineProps, watch } from "vue";
import store from "@/store";
import Button from "@/components/inputs/Button.vue";
import { useRoute } from "vue-router";

const route = useRoute();
const props = defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
});

const selectedMembers = computed(() => store.getters["team/selectedMembers"]);
const isShow = ref(false);
const state = ref("default");
const selectedRole = ref("");

const toggleRoleArea = () => {
  isShow.value = !isShow.value;
};

const save = () => {
  const members = selectedMembers.value.map((m) => m.id);
  const dispatchPath =
    route.name === "team-view" ? "team/item" : "member/items";
  const routeParamsId = route.params.id;

  if (!selectedRole.value) return;
  state.value = "loading";
  store
    .dispatch("member/assignRole", {
      members,
      role: selectedRole.value,
    })
    .then(() => {
      store.dispatch(dispatchPath, routeParamsId).then(() => {
        state.value = "success";
        setTimeout(() => {
          isShow.value = false;
        }, 1000);
      });
    });
};

const onClickAway = (e) => {
  if (e.target.closest(".role-assignment")) return;
  isShow.value = false;
};

watch(
  () => props.isActive,
  (value) => {
    if (!value) {
      isShow.value = false;
    }
  }
);
</script>
