<template>
  <div class="members-to-add is-show">
    <div class="members-to-add-block">
      <div class="members-to-add-block-head">
        <h5>
          {{ $t("team.add_members.title") }}
        </h5>
      </div>
      <div class="members-to-add-block-filter">
        <input
          type="text"
          :placeholder="$t('team.add_members.search_placeholder')"
          @input="search"
        />
        <i>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
              stroke="#6C489E"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.5 17.5L13.875 13.875"
              stroke="#6C489E"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </i>
      </div>
      <div class="members-to-add-block-list" v-if="members.length">
        <div
          :class="[
            'members-to-add-block-list-item',
            { 'is-checked': isChecked(item.id) },
          ]"
          v-for="item in members"
          :key="item.id"
        >
          <div class="members-to-add-block-list-item-head is-soft-purple">
            <span>{{ item.name[0] }}</span>
            <div class="form-item-checkbox">
              <input
                :id="'member-' + item.id"
                type="checkbox"
                @change="toggleMember(item.id)"
                :checked="isChecked(item.id)"
              />
            </div>
          </div>
          <div class="members-to-add-block-list-item-label">
            <label :for="'member-' + item.id">{{ item.name }}</label>
          </div>
        </div>
      </div>
      <div class="members-to-add-block-button">
        <button
          type="button"
          class="btn btn-sm btn-white btn-round-4 btn-400"
          @click="setModalStatus('team_add_member', false)"
        >
          {{ $t("team.add_members.skip") }}
        </button>
        <Button
          type="button"
          class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
          :state="state"
          @click="addMembers"
        >
          {{ $t("team.add_members.add") }}
        </Button>
      </div>
      <div class="members-to-add-block-close">
        <button type="button" @click="setModalStatus('team_add_member', false)">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { setModalStatus, getModalData } from "@/utils/Helpers";
import store from "@/store";
import Button from "@/components/inputs/Button.vue";
const state = ref("default");
let membersSalt = [];
const members = computed(() => {
  const members = store.getters["member/items"];

  if (members.length && !membersSalt.length) {
    membersSalt = members;
    return members;
  }

  return members;
});

const selectedMembers = ref([]);
const data = getModalData("team_add_member");

const toggleMember = (id) => {
  if (selectedMembers.value.includes(id)) {
    selectedMembers.value = selectedMembers.value.filter((item) => item !== id);
  } else {
    selectedMembers.value.push(id);
  }
};

const addMembers = () => {
  if (selectedMembers.value.length) {
    state.value = "loading";

    store
      .dispatch("team/addMembers", {
        id: data.team_id,
        members: selectedMembers.value,
      })
      .then(() => {
        state.value = "success";
        setTimeout(() => {
          setModalStatus("team_add_member", false);
        }, 1000);
      })
      .catch(() => {
        state.value = "error";
      });
  }
  // state.value = "loading";
};

const isChecked = (id) => {
  return selectedMembers.value.includes(id);
};

const search = (e) => {
  const value = e.target.value;
  const filtered = membersSalt.filter((member) => {
    const name = member.name.toLowerCase();

    return name.includes(value.toLowerCase());
  });

  store.commit("member/setItems", filtered);
};

onMounted(() => {
  store.dispatch("member/items");
});
</script>
