<template>
  <header class="header-second-version">
    <div class="header-second-version-contain">
      <div class="header-second-version-contain-item">
        <a href="javascript:;">
          <img src="/img/static/white-logo.png" alt="" />
        </a>
      </div>
      <div class="header-second-version-contain-language">
        <v-select
          v-model="selectedLanguage"
          :options="languages.data"
          :searchable="true"
          :clearable="true"
          :placeholder="$t('settings.preferences.language')"
          :label="$t('settings.preferences.language')"
          :get-option-label="(option) => option.name"
          :get-option-value="(option) => option.locale"
          @input="change"
        >
          <template #no-options>
            <div class="">
              {{ $t("misc.no_results") }}
            </div>
          </template>
        </v-select>
      </div>
    </div>
  </header>

  <main>
    <slot></slot>
  </main>
</template>

<script setup>
import { onMounted, ref, computed, watch } from "vue";
import store from "@/store";
import { useI18n } from "vue-i18n";
const languages = computed(() => store.getters["misc/languages"]);
const selectedLanguage = ref(null);
const { locale } = useI18n();

watch(
  () => selectedLanguage.value,
  (val) => {
    if (val) {
      locale.value = val.slug;

      localStorage.setItem("locale", val.slug);
    }
  }
);

onMounted(() => {
  const alreadySelected = localStorage.getItem("locale");
  store.dispatch("misc/languages").then(() => {
    if (alreadySelected) {
      selectedLanguage.value = languages.value.data.find(
        (lang) => lang.slug === alreadySelected
      );
    }
  });
});
</script>
