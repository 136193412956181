<template>
  <section class="log-out-modal is-show">
    <div
      class="log-out-modal-close"
      @click="setModalStatus('remove_from_group', false)"
    >
      <i>
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 1.72656L1 13.7266"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 1.72656L13 13.7266"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </i>
    </div>
    <ul v-if="selectedNetworks">
      <li v-for="network in selectedNetworks" :key="network.id">
        {{ network.full_name }}
      </li>
    </ul>
    <p>
      {{ $t("network.remove_from_group.text") }}
    </p>
    <div class="log-out-modal-buttons">
      <a
        href="javascript:;"
        class="btn btn-sm btn-white btn-round-4 btn-400"
        @click="setModalStatus('remove_from_group', false)"
      >
        {{ $t("misc.cancel") }}
      </a>

      <Button
        href="javascript:;"
        class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
        :state="state"
        @click="removeNetworks"
      >
        {{ $t("misc.delete") }}
      </Button>
    </div>
  </section>
</template>
<script setup>
import { ref, computed } from "vue";
import { getModalData, setModalStatus } from "@/utils/Helpers";
import Button from "@/components/inputs/Button.vue";
import store from "@/store";
import { network } from "../../../store/network";

const state = ref("default");
const selectedGroup = computed(() => store.getters["network/selectedGroup"]);
const selectedNetworks = computed(
  () => store.getters["network/selectedNetworks"]
);
const removeNetworks = () => {
  state.value = "loading";

  store
    .dispatch("network/removeNetworksFromGroup", {
      id: selectedGroup.value.id,
      networks: selectedNetworks.value.map((network) => network.id),
    })
    .then(() => {
      store
        .dispatch("network/items", {
          filter: {
            group_id: selectedGroup.value.id,
          },
        })
        .then(() => {
          state.value = "success";
          store.dispatch("network/removeAllSelectedNetworks");
          setModalStatus("remove_from_group", false);
        });
    });
};
</script>
