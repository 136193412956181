import { axios } from "@/services/axios";
import AuthHeader from "../AuthHeader";

export const create = (payload) => {
  return axios
    .post("export", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default {
  create
};
