<template>
  <section class="log-out-modal is-show">
    <div
      class="log-out-modal-close"
      @click="setModalStatus('delete_team', false)"
    >
      <i>
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 1.72656L1 13.7266"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 1.72656L13 13.7266"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </i>
    </div>
    <p>
      {{ $t("team.delete.text", { name: data.item.name }) }}
    </p>
    <div class="log-out-modal-buttons">
      <a
        href="javascript:;"
        class="btn btn-sm btn-white btn-round-4 btn-400"
        @click="setModalStatus('delete_team', false)"
      >
        {{ $t("misc.cancel") }}
      </a>

      <Button
        href="javascript:;"
        class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
        :state="state"
        @click="deleteTeam"
      >
        {{ $t("misc.delete") }}
      </Button>
    </div>
  </section>
</template>
<script setup>
import { ref } from "vue";
import { getModalData, setModalStatus } from "@/utils/Helpers";
import Button from "@/components/inputs/Button.vue";
import store from "@/store";
const state = ref("default");
const data = getModalData("delete_team");

const deleteTeam = () => {
  state.value = "loading";
  store
    .dispatch("team/delete", data.item.id)
    .then(() => {
      store.dispatch("team/items").then(() => {
        state.value = "success";
        setTimeout(() => {
          setModalStatus("delete_team", false);
        }, 1000);
      });
    })
    .catch(() => {
      state.value = "error";
    });
};
</script>
