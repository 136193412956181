<template>
  <div class="members-head-filter">
    <button
      type="button"
      class="filter-button"
      @click="toggleFilterArea"
      v-click-away="onClickAway"
    >
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.3335 28.0003V18.667"
          stroke="#979797"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.3335 13.3333V4"
          stroke="#979797"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16 28V16"
          stroke="#979797"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16 10.6667V4"
          stroke="#979797"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.6665 27.9997V21.333"
          stroke="#979797"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M26.6665 16V4"
          stroke="#979797"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.3335 18.667H9.3335"
          stroke="#979797"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 10.667H20"
          stroke="#979797"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M22.6665 21.333H30.6665"
          stroke="#979797"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
    <div :class="['members-head-filter-block', { 'is-show': isShow }]">
      <div class="members-head-filter-block-head">
        <h5>{{ $t("team.filter.title") }}</h5>
        <span>{{ $t("team.filter.description") }}</span>
      </div>
      <div class="members-head-filter-block-body">
        <div
          :class="[
            'members-head-filter-block-body-item',
            { 'is-active': isShowOrderBy },
          ]"
        >
          <div class="members-head-filter-block-body-item-head">
            <button type="button" @click="toggleOrderBy">
              {{
                orderByOptions.find((o) => o.value === selectedOrderBy).label
              }}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </button>
          </div>
          <div
            class="members-head-filter-block-body-item-toggle"
            v-if="orderByOptions"
          >
            <div
              class="members-head-filter-block-body-item-toggle-item"
              v-for="orderByOption in orderByOptions"
              :key="orderByOption.value"
            >
              <button type="button" @click="changeOrderBy(orderByOption.value)">
                {{ orderByOption.label }}
              </button>
            </div>
          </div>
        </div>
        <div
          :class="[
            'members-head-filter-block-body-item',
            { 'is-active': isShowOrder },
          ]"
        >
          <div class="members-head-filter-block-body-item-head">
            <button type="button" @click="toggleOrder">
              {{ orderOptions.find((o) => o.value === selectedOrder).label }}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </button>
          </div>
          <div
            class="members-head-filter-block-body-item-toggle"
            v-if="orderOptions"
          >
            <div
              class="members-head-filter-block-body-item-toggle-item"
              v-for="orderOption in orderOptions"
              :key="orderOption.value"
            >
              <button type="button" @click="changeOrder(orderOption.value)">
                {{ orderOption.label }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import store from "@/store";
import i18n from "@/utils/i18n";

const isShow = ref(false);
const isShowOrder = ref(false);
const isShowOrderBy = ref(false);
const selectedOrder = computed(() => {
  changeOrder();

  return store.getters["team/selectedOrder"];
});

const selectedOrderBy = computed(() => {
  changeOrderBy();

  return store.getters["team/selectedOrderBy"];
});

const orderByOptions = [
  {
    value: "name",
    label: i18n.global.t("team.filter.order_by.name"),
  },
  {
    value: "id",
    label: i18n.global.t("team.filter.order_by.id"),
  },
];

const orderOptions = [
  {
    value: "asc",
    label: i18n.global.t("team.filter.asc"),
  },
  {
    value: "desc",
    label: i18n.global.t("team.filter.desc"),
  },
];

const changeOrder = (order) => {
  if (order) {
    store.commit("team/setSelectedOrder", order);
  }
  applyOrder();
};

const toggleOrder = () => {
  isShowOrder.value = !isShowOrder.value;
};

const toggleFilterArea = () => {
  isShow.value = !isShow.value;
};

const changeOrderBy = (order) => {
  if (order) {
    store.commit("team/setSelectedOrderBy", order);
  }

  applyOrder();
};

const toggleOrderBy = () => {
  isShowOrderBy.value = !isShowOrderBy.value;
  isShowOrder.value = false;
};

const applyOrder = () => {
  const members = store.getters["member/items"] || [];

  const orderBy = selectedOrderBy.value;
  const sortedMembers = members.sort((a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return selectedOrder.value === "asc" ? -1 : 1;
    }

    if (a[orderBy] > b[orderBy]) {
      return selectedOrder.value === "asc" ? 1 : -1;
    }
    return 0;
  });

  store.commit("member/setItems", sortedMembers);
};

const onClickAway = (event) => {
  if (!event.target.closest(".members-head-filter")) {
    isShow.value = false;
  }
};
</script>
