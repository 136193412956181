<template>
  <div
    :class="[
      'members-body-content-item-block-head-settings',
      { 'is-active': props.isActive },
    ]"
  >
    <Membership :is-active="props.isActive" />
    <Role :is-active="props.isActive" />
    <Team :is-active="props.isActive" v-if="props.mode === 'members'" />
    <Remove v-if="props.mode === 'teams'" />
    <Reset v-if="props.mode === 'members'" />
  </div>
</template>

<script setup>
import { ref, computed, defineProps, watch, onMounted } from "vue";
import { setModalStatus } from "@/utils/Helpers";
import store from "@/store";
import Team from "./Bar/Team.vue";
import Membership from "./Bar/Membership.vue";
import Role from "./Bar/Role.vue";
import Remove from "./Bar/Remove.vue";
import Reset from "./Bar/Reset.vue";

const currentWorkspace = computed(() => store.getters["account/workspace"]);

const props = defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
  mode: {
    type: String,
    default: "members",
  },
});
</script>
