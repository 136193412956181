<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>

  <main-layout :head="false" class="main-notifications">
    <div :class="['notifications', { 'is-empty': !list?.data?.length }]">
      <div class="notifications-block">
        <div class="notifications-block-title">
          <h1>
            {{ $t("notifications.title") }}
          </h1>
        </div>
        <div class="notifications-block-content" v-if="list.data">
          <div class="notifications-block-content-empty">
            <div class="notifications-block-content-empty-image">
              <img src="/img/dynamic/no-notification-image.png" alt="" />
            </div>
            <div class="notifications-block-content-empty-content">
              <p>
                {{ $t("notifications.empty_text_1") }}
              </p>
              <p>
                {{ $t("notifications.empty_text_2") }}
              </p>
            </div>
          </div>
          <div class="notifications-block-content-view">
            <div
              :class="[
                'notifications-block-content-view-item',
                { 'is-read': notification.read },
              ]"
              v-for="notification in list.data"
              :key="notification.id"
            >
              <div class="notifications-block-content-view-item-label">
                <p v-html="notification.message">
                </p>
                <span>
                  {{ dayjs(notification.created_at).fromNow() }}
                </span>
              </div>
              <div class="notifications-block-content-view-item-date">
                <span>
                  {{
                    dayjs(notification.created_at).format("DD MMMM dddd, YYYY")
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <b-skeleton v-else class="profile-action-skeleton"></b-skeleton>
      </div>
    </div>
  </main-layout>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import store from "@/store";
import MainLayout from "@/layouts/MainLayout.vue";
import * as dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useMeta } from "vue-meta";
import i18n from "@/utils/i18n";

useMeta({
  title: i18n.global.t("notifications.title"),
});

dayjs.locale('tr');
dayjs.extend(relativeTime);
const list = computed(() => store.getters["notifications/list"]);

onMounted(() => {
  store.dispatch("notifications/list");
});
</script>
