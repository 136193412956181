import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import store from "@/store";
import slugify from "slugify";
import vCardsJS from "vcards-js";
import { createAndDownloadFile } from "@/utils/Helpers";
import { MembershipLevelEnum } from "@/enums";

export default function useProfile(
  data = {
    getterName: "profiles/item",
    actionName: "profiles/get",
    applyWsRules: true,
  }
) {
  const route = useRoute();
  const me = computed(() => store.getters["account/me"]);
  const workspace = computed(() => store.getters["account/workspace"]);
  const item = computed(() => {
    const item = store.getters[`${data.getterName}`];
    return item;
  });

  const displayName = () => {
    if (item.value) {
      return item.value.acf.first_name + " " + item.value.acf.last_name;
    }

    return "";
  };

  const slug = () => {
    return slugify(displayName(), {
      lower: true,
      replacement: "",
    });
  };

  const getValue = (key) => {
    return item.value.acf[key];
  };

  const workspaceSettings = computed(() => {
    const settings = getValue("workspace_settings");

    return settings;
  });

  const workspaceBackgroundColor = computed(() => {
    const settings = workspaceSettings?.value;
    let backgroundColor = "#ffffff";

    if (settings) {
      backgroundColor = settings.background_color;
    }

    return backgroundColor;
  });

  const companyName = computed(() => {
    const workspaceType = item.value.acf.profile_type;
    let companyName = getValue("company_name");

    if (
      workspaceType === "corporate" &&
      !isMainCorporateProfile.value &&
      data.applyWsRules
    ) {
      companyName = item.value.acf.workspace_settings?.company_name;
    }

    return companyName;
  });

  const description = computed(() => {
    const workspaceType = item.value.acf.profile_type;
    let description = getValue("description");

    if (
      workspaceType === "corporate" &&
      !isMainCorporateProfile.value &&
      data.applyWsRules &&
      item.value.acf.workspace_settings?.description
    ) {
      description = item.value.acf.workspace_settings?.description;
    }

    return description;
  });

  const profileType = computed(() => {
    return item.value.acf.profile_type;
  });

  const isMainCorporateProfile = computed(() => {
    return item.value.acf.is_main_corporate_profile;
  });

  const profilePhoto = computed(() => {
    return item.value.acf.profile_photo;
  });

  const companyLogo = computed(() => {
    let companyLogo = item.value.acf.company_logo;
    const workspaceType = item.value.acf.profile_type;

    if (
      workspaceType === "corporate" &&
      !isMainCorporateProfile.value &&
      data.applyWsRules
    ) {
      companyLogo = item.value.acf.workspace_settings?.company_logo;
    }

    return companyLogo;
  });

  const website = computed(() => {
    const socialMedias = item.value.acf.social_medias;

    if (socialMedias) {
      const website = socialMedias.find(
        (item) => item.social_media_type === "website"
      );

      return website;
    }
  });

  const websites = computed(() => {
    const workspaceType = item.value.acf.profile_type;
    const websites = [];
    let selfWebsites = item.value.acf.social_medias;

    if (selfWebsites) {
      selfWebsites = selfWebsites?.filter(
        (item) => item.social_media_type === "website"
      );
    }

    if (
      workspaceType === "corporate" &&
      !isMainCorporateProfile.value &&
      data.applyWsRules
    ) {
      const workspaceSocialMedias =
        item.value.acf.workspace_settings?.social_medias;
      const corporateWebsites = workspaceSocialMedias
        ? workspaceSocialMedias.filter(
            (item) => item.social_media_type === "website"
          )
        : null;

      if (corporateWebsites) {
        corporateWebsites.map((item) => {
          item.type = "corporate";
        });

        websites.push(...corporateWebsites);
      }
    }

    if (selfWebsites) {
      selfWebsites.map((item) => {
        item.type = "self";
      });

      websites.push(...selfWebsites);
    }

    return websites;
  });

  const file = computed(() => {
    const socialMedias = item.value.acf.social_medias;

    if (socialMedias) {
      const file = socialMedias.find(
        (item) => item.social_media_type === "file"
      );

      if (!file) return;

      return {
        ...file,
        type_label: store.getters["defines/get_more_type"]("file").label,
      };
    }
  });

  const address = computed(() => {
    const workspaceType = item.value.acf.profile_type;
    let address = item.value.acf.full_address;

    if (
      workspaceType === "corporate" &&
      !isMainCorporateProfile.value &&
      data.applyWsRules &&
      item.value.acf.workspace_settings?.full_address
    ) {
      address = item.value.acf.workspace_settings?.full_address;
    }

    return address;
  });

  const addressType = computed(() => {
    const workspaceType = item.value.acf.profile_type;

    if (
      workspaceType === "corporate" &&
      !isMainCorporateProfile.value &&
      data.applyWsRules &&
      item.value.acf.workspace_settings?.full_address
    ) {
      return "corporate";
    }

    return "self";
  });

  const addressData = computed(() => {
    if (addressType.value === "self") {
      return item.value.acf.address;
    }

    return item.value.acf.workspace_settings?.address;
  });

  const socialMedias = computed(() => {
    const workspaceType = item.value.acf.profile_type;
    const socialMedias = [];
    const selfSocialMedias = item.value.acf.social_medias;

    if (
      workspaceType === "corporate" &&
      !isMainCorporateProfile.value &&
      data.applyWsRules
    ) {
      const workspaceSocialMedias =
        item.value.acf.workspace_settings?.social_medias;

      if (workspaceSocialMedias) {
        workspaceSocialMedias.map((item) => {
          item.type = "corporate";
        });

        socialMedias.push(...workspaceSocialMedias);
      }
    }

    if (selfSocialMedias) {
      selfSocialMedias.map((item) => {
        item.type = "self";
      });

      socialMedias.push(...selfSocialMedias);
    }

    if (socialMedias) {
      const items = socialMedias.filter(
        (item) =>
          item.social_media_type !== "website" &&
          item.social_media_type !== "dribbble"
      );

      return items.map((item) => {
        const definedItem = store.getters["defines/get_more_type"](
          item.social_media_type
        );

        return {
          ...item,
          link_text: item.link_text || item.url,
          type_label: definedItem.label,
          svg_code: definedItem.svg_code,
          email_svg_code: definedItem.email_svg_code,
          svg_color_state: definedItem.svg_color_state,
        };
      });
    }
  });

  const preparePhones = (phones) => {
    if (!phones) return [];

    return phones.map((item) => {
      return {
        ...item,
        phone_type: {
          id: item.phone_type,
          label: store.getters["defines/get_phone_type"](item.phone_type).label,
        },
        phone_type_slug: item.phone_type,
      };
    });
  };

  const phones = computed(() => {
    const workspaceType = item.value.acf.profile_type;
    const phones = [];

    if (
      workspaceType === "corporate" &&
      !isMainCorporateProfile.value &&
      data.applyWsRules
    ) {
      const corporatePhones = item.value.acf.workspace_settings?.phones;

      phones.push(...preparePhones(corporatePhones));

      if (phones) {
        phones.map((item) => {
          item.type = "corporate";
        });
      }
    }

    const selfPhones = item.value.acf.phones;

    if (selfPhones) {
      selfPhones.map((item) => {
        item.type = "self";
      });
    }

    phones.push(...preparePhones(selfPhones));

    return phones;
  });

  const emails = computed(() => {
    const workspaceType = item.value.acf.profile_type;
    const emails = [];
    const email = item.value.acf.email;

    if (
      workspaceType === "corporate" &&
      !isMainCorporateProfile.value &&
      data.applyWsRules
    ) {
      const corporateEmail = item.value.acf.workspace_settings?.email;

      emails.push({
        email: corporateEmail,
        type: "corporate",
      });
    }

    if (email) {
      emails.push({
        email,
        type: "self",
      });
    }

    return emails;
  });

  const urls = computed(() => {
    const urls = item.value.urls;

    return urls;
  });

  const preferences = computed(() => {
    const preferences = item.value.acf.preferences;

    return preferences;
  });

  const createVCard = () => {
    const vCard = new vCardsJS();
    const visibility = preferences.value?.visibility;
    vCard.firstName = getValue("first_name");
    vCard.lastName = getValue("last_name");
    vCard.organization = getValue("company_name");
    vCard.title = getValue("title");
    vCard.email = getValue("email");

    if (emails.value.length && emails.value[0].type === "corporate") {
      vCard.workEmail = emails.value[0].email;
    }

    if (website.value) {
      vCard.url = website.value.url;
    }

    vCard.workUrl = urls.value?.card.view;

    if (phones.value && visibility?.phone) {
      const workPhone = phones.value.find(
        (item) => item.phone_type_slug === "work" || item.type === "corporate"
      );

      const homePhone = phones.value.find(
        (item) => item.phone_type_slug === "home"
      );

      const mobilePhone = phones.value.find(
        (item) => item.phone_type_slug === "mobile" && item.type === "self"
      );

      if (workPhone) {
        vCard.workPhone = workPhone.phone_number;
      }

      if (homePhone) {
        vCard.homePhone = homePhone.phone_number;
      }

      if (mobilePhone) {
        vCard.cellPhone = mobilePhone.phone_number;
      }
    }

    if(visibility?.address){
    let addressKey = vCard.workAddress;

    if (addressType.value === "self") {
      addressKey = vCard.homeAddress;
    }

    if (addressData.value?.street_label) {
      addressKey.street =
        addressData.value.neighborhood_label +
        " " +
        addressData.value.street_label +
        " " +
        addressData.value.address;
    }

    if (addressData.value?.country_label) {
      addressKey.countryRegion = addressData.value.country_label;
    }

    if (addressData.value?.state_label) {
      addressKey.stateProvince = addressData.value.state_label;
    }

    if (addressData.value?.city_label) {
      addressKey.city = addressData.value.city_label;
    }

    }

    const socialMediasList = socialMedias.value.filter(
      (item) => item.type === "self"
    );

    if (socialMediasList && visibility?.social_media) {
      socialMediasList.map((item, index) => {
        const socialMediaType =
          item.social_media_type.charAt(0).toUpperCase() +
          item.social_media_type.slice(1);
        index = index + 1;
        vCard.socialUrls[socialMediaType + "_" + index] = item.url;
      });
    }

    if (companyLogo.value?.base64) {
      vCard.photo.embedFromString(companyLogo.value.base64);
    }

    if (profilePhoto.value?.base64) {
      vCard.photo.embedFromString(profilePhoto.value.base64);
    }

    let vcardString = vCard.getFormattedString();
    vcardString = vcardString.replaceAll(
      "X-SOCIALPROFILE;CHARSET=UTF-8;",
      "X-SOCIALPROFILE;"
    );

    createAndDownloadFile(slug() + ".vcf", vcardString);

    // phones
  };

  onMounted(() => {
    if (!item.value) {
      store.commit(`${data.actionName}`, null);
      store.dispatch(`${data.actionName}`, route.params.profileId);
    }
  });

  return {
    displayName,
    getValue,
    slug,
    workspaceSettings,
    workspaceBackgroundColor,
    companyName,
    description,
    emails,
    profileType,
    isMainCorporateProfile,
    profilePhoto,
    companyLogo,
    website,
    websites,
    file,
    address,
    addressData,
    addressType,
    socialMedias,
    preparePhones,
    phones,
    urls,
    preferences,
    createVCard,
  };
}
