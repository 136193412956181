<template>
  <div class="email-detail-actions">
    <div class="email-detail-actions-inner">
      <Loading v-if="isLoading" />
      <template v-else>
        <div class="add-image-container">
          <input
            type="file"
            class="d-none"
            ref="fileInput"
            @change="chosenImage"
          />
          <a href="javascript:;" class="add-image" @click="addImage">
            <i>
              <img src="/img/static/download-icon.svg" alt="Görsel Ekle" />
            </i>
            <span>
              {{ $t("email_signature.add_image") }}
            </span>
            <p>(650 - 300 px)</p>
          </a>
        </div>
        <swiper
          v-if="emailSettings.images"
          class="email-detail-actions-card-slider custom-nav"
          slides-per-view="auto"
          :space-between="20"
          :center-insufficient-slides="true"
          :slides-offset-after="43"
          :slides-offset-before="43"
          :navigation="{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }"
          :breakpoints="{
            0: {
              slidesOffsetBefore: 30,
              slidesOffsetAfter: 30,
            },
            1201: {
              slidesOffsetBefore: 43,
              slidesOffsetAfter: 43,
            },
          }"
        >
          <swiper-slide
            v-for="(imageItem, index) in emailSettings.images"
            :key="index"
          >
            <div class="card-preview" @click="choseImage(imageItem, index)">
              <img :src="imageItem.image.url" />
              <div
                class="swiper-slide-content-image-delete swiper-slide-delete"
                v-if="
                  index !==
                  item.acf.preferences.email_settings.images.length - 1
                "
                @click="deleteImage(index)"
              >
                <button>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 6H5H21"
                      stroke="#e3e3e3"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
                      stroke="#e3e3e3"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="swiper-slide">
              <a
                href="javascript:;"
                class="card-add-image add-image"
                @click="addImage"
              >
                {{ $t("email_signature.add_image") }}
              </a>
            </div>
          </swiper-slide>
          <div class="swiper-nav-btn swiper-button-prev">
            <img
              src="/img/static/email-signature-slider-left-arrow.svg"
              alt="Prev"
            />
          </div>
          <div class="swiper-nav-btn swiper-button-next">
            <img
              src="/img/static/email-signature-slider-right-arrow.svg"
              alt="Next"
            />
          </div>
        </swiper>

        <div class="email-signature-mail-template-version-btn" v-if="viewModes">
          <button
            type="button"
            v-for="viewMode in viewModes"
            :key="viewMode.id"
            :class="[
              'mail-template-is-full-view btn btn-xs btn-round-4 btn-400',
              {
                'btn-secondary-purple': selectedViewMode === viewMode.id,
                'btn-secondary-purple-outline':
                  selectedViewMode !== viewMode.id,
              },
            ]"
            @click="changeViewMode(viewMode.id)"
          >
            {{ viewMode.label }}
          </button>
        </div>

        <div class="save-container">
          <Button
            :title="$t('misc.save')"
            @click="save"
            :state="state"
            class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
          >
            {{ $t("misc.save") }}
          </Button>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import store from "@/store";
import Button from "@/components/inputs/Button.vue";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { setModalStatus } from "@/utils/Helpers";
import Loading from "@/components/Common/Loading.vue";

SwiperCore.use([Navigation, Pagination, A11y]);

const isLoading = ref(false);
const workspace = computed(() => store.getters["account/workspace"]);
const isMainCorporateProfile = ref(false);
const fileInput = ref(null);
const state = ref("default");
const viewModes = store.getters["email/viewModes"];
const selectedViewMode = computed(() => store.getters["email/viewMode"]);
const selectedImageIndex = ref(0);

const item = computed(() => {
  const item = store.getters["profiles/item"];

  if (item?.acf?.preferences) {
    setTimeout(() => {
      defineImages();
    }, 200);
  }

  return item;
});

const preferences = computed(() => item.value.acf?.preferences);
const emailSettings = computed(() => preferences.value?.email_settings);
const changeViewMode = (viewMode) => {
  store.commit("email/setViewMode", viewMode);
};

const deleteImage = (index) => {
  isLoading.value = true;
  store
    .dispatch("profiles/deleteEmailImage", {
      id: item.value.id,
      type: "images",
      image_id: index,
    })
    .then(() => {
      store.dispatch("profiles/get", item.value.id).then(() => {
        isLoading.value = false;
      });
    });
};

const addImage = () => {
  fileInput.value.click();
};

const choseImage = (item, index) => {
  store.commit("email/setImage", item);
  selectedImageIndex.value = index;
};

const chosenImage = () => {
  const image = fileInput.value.files[0];
  setModalStatus("email_add_pattern", true, {
    image,
    type: "image",
  });
};

const save = () => {
  state.value = "loading";
  store
    .dispatch("profiles/updatePreferences", {
      id: item.value.id,
      email_settings: {
        view_mode: selectedViewMode.value,
        selected_image: selectedImageIndex.value,
      },
    })
    .then(() => {
      state.value = "success";
    });
};

const defineImages = () => {
  const settings = item.value?.acf?.preferences.email_settings;
  const selectedImage = parseInt(settings.selected_image);
  const images = settings.images;

  images.push({
    image: {
      url: "/img/static/mail-template-bg-row.png",
    },
    layout_mode: "horizontal",
  });

  if (settings) {
    store.commit("email/setViewMode", settings.view_mode);

    if (selectedImage >= 0) {
      const image = images.find((item, index) => index === selectedImage);

      store.commit("email/setImage", image);
    } else {
      store.commit("email/setImage", images[0]);
    }
  }
};

onMounted(() => {
  const workspaceType = workspace.value.type;
  const workspaceSettings = workspace.value.settings;
  isMainCorporateProfile.value =
    item.value.acf?.is_main_corporate_profile || false;
});
</script>
