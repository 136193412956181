<template>
  <div class="statistics-block-head-sub" v-if="tabs">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      :class="[
        'statistics-block-head-sub-item',
        { 'is-active': activeTab.id == tab.id },
      ]"
      :data-color="tab.color"
      :data-color-name="tab.colorName"
      @click="changeTab(index)"
    >
      <button type="button">
        <span>{{ tab.name }}</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { setModalStatus } from "@/utils/Helpers";
import store from "@/store";
import { MembershipLevelEnum } from "@/enums";

const activeTab = computed(() => store.getters["stats/activeTab"]);
const tabs = store.getters["stats/tabs"];
const me = computed(() => store.getters["account/me"]);

const changeTab = (index) => {
  store.commit("stats/activeTab", index);

  const activeTabId = activeTab.value.id;
  const freeTabs = ["card", "networkit"];
  if (
    freeTabs.includes(activeTabId) &&
    me.value.membership_level === MembershipLevelEnum.FREE
  ) {
    setModalStatus("page_premium", false);
  } else {
    setModalStatus("page_premium", true, { toName: "stats-main" });
  }
};

onMounted(() => {
  // store.commit("stats/activeTab", 0);
});
</script>
