<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>

  <SecondLayout>
    <section class="reset-password">
      <div class="reset-password-contain">
        <div class="reset-password-main">
          <div class="reset-password-prev">
            <a
              @click="back"
              class="btn btn-text btn-sm btn-primary-blue btn-500"
            >
              <i data-position="left">
                <svg
                  width="7"
                  height="15"
                  viewBox="0 0 7 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.83749 14.5139C5.68809 14.5144 5.54048 14.4814 5.4055 14.4172C5.27052 14.3531 5.15161 14.2595 5.05749 14.1432L0.227488 8.13171C0.0804062 7.95244 0 7.72756 0 7.4955C0 7.26343 0.0804062 7.03856 0.227488 6.85928L5.22749 0.847784C5.39723 0.643176 5.64114 0.514506 5.90556 0.490079C6.16999 0.465653 6.43327 0.547471 6.63749 0.717535C6.8417 0.887599 6.97013 1.13198 6.99451 1.39691C7.01889 1.66184 6.93723 1.92563 6.76749 2.13024L2.29749 7.50051L6.61749 12.8708C6.73977 13.0178 6.81745 13.1969 6.84133 13.3868C6.86521 13.5767 6.83429 13.7695 6.75223 13.9424C6.67018 14.1153 6.54042 14.2609 6.37831 14.3622C6.2162 14.4635 6.02852 14.5162 5.83749 14.5139Z"
                    fill="#167FFC"
                  />
                </svg>
              </i>
              {{ $t("misc.back") }}
            </a>
          </div>
          <template v-if="view == 'form'">
            <ForgotForm @success="success" @error="error" />
          </template>
          <template v-else-if="view == 'success'">
            <SentSuccess :email="email" />
          </template>
          <template v-else>
            <ForgotError />
          </template>
        </div>
      </div>
    </section>
  </SecondLayout>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useMeta } from "vue-meta";
import i18n from "@/utils/i18n.vue";
import store from "@/store";
import { useRouter } from "vue-router";

// Components
import SecondLayout from "@/layouts/SecondLayout.vue";
import ForgotForm from "@/components/Auth/ForgotForm.vue";
import SentSuccess from "@/components/Auth/SentSuccess.vue";
import ForgotError from "@/components/Auth/ForgotError.vue";

//meta
useMeta({
  title: i18n.global.t("auth.forgot.page_title"),
});

const router = useRouter();
const view = ref("form");
const email = ref("");

const success = (dataEmail) => {
  view.value = "success";
  email.value = dataEmail;
};

const error = () => {
  view.value = "error";
};

const back = () => {
  if (view.value === "error") {
    view.value = "form";

    return;
  }

  router.push({ name: "login" });
};

onMounted(() => {
  // store.commit("common/apply_mode");
});
</script>
