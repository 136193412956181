<template>
  <div class="profile-creation-body-content-item">
    <div class="profile-add-more">
      <div id="profile-add-more-accordion">
        <div class="card">
          <div
            v-b-toggle.addMorecollapseOne
            class="card-header"
            id="addMoreheadingOne"
            data-toggle="collapse"
            data-target="#addMorecollapseOne"
            aria-expanded="false"
            aria-controls="addMorecollapseOne"
          >
            <a
              href="javascript:;"
              class="btn btn-text btn-sm btn-primary-blue btn-500"
              >+ {{ $t("profile.addMore") }}</a
            >
          </div>

          <b-collapse
            id="addMorecollapseOne"
            data-parent="#profile-add-more-accordion"
          >
            <div class="card-body">
              <div class="profile-add-more-body h-auto">
                <div class="profile-add-more-body-list">
                  <div class="container p-0">
                    <div class="row" v-if="items">
                      <div
                        class="col-lg-3 col-md-4 col-4"
                        v-for="(item, key) in items"
                        :key="key"
                        @click="addMore(item)"
                      >
                        <div class="profile-add-more-body-list-item">
                          <button type="button">
                            <div class="profile-add-more-body-list-item-icon">
                              <img :src="item.icon" :alt="item.label" />
                              <div
                                class="profile-add-more-body-list-item-icon-star"
                                v-if="item.is_premium"
                              >
                                <img src="/img/static/icon-star.svg" alt="" />
                              </div>
                            </div>
                            <div class="profile-add-more-body-list-item-label">
                              <span>{{ item.label }}</span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="profile-add-more-body-list-locked is-show"
                    v-if="isShowPremiumBox"
                  >
                    <div class="profile-add-more-body-list-locked-block">
                      <div class="profile-add-more-body-list-locked-block-icon">
                        <img
                          src="/img/static/icon-file-calendar-locked.svg"
                          alt=""
                        />
                      </div>
                      <div
                        class="profile-add-more-body-list-locked-block-content"
                      >
                        <p v-html="$t('profile.more_item_premium_text')"></p>
                        <router-link
                          :to="{ name: 'settings' }"
                          class="btn btn-xl btn-primary-blue btn-round-8 btn-600"
                        >
                          {{ $t("misc.premium_button_label") }}
                        </router-link>
                      </div>
                      <div
                        class="profile-add-more-body-list-locked-block-close"
                        @click="togglePremiumBox"
                      >
                        <button type="button">
                          <img src="/img/static/icon-x-12-12.svg" alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="profile-add-more-body-close"
                  id="addMoreheadingOne"
                  data-toggle="collapse"
                  data-target="#addMorecollapseOne"
                  aria-expanded="false"
                  aria-controls="addMorecollapseOne"
                >
                  <button type="button" v-b-toggle.addMorecollapseOne>
                    <img src="/img/static/icon-x.svg" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  defineEmits,
  defineProps,
} from "vue";
import { MembershipLevelEnum } from "@/enums";
import store from "@/store";

const props = defineProps({
  addItem: {
    type: Function,
    required: true,
  },
  fields: {
    type: Array,
    required: true,
  },
});

const items = ref([]);
const account = computed(() => store.getters["account/me"]);
const bodyIsOpened = ref(false);
const isShowPremiumBox = ref(false);

const togglePremiumBox = () => {
  isShowPremiumBox.value = !isShowPremiumBox.value;

  if (isShowPremiumBox.value) {
    document.querySelector("main").classList.add("main-background");
  } else {
    document.querySelector("main").classList.remove("main-background");
  }
};

const addMore = (item) => {
  const fields = props.fields;
  if (
    item.is_premium &&
    account.value.membership_level === MembershipLevelEnum.FREE
  ) {
    togglePremiumBox();
    return;
  }

  // is already added
  setTimeout(() => {
    const isAdded = fields.find(
      (field) => field.value.type === item.type && field.value.is_self === true
    );
    // if (isAdded) {
    //   return;
    // }

    item.is_self = true;

    props.addItem(item);
  }, 100);
  // if (fields.find((field) => field.value.type === "website")) {
  //   return;
  // }

  // props.addItem(item);
};

onMounted(() => {
  items.value = store.getters["defines/get_more_types"];
});
</script>
