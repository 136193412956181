<template>
  <div class="settings-accordion-item">
    <div
        class="settings-accordion-item-btn"
        id="account-heading"
        data-bs-toggle="collapse"
        data-bs-target="#account"
        aria-expanded="false"
        aria-controls="account"
    >
      <h2>{{ $t("settings.account_management.title") }}</h2>
    </div>
    <div
        id="account"
        class="settings-accordion-item-content is-account-management accordion-collapse collapse"
        data-bs-parent="#settings-accordions"
        aria-labelledby="account-heading"
        v-if="me"
    >
      <b-card>
        <ul>
          <li v-if="me.register_type === 'default' || me.register_type === 'old'">
            <a
                href="javascript:;"
                class="email-change-btn"
                @click="setModalStatus('settings_change_email', true)"
            >
              {{ $t("settings.account_management.email.title") }}
            </a>
          </li>
          <li v-if="me.register_type === 'default' || me.register_type === 'old'">
            <a
                href="javascript:;"
                class="password-change-btn"
                @click="setModalStatus('settings_change_password', true)"
            >
              {{ $t("settings.account_management.password.title") }}
            </a>
          </li>
          <li
              v-if="
              currentWorkspace.type === 'individual' ||
              currentWorkspace.role !== 'member'
            "
          >
            <a
                href="javascript:;"
                class="freeze-account-btn"
                @click="setModalStatus('settings_freeze_account', true)"
            >
              {{ $t("settings.account_management.freeze_account.title") }}
            </a>
          </li>
          <li
              v-if="
              currentWorkspace.type === 'individual' ||
              currentWorkspace.role !== 'member'
            "
          >
            <a
                href="javascript:;"
                class="delete-account-btn"
                @click="setModalStatus('settings_delete_account', true)"
            >
              {{ $t("settings.account_management.delete_account.title") }}
            </a>
          </li>
        </ul>
      </b-card>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { setModalStatus } from "@/utils/Helpers";
import store from "@/store";

const me = computed(() => store.getters["account/me"]);
const currentWorkspace = computed(() => store.getters["account/workspace"]);
</script>
