<template>
  <div
    :class="['notification-mobile is-show', { 'is-empty': !list.data.length }]"
    v-if="list.data"
  >
    <div class="notification-mobile-block">
      <div class="notification-mobile-block-empty">
        <div class="notification-mobile-block-empty-image">
          <img src="/img/dynamic/no-notification-image.png" alt="" />
        </div>
        <div class="notifications-block-content-empty-content">
          <p>{{ $t("notifications.empty_text_1") }}</p>
          <p>{{ $t("notifications.empty_text_2") }}</p>
        </div>
      </div>
      <div class="notification-mobile-block-view">
        <div class="notification-mobile-block-title">
          <h2>
            {{ $t("notifications.title") }}
          </h2>
        </div>
        <div class="notification-mobile-block-content">
          <div
            class="notification-mobile-block-content-body"
            v-if="list.data.length"
          >
            <div
              :class="[
                'notification-mobile-block-content-body-item',
                { 'is-read': notification.read },
              ]"
              v-for="notification in list.data"
              :key="notification.id"
            >
              <div class="notification-mobile-block-content-body-item-label">
                <p>
                  {{ notification.message }}
                </p>
                <span>
                  {{ dayjs(notification.created_at).fromNow() }}
                </span>
              </div>
              <div class="notification-mobile-block-content-body-item-date">
                <span>
                  {{
                    dayjs(notification.created_at).format("DD MMMM dddd, YYYY")
                  }}
                </span>
              </div>
            </div>
          </div>
          <div class="notification-mobile-block-content-more" v-if="list.data">
            <router-link :to="{ name: 'notifications' }">
              {{ $t("notifications.show_more") }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import store from "@/store";
import * as dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);
const list = computed(() => store.getters["notifications/list"]);

onMounted(() => {
  store.dispatch("notifications/list");
});
</script>