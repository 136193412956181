<template>
  <div class="members-body-content-item-block-teams-item">
    <button type="button" class="is-team" @click="viewTeam">
      {{ props.item.name }}
    </button>
    <button
      type="button"
      class="is-edit"
      @click="toggleDropdown"
      v-click-away="onClickAway"
    >
      <svg
        width="15"
        height="5"
        viewBox="0 0 15 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse
          cx="1.68632"
          cy="2.50044"
          rx="1.68632"
          ry="1.70161"
          fill="#979797"
        />
        <ellipse
          cx="7.05937"
          cy="2.50044"
          rx="1.68632"
          ry="1.70161"
          fill="#979797"
        />
        <ellipse
          cx="12.4324"
          cy="2.50044"
          rx="1.68632"
          ry="1.70161"
          fill="#979797"
        />
      </svg>

      <div :class="['is-remove-team', { 'is-show': dropdownStatus }]">
        <div
          class="is-remove-team-block"
          @click="setModalStatus('delete_team', true, { item: props.item })"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.5 5H4.16667H17.5"
              stroke="#D63835"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.66699 5.0013V3.33464C6.66699 2.89261 6.84259 2.46868 7.15515 2.15612C7.46771 1.84356 7.89163 1.66797 8.33366 1.66797H11.667C12.109 1.66797 12.5329 1.84356 12.8455 2.15612C13.1581 2.46868 13.3337 2.89261 13.3337 3.33464V5.0013M15.8337 5.0013V16.668C15.8337 17.11 15.6581 17.5339 15.3455 17.8465C15.0329 18.159 14.609 18.3346 14.167 18.3346H5.83366C5.39163 18.3346 4.96771 18.159 4.65515 17.8465C4.34259 17.5339 4.16699 17.11 4.16699 16.668V5.0013H15.8337Z"
              stroke="#D63835"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11.667 9.16797V14.168"
              stroke="#D63835"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.33398 9.16797V14.168"
              stroke="#D63835"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ $t("team.delete.title") }}
        </div>
      </div>
    </button>
  </div>
</template>

<script setup>
import { ref, defineProps, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { setModalStatus } from "@/utils/Helpers";
const router = useRouter();
const dropdownStatus = ref(false);
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const toggleDropdown = () => {
  dropdownStatus.value = !dropdownStatus.value;
};

const viewTeam = () => {
  router.push({
    name: "team-view",
    params: {
      id: props.item.id,
    },
  });
};

const onClickAway = () => {
  dropdownStatus.value = false;
};
</script>
