<template>
  <div class="email-detail-actions">
    <div class="email-detail-actions-inner">
      <Loading v-if="isLoading" />
      <template v-else>
        <div class="add-image-container">
          <input
            type="file"
            class="d-none"
            ref="fileInput"
            @change="chosenImage"
          />

          <a href="javascript:;" class="add-image" @click="addImage">
            <i>
              <img src="/img/static/download-icon.svg" />
            </i>
            <span>
              {{ $t("email_signature.add_image") }}
            </span>
            <p>(650 - 150 px)</p>
          </a>
        </div>

        <swiper
          class="email-detail-actions-banner-slider custom-nav"
          slides-per-view="auto"
          :space-between="20"
          :center-insufficient-slides="true"
          :slides-offset-after="43"
          :slides-offset-before="43"
          :navigation="{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }"
          :breakpoints="{
            0: {
              slidesOffsetBefore: 30,
              slidesOffsetAfter: 30,
            },
            1201: {
              slidesOffsetBefore: 43,
              slidesOffsetAfter: 43,
            },
          }"
        >
          <swiper-slide
            v-for="(bannerItem, index) in item.acf.preferences.email_settings
              .banners"
            :key="index"
          >
            <div class="card-preview" @click="choseImage(bannerItem, index)">
              <img :src="bannerItem.banner.url" />
            </div>
            <div
              class="swiper-slide-content-image-delete swiper-slide-delete"
              v-if="
                index !== item.acf.preferences.email_settings.banners.length - 1
              "
              @click="deleteImage(index)"
            >
              <button>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 6H5H21"
                    stroke="#e3e3e3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
                    stroke="#e3e3e3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </swiper-slide>

          <swiper-slide>
            <a
              href="javascript:;"
              title="Banner"
              class="add-banner add-image"
              @click="addImage"
            >
              {{ $t("email_signature.add_image") }}
            </a>
          </swiper-slide>
          <div class="swiper-nav-btn swiper-button-prev">
            <img
              src="/img/static/email-signature-slider-left-arrow.svg"
              alt="Prev"
            />
          </div>
          <div class="swiper-nav-btn swiper-button-next">
            <img
              src="/img/static/email-signature-slider-right-arrow.svg"
              alt="Next"
            />
          </div>
        </swiper>

        <div class="save-container">
          <Button
            :title="$t('misc.save')"
            @click="save"
            :state="state"
            class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
          >
            {{ $t("misc.save") }}
          </Button>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import store from "@/store";
import Button from "@/components/inputs/Button.vue";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { setModalStatus } from "@/utils/Helpers";
import Loading from "@/components/Common/Loading.vue";
SwiperCore.use([Navigation, Pagination, A11y]);

const fileInput = ref(null);
const state = ref("default");
const selectedImageIndex = ref(0);
const isLoading = ref(false);
const item = computed(() => {
  const item = store.getters["profiles/item"];

  if (item?.acf) {
    setTimeout(() => {
      defineBanners();
    }, 200);
  }

  return item;
});

const addImage = () => {
  fileInput.value.click();
};

const chosenImage = () => {
  const image = fileInput.value.files[0];
  setModalStatus("email_add_pattern", true, {
    image,
    type: "banner",
  });
};

const choseImage = (item, index) => {
  store.commit("email/setBanner", item);
  selectedImageIndex.value = index;
};

const save = () => {
  state.value = "loading";
  store
    .dispatch("profiles/updatePreferences", {
      id: item.value.id,
      email_settings: {
        selected_banner: selectedImageIndex.value,
      },
    })
    .then(() => {
      state.value = "success";
    });
};

const deleteImage = (index) => {
  isLoading.value = true;
  store
    .dispatch("profiles/deleteEmailImage", {
      id: item.value.id,
      type: "banners",
      image_id: index,
    })
    .then(() => {
      store.dispatch("profiles/get", item.value.id).then(() => {
        isLoading.value = false;
      });
    });
};

const defineBanners = () => {
  const settings = item.value.acf.preferences?.email_settings;
  const selectedBanner = parseInt(settings.selected_banner);
  const banners = settings.banners;

  banners.push({
    banner: {
      url: "/img/static/email-banner.png",
    },
  });

  if (settings) {
    if (selectedBanner >= 0) {
      const banner = banners.find((item, index) => index === selectedBanner);

      store.commit("email/setBanner", banner);
    } else {
      store.commit("email/setBanner", banners[0]);
    }
  }
};

onMounted(() => {});
</script>
