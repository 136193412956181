import { axios } from "@/services/axios";
import AuthHeader from "../AuthHeader";

export const list = (payload) => {
  return axios
    .get("backgrounds", {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const upload = (payload) => {
  return axios
    .post("backgrounds", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...AuthHeader(),
      },
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const generate = (payload) => {
  return axios
    .post("backgrounds/generate", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
        ...AuthHeader(),
      },
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const deleteBackground = (id) => {
  return axios
    .delete("backgrounds/" + id, {
      headers: {
        ...AuthHeader(),
      },
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default {
  list,
  upload,
  generate,
  deleteBackground,
};
