<template>
  <div class="statistics-block-body-content" v-if="stats.success">
<!--    <Loading type="skeleton" v-if="isLoading" />-->
    <div class="statistics-block-body-content-title">
      <h4>{{ $t("stats.contribution.title") }}</h4>
      <p>{{ $t("stats.contribution.description") }}</p>
    </div>
    <div class="statistics-block-body-content-item">
      <div class="statistics-block-body-content-item-icon">
        <img src="/img/static/statistics-block-body-item-icon-1.png" alt="" />
      </div>
      <div class="statistics-block-body-content-item-info">
        <div class="statistics-block-body-content-item-info-title">
          <span>
            {{ $t("stats.contribution.item_1_title") }}
          </span>
        </div>
        <div class="statistics-block-body-content-item-info-value">
          <span>{{ stats.data.contributions.oxygen_recovered }} {{ $t("stats.contribution.liter") }}</span>
        </div>
      </div>
    </div>
    <div class="statistics-block-body-content-item">
      <div class="statistics-block-body-content-item-icon">
        <img src="/img/static/statistics-block-body-item-icon-2.png" alt="" />
      </div>
      <div class="statistics-block-body-content-item-info">
        <div class="statistics-block-body-content-item-info-title">
          <span>
            {{ $t("stats.contribution.item_2_title") }}
          </span>
        </div>
        <div class="statistics-block-body-content-item-info-value">
          <span>{{ stats.data.contributions.watts_reduced }} Watt</span>
        </div>
      </div>
    </div>
    <div class="statistics-block-body-content-item">
      <div class="statistics-block-body-content-item-icon">
        <img src="/img/static/statistics-block-body-item-icon-3.png" alt="" />
      </div>
      <div class="statistics-block-body-content-item-info">
        <div class="statistics-block-body-content-item-info-title">
          <span>
            {{ $t("stats.contribution.item_3_title") }}
          </span>
        </div>
        <div class="statistics-block-body-content-item-info-value">
          <span>{{ stats.data.contributions.water_recovered }} {{ $t("stats.contribution.liter") }}</span>
        </div>
      </div>
    </div>
    <div class="statistics-block-body-content-item">
      <div class="statistics-block-body-content-item-icon">
        <img src="/img/static/statistics-block-body-item-icon-4.png" alt="" />
      </div>
      <div class="statistics-block-body-content-item-info">
        <div class="statistics-block-body-content-item-info-title">
          <span>
            {{ $t("stats.contribution.item_4_title") }}
          </span>
        </div>
        <div class="statistics-block-body-content-item-info-value">
          <span>{{ stats.data.contributions.co2_reduced }} {{ $t("stats.contribution.liter") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import store from "@/store";
import Loading from "@/components/Common/Loading.vue";

const isLoading = computed(() => store.getters["stats/isLoading"]);
const contributions = computed(() => store.getters["stats/contributions"]);
const stats = computed(() => {
  const stats = store.getters["stats/get"];
  return stats;
});

onMounted(() => {
  // store.dispatch("stats/contributions");
});
</script>
