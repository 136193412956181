<template>
  <div class="sidebar-menu-head-profile-more-modal is-show">
    <div class="sidebar-menu-head-profile-more-modal-block">
      <div class="sidebar-menu-head-profile-more-modal-block-image">
        <div class="form-item-drop-img">
          <div class="dropzone dz-clickable" ref="photoInput">
            <div class="dropzone-image-edit">
              <div class="dropzone-image-edit-block">
                <div class="dropzone-image-edit-block-button">
                  <button type="button" @click="togglePhotoMenu">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.1654 15.8333C19.1654 16.2754 18.9898 16.6993 18.6772 17.0118C18.3646 17.3244 17.9407 17.5 17.4987 17.5H2.4987C2.05667 17.5 1.63275 17.3244 1.32019 17.0118C1.00763 16.6993 0.832031 16.2754 0.832031 15.8333V6.66667C0.832031 6.22464 1.00763 5.80072 1.32019 5.48816C1.63275 5.17559 2.05667 5 2.4987 5H5.83203L7.4987 2.5H12.4987L14.1654 5H17.4987C17.9407 5 18.3646 5.17559 18.6772 5.48816C18.9898 5.80072 19.1654 6.22464 19.1654 6.66667V15.8333Z"
                        stroke="#545454"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.0013 14.1667C11.8423 14.1667 13.3346 12.6743 13.3346 10.8333C13.3346 8.99238 11.8423 7.5 10.0013 7.5C8.16035 7.5 6.66797 8.99238 6.66797 10.8333C6.66797 12.6743 8.16035 14.1667 10.0013 14.1667Z"
                        stroke="#545454"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div
                  :class="[
                    'dropzone-image-edit-block-content',
                    { 'is-show': isShowPhotoMenu },
                  ]"
                >
                  <div class="dropzone-image-edit-block-content-item">
                    <button type="button" @click="addPhoto">
                      {{ $t("misc.add_photo") }}
                    </button>
                  </div>
                  <div class="dropzone-image-edit-block-content-item">
                    <button type="button" @click="removePhoto">
                      {{ $t("misc.remove_photo") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="dz-message" data-dz-message="">
              <i>
                <svg
                  width="33"
                  height="32"
                  viewBox="0 0 33 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5625 16C20.9825 16 24.5625 12.42 24.5625 8C24.5625 3.58 20.9825 0 16.5625 0C12.1425 0 8.5625 3.58 8.5625 8C8.5625 12.42 12.1425 16 16.5625 16ZM16.5625 20C11.2225 20 0.5625 22.68 0.5625 28V32H32.5625V28C32.5625 22.68 21.9025 20 16.5625 20Z"
                    fill="#167FFC"
                  ></path>
                </svg>
              </i>
              <p>
                <span>
                  {{ $t("settings.edit_account.add_profile_photo") }}
                </span>
                <em>({{ $t("misc.optional") }})</em>
              </p>
              <i>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 13V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V13"
                    stroke="#167FFC"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M5 8L10 13L15 8"
                    stroke="#167FFC"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M10 13V1"
                    stroke="#167FFC"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </i>
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar-menu-head-profile-more-modal-block-choice">
        <h3>
          {{ me.current_workspace.name }}
        </h3>
      </div>
      <div class="sidebar-menu-head-profile-more-modal-block-form">
        <Form :validation-schema="schema" @submit="submit" v-slot="{ errors }">
          <div class="sidebar-menu-head-profile-more-modal-block-form-title">
            <span>
              {{ $t("settings.edit_account.account_name_title") }}
            </span>
          </div>
          <Field
            class="form-item h-40"
            name="display_name"
            :errors="errors"
            :placeholder="$t('settings.edit_account.account_name_title')"
            :value="me.current_workspace.name"
          />
          <div
            class="sidebar-menu-head-profile-more-modal-block-form-description"
          >
            <p>
              {{ $t("settings.edit_account.account_name_description") }}
            </p>
          </div>
          <div class="sidebar-menu-head-profile-more-modal-block-form-buttons">
            <a
              class="btn btn-sm btn-white btn-round-4 btn-400 is-close-button"
              href="javascript:;"
              @click="setModalStatus('edit_account', false)"
            >
              {{ $t("misc.cancel") }}
            </a>
            <Button
              class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
              type="submit"
              :state="state"
            >
              {{ $t("misc.save") }}
            </Button>
          </div>
        </Form>
      </div>
      <div class="sidebar-menu-head-profile-more-modal-block-back">
        <button type="button" @click="setModalStatus('edit_account', false)">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 18L9 12L15 6"
              stroke="#C4C4C4"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Geri
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { getModalStatus, setModalStatus } from "@/utils/Helpers";
import store from "@/store";
import Dropzone from "dropzone";
import authHeader from "@/services/AuthHeader";
import { Form } from "vee-validate";
import Field from "@/components/inputs/Field";
import Button from "@/components/inputs/Button";
import * as yup from "yup";

const schema = yup.object().shape({
  display_name: yup.string().required(),
});

let dropzoneInit = null;
let initFile = null;
const state = ref("default");
const photoInput = ref(null);
const photoId = ref(null);
const isShowPhotoMenu = ref(false);
const me = computed(() => store.getters["account/me"]);

const togglePhotoMenu = () => {
  isShowPhotoMenu.value = !isShowPhotoMenu.value;
};

const removePhoto = () => {
  dropzoneInit.removeFile(initFile);
  initFile = null;

  togglePhotoMenu();

  photoId.value = null;
};

const addPhoto = (dropzoneType) => {
  removePhoto();
  dropzoneInit.hiddenFileInput.click();
};

const submit = (values) => {
  state.value = "loading";

  const displayName = values.display_name;
  const splitDisplayName = displayName.split(" ");
  const firstName = splitDisplayName.slice(0, -1).join(" ");
  const lastName = splitDisplayName[splitDisplayName.length - 1];
  const data = {
    first_name: firstName,
    last_name: lastName,
    display_name: displayName,
    profile_photo: photoId.value,
  };

  store.dispatch("account/update", data).then(() => {
    state.value = "success";
    setTimeout(() => {
      store.dispatch("account/me");
      setModalStatus("edit_account", false);
    }, 1000);
  });
};

onMounted(() => {
  let dropzone = new Dropzone(photoInput.value, {
    url: process.env.VUE_APP_API_DOMAIN + "/misc/upload_file",
    headers: authHeader(),
    maxFiles: 1,
    maxThumbnailFilesize: 1,
    parallelUploads: 1,
    uploadMultiple: false,
    acceptedFiles: "image/*",
    addRemoveLinks: false,
    init: function () {
      const self = this;
      const profilePhoto = me.value.profile_photo;

      if (profilePhoto) {
        const profilePhotoMock = {
          name: profilePhoto.filename,
          size: profilePhoto.filesize,
          type: profilePhoto.mime_type,
        };

        self.emit("addedfile", profilePhotoMock);
        self.emit("success", profilePhotoMock);
        self.emit("thumbnail", profilePhotoMock, profilePhoto.url);
        photoId.value = profilePhoto.unique_id;
      }
    },
    success: function (file, response) {
      if (response) {
        const uniqueId = response.uniq_id;

        photoId.value = uniqueId;
      }

      initFile = file;
    },
  });

  dropzoneInit = dropzone;
});
</script>
