import { axios } from "@/services/axios";
import AuthHeader from "../AuthHeader";
export const list = (payload) => {
  return axios
    .get("profiles", {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const create = (payload) => {
  return axios
    .post("profiles", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const get = (id) => {
  return axios
    .get(`profiles/${id}`, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const update = (id, payload) => {
  return axios
    .put(`profiles/${id}`, payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const destroy = (id) => {
  return axios
    .delete(`profiles/${id}`, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const share = (id, type, recipients) => {
  return axios
    .post(
      `profiles/${id}/share`,
      {
        type,
        recipients,
      },
      {
        headers: AuthHeader(),
      }
    )
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const updateVisibility = (payload) => {
  return axios
    .put(
      `profiles/${payload.id}/preferences`,
      {
        ...payload,
      },
      {
        headers: AuthHeader(),
      }
    )
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const updatePreferences = (payload) => {
  return axios
    .put(
      `profiles/${payload.id}/preferences`,
      {
        ...payload,
      },
      {
        headers: AuthHeader(),
      }
    )
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const addEmailImage = (data) => {
  return axios
    .post(
      `profiles/${data.id}/email_images`,
      {
        ...data,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          ...AuthHeader(),
        },
      }
    )
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const deleteEmailImage = (data) => {
  return axios
    .delete(
      `profiles/${data.id}/email_images/${data.image_id}?type=${data.type}`,
      {
        headers: AuthHeader(),
      }
    )
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const match = (data) => {
  return axios
    .post(
      `profiles/${data.id}/match`,
      {
        ...data,
      },
      {
        headers: AuthHeader(),
      }
    )
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default {
  list,
  create,
  get,
  update,
  destroy,
  share,
  updateVisibility,
  updatePreferences,
  addEmailImage,
  deleteEmailImage,
  match,
};
