import NotificationService from "@/services/modules/NotificationService";

export const notifications = {
  namespaced: true,
  state: {
    list: [],
  },
  actions: {
    list({ commit }) {
      return NotificationService.list().then(
        (data) => {
          commit("list", data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    list(state, data) {
      state.list = data;
    },
  },
  getters: {
    list: (state) => {
      return state.list;
    },
  },
};
