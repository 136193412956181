<template>
  <div class="email-signature-add-buttons" v-if="tabs">
    <h3>{{ $t("email_signature.add") }}</h3>
    <swiper
      class="email-signature-buttons-slider"
      :slides-per-view="5"
      :space-between="10"
      :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
      :breakpoints="{
        320: {
          slidesPerView: 'auto',
        },
      }"
    >
      <swiper-slide v-for="(tab, index) in tabs" :key="index">
        <a
          href="javascript:;"
          :class="[
            'btn btn-network btn-round-8 btn-500',
            tab.color,
            { 'is-active': activeTabIndex === index },
          ]"
          @click="choseTab(tab, index)"
        >
          <img :src="`/img/static/icon-logo-${tab.id}.svg`" :alt="tab.name" />
          <span>{{ tab.name }}</span>
        </a>
      </swiper-slide>
    </swiper>
  </div>
  <VisibilityModal v-if="getModalStatus('email_visibility')" />
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import Slides from "../Slides.vue";
import { getModalStatus, setModalStatus } from "@/utils/Helpers";
import VisibilityModal from "@/components/Email/Modals/Visibility";
import i18n from "@/utils/i18n";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import store from "@/store";

SwiperCore.use([Navigation, Pagination, A11y]);

const activeTab = computed(() => tabs[activeTabIndex.value]);
const activeTabIndex = ref(null);
const tabs = computed(() => store.getters["email/services"]);

const choseTab = (tab, index) => {
  activeTabIndex.value = index;
  setModalStatus("email_copy_view", true, {
    done: () => {
      setModalStatus("email_copy_view", false);
      setModalStatus("email_service", true, {
        tab,
      });
    },
  });
  // setModalStatus("email_service", true, {
  //   tab,
  // });
};
</script>
