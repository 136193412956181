<template>
  <button
    type="submit"
    :class="[
      state === 'disabled'
        ? buttonClasses.disabled
        : buttonClasses[state] + ' ' + props.class,
      {
        'btn-success': props.success_text && state === 'success',
      },
    ]"
  >
    <slot v-if="state === 'default' || state === 'disabled'"></slot>
    <template v-else-if="state == 'loading'">
      <span></span>
      <span></span>
      <span></span>
    </template>
    <template v-else-if="state == 'success' && !props.success_text">
      <span></span>
      <span></span>
    </template>
    <template v-else-if="state == 'success' && props.success_text">
      {{ props.success_text }}
      <i>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M20.2069 6.79279C20.3944 6.98031 20.4997 7.23462 20.4997 7.49979C20.4997 7.76495 20.3944 8.01926 20.2069 8.20679L10.7069 17.7068C10.5194 17.8943 10.2651 17.9996 9.99992 17.9996C9.73475 17.9996 9.48045 17.8943 9.29292 17.7068L4.79292 13.2068C4.61076 13.0182 4.50997 12.7656 4.51224 12.5034C4.51452 12.2412 4.61969 11.9904 4.8051 11.805C4.99051 11.6196 5.24132 11.5144 5.50352 11.5121C5.76571 11.5098 6.01832 11.6106 6.20692 11.7928L9.99992 15.5858L18.7929 6.79279C18.9804 6.60532 19.2348 6.5 19.4999 6.5C19.7651 6.5 20.0194 6.60532 20.2069 6.79279Z"
            fill="#36A549"
          />
        </svg>
      </i>
    </template>
  </button>
</template>

<script setup>
import { defineProps, watch, ref, computed } from "vue";

const buttonClasses = {
  loading: "btn-loader",
  success: "btn-successful btn-secondary-green",
  success_text: "btn-success",
  disabled: "btn btn-sm btn-round-4 btn-400 btn-disabled",
  default: "",
};

// props
const props = defineProps({
  class: {
    type: String,
    default: "",
  },
  state: {
    type: String,
    default: "default",
  },
  success_text: {
    type: String,
    default: "",
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const state = ref(props.state);

watch(
  () => props.state,
  (val) => {
    state.value = val;
    if (val === "success") {
      setTimeout(() => {
        state.value = "default";
      }, 1000);
    }
  }
);
</script>
