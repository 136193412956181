<template>
  <div class="virtual-background-block-new-background is-show">
    <div class="virtual-background-block-new-background-block">
      <div class="virtual-background-block-new-background-block-head">
        <i>
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_13971_80397)">
              <path
                d="M23.5 20.9999V18.9999C23.4993 18.1136 23.2044 17.2527 22.6614 16.5522C22.1184 15.8517 21.3581 15.3515 20.5 15.1299"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M17.5 21V19C17.5 17.9391 17.0786 16.9217 16.3284 16.1716C15.5783 15.4214 14.5609 15 13.5 15H5.5C4.43913 15 3.42172 15.4214 2.67157 16.1716C1.92143 16.9217 1.5 17.9391 1.5 19V21"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M16.5 3.12988C17.3604 3.35018 18.123 3.85058 18.6676 4.55219C19.2122 5.2538 19.5078 6.11671 19.5078 7.00488C19.5078 7.89305 19.2122 8.75596 18.6676 9.45757C18.123 10.1592 17.3604 10.6596 16.5 10.8799"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M9.5 11C11.7091 11 13.5 9.20914 13.5 7C13.5 4.79086 11.7091 3 9.5 3C7.29086 3 5.5 4.79086 5.5 7C5.5 9.20914 7.29086 11 9.5 11Z"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_13971_80397">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0.5)"
                ></rect>
              </clipPath>
            </defs>
          </svg>
        </i>
        <h4>
          {{ $t("bg.new.title") }}
        </h4>
      </div>
      <div class="virtual-background-block-new-background-block-body">
        <div class="virtual-background-block-new-background-block-body-image">
          <div
            class="virtual-background-block-new-background-block-body-image-content"
          >
            <img v-if="lastUploadItem" :src="lastUploadItem.image.url" alt="" />
          </div>
          <div
            class="virtual-background-block-new-background-block-body-image-label"
          >
            <i
              ><svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_13971_80410)">
                  <path
                    d="M19.167 17.5001V15.8334C19.1664 15.0948 18.9206 14.3774 18.4681 13.7937C18.0156 13.2099 17.3821 12.793 16.667 12.6084"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M14.1663 17.5V15.8333C14.1663 14.9493 13.8152 14.1014 13.19 13.4763C12.5649 12.8512 11.7171 12.5 10.833 12.5H4.16634C3.28229 12.5 2.43444 12.8512 1.80932 13.4763C1.1842 14.1014 0.833008 14.9493 0.833008 15.8333V17.5"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M13.333 2.6084C14.05 2.79198 14.6855 3.20898 15.1394 3.79366C15.5932 4.37833 15.8395 5.09742 15.8395 5.83757C15.8395 6.57771 15.5932 7.2968 15.1394 7.88147C14.6855 8.46615 14.05 8.88315 13.333 9.06673"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M7.50033 9.16667C9.34127 9.16667 10.8337 7.67428 10.8337 5.83333C10.8337 3.99238 9.34127 2.5 7.50033 2.5C5.65938 2.5 4.16699 3.99238 4.16699 5.83333C4.16699 7.67428 5.65938 9.16667 7.50033 9.16667Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_13971_80410">
                    <rect width="20" height="20" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
            </i>
          </div>
        </div>
        <div class="virtual-background-block-new-background-block-body-label">
          <button type="button" @click="setModalStatus('bg_new', false)">
            {{ $t("bg.new.text_1") }}
          </button>
        </div>
      </div>
      <div class="virtual-background-block-new-background-block-button">
        <button
          type="button"
          class="btn btn-lg btn-secondary-yellow btn-round-8 btn-500"
          @click="setModalStatus('bg_new', false)"
        >
          {{ $t("bg.new.button_label") }}
        </button>
      </div>
      <div class="virtual-background-block-new-background-block-close">
        <button type="button" @click="setModalStatus('bg_new', false)">
          <img src="/img/static/icon-x.svg" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { setModalStatus, getModalData } from "@/utils/Helpers";

const lastUploadItem = ref(null);

onMounted(() => {
  const modalData = getModalData("bg_new");

  if (modalData?.lastUploadItem) {
    lastUploadItem.value = modalData.lastUploadItem;
  }
});
</script>
