<template>
  <div class="statistics-team is-show">
    <div
      :class="['statistics-team-block', { 'is-hide': isOpenDetail }]"
      v-if="props.items.length"
    >
      <div class="statistics-team-block-head">
        <div class="statistics-team-block-head-name">
          <span>Takım</span>
        </div>
        <div class="statistics-team-block-head-number">
          <span>Üye Sayısı</span>
        </div>
        <div class="statistics-team-block-head-ratio">
          <span>Verimlilik Oranı</span>
        </div>
      </div>
      <div class="statistics-team-block-body">
        <div
          class="statistics-team-block-body-item is-increase"
          v-for="team in props.items"
          :key="team.id"
        >
          <div class="statistics-team-block-body-item-name">
            <span>{{ team.name }}</span>
          </div>
          <div class="statistics-team-block-body-item-number">
            <span>{{ team.member_count }}</span>
          </div>
          <div class="statistics-team-block-body-item-ratio">
            <div class="statistics-team-block-body-item-ratio-label">
              <span>%36</span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.1699 5.83268L5.83659 14.166"
                  stroke="#D63835"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.1699 14.166H5.83659V5.83268"
                  stroke="#D63835"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="statistics-team-block-body-item-ratio-button">
              <button type="button" @click="choseItem(team, 'team')">
                <svg
                  width="7"
                  height="16"
                  viewBox="0 0 7 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1.5L6 7.675L1 14.5"
                    stroke="#545454"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="statistics-team-empty is-show" v-else>
      <div class="statistics-team-empty-content">
        <p>
          {{ $t("stats.search.teams.empty.title") }}
        </p>
        <router-link :to="{ name: 'teams' }">
          {{ $t("stats.search.teams.empty.create") }}
        </router-link>
      </div>
      <div class="statistics-team-empty-close">
        <button type="button">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#979797"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M6 6L18 18"
              stroke="#979797"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </button>
      </div>
    </div>
    <div
      v-if="selectedItem"
      :class="['statistics-team-detail', { 'is-show': isOpenDetail }]"
    >
      <div class="statistics-team-detail-head">
        <div class="statistics-team-detail-head-image is-image-none">
          {{ getLetters(selectedItem.name) }}
        </div>
        <div class="statistics-team-detail-head-profile">
          <a href="javascript:;" @click="viewTeam">Üyeleri Gör</a>
        </div>
      </div>
      <div class="statistics-team-detail-body">
        <div class="statistics-team-detail-body-content">
          <div class="statistics-team-detail-body-content-title">
            <span>
              {{ selectedItem.name }}
            </span>
          </div>
          <div class="statistics-team-detail-body-content-number">
            <span>
              {{
                $t("stats.search.teams.member_count", {
                  count: selectedItem.member_count,
                })
              }}
            </span>
          </div>
        </div>
        <div class="statistics-team-detail-body-detail">
          <span>
            {{
              $t("stats.search.teams.created_at_text", {
                date: selectedItem.created_at,
              })
            }}
          </span>
        </div>
      </div>
      <div class="statistics-team-detail-close">
        <button type="button" @click="removeChosenItem">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#979797"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M6 6L18 18"
              stroke="#979797"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, defineProps } from "vue";
import store from "@/store";
import { getLetters } from "@/utils/Helpers";
import { useRouter } from "vue-router";

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
});

const router = useRouter();
const isOpenDetail = ref(null);
const detailType = ref(null);
const selectedItem = computed(() => store.getters["stats/selectedItem"]);

const choseItem = (item, type) => {
  store.commit("stats/setSelectedItem", item);

  isOpenDetail.value = true;
  detailType.value = type;

  store.commit("stats/setIsLoading", true);

  store
    .dispatch("stats/get", {
      team_id: selectedItem.value.id,
    })
    .then(() => {
      store.commit("stats/setIsLoading", false);
    });
};

const removeChosenItem = () => {
  store.commit("stats/setSelectedItem", null);

  isOpenDetail.value = false;
  detailType.value = null;
};

const viewTeam = () => {
  router.push({
    name: "team-view",
    params: {
      id: selectedItem.value.id,
    },
  });
};
</script>
