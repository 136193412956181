import { axios } from "@/services/axios";
import AuthHeader from "../AuthHeader";

export const get = (payload) => {
  return axios
    .get("stats", {
      headers: AuthHeader(),
      params: payload,
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const contributions = () => {
  return axios
    .get("stats/contributions", {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default {
  get,
  contributions,
};
