<template>
  <div class="members-head">
    <div class="members-head-label">
      <button type="button" @click="action" v-click-away="onClickAway">
        <template v-if="props.mode === 'members'">
          {{ $t("team.members.title") }}
        </template>
        <template v-else>
          {{ $t("team.title") }}
        </template>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.80019 8.39961L12.0002 15.5996L19.2002 8.39961"
            stroke="#167FFC"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <div :class="['members-head-label-list', { 'is-show': dropdownStatus }]">
        <div class="members-head-label-list-item">
          <a href="javascript:;" @click="switchMode">
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_13973_122165)">
                <path
                  d="M23.5 19V17.6371C23.4996 17.0332 23.3029 16.4465 22.9409 15.9692C22.5789 15.4919 22.0721 15.151 21.5 15"
                  stroke="#545454"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.5 19V17.6667C18.5 16.9594 18.1839 16.2811 17.6213 15.781C17.0587 15.281 16.2956 15 15.5 15H9.5C8.70435 15 7.94129 15.281 7.37868 15.781C6.81607 16.2811 6.5 16.9594 6.5 17.6667V19"
                  stroke="#545454"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M18.5 6C19.0721 6.14213 19.5792 6.46497 19.9413 6.91762C20.3034 7.37027 20.5 7.92699 20.5 8.5C20.5 9.07301 20.3034 9.62973 19.9413 10.0824C19.5792 10.535 19.0721 10.8579 18.5 11"
                  stroke="#545454"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.5 11C14.1569 11 15.5 9.65685 15.5 8C15.5 6.34315 14.1569 5 12.5 5C10.8431 5 9.5 6.34315 9.5 8C9.5 9.65685 10.8431 11 12.5 11Z"
                  stroke="#545454"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.5 19V17.6371C1.50044 17.0332 1.6971 16.4465 2.05909 15.9692C2.42108 15.4919 2.92791 15.151 3.5 15"
                  stroke="#545454"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.5 6C5.92789 6.14213 5.4208 6.46497 5.05868 6.91762C4.69655 7.37027 4.5 7.92699 4.5 8.5C4.5 9.07301 4.69655 9.62973 5.05868 10.0824C5.4208 10.535 5.92789 10.8579 6.5 11"
                  stroke="#545454"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_13973_122165">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            <template v-if="props.mode === 'members'">
              {{ $t("team.title") }}
            </template>
            <template v-else>
              {{ $t("team.members.title") }}
            </template>
          </a>
        </div>
      </div>
    </div>
    <Filter />
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
import { useRouter } from "vue-router";
import Filter from "./Filter.vue";
const router = useRouter();
const props = defineProps({
  mode: {
    type: String,
    required: true,
    default: "teams",
  },
  isView: {
    type: Boolean,
    required: true,
    default: false,
  },
});

const dropdownStatus = ref(false);

const action = () => {
  if (props.isView) {
    if (props.mode === "teams") {
      router.push({ name: "teams-main" });
    } else {
      router.push({ name: "members" });
    }
  } else {
    dropdownStatus.value = !dropdownStatus.value;
  }
};

const switchMode = () => {
  if (props.mode === "members") {
    router.push({ name: "teams-main" });
  } else {
    router.push({ name: "members" });
  }
};

const onClickAway = (e) => {
  if (e.target.closest(".members-head-label-list")) return;
  dropdownStatus.value = false;
};
</script>
