<template>
  <div class="business-card-view-content-register-modal is-show">
    <div class="business-card-view-content-register-modal-block">
      <div class="business-card-view-content-register-modal-block-content">
        <p>
          {{
            $t("public_profile.register_modal.info_1", {
              name: data.display_name,
            })
          }}
        </p>
        <p>
          {{ $t("public_profile.register_modal.info_2") }}
        </p>
      </div>
      <div class="business-card-view-content-register-modal-block-button">
        <router-link
          :to="{ name: 'signup' }"
          class="btn btn-md btn-primary-blue btn-round-8 btn-500"
          href="javascript:;"
        >
          {{ $t("public_profile.register_modal.register") }}
        </router-link>
      </div>
      <div class="business-card-view-content-register-modal-block-close">
        <button
          type="button"
          @click="setModalStatus('public_profile_register', false)"
        >
          <img src="/img/static/icon-x.svg" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { getModalStatus, setModalStatus, getModalData } from "@/utils/Helpers";

const data = getModalData("public_profile_register");
</script>