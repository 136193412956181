import { axios } from "@/services/axios";
import AuthHeader from "../AuthHeader";

export const countries = (payload) => {
  return axios
    .get("location", {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const states = (country) => {
  return axios
    .get("location/" + country, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const cities = (country, state) => {
  return axios
    .get("location/" + country + "/" + state, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const neighborhoods = (country, state, city) => {
  return axios
    .get("location/" + country + "/" + state + "/" + city, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const streets = (country, state, city, neighborhood) => {
  return axios
    .get(
      "location/" + country + "/" + state + "/" + city + "/" + neighborhood,
      {
        headers: AuthHeader(),
      }
    )
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default {
  countries,
  states,
  cities,
  neighborhoods,
  streets,
};
