<template>
  <section class="log-out-modal is-show">
    <div
      class="log-out-modal-close"
      @click="setModalStatus('reset_member', false)"
    >
      <i>
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 1.72656L1 13.7266"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 1.72656L13 13.7266"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </i>
    </div>
    <ul v-if="selectedMembers">
      <li v-for="member in selectedMembers" :key="member.id">
        {{ member.name }}
      </li>
    </ul>
    <p>
      {{ $t("team.reset_members.text") }}
    </p>
    <div class="log-out-modal-buttons">
      <a
        href="javascript:;"
        class="btn btn-sm btn-white btn-round-4 btn-400"
        @click="setModalStatus('reset_member', false)"
      >
        {{ $t("misc.cancel") }}
      </a>

      <Button
        href="javascript:;"
        class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
        :state="state"
        @click="resetMembers"
      >
        {{ $t("misc.delete") }}
      </Button>
    </div>
  </section>
</template>
<script setup>
import { ref, computed } from "vue";
import { getModalData, setModalStatus } from "@/utils/Helpers";
import Button from "@/components/inputs/Button.vue";
import store from "@/store";

const state = ref("default");
const selectedMembers = computed(() => store.getters["team/selectedMembers"]);

const resetMembers = () => {
  state.value = "loading";

  store
    .dispatch("member/reset", {
      members: selectedMembers.value.map((member) => member.id),
    })
    .then(() => {
      store.dispatch("member/items").then(() => {
        state.value = "success";
        setTimeout(() => {
          setModalStatus("reset_member", false);
        }, 1000);
      });
    });
};
</script>
