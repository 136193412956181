<template>
  <metainfo>
    <template v-slot:title="{ content }">
      {{ item?.name }}
    </template>
  </metainfo>

  <main-layout :head="false" class="main-members">
    <div class="members">
      <Head mode="teams" :is-view="true" />
      <b-skeleton v-if="!item?.id" class="profile-action-skeleton"></b-skeleton>
      <template v-else>
        <EditArea />
        <div class="members-body" v-if="item?.members">
          <Empty v-if="!item.members.length" mode="members" />
          <List
            v-else
            :members="item.members"
            :exclude-columns="['teams']"
            bar-mode="teams"
          />
        </div>
      </template>
      <RemoveMember v-if="getModalStatus('remove_member')" />
      <AddToTeamButton :id="id" v-if="currentWorkspace?.role !== 'member'" />
      <AddModal v-if="getModalStatus('team_add_member')" />
      <View
        v-if="getModalStatus('member_view')"
        :item="getModalData('member_view')"
        :actions="['edit', 'close']"
        :is-modal="true"
      />
      <EditProfile
        v-if="getModalStatus('member_edit')"
        :item="getModalData('member_edit')"
        :is-modal="true"
      />
    </div>
  </main-layout>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import store from "@/store";
import MainLayout from "@/layouts/MainLayout.vue";
import { useMeta } from "vue-meta";
import i18n from "@/utils/i18n";
import Head from "@/components/Team/Common/Head.vue";
import EditArea from "@/components/Team/EditArea.vue";
import ListItem from "@/components/Team/Members/ListItem.vue";
import Bar from "@/components/Team/Common/Bar.vue";
import RemoveMember from "@/components/Team/Modals/RemoveMember.vue";
import { getModalStatus, getModalData } from "@/utils/Helpers";
import { useRoute } from "vue-router";
import List from "@/components/Team/Members/List.vue";
import Empty from "@/components/Team/Empty.vue";
import AddToTeamButton from "@/components/Team/Common/AddToTeamButton.vue";
import AddModal from "@/components/Team/Modals/Add.vue";
import View from "@/components/Profile/View.vue";
import EditProfile from "@/components/Profile/Page.vue";

const currentWorkspace = computed(() => store.getters["account/workspace"]);
const route = useRoute();
const item = computed(() => store.getters["team/item"]);
const id = computed(() => route.params.id);
useMeta({
  title: "",
});

const selectedMembers = computed(() => {
  return store.getters["team/selectedMembers"];
});

const dropdownStatus = ref(false);
const toggleDropdown = () => {
  dropdownStatus.value = !dropdownStatus.value;
};

onMounted(() => {
  const id = route.params.id;
  store.commit("team/setItem", {});
  store.dispatch("team/removeAllSelectedMembers");
  store.dispatch("team/item", id);
});
</script>
