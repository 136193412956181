<template>
  <div
    class="email-signature-details-member"
    v-if="!isAccessForCorporateStatus"
  >
    <div class="email-signature-details-member-inner">
      <RightInner />
      <div class="modal-container">
        <div class="modal-container-inner">
          <p>
            {{ $t("email_signature.noAccess.text_1") }}
          </p>
          <p>
            <strong>
              {{ $t("email_signature.noAccess.text_2") }}
            </strong>
          </p>
        </div>
      </div>
    </div>
  </div>
  <RightInner v-else />
  <Slide />
</template>

<script setup>
import { computed } from "vue";
import Slide from "./Slide";
import RightInner from "./RightInner.vue";
import { slideToggle, isAccessForCorporate } from "@/utils/Helpers";

const isAccessForCorporateStatus = computed(() => {
  return isAccessForCorporate();
});
</script>
