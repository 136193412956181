import MiscService from "@/services/modules/MiscService";

export const misc = {
  namespaced: true,
  state: {
    languages: [],
    freeze_questions: [],
    profile: {},
    options: {},
  },
  actions: {
    languages({ commit }) {
      return MiscService.languages().then(
        (data) => {
          commit("languages", data);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    freezeRequestQuestions({ commit }) {
      return MiscService.freezeRequestQuestions().then(
        (response) => {
          commit("freeze_questions", response);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getProfile({ commit }, id) {
      return MiscService.getProfile(id).then(
        (response) => {
          commit("profile", response);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    shareInfo({ commit }, payload) {
      return MiscService.shareInfo(payload).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    options({ commit }) {
      return MiscService.options().then(
        (response) => {
          commit("options", response);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    support({ commit }, payload) {
      return MiscService.support(payload).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    getLocationDetailFromGMap({ commit }, payload) {
      return MiscService.getLocationDetailFromGMap(payload).then(
        (response) => {
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    languages(state, data) {
      state.languages = data;
    },
    freeze_questions(state, data) {
      state.freeze_questions = data;
    },
    profile(state, data) {
      state.profile = data;
    },
    options(state, data) {
      state.options = data;
    },
  },
  getters: {
    languages: (state) => {
      return state.languages;
    },
    freeze_questions: (state) => {
      return state.freeze_questions;
    },
    profile: (state) => {
      return state.profile;
    },
    options: (state) => {
      return state.options;
    },
  },
};
