<template>
  <div class="delete-group is-show">
    <div class="delete-group-block">
      <div class="delete-group-block-label">
        <h4>
          {{ $t("network.groups.delete.title") }}
        </h4>
      </div>
      <div class="delete-group-block-description">
        <p>
          {{ $t("network.groups.delete.text", { name: data.name }) }}
        </p>
      </div>
      <div class="delete-group-block-button">
        <button
          type="button"
          class="btn btn-sm btn-white btn-round-4 btn-400"
          @click="setModalStatus('group_delete', false)"
        >
          {{ $t("misc.cancel") }}
        </button>
        <Button
          type="button"
          class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
          :state="state"
          @click="deleteGroup"
        >
          {{ $t("misc.delete") }}
        </Button>
      </div>
      <div class="delete-group-block-close">
        <button type="button" @click="setModalStatus('group_delete', false)">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { getModalData, setModalStatus } from "@/utils/Helpers";
import store from "@/store";
import Button from "@/components/inputs/Button.vue";

const state = ref("default");
const data = getModalData("group_delete");

const deleteGroup = () => {
  state.value = "loading";

  store.dispatch("network/deleteGroup", data.id).then(() => {
    state.value = "success";
    store.dispatch("network/groups").then(() => {
      setModalStatus("group_delete", false);
    });
  });
};
</script>
