import { computed } from "vue";
import store from "@/store";
import { MembershipLevelEnum } from "@/enums";
import { setModalStatus } from "@/utils/Helpers";
import { useRouter } from "vue-router";

export default function isAccess(to, from, next) {
  const router = useRouter();
  const me = computed(() => store.getters["account/me"]);
  const mode = computed(() => store.getters["common/mode"]);
  const currentWorkspace = computed(() => store.getters["account/workspace"]);
  const routeName = computed(() => to.name);
  if (
    ["network", "members"].includes(routeName.value) &&
    mode.value === "individual"
  ) {
    return;
  }

  if (!me.value.profile_count) {
    return next();
  }

  if (!me.value || !me.value?.id) {
    store.dispatch("account/me").then((response) => {
      if (response.membership_level === MembershipLevelEnum.FREE) {
        setModalStatus("page_premium", true, { toName: to.name });
        return;
      } else {
        // setModalStatus("page_premium", false);
      }
    });
  }

  if (me.value.membership_level === MembershipLevelEnum.FREE) {
    setModalStatus("page_premium", true, { toName: to.name });
    // return;
  } else {
    // setModalStatus("page_premium", false);
  }

  return next();
}
