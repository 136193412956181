import { axios } from "@/services/axios";
import AuthHeader from "../AuthHeader";

export const languages = (payload) => {
  return axios
    .get("misc/languages", {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const freezeRequestQuestions = (payload) => {
  return axios
    .get("misc/freeze_request_questions", {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getProfile = (id) => {
  return axios
    .get(`misc/profiles/${id}`, {
      headers: AuthHeader(),
    })

    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const shareInfo = (payload) => {
  return axios
    .post("misc/share_info", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const options = (payload) => {
  return axios
    .get("misc/options", {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const support = (payload) => {
  return axios
    .post("misc/support", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getLocationDetailFromGMap = (payload) => {
  return axios
    .get(
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
        payload.lat +
        "," +
        payload.lng +
        "&key=" +
        process.env.VUE_APP_GOOGLE_API_KEY
    )
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default {
  languages,
  freezeRequestQuestions,
  getProfile,
  shareInfo,
  options,
  support,
  getLocationDetailFromGMap,
};
