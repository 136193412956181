<template>
  <div class="toast-block">
    <b-container
      :toast="{ root: true }"
      fluid="sm"
      position="position-fixed"
    ></b-container>
  </div>
  <div class="invite-team-mates is-show">
    <div class="invite-team-mates-block">
      <div class="invite-team-mates-block-close">
        <button type="button" @click="setModalStatus('member_invite', false)">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <div class="invite-team-mates-block-head" v-if="!isSuccessful">
        <h4>
          {{ $t("team.invite.title") }}
        </h4>
        <p>
          {{ $t("team.invite.text") }}
        </p>
      </div>
      <div class="invite-team-mates-block-content">
        <div class="tab-content-email" v-if="!isSuccessful">
          <div class="tab-content-email-head">
            <div class="tab-content-email-head-list" v-if="emails">
              <span
                class="tab-content-email-head-list-item"
                v-for="(email, index) in emails"
                :key="index"
              >
                {{ email }}
                <button
                  class="profile-sharing-delete-email"
                  @click="deleteEmail(index)"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.2332 1L1.00001 10.9996"
                      stroke="#7B7B7B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M11.2332 11L1.00001 1.00038"
                      stroke="#7B7B7B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg></button
              ></span>
            </div>
            <div class="tab-content-email-head-input">
              <input
                type="email"
                name="email"
                class="profile-sharing-enter-email-id"
                :placeholder="$t('misc.email')"
                @keyup.enter="addEmail"
              />
            </div>
          </div>
          <div
            :class="[
              'tab-content-email-share',
              { 'is-shareable': isActiveEmailShare },
            ]"
          >
            <button
              @click="copyLink"
              type="button"
              class="btn btn-sm btn-copied-primary btn-round-4 btn-400 is-copied"
            >
              {{ $t("team.invite.copy_link") }}
            </button>
            <Button
              type="button"
              class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400 btn-invite"
              @click="invite"
              :state="state"
            >
              {{ $t("team.invite.invite") }}
            </Button>
          </div>
        </div>
        <div class="tab-content-forwarded d-block" v-else>
          <div class="tab-content-forwarded-image">
            <img src="/img/static/forwarded-image.png" alt="" />
          </div>
          <div class="tab-content-forwarded-content">
            <span>{{ $t("team.invite.success_text_1") }}</span>
            <span>{{ $t("team.invite.success_text_2") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { validateEmail, qrInit } from "@/utils/Helpers";
import Button from "@/components/inputs/Button.vue";
import { setModalStatus, getErrorMessage } from "@/utils/Helpers";
import store from "@/store";
import copy from "copy-to-clipboard";
import { useToast } from "bootstrap-vue-3";
import i18n from "@/utils/i18n";
const currentWorkspace = computed(() => store.getters["account/workspace"]);
const members = computed(() => store.getters["member/items"]);
const isActiveEmailShare = ref(false);
const state = ref("default");
const emails = ref([]);
const isSuccessful = ref(false);
const toast = useToast();

const copyLink = () => {
  copy(currentWorkspace.value.signup_url);
};

const addEmail = (e) => {
  const employeeCountLimit = currentWorkspace.value.employee_count_limit;
  const memberCount = members.value ? members.value.length + 1 : 1;

  if (emails.value.length + memberCount >= employeeCountLimit) {
    toast.show({
      title: i18n.global.t("team.invite.limit_error.title"),
      body: i18n.global.t("team.invite.limit_error.text"),
      autoHideDelay: 5000,
    });
    return;
  }

  let email = e.target.value;
  email = email.trim();

  if (!validateEmail(email)) {
    return;
  }

  emails.value.push(email);
  e.target.value = "";
};

const deleteEmail = (index) => {
  emails.value.splice(index, 1);
};

const invite = () => {
  state.value = "loading";

  store
    .dispatch("workspace/invite", {
      emails: emails.value,
    })
    .then(() => {
      state.value = "success";
      isSuccessful.value = true;
    })
    .catch((err) => {
      toast.show({
        title: i18n.global.t("misc.error_occurred"),
        body: getErrorMessage(err),
        autoHideDelay: 5000,
      });
    });
};

watch(
  emails,
  (newVal) => {
    if (newVal.length > 0) {
      isActiveEmailShare.value = true;
    } else {
      isActiveEmailShare.value = false;
    }
  },
  { deep: true }
);
</script>
