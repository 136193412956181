<template>
  <header class="header-first-version">
    <div class="header-first-version-contain">
      <div class="header-first-version-contain-item">
        <div
          class="header-first-version-contain-item-menu"
          @click="openSidebar"
        >
          <button type="button">
            <svg
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.25 8.75C5.25 8.28587 5.43437 7.84075 5.76256 7.51256C6.09075 7.18437 6.53587 7 7 7H28C28.4641 7 28.9092 7.18437 29.2374 7.51256C29.5656 7.84075 29.75 8.28587 29.75 8.75C29.75 9.21413 29.5656 9.65925 29.2374 9.98744C28.9092 10.3156 28.4641 10.5 28 10.5H7C6.53587 10.5 6.09075 10.3156 5.76256 9.98744C5.43437 9.65925 5.25 9.21413 5.25 8.75Z"
                fill="#167FFC"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.25 17.5C5.25 17.0359 5.43437 16.5908 5.76256 16.2626C6.09075 15.9344 6.53587 15.75 7 15.75H28C28.4641 15.75 28.9092 15.9344 29.2374 16.2626C29.5656 16.5908 29.75 17.0359 29.75 17.5C29.75 17.9641 29.5656 18.4092 29.2374 18.7374C28.9092 19.0656 28.4641 19.25 28 19.25H7C6.53587 19.25 6.09075 19.0656 5.76256 18.7374C5.43437 18.4092 5.25 17.9641 5.25 17.5Z"
                fill="#167FFC"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.25 26.25C5.25 25.7859 5.43437 25.3408 5.76256 25.0126C6.09075 24.6844 6.53587 24.5 7 24.5H28C28.4641 24.5 28.9092 24.6844 29.2374 25.0126C29.5656 25.3408 29.75 25.7859 29.75 26.25C29.75 26.7141 29.5656 27.1592 29.2374 27.4874C28.9092 27.8156 28.4641 28 28 28H7C6.53587 28 6.09075 27.8156 5.76256 27.4874C5.43437 27.1592 5.25 26.7141 5.25 26.25Z"
                fill="#167FFC"
              />
            </svg>
          </button>
        </div>
        <div class="header-first-version-contain-item-logo">
          <a href="javascript:;">
            <img src="../dist/img/static/blue-logo-s.png" alt="" />
          </a>
        </div>
        <div class="header-first-version-contain-item-notification">
          <button
            type="button"
            class="is-show-button"
            @click="
              setModalStatus(
                'header_notifications',
                !getModalStatus('header_notifications')
              )
            "
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
                stroke="#979797"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.73 21C13.5542 21.3031 13.3019 21.5547 12.9982 21.7295C12.6946 21.9044 12.3504 21.9965 12 21.9965C11.6496 21.9965 11.3054 21.9044 11.0018 21.7295C10.6982 21.5547 10.4458 21.3031 10.27 21"
                stroke="#979797"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <Notifications v-if="getModalStatus('header_notifications')" />
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import store from "@/store";
import Notifications from "@/components/Common/Header/Notifications.vue";
import { getModalStatus, setModalStatus } from "@/utils/Helpers";

const openSidebar = () => {
  store.commit("common/set_sidebar_status", true);

  sidebarControl();
};

const sidebarControl = () => {
  document.addEventListener("click", (e) => {
    if (
      typeof e.target.className === "string" &&
      e.target.className.includes("sidebar-is-active")
    ) {
      store.commit("common/set_sidebar_status", false);
    }
  });
};

onMounted(() => {
  window.addEventListener("scroll", () => {
    const header = document.querySelector("header");
    const scrollY = window.scrollY;

    if (scrollY > 2) {
      header.classList.add("scrolled");
    } else {
      header.classList.remove("scrolled");
    }
  });
});
</script>
