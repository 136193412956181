<template>
  <input
    class="crop-zoom-range"
    type="range"
    step="0.01"
    :min="zoomMinVal"
    :max="zoomMaxVal"
    :value="zoomVal"
    @input="zoomChange"
  />
</template>

<script setup>
import { ref, onMounted, defineProps } from "vue";

const props = defineProps({
  cropper: {
    type: Object,
    required: true,
  },
  imageContainer: {
    type: Object,
    required: true,
  },
});

const zoomMinVal = ref(0);
const zoomMaxVal = ref(2);
const zoomVal = ref(0);

const zoomChange = (event) => {
  const zoomValue = parseFloat(event.target.value).toFixed(4);
  const containerData = props.cropper.getContainerData();

  props.cropper.zoomTo(zoomValue, {
    x: containerData.width / 2,
    y: containerData.height / 2,
  });
};

onMounted(() => {
  const cropper = props.cropper;
  const imageContainer = props.imageContainer;
  cropper.options.ready = () => {
    imageContainer.addEventListener("zoom", (event) => {
      zoomVal.value = event.detail.ratio;
    });

    const { width, height, naturalWidth, naturalHeight } =
      cropper.getImageData();

    const isLandscape = width >= height;
    let zoomMin = width / naturalWidth;

    if (isLandscape) {
      zoomMin = height / naturalHeight;
    }

    const containerData = cropper.getContainerData();
    const canvasData = cropper.getCanvasData();
    const minRatio = containerData.height / canvasData.naturalHeight;

    zoomMinVal.value = zoomMin;
    zoomMaxVal.value = minRatio * 3;
  };

  cropper.options.zoom = (e) => {
    if (e.detail.ratio > zoomMaxVal.value) {
      e.preventDefault();
      cropper.zoomTo(zoomMaxVal.value);
      zoomVal.value = zoomMaxVal.value;
    }
  };
});
</script>
