<template>
  <input
    :placeholder="props.placeholder"
    :type="type"
    v-bind="props.bindValue"
  />
  <a
    :class="[
      'form-item-action',
      {
        'icon-eye-gray': type === 'password',
        'icon-eye-blue': type === 'text',
      },
    ]"
    href="javascript:;"
    @click="togglePasswordVisibility"
  ></a>
</template>

<script setup>
import { ref, defineProps } from "vue";

// props
const props = defineProps({
  placeholder: {
    type: String,
    default: "",
  },
  bindValue: {
    type: Object,
    default: "",
  },
});

// data
const type = ref("password");

const togglePasswordVisibility = () => {
  type.value = type.value === "password" ? "text" : "password";
};
</script>