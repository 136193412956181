<template>
  <SecondLayout>
    <Loading :is-full-screen="true" v-if="isLoading" />
    <section class="onboarding" v-if="onboardingItems && !isLoading">
      <div class="onboarding-contain">
        <div
          :class="[
            'onboarding-main',
            { 'onboarding-reached-end': isLastSlide },
          ]"
        >
          <div
            class="onboarding-item-prev-btn btn btn-sm btn-white btn-round-4 btn-400"
            @click="go"
          >
            {{ $t("misc.skip") }}
          </div>
          <swiper
            :slides-per-view="1"
            :space-between="50"
            :rewind="true"
            effect="fade"
            :fadeEffect="{
              crossFade: true,
            }"
            :pagination="{
              clickable: true,
              el: '.swiper-pagination',
            }"
            :keyboard="{
              enabled: true,
            }"
            :navigation="{
              prevEl: '.button-prev',
              nextEl: '.button-next',
            }"
            @slide-change="onSlideChange"
            @init="onInit"
          >
            <swiper-slide
              v-for="(item, index) in workspace.type === 'corporate'
                ? onboardingItems.corporate_boardings
                : onboardingItems.boardings"
              :key="index"
            >
              <div class="onboarding-item">
                <div class="onboarding-item-title">
                  <h2>
                    {{ item.title }}
                  </h2>
                </div>
                <div class="onboarding-item-img" v-if="item.image">
                  <img :src="item.image.url" :alt="item.title" />
                </div>
                <div
                  class="onboarding-item-text"
                  v-if="item.description"
                  v-html="item.description"
                ></div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="onboarding-item-footer">
            <div class="swiper-pagination"></div>
            <div class="onboarding-item-footer-slide-btn">
              <a
                :class="[
                  'btn btn-sm btn-white btn-round-4 btn-400 button-prev',
                  { 'swiper-button-disabled': isFirstSlide },
                ]"
                ref="slideButtonPrev"
                href="javascript:;"
              >
                {{ $t("misc.prev") }}
              </a>
              <a
                class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400 button-next"
                href="javascript:;"
              >
                {{ $t("misc.next") }}
              </a>
            </div>
            <div class="onboarding-item-footer-start-btn">
              <Button
                class="btn btn-md btn-primary-blue btn-round-8 btn-500"
                @click="go"
                :state="state"
              >
                {{ $t("misc.get_started") }}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </SecondLayout>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import store from "@/store";
import SwiperCore, {
  Navigation,
  Pagination,
  A11y,
  Keyboard,
  EffectFade,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { useRouter } from "vue-router";
import Button from "@/components/inputs/Button.vue";
import Loading from "@/components/Common/Loading.vue";
SwiperCore.use([Navigation, Pagination, A11y, Keyboard, EffectFade]);

const router = useRouter();
const onboardingItems = computed(() => store.getters["onboarding/list"]);
const slideButtonPrev = ref(null);
const isLastSlide = ref(false);
const isFirstSlide = ref(true);
const state = ref("default");
const me = computed(() => store.getters["account/me"]);
const workspace = computed(() => store.getters["account/workspace"]);
const isLoading = ref(true);

const onSlideChange = (swiper) => {
  const activeSlideIndex = swiper.activeIndex;
  const totalSlideCount = swiper.slides.length;
  isLastSlide.value = activeSlideIndex === totalSlideCount - 1;
  isFirstSlide.value = activeSlideIndex === 0;
};

const onInit = (swiper) => {
  setTimeout(() => {
    slideButtonPrev.value.classList.add("swiper-button-disabled");
  }, 1000);
};

const go = () => {
  state.value = "loading";
  store.dispatch("onboarding/update").then(() => {
    state.value = "success";
    setTimeout(() => {
      router.push({
        name: "profile-main",
        params: {
          workspace: me.value.last_workspace,
        },
      });
    }, 1000);
  });
};

onMounted(() => {
  store.dispatch("onboarding/list").then(() => {
    setTimeout(() => {
      isLoading.value = false;
    }, 200);
  });
});
</script>
