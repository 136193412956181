<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>

  <MainLayout
    class="main-virtual-background"
    :title="$t('bg.head_title')"
    container-class="virtual-background"
  >
    <template v-if="!isLoading">
      <div class="virtual-background-block">
        <div class="virtual-background-block-head">
          <div class="virtual-background-block-head-image" ref="imageContainer">
            <div
              class="virtual-background-block-head-image-block"
              v-if="selectedBackground"
            >
              <img
                cross-origin="use-credentials"
                :src="selectedBackground.image.url"
                :alt="selectedBackground.title"
              />
            </div>
            <div class="virtual-background-block-head-image-profile">
              <div class="virtual-background-block-head-image-profile-block">
                <h4>{{ displayName() }}</h4>
                <h5>{{ getValue("title") }}</h5>
                <span>{{ getValue("company_name") }}</span>
              </div>
            </div>
            <div class="virtual-background-block-head-image-illustration">
              <div class="virtual-background-block-head-image-illustration-qr">
                <canvas id="qr-canvas"></canvas>
              </div>
              <div
                class="virtual-background-block-head-image-illustration-colleague"
                v-if="companyLogo"
              >
                <img :src="companyLogo.url" :alt="getValue('company_name')" />
              </div>
            </div>
          </div>
          <div class="virtual-background-block-head-button">
            <Button
              type="button"
              class="btn btn-lg btn-secondary-yellow btn-round-8 btn-500 is-download-button"
              @click="download"
              :state="downloadButtonState"
            >
              {{ $t("bg.download.button_label") }}
            </Button>
            <a href="javascript:;" class="how-to-add-button">
              {{ $t("bg.download.how_button_label") }}
            </a>
          </div>
        </div>
        <div class="virtual-background-block-body">
          <div class="virtual-background-block-body-block">
            <div
              class="virtual-background-block-body-block-button"
              v-if="
                (workspace.type === 'corporate' &&
                  workspace.role !== 'member' &&
                  isMainCorporateProfile) ||
                workspace.type === 'individual'
              "
            >
              <input
                type="file"
                ref="fileInput"
                @change="uploadFile"
                class="d-none"
                accept="image/png, image/jpeg"
              />
              <a @click="uploadFileButton" href="javascript:;">
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 35 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5 7.29102V27.7077"
                    stroke="#545454"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.29102 17.5H27.7077"
                    stroke="#545454"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="virtual-background-block-body-block-button-label">
                  <span>
                    {{ $t("bg.upload.image_button_label") }}
                  </span>
                </div>
              </a>
            </div>
            <div
              class="virtual-background-block-body-block-swiper"
              v-if="backgrounds"
            >
              <div
                class="swiper virtual-background-block-body-block-swiper-content"
              >
                <swiper
                  @swiper="onSwiper"
                  slides-per-view="auto"
                  :space-between="20"
                  :rewind="false"
                  :pagination="{
                    clickable: true,
                    el: '.swiper-pagination',
                  }"
                  :navigation="{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                  }"
                  :scrollbar="{
                    el: '.virtual-background-block-scrollbar .swiper-scrollbar',
                    draggable: true,
                  }"
                >
                  <swiper-slide
                    v-for="(background, index) in backgrounds"
                    :key="index"
                  >
                    <div
                      class="swiper-slide-content"
                      @click="selectBackground(background)"
                    >
                      <div class="swiper-slide-content-image">
                        <img
                          :src="background.image.url"
                          :alt="background.title"
                        />
                      </div>
                      <div
                        class="swiper-slide-content-delete"
                        v-if="
                          isMainCorporateProfile &&
                          background.type !== 'default'
                        "
                        @click.prevent="deleteBackground(background.index)"
                      >
                        <button>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3 6H5H21"
                              stroke="#e3e3e3"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z"
                              stroke="#e3e3e3"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
            </div>
          </div>
        </div>
        <div class="virtual-background-block-scrollbar">
          <div class="swiper-button-next">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 18L15 12L9 6"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="swiper-button-prev">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 18L9 12L15 6"
                stroke="#545454"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="swiper-scrollbar"></div>
        </div>
        <UploadModal
          v-if="getModalStatus('bg_upload')"
          @uploadedFile="uploadedFile"
        />
        <DownloadModal v-if="getModalStatus('bg_download')" />
        <DoneModal v-if="getModalStatus('bg_done')" />
        <NewModal v-if="getModalStatus('bg_new')" />
      </div>
      <div class="virtual-background-mobile">
        <div class="virtual-background-mobile-block">
          <div class="virtual-background-mobile-block-image">
            <img
              src="/img/dynamic/virtual-background-mobile-block-image.png"
              alt=""
            />
          </div>
          <div class="virtual-background-mobile-block-content">
            <p>
              <i18n-t keypath="bg.mobile.text_1">
                <template v-slot:bold_text>
                  <span>
                    {{ $t("bg.mobile.bold_text") }}
                  </span>
                </template>
              </i18n-t>
            </p>
            <p>
              {{ $t("bg.mobile.text_2") }}
            </p>
          </div>
          <div class="virtual-background-mobile-block-button">
            <a :href="options.blog_page_url" target="_blank">
              {{ $t("bg.mobile.button_label") }}
              <svg
                width="7"
                height="14"
                viewBox="0 0 7 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.78935 0.386059C1.45028 -0.0498878 0.822007 -0.128422 0.386059 0.210648C-0.0498878 0.549718 -0.128422 1.17799 0.210648 1.61394L1.78935 0.386059ZM6 7.42857L6.74424 8.09648C7.06741 7.73638 7.08641 7.19656 6.78935 6.81463L6 7.42857ZM0.255758 12.3321C-0.113119 12.7431 -0.0789434 13.3754 0.33209 13.7442C0.743124 14.1131 1.37537 14.0789 1.74424 13.6679L0.255758 12.3321ZM0.210648 1.61394L5.21065 8.04251L6.78935 6.81463L1.78935 0.386059L0.210648 1.61394ZM5.25576 6.76066L0.255758 12.3321L1.74424 13.6679L6.74424 8.09648L5.25576 6.76066Z"
                  fill="#167FFC"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <b-skeleton class="profile-action-skeleton"></b-skeleton>
    </template>
  </MainLayout>
</template>
<script setup>
import { ref, onMounted, computed } from "vue";
import useProfile from "@/composables/profile";
import MainLayout from "@/layouts/MainLayout.vue";
import store from "@/store";
import { useRoute, useRouter } from "vue-router";
import {
  getModalStatus,
  setModalStatus,
  qrInit,
  getWithProxyURL,
  getProxyURL,
} from "@/utils/Helpers";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/vue/swiper-vue";
import DownloadModal from "@/components/Bg/Modals/Download.vue";
import UploadModal from "@/components/Bg/Modals/Upload.vue";
import DoneModal from "@/components/Bg/Modals/Done.vue";
import NewModal from "@/components/Bg/Modals/New.vue";
import html2canvas from "html2canvas";
import Button from "@/components/inputs/Button";
import { MembershipLevelEnum } from "@/enums";
import { useMeta } from "vue-meta";
import i18n from "@/utils/i18n.vue";
import domtoimage from "dom-to-image";

//meta
useMeta({
  title: i18n.global.t("bg.page_title"),
});

SwiperCore.use([Navigation, Pagination, A11y, Scrollbar]);

const isLoading = ref(true);
const route = useRoute();
const {
  getValue,
  displayName,
  website,
  phones,
  address,
  socialMedias,
  profilePhoto,
  companyLogo,
  urls,
  isMainCorporateProfile,
} = useProfile();

const imageContainer = ref(null);
const selectedBackground = ref(null);
const fileInput = ref(null);
const swiperRef = ref(null);
const downloadButtonState = ref("default");

const onSwiper = (swiper) => {
  swiperRef.value = swiper;
};

const me = computed(() => store.getters["account/me"]);
const workspace = computed(() => store.getters["account/workspace"]);
const options = computed(() => store.getters["misc/options"]);
const item = computed(() => {
  const item = store.getters["profiles/item"];
  return item;
});

const backgrounds = computed(() => {
  const backgrounds = store.getters["background/list"];

  return backgrounds;
});

const selectBackground = (background) => {
  selectedBackground.value = background;
};

const uploadFileButton = () => {
  fileInput.value.click();
};

const uploadFile = () => {
  const image = fileInput.value.files[0];
  setModalStatus("bg_upload", true, image);
};

const uploadedFile = (image) => {
  selectedBackground.value = {
    title: "",
    image: {
      url: image,
    },
  };

  swiperRef.value.update();
  setModalStatus("bg_upload", false);

  if (workspace.value.type === "corporate") {
    setModalStatus("bg_done", true);
  }
};

const saveAs = (uri, filename) => {
  const link = document.createElement("a");

  if (typeof link.download === "string") {
    link.href = uri;
    link.download = filename;

    //Firefox requires the link to be in the body
    document.body.appendChild(link);

    //simulate click
    link.click();

    //remove the link when done
    document.body.removeChild(link);
  } else {
    window.open(uri);
  }
};

const download = () => {
  if (me.value.membership_level === MembershipLevelEnum.FREE) {
    setModalStatus("page_premium", true);
    return;
  }

  downloadButtonState.value = "loading";

  const images = imageContainer.value.querySelectorAll("img");

  images.forEach((image) => {
    image.src = getWithProxyURL(image.src);
  });

  domtoimage
    .toBlob(imageContainer.value, {
      width: imageContainer.value.clientWidth * 2,
      height: imageContainer.value.clientHeight * 2,
      style: {
        transform: "scale(2)",
        transformOrigin: "top left",
      },
    })
    .then(function (blob) {
      images.forEach((image) => {
        image.src = image.src.replace(getProxyURL(), "");
      });

      store
        .dispatch("background/generate", {
          file: blob,
          type: "virtual-background",
        })
        .then((data) => {
          const url = data.data.url;

          saveAs(url, "virtual-backgrounds.zip");
        });

      setModalStatus("bg_download", true);
      downloadButtonState.value = "success";
    });
};

const deleteBackground = (index) => {
  isLoading.value = true;

  store.dispatch("background/delete", index).then(() => {
    getBackgrounds();
  });
};

const getBackgrounds = () => {
  store.dispatch("background/list").then((response) => {
    const items = response.data.items;
    const firstItem = items ? items[0] : null;
    const userLastCheckTimes = me.value.last_check_times;
    const lastUploadTime = response.data.last_upload_time;
    const lastUploadItem = response.data.last_upload_item;

    if (firstItem) {
      selectBackground(firstItem);
    }
    if (
      userLastCheckTimes?.virtual_backgrounds < lastUploadTime &&
      lastUploadItem &&
      workspace.value.type === "corporate" &&
      workspace.value.role === "member"
    ) {
      setModalStatus("bg_new", true, {
        lastUploadTime: lastUploadTime,
        lastUploadItem: lastUploadItem,
      });
    }

    isLoading.value = false;
    // generate qr code
    setTimeout(() => {
      qrInit(urls.value.bg.view);
    }, 200);
  });
};

onMounted(async () => {
  store.dispatch("misc/options");
  store.commit("profiles/get", null);
  await store.dispatch("profiles/get", route.params.id).then(() => {});

  getBackgrounds();
});
</script>
