<template>
  <div class="settings-accordion-item accordion-item">
    <div
      class="settings-accordion-item-btn accordion-header"
      id="billing-heading"
      data-bs-toggle="collapse"
      data-bs-target="#billing"
      aria-expanded="false"
      aria-controls="billing"
    >
      <h2 class="accordion-button">
        {{ $t("settings.account_management.billing_information.title") }}
      </h2>
    </div>
    <div
      id="billing"
      class="settings-accordion-item-content is-billing-information accordion-collapse collapse"
      data-bs-parent="#settings-accordions"
      aria-labelledby="preferences-heading"
    >
      <div class="accordion-body invoice-information-modal" v-if="me">
        <div class="invoice-information-modal-block">
          <div class="invoice-information-modal-block-vote">
            <div class="form-item h-40">
              <v-select
                @option:selecting="(type) => onBillingTypeChange(type)"
                :options="billingTypes"
                :get-option-label="(option) => option.label"
                :get-option-value="(option) => option.value"
                v-model="selectedBillingType"
              >
                <template #no-options>
                  <div class="">
                    {{ $t("misc.no_results") }}
                  </div>
                </template>
              </v-select>
            </div>
          </div>

          <div class="invoice-information-modal-block-tab">
            <div class="tab-content" id="invoice-information-modal-tab-content">
              <div class="tab-pane fade show active">
                <div class="tab-pane-content">
                  <Form
                    @submit="submit"
                    :validation-schema="schema"
                    v-slot="{ errors }"
                    ref="billingInformationForm"
                  >
                    <div class="tab-pane-content" v-if="selectedBillingType">
                      <Field
                        :errors="errors"
                        name="billing_type"
                        class="d-none"
                      />

                      <div
                        class="tab-pane-content-item"
                        v-if="selectedBillingType.value === 'individual'"
                      >
                        <div class="tab-pane-content-item-label">
                          <span>{{ $t("misc.first_name") }}</span>
                        </div>
                        <Field
                          :errors="errors"
                          name="billing_first_name"
                          :placeholder="$t('misc.first_name')"
                        />
                      </div>
                      <div
                        class="tab-pane-content-item"
                        v-if="selectedBillingType.value === 'individual'"
                      >
                        <div class="tab-pane-content-item-label">
                          <span>{{ $t("misc.last_name") }}</span>
                        </div>
                        <Field
                          :errors="errors"
                          name="billing_last_name"
                          :placeholder="$t('misc.last_name')"
                        />
                      </div>
                      <div
                        class="tab-pane-content-item"
                        v-if="selectedBillingType.value === 'individual'"
                      >
                        <div class="tab-pane-content-item-label">
                          <span>{{ $t("misc.identify_number") }}</span>
                        </div>
                        <Field
                          :errors="errors"
                          name="identify_number"
                          :placeholder="$t('misc.identify_number')"
                        />
                      </div>
                      <div
                        class="tab-pane-content-item"
                        v-if="selectedBillingType.value === 'corporate'"
                      >
                        <div class="tab-pane-content-item-label">
                          <span>{{ $t("misc.tax_number") }}</span>
                        </div>
                        <Field
                          :errors="errors"
                          name="billing_tax_number"
                          :placeholder="$t('misc.tax_number')"
                        />
                      </div>
                      <div
                        class="tab-pane-content-item"
                        v-if="selectedBillingType.value === 'corporate'"
                      >
                        <div class="tab-pane-content-item-label">
                          <span>{{ $t("misc.tax_office") }}</span>
                        </div>
                        <Field
                          :errors="errors"
                          name="billing_tax_office"
                          :placeholder="$t('misc.tax_office')"
                        />
                      </div>
                      <div
                        class="tab-pane-content-item"
                        v-if="selectedBillingType.value === 'corporate'"
                      >
                        <div class="tab-pane-content-item-label">
                          <span>{{ $t("misc.company") }}</span>
                        </div>
                        <Field
                          :errors="errors"
                          name="billing_company"
                          :placeholder="$t('misc.company')"
                        />
                      </div>
                      <div class="tab-pane-content-item">
                        <div class="tab-pane-content-item-label">
                          <span>{{ $t("misc.address") }}</span>
                        </div>
                        <div class="tab-pane-content-item-form">
                          <div class="form-item h-40">
                            <v-select
                              @option:selecting="
                                (country) => onCountryChange(country)
                              "
                              v-model="selectedCountry"
                              :options="countries"
                              :searchable="true"
                              :clearable="true"
                              :placeholder="$t('misc.country')"
                              :label="$t('misc.country')"
                              :get-option-label="(option) => option.name"
                              :get-option-value="(option) => option.id"
                            >
                              <template #no-options>
                                <div class="">
                                  {{ $t("misc.no_results") }}
                                </div>
                              </template>
                            </v-select>

                            <div
                              class="form-item-error-text"
                              v-if="customErrors.billing_country"
                            >
                              {{ customErrors.billing_country }}
                            </div>
                          </div>

                          <div class="form-item h-40">
                            <v-select
                              @option:selecting="
                                (state) => onStateChange(state)
                              "
                              v-model="selectedState"
                              :options="states"
                              :searchable="true"
                              :clearable="true"
                              :placeholder="$t('misc.state')"
                              :label="$t('misc.state')"
                              :get-option-label="(option) => option.name"
                              :get-option-value="(option) => option.id"
                            >
                              <template #no-options>
                                <div class="">
                                  {{ $t("misc.no_results") }}
                                </div>
                              </template>
                            </v-select>

                            <div
                              class="form-item-error-text"
                              v-if="customErrors.billing_state"
                            >
                              {{ customErrors.billing_state }}
                            </div>
                          </div>

                          <div class="form-item h-40">
                            <v-select
                              :options="cities"
                              v-model="selectedCity"
                              :searchable="true"
                              :clearable="true"
                              :placeholder="$t('misc.city')"
                              :label="$t('misc.city')"
                              :get-option-label="(option) => option.name"
                              :get-option-value="(option) => option.id"
                            >
                              <template #no-options>
                                <div class="">
                                  {{ $t("misc.no_results") }}
                                </div>
                              </template>
                            </v-select>

                            <div
                              class="form-item-error-text"
                              v-if="customErrors.billing_city"
                            >
                              {{ customErrors.billing_city }}
                            </div>
                          </div>

                          <Field
                            :errors="errors"
                            name="billing_neighborhood"
                            :placeholder="$t('misc.neighbourhood')"
                          />

                          <VField v-slot="{ field }" name="billing_address">
                            <div class="form-item h-40">
                              <textarea
                                :placeholder="$t('misc.address')"
                                class="form-control"
                                v-bind="field"
                              />
                            </div>

                            <div
                              class="form-item-error-text"
                              v-if="errors.billing_address"
                            >
                              {{ errors.billing_address }}
                            </div>
                          </VField>
                        </div>
                      </div>
                      <div class="tab-pane-content-item">
                        <div class="tab-pane-content-item-label">
                          <span>{{ $t("misc.phone") }}</span>
                        </div>
                        <div class="form-item h-40">
                          <Field
                            :errors="errors"
                            name="billing_phone"
                            :placeholder="$t('misc.phone')"
                            mask="+90 (###) ### ## ##"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="tab-content-buttons">
                      <Button
                        type="submit"
                        class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
                        :state="state"
                      >
                        {{ $t("misc.save") }}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, reactive } from "vue";
import { setModalStatus } from "@/utils/Helpers";
import { Form, Field as VField, useField } from "vee-validate";
import Field from "@/components/inputs/Field.vue";
import * as yup from "yup";
import i18n from "@/utils/i18n.vue";
import store from "@/store";
import Button from "@/components/inputs/Button.vue";
import { read } from "@popperjs/core";

const billingTypes = [
  {
    value: "individual",
    label: i18n.global.t(
      "settings.account_management.billing_information.individual"
    ),
  },
  {
    value: "corporate",
    label: i18n.global.t(
      "settings.account_management.billing_information.corporate"
    ),
  },
];

const isInitDataAdded = ref(false);
const selectedCountry = ref(null);
const selectedState = ref(null);
const selectedCity = ref(null);
const selectedBillingType = ref(null);
const billingInformationForm = ref(null);
const state = ref("default");
const me = computed(() => store.getters["account/me"]);
const customErrors = ref({});
const phoneRegExp =
  /^(\+90|0)?\s*\(?([0-9]{3})\)?\s*([0-9]{3})\s*([0-9]{2})\s*([0-9]{2})$/;

const schema = yup.object().shape({
  billing_type: yup.string().required().label(i18n.global.t("misc.type")),
  billing_first_name: yup
    .string()
    .when("billing_type", {
      is: "individual",
      then: yup.string().required().label(i18n.global.t("misc.first_name")),
    })
    .label(i18n.global.t("misc.first_name"))
    .trim(),
  billing_last_name: yup
    .string()
    .when("billing_type", {
      is: "individual",
      then: yup.string().required().label(i18n.global.t("misc.last_name")),
    })
    .label(i18n.global.t("misc.last_name"))
    .trim(),
  identify_number: yup
    .string()
    .when("billing_type", {
      is: "individual",
      then: yup
        .string()
        .min(11)
        .max(11)
        .required()
        .label(i18n.global.t("misc.identify_number")),
    })
    .label(i18n.global.t("misc.identify_number")),
  billing_tax_office: yup
    .string()
    .when("billing_type", {
      is: "corporate",
      then: yup.string().required().label(i18n.global.t("misc.tax_office")),
    })
    .label(i18n.global.t("misc.tax_office"))
    .trim(),
  billing_tax_number: yup
    .string()
    .when("billing_type", {
      is: "corporate",
      then: yup.string().required().label(i18n.global.t("misc.tax_number")),
    })
    .label(i18n.global.t("misc.tax_number"))
    .trim(),
  billing_company: yup
    .string()
    .when("billing_type", {
      is: "corporate",
      then: yup.string().required().label(i18n.global.t("misc.company")),
    })
    .label(i18n.global.t("misc.company"))
    .trim(),
  billing_phone: yup
    .string()
    .required()
    .matches(phoneRegExp, i18n.global.t("validation.phone"))
    .label(i18n.global.t("misc.phone"))
    .trim(),
  billing_address: yup
    .string()
    .required()
    .label(i18n.global.t("misc.address"))
    .trim(),
});

const onBillingTypeChange = (type) => {
  billingInformationForm.value.setValues({
    billing_type: type.value,
    billing_first_name: me.value.billing.first_name,
    billing_last_name: me.value.billing.last_name,
    identify_number: me.value.billing.identify_number,
    billing_phone: me.value.billing.phone,
    billing_neighborhood: me.value.billing.neighborhood,
    billing_address: me.value.billing.address_1,
    identify_number: me.value.identify_number,
    billing_tax_number: me.value.billing.tax_number,
    billing_tax_office: me.value.billing.tax_office,
    billing_company: me.value.billing.company,
  });
};

const changedTab = (newTabIndex, prevTabIndex) => {
  const clickedTab =
    document.querySelectorAll(".nav-link")[newTabIndex].parentElement;
  const tabBaseActive = document.querySelector(".tab-base-active");

  const clickedWidth = clickedTab.offsetWidth;
  const clickedOffsetLeft = clickedTab.offsetLeft;

  tabBaseActive.style.left = clickedOffsetLeft + 2 + "px";
  tabBaseActive.style.width = clickedWidth - 4 + "px";
};

const countries = computed(() => {
  const countries = store.getters["location/countries"];

  if (countries && !isInitDataAdded.value && me.value.billing.country) {
    selectedCountry.value = countries.find(
      (country) => country.iso2 === me.value.billing.country
    );

    onCountryChange(selectedCountry.value);
  }

  return countries;
});

const states = computed(() => {
  const states = store.getters["location/states"];

  if (states && !isInitDataAdded.value && me.value.billing.state) {
    selectedState.value = states.find(
      (state) => state.iso2 === me.value.billing.state
    );

    if (selectedState.value) {
      onStateChange(selectedState.value);
    }
  }

  return states;
});

const cities = computed(() => {
  const cities = store.getters["location/cities"];
  if (cities && !isInitDataAdded.value) {
    selectedCity.value = cities.find(
      (city) => city.id === parseInt(me.value.billing.city)
    );

    isInitDataAdded.value = true;
  }

  return cities;
});

const onCountryChange = (country) => {
  store.dispatch("location/states", country.iso2);

  // selectedCountry.value = null;
  selectedState.value = null;
  selectedCity.value = null;
};

const onStateChange = (state) => {
  store.dispatch("location/cities", {
    country: selectedCountry.value.iso2,
    state: state?.iso2,
  });

  selectedCity.value = null;
};

const submit = (values) => {
  customErrors.value = {};

  if (!selectedCountry.value) {
    customErrors.value.billing_country = i18n.global.t("validation.required", {
      label: i18n.global.t("misc.country"),
    });
  }

  if (!selectedState.value) {
    customErrors.value.billing_state = i18n.global.t("validation.required", {
      label: i18n.global.t("misc.state"),
    });
  }

  if (!selectedCity.value) {
    customErrors.value.billing_city = i18n.global.t("validation.required", {
      label: i18n.global.t("misc.city"),
    });
  }

  if (Object.keys(customErrors.value).length) {
    return;
  }

  state.value = "loading";

  // remove empty values
  Object.keys(values).forEach((key) => {
    if (values[key] === "") {
      delete values[key];
    }
  });

  const data = {
    ...values,
    // remove mask from phone number
    billing_phone: values.billing_phone.replace(/\D/g, ""),
    billing_country: selectedCountry.value?.iso2,
    billing_state: selectedState.value?.iso2,
    billing_city: selectedCity.value?.id,
  };

  store.dispatch("account/update", data).then(() => {
    store.dispatch("account/me").then(() => {
      state.value = "success";
    });
    // setModalStatus("settings_billing_information", false);
  });
};

onMounted(() => {
  store.dispatch("location/countries");

  if (!me.value.billing.type) {
    selectedBillingType.value = billingTypes[0];
  } else {
    selectedBillingType.value = billingTypes.find(
      (type) => type.value === me.value.billing.type
    );
  }

  billingInformationForm.value.setValues({
    billing_type: selectedBillingType.value.value,
    billing_first_name: me.value.billing.first_name,
    billing_last_name: me.value.billing.last_name,
    identify_number: me.value.billing.identify_number,
    billing_phone: me.value.billing.phone,
    billing_neighborhood: me.value.billing.neighborhood,
    billing_address: me.value.billing.address_1,
    identify_number: me.value.identify_number,
    billing_tax_number: me.value.billing.tax_number,
    billing_tax_office: me.value.billing.tax_office,
    billing_company: me.value.billing.company,
  });
});
</script>
