import TeamService from "@/services/modules/TeamService";

export const team = {
  namespaced: true,
  state: {
    selectedOrderBy: "name",
    selectedOrder: "asc",
    selectedMembers: [],
    items: [],
    item: {},
  },
  actions: {
    items({ commit }) {
      return TeamService.list().then(
        (data) => {
          if (data.status) {
            commit("items", data.items);
          } else {
            commit("items", []);
          }
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    item({ commit }, id) {
      return TeamService.get(id).then(
        (data) => {
          commit("item", data.item);
          return Promise.resolve(data);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    create({ commit }, payload) {
      return TeamService.create(payload).then(
        (item) => {
          return Promise.resolve(item);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    update({ commit }, payload) {
      return TeamService.update(payload).then(
        (item) => {
          return Promise.resolve(item);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    delete({ commit }, id) {
      return TeamService.deleteItem(id).then(
        (item) => {
          return Promise.resolve(item);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    addMembers({ commit }, payload) {
      return TeamService.addMembers(payload).then(
        (item) => {
          return Promise.resolve(item);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    removeMembers({ commit }, payload) {
      return TeamService.removeMembers(payload).then(
        (item) => {
          return Promise.resolve(item);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    addMember({ commit }, member) {
      commit("addMember", member);
    },
    removeMember({ commit }, member) {
      commit("removeMember", member);
    },
    selectAllMembers({ commit }, members) {
      members.map((member) => {
        if (!this.getters.isSelectedMember(member)) {
          commit("addMember", member);
        }
      });
    },
    removeAllSelectedMembers({ commit }) {
      commit("removeAllSelectedMembers");
    },
  },
  mutations: {
    addMember(state, member) {
      state.selectedMembers.push(member);
    },
    removeMember(state, member) {
      state.selectedMembers = state.selectedMembers.filter(
        (m) => m.id !== member.id
      );
    },
    removeAllSelectedMembers(state) {
      state.selectedMembers = [];
    },
    setItems(state, items) {
      state.items = items;
    },
    setItem(state, item) {
      state.item = item;
    },
    items(state, items) {
      state.items = items;
    },
    item(state, item) {
      state.item = item;
    },
    setSelectedOrderBy(state, orderBy) {
      state.selectedOrderBy = orderBy;
    },
    setSelectedOrder(state, order) {
      state.selectedOrder = order;
    },
  },
  getters: {
    selectedMembers: (state) => {
      return state.selectedMembers;
    },
    isSelectedMember: (state) => (member) => {
      return state.selectedMembers.some((m) => m.id === member.id);
    },
    items: (state) => {
      return state.items;
    },
    item: (state) => {
      return state.item;
    },
    selectedOrderBy: (state) => {
      return state.selectedOrderBy;
    },
    selectedOrder: (state) => {
      return state.selectedOrder;
    },
  },
};
