import { axios } from "@/services/axios";
import AuthHeader from "../AuthHeader";

export const list = (payload) => {
  return axios
    .get("teams", {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
export const get = (id) => {
  return axios
    .get("teams/" + id, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const create = (payload) => {
  return axios
    .post("teams", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const update = (payload) => {
  return axios
    .put("teams/" + payload.id, payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const deleteItem = (id) => {
  return axios
    .delete("teams/" + id, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const removeMembers = (payload) => {
  return axios
    .post("teams/remove-members", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const addMembers = (payload) => {
  return axios
    .post("teams/add-members", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default {
  list,
  get,
  create,
  update,
  deleteItem,
  addMembers,
  removeMembers,
};
