<template>
  <div class="pairing-reminder is-show">
    <div class="pairing-reminder-block">
      <div class="pairing-reminder-block-head">
        <h4>
          {{ $t("settings.packages.pairing.title") }}
        </h4>
        <p>
          {{ $t("settings.packages.pairing.content") }}
        </p>
      </div>
      <div class="pairing-reminder-block-body">
        <template v-if="profiles">
          <div
            class="pairing-reminder-block-body-item"
            v-for="profile in profiles"
            :key="profile.id"
          >
            <div class="pairing-reminder-block-body-item-label">
              <span>{{ profile.title.rendered }}</span>
            </div>
            <div class="pairing-reminder-block-body-item-form">
              <div class="pairing-reminder-block-body-item-form-block">
                <div class="form-item h-40">
                  <input
                    :placeholder="profile.id"
                    :value="profile.id"
                    type="text"
                  />
                </div>
                <div class="pairing-reminder-block-body-item-form-block-button">
                  <button type="button" @click="copyId(profile.id)">
                    {{ $t("settings.packages.pairing.copy") }}
                  </button>
                </div>
              </div>
              <div
                class="pairing-reminder-block-body-item-form-message"
                v-if="copiedId == profile.id"
              >
                <span>{{ $t("settings.packages.pairing.copied") }}</span>
              </div>
            </div>
          </div>
        </template>
        <div class="pairing-reminder-block-body-button">
          <button type="button" @click="continueBuy">
            {{ $t("settings.packages.pairing.continue") }}
          </button>
        </div>
      </div>
      <div class="pairing-reminder-block-close">
        <button
          type="button"
          @click="setModalStatus('settings_package_pairing', false)"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import store from "@/store";
import { setModalStatus, getModalData } from "@/utils/Helpers";
import copy from "copy-to-clipboard";

const profiles = computed(() => store.getters["profiles/items"]);
const copiedId = ref(null);

const copyId = (id) => {
  copy(id);
  copiedId.value = id;
};

const continueBuy = () => {
  const data = getModalData("settings_package_pairing");
  const buy_page = data.buy_page;

  window.open(buy_page, "_blank");
};

onMounted(() => {
  store.dispatch("profiles/items");
});
</script>
