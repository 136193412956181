<template>
  <div class="login-left-side-form-line">
    <span>
      {{ t("auth.common.or") }}
    </span>
  </div>
  <div class="login-left-side-form-other-login">
    <GoogleLogin :callback="googleLoginCallback" v-if="!isLoading">
    </GoogleLogin>

    <a
      v-if="!isLoading"
      class="btn btn-login btn-xs btn-full btn-white btn-white-shadow btn-round-8 btn-500 btn-microsoft"
      href="javascript:;"
      @click="loginWithMicrosoft"
    >
      <img src="/img/static/icon-microsoft.svg" alt="Microsoft" />
      {{ t("auth.common.microsoft") }}
    </a>

    <a
      v-if="isLoading"
      class="btn btn-login btn-xs btn-full btn-white btn-white-shadow btn-round-8 btn-500 btn-loader"
    >
      <span></span>
      <span></span>
    </a>

    <a
      class="btn btn-login btn-xs btn-full btn-white btn-white-shadow btn-round-8 btn-500 btn-linkedin"
      v-if="!isLoading"
      @click="linkedInLogin"
    >
      <img src="/img/static/icon-linkedin-login.svg" alt="Microsoft" />
      Linkedin ile Kaydol
    </a>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { decodeCredential } from "vue3-google-login";
import { useI18n } from "vue-i18n";
import store from "@/store";
import { useRouter } from "vue-router";
import useAuth from "@/composables/auth";
import { useMsal } from "@/composables/msal/useMsal";
import { loginRequest } from "@/utils/msalAuth";
import { onMounted } from "@vue/runtime-core";
import { useLinkedIn, LinkedInCallback } from "vue3-linkedin-login";

const selectedLoginType = ref(null);
const isLoading = ref(false);
const { instance, accounts } = useMsal();
const { t } = useI18n();
const router = useRouter();
const { login, register } = useAuth({ router });
const { linkedInLogin, exchangeCodeForToken, getAccount, getMail } =
  useLinkedIn({
    clientId: process.env.VUE_APP_LINKEDIN_CLIENT_ID,
    clientSecret: process.env.VUE_APP_LINKEDIN_CLIENT_SECRET,
    redirectUri: process.env.VUE_APP_LINKEDIN_REDIRECT_URI,
    useProxy: true,
    proxyUrl: process.env.VUE_APP_API_DOMAIN + "/misc/proxy/?url=",
    exchangeCodeForTokenMethod: "GET",
    onSuccess: async (code) => {
      loginWithLinkedin(code);
    },
    scope: "r_emailaddress,r_liteprofile",
    onError: (error) => {
      isLoading.value = false;
    },
  });

const googleLoginCallback = (response) => {
  selectedLoginType.value = "google";
  isLoading.value = true;

  const credential = decodeCredential(response.credential);

  login({
    username: credential.email,
    password: credential.sub,
    login_type: "google",
  })
    .then((res) => {})
    .catch((err) => {
      const code = err.response.data.code;

      if (code === "auth_login_invalid_user") {
        register({
          first_name: credential.given_name,
          last_name: credential.family_name,
          email: credential.email,
          register_type: "google",
          oauth_id: credential.sub,
        })
          .then((res) => {
            login({
              username: credential.email,
              password: credential.sub,
              login_type: "google",
            });
          })
          .catch((err) => {
            isLoading.value = false;
            selectedLoginType.value = null;
          });
      } else {
        isLoading.value = false;
        selectedLoginType.value = null;
      }
    });
};

const loginWithMicrosoft = () => {
  instance
    .loginPopup(loginRequest)
    .then((response) => {
      isLoading.value = true;
      selectedLoginType.value = "microsoft";

      const account = accounts.value[0];
      const username = account.username.split("@")[0];

      login({
        username: account.username,
        password: account.tenantId,
        login_type: "microsoft",
      })
        .then((res) => {})
        .catch((err) => {
          const code = err.response.data.code;

          if (code === "auth_login_invalid_user") {
            register({
              first_name: username,
              // last_name: username,
              email: account.username,
              register_type: "microsoft",
              oauth_id: account.tenantId,
            })
              .then((res) => {
                login({
                  username: account.username,
                  password: account.tenantId,
                  login_type: "microsoft",
                });
              })
              .catch((err) => {
                isLoading.value = false;
                selectedLoginType.value = null;
              });
          }
        });
    })
    .catch((err) => {
      isLoading.value = false;
      selectedLoginType.value = null;
    });
};

const loginWithLinkedin = async (code) => {
  isLoading.value = true;

  const exchangeCode = await exchangeCodeForToken(code);
  const account = await getAccount(exchangeCode.access_token);
  const email = await getMail(exchangeCode.access_token);

  if (!account || !email) {
    isLoading.value = false;
    return;
  }

  const firstName = account.localizedFirstName;
  const lastName = account.localizedLastName;
  const emailAddress = email.elements[0]["handle~"].emailAddress;

  login({
    username: emailAddress,
    password: account.id,
    login_type: "linkedin",
  })
    .then((res) => {})
    .catch((err) => {
      const code = err.response.data.code;

      if (code === "auth_login_invalid_user") {
        register({
          first_name: firstName,
          last_name: lastName,
          email: emailAddress,
          register_type: "linkedin",
          oauth_id: account.id,
        })
          .then((res) => {
            login({
              username: emailAddress,
              password: account.id,
              login_type: "linkedin",
            });
          })
          .catch((err) => {
            isLoading.value = false;
            selectedLoginType.value = null;
          });
      } else {
        isLoading.value = false;
        selectedLoginType.value = null;
      }
    });
};

onMounted(() => {
  LinkedInCallback();

  const gBtn = document.querySelector(
    ".login-left-side-form-other-login .g-btn"
  );
  const btnMicrosoft = document.querySelector(
    ".login-left-side-form-other-login .btn-microsoft"
  );

  const btnLinkedin = document.querySelector(
    ".login-left-side-form-other-login .btn-linkedin"
  );

  setTimeout(() => {
    const gBtnWidth = gBtn.getBoundingClientRect().width;

    btnMicrosoft.style.width = gBtnWidth + "px";
    btnLinkedin.style.width = gBtnWidth + "px";
  }, 1000);
});
</script>
