<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>

  <MainLayout
    :title="$t('profile.create_page_title')"
    container-class="profile-creation"
    class="main-profile-creation"
  >
    <Page v-if="me.id" />
  </MainLayout>
</template>

<script setup>
import { computed } from "vue";
import MainLayout from "@/layouts/MainLayout.vue";
import Page from "@/components/Profile/Page";
import { useMeta } from "vue-meta";
import i18n from "@/utils/i18n.vue";
import store from "@/store";

const me = computed(() => store.getters["account/me"]);

useMeta({
  title: i18n.global.t("profile.create_page_title"),
});
</script>
