<template>
  <div class="network-pool-search" v-if="networks">
    <input
      type="text"
      :placeholder="$t('network.search.placeholder')"
      class="network-pool-search-input"
      @input="search"
    />
    <button type="button" class="network-pool-search-button">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.9999 21.0004L16.6499 16.6504"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import store from "@/store";

let networksSalt = [];
const networks = computed(() => {
  const networks = store.getters["network/items"];
  if (networks?.length && !networksSalt.length) {
    networksSalt = networks;
    return networks;
  }

  return networks;
});

const search = (e) => {
  const value = e.target.value;
  const filtered = networksSalt.filter((network) => {
    const name = network.full_name.toLowerCase();
    const company_name = network.company_name.toLowerCase();
    const created_at = network.created_at.toLowerCase();
    const id = network.id.toString();
    const email = network.email.toLowerCase();
    const title = network.title.toLowerCase();

    return (
      name.includes(value.toLowerCase()) ||
      company_name.includes(value.toLowerCase()) ||
      created_at.includes(value.toLowerCase()) ||
      id.includes(value.toLowerCase()) ||
      email.includes(value.toLowerCase()) ||
      title.includes(value.toLowerCase())
    );
  });

  store.commit("network/setItems", filtered);
};
</script>
