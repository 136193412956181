import { axios } from "@/services/axios";
import AuthHeader from "../AuthHeader";

export const invite = (payload) => {
  return axios
    .post("workspace/invite", payload, {
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default {
  invite,
};
