import { axios } from "@/services/axios";
import AuthHeader from "../AuthHeader";

export const check = (token) => {
  return axios
    .get("invitation/check", {
      params: {
        token: token,
      },
      headers: AuthHeader(),
    })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default {
  check,
};
