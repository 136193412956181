<template>
  <div class="profile-creation-body-content-item-telephone">
    <FieldArray :name="name" v-slot="{ fields, push, remove }">
      <div
          class="profile-creation-body-content-item-telephone-block"
          v-for="(field, idx) in fields"
          :key="idx"
      >
        <div class="form-item-phone">
          <div class="form-item h-40">
            <VField :name="`${name}[${idx}].phone_type`" v-slot="{ field }">
              <v-select
                  :options="phoneTypes"
                  class="default-select"
                  v-model="field.value"
                  v-bind="field"
              >
                <template #no-options>
                  <div class="">
                    {{ $t("misc.no_results") }}
                  </div>
                </template>
              </v-select>
            </VField>
          </div>
          <Field
              class="phone"
              :name="`${name}[${idx}].phone_number`"
              :errors="props.errors"
              :key="idx"
              :placeholder="$t('profile.placeholders.phone')"
          />
          <a
              class="form-item-action icon-trash-gray"
              href="javascript:;"
              v-if="idx !== 0"
              @click="remove(idx)"
          ></a>
        </div>
        <div class="add-phone-button" @click="addPhoneNumber(push)">
          <button type="button">
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M8 1V15"
                  stroke="#979797"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
              />
              <path
                  d="M1 8H15"
                  stroke="#979797"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </FieldArray>
  </div>
  <PhoneLimitModal v-if="getModalStatus('profile_phone_premium')"/>
</template>

<script setup>
import {ref, onMounted, onUnmounted, computed, defineProps, reactive} from "vue";
import {useI18n} from "vue-i18n";
import store from "@/store";
import {MembershipLevelEnum} from "@/enums";
import {FieldArray, useFieldArray, Field as VField} from "vee-validate";
import Field from "@/components/inputs/Field";
import PhoneLimitModal from "@/components/Profile/Modals/PhoneLimit";
import {getModalStatus, setModalStatus} from "@/utils/Helpers";
import mask from "@/utils/phone-masks";


const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  errors: {
    type: Object,
    required: true,
  },
  addDefault: {
    type: Boolean,
    default: true,
    required: false,
  },
});

const {t} = useI18n();
const {remove, push, fields} = useFieldArray(props.name);

const phoneRegExp =
    /^(\+90|0)?\s*\(?([0-9]{3})\)?\s*([0-9]{3})\s*([0-9]{2})\s*([0-9]{2})$/;
const phoneTypes = ref([]);
const phoneNumbers = ref([]);
const accountCan = computed(() => store.getters["account/can"]);


onMounted(() => {
  phoneTypes.value = store.getters["defines/get_phone_types"];
  if (props.addDefault) {
    push({
      phone_type: phoneTypes.value[0],
      phone_number: "",
    });
  }

  setTimeout(() => {
    mask(".phone-input");
  }, 1000);
});

const addPhoneNumber = (push) => {
  if (fields.value.length === accountCan.value.populate_phones_field.limit) {
    setModalStatus("profile_phone_premium", true);
    return;
  }

  push({
    phone_type: phoneTypes.value[0],
    phone_number: "",
  });

  setTimeout(() => {
    mask(".phone-input");
  }, 100);
};
</script>
