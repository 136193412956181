<template>
  <div class="business-card-view-content-sharing is-show">
    <Form :validation-schema="schema" v-slot="{ errors, meta }" @submit="share">
      <div class="business-card-view-content-sharing-block">
        <Loading v-if="isLoading" />
        <template v-else>
          <div class="business-card-view-content-sharing-block-head">
            <span>
              {{ $t("public_profile.share_modal.title") }}
            </span>

            <p>
              {{
                $t("public_profile.share_modal.info", {
                  name: data.display_name,
                })
              }}
            </p>
          </div>
          <div class="business-card-view-content-sharing-block-content">
            <div
                class="business-card-view-content-sharing-block-content-item is-check"
            >
              <Field
                  :errors="errors"
                  name="full_name"
                  :placeholder="$t('misc.full_name')"
              />
            </div>
            <div
                class="business-card-view-content-sharing-block-content-item is-check"
            >
              <Field
                  :errors="errors"
                  name="email"
                  :placeholder="$t('misc.email')"
              />
            </div>
            <div
                class="business-card-view-content-sharing-block-content-item is-check"
            >
              <Field
                  class="phone"
                  :errors="errors"
                  name="phone"
                  :placeholder="$t('misc.phone')"
              />
            </div>
            <div
                :class="[
                'business-card-view-content-sharing-block-content-item',
                { 'is-check': isShowingOtherFields },
              ]"
            >
              <Field
                  :errors="errors"
                  name="title"
                  :placeholder="$t('misc.title')"
              />
            </div>
            <div
                :class="[
                'business-card-view-content-sharing-block-content-item',
                { 'is-check': isShowingOtherFields },
              ]"
            >
              <Field
                  :errors="errors"
                  name="company"
                  :placeholder="$t('misc.company')"
              />
            </div>
            <div
                :class="[
                'business-card-view-content-sharing-block-content-item',
                { 'is-check': isShowingOtherFields },
              ]"
            >
              <VField v-slot="{ field }" name="message">
                <div class="form-item h-40">
                  <textarea :placeholder="$t('misc.message')" v-bind="field" />
                </div>
              </VField>
            </div>
            <div
                class="business-card-view-content-sharing-block-content-item is-add-more"
            >
              <a
                  class="btn btn-text btn-sm btn-primary-blue btn-500"
                  href="javascript:;"
                  @click="isShowingOtherFields = true"
                  v-if="!isShowingOtherFields"
              >
                + {{ $t("public_profile.share_modal.add_more") }}
              </a>
            </div>
          </div>
          <div class="business-card-view-content-sharing-block-button">
            <button
                class="btn btn-sm btn-white btn-round-4 btn-400"
                @click="skip"
            >
              {{ $t("misc.skip") }}
            </button>
            <Button
                type="submit"
                :class="[
                'btn btn-sm btn-secondary-yellow btn-round-4 btn-400',
                { 'is-active': meta.valid },
              ]"
                :state="state"
            >
              {{ $t("misc.share") }}
            </Button>
          </div>
          <div class="business-card-view-content-sharing-block-description">
            <p>
              <i18n-t keypath="public_profile.share_modal.terms_text" tag="p">
                <template v-slot:text_1>
                  {{ $t("public_profile.share_modal.terms_text_1") }}
                </template>
                <template v-slot:text_2>
                  <a :href="options.terms.membership.url" target="_blank">
                    {{ $t("public_profile.share_modal.terms_text_2") }}
                  </a>
                </template>
                <template v-slot:text_3>
                  <a :href="options.terms.cookie.url" target="_blank">
                    {{ $t("public_profile.share_modal.terms_text_3") }}
                  </a>
                </template>
                <template v-slot:text_4>
                  <a
                      :href="options.terms.express_written_consent.url"
                      target="_blank"
                  >
                    {{ $t("public_profile.share_modal.terms_text_4") }}
                  </a>
                </template>
                <template v-slot:text_5>
                  <a :href="options.terms.personal_data.url" target="_blank">
                    {{ $t("public_profile.share_modal.terms_text_5") }}
                  </a>
                </template>
              </i18n-t>
            </p>
          </div>
        </template>
        <div class="business-card-view-content-sharing-block-close">
          <button type="button" @click="skip">
            <img src="/img/static/icon-x.svg" alt="" />
          </button>
        </div>
      </div>
    </Form>
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from "vue";
import { setModalStatus, getModalData } from "@/utils/Helpers";
import store from "@/store";
import { Form, Field as VField } from "vee-validate";
import * as yup from "yup";
import Field from "@/components/inputs/Field.vue";
import Button from "@/components/inputs/Button.vue";
import i18n from "@/utils/i18n.vue";
import Loading from "@/components/Common/Loading.vue";
import mask from "@/utils/phone-masks";

const isLoading = ref(true);
const phoneRegExp =
    /^(\+90|0)?\s*\(?([0-9]{3})\)?\s*([0-9]{3})\s*([0-9]{2})\s*([0-9]{2})$/;

const options = computed(() => store.getters["misc/options"]);
const isShowingOtherFields = ref(false);
const location = ref("");
const state = ref("default");
const data = getModalData("public_profile_share");
const schema = yup.object({
  full_name: yup.string().required().label(i18n.global.t("misc.full_name")),
  email: yup.string().email().label(i18n.global.t("misc.email")),
  phone: yup
      .string()
      // .matches(phoneRegExp, {
      //   excludeEmptyString: true,
      //   message: i18n.global.t("validation.phone"),
      // })
      .label(i18n.global.t("misc.phone")),
});

const skip = () => {
  setModalStatus("public_profile_share", false);
  // setModalStatus("public_profile_register", true, data);
};

const share = (values) => {
  state.value = "loading";
  store
      .dispatch("misc/shareInfo", {
        ...values,
        profile_id: data.item.id,
        phone: values.phone || "",
        // phone: values.phone ? values.phone.replace(/\D/g, "") : "",
        location: location.value,
      })
      .then(() => {
        state.value = "success";
        setTimeout(() => {
          skip();
        }, 1000);
      })
      .catch(() => {
        state.value = "default";
      });
};

onMounted(() => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
        (position) => {
          store
              .dispatch("misc/getLocationDetailFromGMap", {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              })
              .then((res) => {
                isLoading.value = false;

                location.value = res.plus_code.compound_code.replace(/^\S* /, "");
              });
        },
        (error) => {
          isLoading.value = false;
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
    );
  } else {
    isLoading.value = false;
  }

  setTimeout(() => {
    mask(".phone-input");
  }, 1000);

});
</script>
<style scoped>
.business-card-view-content-sharing-block-head span {
  font-weight: 500;
  color: #167ffc;
  font-size: 18px;
}
</style>
