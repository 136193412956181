import store from "@/store";
import { getWorkspaceSlug } from "../utils/Helpers";

const redirectName = (route) => {
  switch (route.name) {
    case "profile":
      return "profile-view";
    case "email-main":
      return "email-view";
    case "bg-main":
      return "bg-view";
    case "stats-main":
      return "stats-view";
    default:
      return "profile-view";
  }
};

export default function profileRedirect(to, from, next) {
  const me = store.getters["account/me"];
  const profiles = store.getters["profiles/items"];

  if (!me.profile_count) {
    next({
      name: "profile-create",
      params: { workspace: to.params.workspace },
    });
  }

  if (profiles && profiles.length) {
    const firstProfile = profiles[0];
    let id = firstProfile.id;

    if (from?.params?.id) {
      id = from.params.id;
    }

    next({
      name: redirectName(to),
      params: { workspace: to.params.workspace, id },
    });
  } else {
    store.dispatch("profiles/items").then((response) => {
      if (response.length) {
        const firstProfile = response[0];
        next({
          name: redirectName(to),
          params: { workspace: to.params.workspace, id: firstProfile.id },
        });
      } else {
        next({
          name: "profile-create",
          params: { workspace: to.params.workspace },
        });
      }
    });
  }
}
