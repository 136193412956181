<template>
  <div class="choose-to-stay-in-touch-block-body-item">
    <div class="choose-to-stay-in-touch-block-body-item-icon">
      <i>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.8 10.75H18L10.2 22V13.2538L5 13.25L12.8 2V10.75Z"
            stroke="#167FFC"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      </i>
    </div>
    <span>Her Ayın</span>
    <div :class="['choose-to-stay-in-touch-block-body-item-content']">
      <button
        type="button"
        class="choose-to-stay-in-touch-block-body-item-content-text"
        @click="isDaysDropdownOpen = !isDaysDropdownOpen"
      >
        {{ selectedDayNumber?.label }}
        <i>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.12016 9.29202L12.0002 13.172L15.8802 9.29202C15.9727 9.19944 16.0827 9.126 16.2036 9.07589C16.3246 9.02579 16.4542 9 16.5852 9C16.7161 9 16.8457 9.02579 16.9667 9.07589C17.0877 9.126 17.1976 9.19944 17.2902 9.29202C17.3827 9.3846 17.4562 9.49451 17.5063 9.61548C17.5564 9.73644 17.5822 9.86609 17.5822 9.99702C17.5822 10.128 17.5564 10.2576 17.5063 10.3786C17.4562 10.4995 17.3827 10.6094 17.2902 10.702L12.7002 15.292C12.6076 15.3847 12.4978 15.4583 12.3768 15.5085C12.2558 15.5586 12.1261 15.5845 11.9952 15.5845C11.8642 15.5845 11.7345 15.5586 11.6135 15.5085C11.4926 15.4583 11.3827 15.3847 11.2902 15.292L6.70016 10.702C6.60746 10.6095 6.53391 10.4996 6.48373 10.3786C6.43354 10.2577 6.40771 10.128 6.40771 9.99702C6.40771 9.86605 6.43354 9.73637 6.48373 9.6154C6.53391 9.49442 6.60746 9.38453 6.70016 9.29202C7.09016 8.91202 7.73016 8.90202 8.12016 9.29202Z"
              fill="#707070"
            ></path>
          </svg>
        </i>
      </button>
      <div
        :class="[
          'choose-to-stay-in-touch-block-body-item-content-dropdown',
          {
            'is-active': isDaysDropdownOpen,
          },
        ]"
        v-if="days"
      >
        <button
          type="button"
          v-for="day in days"
          :key="day.key"
          @click="choseDay(day)"
        >
          {{ day.label }}
        </button>
      </div>
    </div>
    <span>Günü</span>
  </div>

  <div class="choose-to-stay-in-touch-block-body-item">
    <span>Saat</span>
    <div class="choose-to-stay-in-touch-block-body-item-content">
      <button
        type="button"
        class="choose-to-stay-in-touch-block-body-item-content-text"
        @click="isTimeDropdownOpen = !isTimeDropdownOpen"
      >
        {{ selectedTime?.label }}
        <i>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.12016 9.29202L12.0002 13.172L15.8802 9.29202C15.9727 9.19944 16.0827 9.126 16.2036 9.07589C16.3246 9.02579 16.4542 9 16.5852 9C16.7161 9 16.8457 9.02579 16.9667 9.07589C17.0877 9.126 17.1976 9.19944 17.2902 9.29202C17.3827 9.3846 17.4562 9.49451 17.5063 9.61548C17.5564 9.73644 17.5822 9.86609 17.5822 9.99702C17.5822 10.128 17.5564 10.2576 17.5063 10.3786C17.4562 10.4995 17.3827 10.6094 17.2902 10.702L12.7002 15.292C12.6076 15.3847 12.4978 15.4583 12.3768 15.5085C12.2558 15.5586 12.1261 15.5845 11.9952 15.5845C11.8642 15.5845 11.7345 15.5586 11.6135 15.5085C11.4926 15.4583 11.3827 15.3847 11.2902 15.292L6.70016 10.702C6.60746 10.6095 6.53391 10.4996 6.48373 10.3786C6.43354 10.2577 6.40771 10.128 6.40771 9.99702C6.40771 9.86605 6.43354 9.73637 6.48373 9.6154C6.53391 9.49442 6.60746 9.38453 6.70016 9.29202C7.09016 8.91202 7.73016 8.90202 8.12016 9.29202Z"
              fill="#707070"
            ></path>
          </svg>
        </i>
      </button>
      <div
        :class="[
          'choose-to-stay-in-touch-block-body-item-content-dropdown',
          {
            'is-active': isTimeDropdownOpen,
          },
        ]"
        v-if="times"
      >
        <button
          type="button"
          v-for="time in times"
          :key="time.key"
          @click="choseTime(time)"
        >
          {{ time.label }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import * as dayjs from "dayjs";
import i18n from "@/utils/i18n.vue";
import store from "@/store";

const isDaysDropdownOpen = ref(false);
const isTimeDropdownOpen = ref(false);
const selectedDayNumber = ref(null);
const selectedTime = ref(null);

const times = ref([]);
const days = ref([]);

onMounted(() => {
  const now = dayjs();
  for (let i = 0; i < 24; i++) {
    const time = now.hour(i).minute(0).second(0);
    times.value.push({
      key: time.format("HH:mm"),
      label: time.format("HH:mm"),
    });
  }

  for (let i = 1; i <= 30; i++) {
    days.value.push({
      key: i,
      label: i,
    });
  }

  selectedDayNumber.value = days.value[0];
  selectedTime.value = times.value[0];

  store.commit("network/setStayTouch", {
    type: "monthly",
    day: days.value[0].key,
    time: times.value[0].key,
  });
});

const choseDay = (day) => {
  selectedDayNumber.value = day;
  isDaysDropdownOpen.value = false;

  store.commit("network/setStayTouch", {
    ...store.state.network.stayTouch,
    day: day.key,
  });
};

const choseTime = (time) => {
  selectedTime.value = time;
  isTimeDropdownOpen.value = false;

  store.commit("network/setStayTouch", {
    ...store.state.network.stayTouch,
    time: time.key,
  });
};
</script>
