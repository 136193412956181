<template>
  <div class="toast-block">
    <b-container
      :toast="{ root: true }"
      fluid="sm"
      position="position-fixed"
    ></b-container>
  </div>
  <div class="virtual-background-block-upload is-show">
    <div class="virtual-background-block-upload-block">
      <div class="virtual-background-block-upload-block-image">
        <img
          id="virtual-upload-image"
          ref="virtualPreviewImage"
          :src="selectedImageSrc"
          alt=""
        />
      </div>
      <div class="virtual-background-block-upload-block-content">
        <cropper-zoom
          v-if="cropper"
          :cropper="cropper"
          :image-container="virtualPreviewImage"
        />
        <div class="virtual-background-block-upload-block-content-description">
          <p>
            {{ $t("bg.upload.description") }}
          </p>
        </div>
        <div class="virtual-background-block-upload-block-content-button">
          <button
            type="button"
            class="btn btn-sm btn-white btn-round-4 btn-400"
            href="javascript:;"
            @click="setModalStatus('bg_upload', false)"
          >
            {{ $t("misc.cancel") }}
          </button>
          <Button
            type="button"
            class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
            href="javascript:;"
            :state="state"
            @click="upload"
          >
            {{ $t("bg.upload.button_label") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, onMounted, ref } from "vue";
import { setModalStatus, getModalData, getErrorMessage } from "@/utils/Helpers";
import Button from "@/components/inputs/Button.vue";
import Cropper from "cropperjs";
import store from "@/store";
import { useToast } from "bootstrap-vue-3";
import { useI18n } from "vue-i18n";
import cropperZoom from "@/components/Common/CropperZoom.vue";
import "cropperjs/dist/cropper.css";

const emit = defineEmits(["uploadedFile"]);
const toast = useToast();
const virtualPreviewImage = ref(null);
const selectedImage = getModalData("bg_upload");
const selectedImageSrc = URL.createObjectURL(selectedImage);
const cropper = ref(null);
const state = ref("default");
const { t } = useI18n();

const upload = () => {
  state.value = "loading";
  const canvas = cropper.value.getCroppedCanvas();
  const image = canvas.toDataURL("image/png");
  const formData = new FormData();
  canvas.toBlob(function (blob) {
    formData.append("file", blob, "bg.png");
    formData.append("type", "virtual-background");
    
    store
      .dispatch("background/upload", formData)
      .then(() => {
        state.value = "success";
        store.dispatch("background/list").then((response) => {
          emit("uploadedFile", image);
        });
      })
      .catch((err) => {
        state.value = "default";

        toast.show({
          title: t("misc.error_occurred"),
          body: getErrorMessage(err),
          autoHideDelay: 5000,
        });
      });
  });
};

onMounted(() => {
  cropper.value = new Cropper(virtualPreviewImage.value, {
    cropBoxMovable: false,
    cropBoxResizable: false,
    dragMode: "move",
    viewMode: 1,
    aspectRatio: 16 / 9,
  });
});
</script>
