import i18n from "@/utils/i18n";

export const email = {
  namespaced: true,
  state: {
    viewModes: [
      {
        id: "full",
        label: i18n.global.t("email_signature.photo_logo"),
      },
      {
        id: "logo",
        label: i18n.global.t("email_signature.logo"),
      },
      {
        id: "text",
        label: i18n.global.t("email_signature.text"),
      },
    ],
    services: [
      {
        name: "HubSpot",
        id: "hubspot",
        color: "btn-soft-red",
        colorClass: "is-orange",
        title: i18n.global.t("email_signature.hubspot.title"),
        buttonLabel: i18n.global.t("email_signature.hubspot.button_label"),
        description: i18n.global.t("email_signature.hubspot.description"),
        url: "https://app.hubspot.com/email/0/contacts/0/emails",
      },
      {
        name: "Gmail",
        id: "google",
        color: "btn-soft-green",
        colorClass: "is-green",
        title: i18n.global.t("email_signature.gmail.title"),
        buttonLabel: i18n.global.t("email_signature.gmail.button_label"),
        description: i18n.global.t("email_signature.gmail.description"),
        url: "https://mail.google.com/mail/u/0/#settings/general",
      },
      {
        name: "Outlook",
        id: "outlook",
        color: "btn-soft-blue",
        colorClass: "is-blue",
        title: i18n.global.t("email_signature.outlook.title"),
        buttonLabel: i18n.global.t("email_signature.outlook.button_label"),
        description: i18n.global.t("email_signature.outlook.description"),
        url: "https://outlook.live.com/mail/options/general/signature",
      },
      {
        name: "Apple Mail",
        id: "apple",
        color: "btn-soft-grey",
        colorClass: "is-gray",
        title: i18n.global.t("email_signature.apple.title"),
        buttonLabel: i18n.global.t("email_signature.apple.button_label"),
        description: i18n.global.t("email_signature.apple.description"),
        url: "https://support.apple.com/tr-tr/guide/mail/trml1001/mac",
      },
      {
        name: "Yahoo Mail",
        id: "yahoo",
        color: "btn-soft-purple",
        colorClass: "is-purple",
        title: i18n.global.t("email_signature.yahoo.title"),
        buttonLabel: i18n.global.t("email_signature.yahoo.button_label"),
        description: i18n.global.t("email_signature.yahoo.description"),
        url: "https://help.yahoo.com/kb/SLN27791.html",
      },
    ],
    viewMode: "full",
    layoutMode: "horizontal",
    image: {},
    banner: {},
    text: "",
    generatedImageURL: "",
    willCopySelector: "email-signature-mail-template",
  },
  mutations: {
    setViewMode(state, data) {
      state.viewMode = data;
    },
    setLayoutMode(state, data) {
      state.image.layout_mode = data;
      state.layoutMode = data;
    },
    setImage(state, data) {
      state.image = data;
    },
    setBanner(state, data) {
      state.banner = data;
    },
    setText(state, data) {
      state.text = data;
    },
    setGeneratedImageURL(state, data) {
      state.generatedImageURL = data;
    },
    setWillCopySelector(state, data) {
      state.willCopySelector = data;
    },
  },
  getters: {
    viewModes: (state) => {
      return state.viewModes;
    },
    viewMode: (state) => {
      return state.viewMode;
    },
    layoutMode: (state) => {
      return state.layoutMode;
    },
    image: (state) => {
      return state.image;
    },
    banner: (state) => {
      return state.banner;
    },
    text: (state) => {
      return state.text;
    },
    services: (state) => {
      return state.services;
    },
    generatedImageURL: (state) => {
      return state.generatedImageURL;
    },
    willCopySelector: (state) => {
      return state.willCopySelector;
    },
  },
};
