<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>

  <SecondLayout>
    <section class="reset-password">
      <div class="reset-password-contain">
        <div class="reset-password-main">
          <div class="reset-password-prev">
            <router-link
              to="/login"
              class="btn btn-text btn-sm btn-primary-blue btn-500"
            >
              <i data-position="left">
                <svg
                  width="7"
                  height="15"
                  viewBox="0 0 7 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.83749 14.5139C5.68809 14.5144 5.54048 14.4814 5.4055 14.4172C5.27052 14.3531 5.15161 14.2595 5.05749 14.1432L0.227488 8.13171C0.0804062 7.95244 0 7.72756 0 7.4955C0 7.26343 0.0804062 7.03856 0.227488 6.85928L5.22749 0.847784C5.39723 0.643176 5.64114 0.514506 5.90556 0.490079C6.16999 0.465653 6.43327 0.547471 6.63749 0.717535C6.8417 0.887599 6.97013 1.13198 6.99451 1.39691C7.01889 1.66184 6.93723 1.92563 6.76749 2.13024L2.29749 7.50051L6.61749 12.8708C6.73977 13.0178 6.81745 13.1969 6.84133 13.3868C6.86521 13.5767 6.83429 13.7695 6.75223 13.9424C6.67018 14.1153 6.54042 14.2609 6.37831 14.3622C6.2162 14.4635 6.02852 14.5162 5.83749 14.5139Z"
                    fill="#167FFC"
                  />
                </svg>
              </i>
              {{ $t("misc.back") }}
            </router-link>
          </div>
          <div class="reset-password-icon">
            <img src="/img/static/icon-unlock.svg" alt="" />
          </div>
          <div class="reset-password-title">
            <h4>
              {{ $t("auth.set_email.title") }}
            </h4>
          </div>
          <div class="reset-password-text">
            <p>
              {{ $t("auth.set_email.text_1") }}
              <br />
              <br />
              {{ $t("auth.set_email.text_2") }}
            </p>
          </div>
          <div class="reset-password-form reset-password-form-group">
            <Form
              @submit="submit"
              :validation-schema="schema"
              v-slot="{ errors }"
            >
              <Field
                name="first_name"
                :errors="errors"
                :placeholder="$t('misc.first_name')"
              />
              <Field
                name="last_name"
                :errors="errors"
                :placeholder="$t('misc.last_name')"
              />
              <Field
                name="email"
                :errors="errors"
                :placeholder="$t('auth.common.email')"
              />

              <div class="header-second-version-contain-language form-item h-40">
                <v-select
                    v-model="selectedLanguage"
                    :options="languages.data"
                    :placeholder="$t('settings.preferences.language')"
                    :label="$t('settings.preferences.language')"
                    :get-option-label="(option) => option.name"
                    :get-option-value="(option) => option.locale"
                    @input="change"
                >
                  <template #no-options>
                    <div class="">
                      {{ $t("misc.no_results") }}
                    </div>
                  </template>
                </v-select>
              </div>
              <div class="reset-password-form-send-btn">
                <Button
                  :state="state"
                  class="btn btn-md btn-full btn-primary-blue btn-round-8 btn-500"
                >
                  {{ $t("auth.set_email.button_label") }}
                </Button>
              </div>
              <div class="reset-password-form-error-text">
                <p>
                  {{ errorMessage }}
                </p>
              </div>
              <div class="reset-password-form-line">
                <span>{{ $t("auth.common.or") }}</span>
              </div>
              <div class="reset-password-form-footer">
                <router-link to="/sign-up">
                  {{ $t("auth.common.create_account") }}
                </router-link>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </section>
  </SecondLayout>
</template>

<script setup>
import { computed, ref } from "vue";
import { useMeta } from "vue-meta";
import i18n from "@/utils/i18n.vue";
import store from "@/store";
import { useRouter } from "vue-router";
import { Form, Field as VField } from "vee-validate";
import * as yup from "yup";
import Field from "@/components/inputs/Field";
import Button from "@/components/inputs/Button";
import { getErrorMessage } from "@/utils/Helpers";
// Components
import SecondLayout from "@/layouts/SecondLayout.vue";

//meta
useMeta({
  title: i18n.global.t("auth.set_email.page_title"),
});

const schema = yup.object({
  first_name: yup.string().required().label(i18n.global.t("misc.first_name")),
  last_name: yup.string().required().label(i18n.global.t("misc.last_name")),
  email: yup
    .string()
    .required()
    .email()
    .label(i18n.global.t("auth.common.email")),
});

const view = ref("form");
const email = ref("");
const state = ref("default");
const errorMessage = ref("");
const router = useRouter();

const selectedLanguage = ref(null);
const languages = computed(() => {
  const languages = store.getters["misc/languages"]

  if (languages.data?.length > 0) {
    selectedLanguage.value = languages.data[0]
  }

  return languages
});


const submit = (values) => {
  const { first_name, last_name, email } = values;

  state.value = "loading";
  errorMessage.value = "";
  store
    .dispatch("account/setAccount", {
      first_name,
      last_name,
      email,
      language: selectedLanguage.value.locale,
    })
    .then((response) => {
      state.value = "success";
      router.push({
        name: "onboarding",
      });
    })
    .catch((error) => {
      errorMessage.value = getErrorMessage(error);
      state.value = "default";
    });
};
</script>
