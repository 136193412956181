<template>
  <div class="reset-password-icon">
    <img src="/img/static/icon-check-primary.svg" alt="" />
  </div>
  <div class="reset-password-title">
    <h4>{{ $t("auth.forgot.sent") }}</h4>
  </div>
  <div class="reset-password-text">
    <p>
      {{
        $t("auth.forgot.sent_text", {
          email: props.email,
        })
      }}
    </p>
  </div>
  <div class="reset-password-comment">
    <p>Şifre yenileme linkin eline ulaşmadıysa:</p>
    <ul>
      <li>Spam klasörünü kontrol edebilirsin.</li>
      <li>Girdiğin e-posta adresini kontrol edebilirsin.</li>
      <li>Eğer hala e-postan gelmediyse tekrar göndermelisin.</li>
    </ul>
  </div>
  <div class="reset-password-form">
    <div class="reset-password-form-again-btn">
      <button
        type="submit"
        :class="[
          'btn btn-md btn-full btn-round-8 btn-500',
          isCounting ? 'btn-disabled' : 'btn-secondary-purple',
        ]"
        :disabled="isCounting"
        @click.prevent="resend"
      >
        <vue-countdown
          v-if="isCounting"
          :time="5000"
          @end="onCountdownEnd"
          v-slot="{ minutes, seconds }"
        >
          {{ $t("auth.forgot.resend") }} ({{ minutes }}:{{ seconds }})
        </vue-countdown>
        <span v-else>
          {{ $t("auth.forgot.resend") }}
        </span>
      </button>
    </div>
    <div class="reset-password-form-send-btn">
      <router-link
        to="/login"
        class="btn btn-md btn-full btn-secondary-yellow btn-round-8 btn-500"
        tag="button"
      >
        {{ $t("auth.login.button_label") }}
      </router-link>
    </div>
    <div class="reset-password-form-line">
      <span>{{ $t("auth.common.or") }}</span>
    </div>
    <div class="reset-password-form-footer">
      <router-link to="/sign-up">
        {{ $t("auth.common.create_account") }}
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from "vue";
import store from "@/store";
import { useRouter, useRoute } from "vue-router";

const props = defineProps({
  email: {
    type: String,
    required: true,
  },
});

const router = useRouter();
const isCounting = ref(true);
const state = ref("default");

const onCountdownEnd = () => {
  isCounting.value = false;
};

const resend = () => {
  store.dispatch("auth/resetPassword", { email: props.email }).then(
    () => {
      isCounting.value = true;
    },
    (error) => {
      state.value = "default";
      router.push({
        name: "ForgotError",
      });
    }
  );
};
</script>