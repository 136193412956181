<template>
  <div class="location-chart">
    <Loading type="skeleton" v-if="isLoading" />
    <div class="location-chart-head">
      <div class="location-chart-head-title">
        <h3>{{ $t("stats.locations.title") }}</h3>
      </div>
    </div>

    <div class="location-chart-body" v-if="stats">
      <div
        :class="[
          'location-chart-body-block',
          { 'no-data': !chartDataValues.length },
        ]"
      >
        <Bar :chart-data="chartData" :chart-options="chartOptions" />
      </div>
      <div class="location-chart-body-content" v-if="chartDataValues.length">
        <div class="location-chart-body-content-block">
          <div class="location-chart-body-content-block-head">
            <div class="location-chart-body-content-block-head-label">
              <h5>{{ $t("stats.locations.location") }}</h5>
            </div>
            <div
              class="location-chart-body-content-block-head-item"
              v-for="(locationLabel, index) in chartDataLabels.slice(0, 4)"
              :key="index"
            >
              <span>{{ locationLabel }}</span>
            </div>
          </div>
          <div class="location-chart-body-content-block-head">
            <div class="location-chart-body-content-block-head-label">
              <h5>{{ $t("stats.locations.value") }}</h5>
            </div>
            <div
              class="location-chart-body-content-block-head-item"
              v-for="(locationValue, index) in chartDataValues.slice(0, 4)"
              :key="index"
            >
              <span>{{ locationValue }}</span>
            </div>
          </div>
        </div>
        <div
          class="location-chart-body-content-button"
          v-if="chartDataLabels.length > 4"
        >
          <button
            type="button"
            @click="setModalStatus('stats_locations', true)"
          >
            {{ $t("stats.locations.view_more") }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <LocationsModal
    v-if="getModalStatus('stats_locations')"
    :items="{ labels: chartDataLabels, values: chartDataValues }"
  />
</template>

<script setup>
import { computed, ref } from "vue";
import { Bar } from "vue-chartjs";
import { setModalStatus, getModalStatus } from "@/utils/Helpers";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Plugin,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";
import LocationsModal from "@/components/Stats/Modals/Locations.vue";
import store from "@/store";
import Loading from "../../Common/Loading.vue";
import i18n from "@/utils/i18n.vue";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  TimeScale
);

const isLoading = computed(() => store.getters["stats/isLoading"]);
const chartDataLabels = ref([]);
const chartDataValues = ref([]);
const chartDataValuesMutation = ref([]);
const chartData = ref({
  labels: [],
  datasets: [
    {
      data: [],
      borderColor: "#167FFC",
      backgroundColor: "#167FFC",
      borderWidth: 2,
      borderRadius: 8,
      borderSkipped: "bottom",
      color: "red",
    },
  ],
});

const stats = computed(() => {
  chartDataValuesMutation.value = [];

  const stats = store.getters["stats/statsFiltered"];
  if (stats[0].data?.cities) {
    const cities = stats[0].data.cities;
    const labels = Object.keys(cities);
    const values = Object.values(cities);

    chartDataLabels.value = labels;
    chartDataValues.value = values;

    chartData.value.labels = [];

    labels.map((label, index) => {
      if (index <= 3) {
        chartData.value.labels.push(label);
      }
    });

    chartData.value.datasets[0].data = values;

    for (let index = 0; index < chartDataValues.value.length; index++) {
      let mutationDataValue = chartDataValues.value[index];
      if (mutationDataValue >= 100) {
        mutationDataValue = "99+";
      }

      chartDataValuesMutation.value.push(mutationDataValue);
    }
  }

  return stats;
});

const chartOptions = {
  maintainAspectRatio: false,
  events: [],
  responsive: true,
  barThickness: "34",
  animation: {
    duration: 1,
    onComplete: function () {
      const chart = this;
      const ctx = chart.ctx;
      const labels = chart.data.labels;

      if (!labels.length) {
        const width = chart.width;
        const height = chart.height;
        chart.clear();
        ctx.save();
        ctx.fillStyle = "#000";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = "18px normal 'Helvetica Nueue'";
        ctx.fillText(
          i18n.global.t("stats.no_data_text"),
          width / 2,
          height / 2
        );

        ctx.restore();

        return;
      }

      ctx.textAlign = "left";
      ctx.textBaseline = "center";

      this.data.datasets.forEach(function (dataset, i) {
        var meta = chart.getDatasetMeta(i);

        meta.data.forEach(function (bar, index) {
          const data = dataset.data[index];
          const dataLength = data.toString().length;
          const dataPx = dataLength == 3 ? 6 : 9;
          const calcX = bar.x - bar.width / 2;

          ctx.fillStyle = "#fff";
          ctx.font = "bold 14px Roboto";

          ctx.fillText(
            chartDataValuesMutation.value[index],
            calcX + dataPx,
            bar.y + bar.height - 10
          );
        });
      });
    },
  },
  chartArea: {
    backgroundColor: "rgba(251, 85, 85, 0.4)",
  },
  layout: {
    padding: 0,
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    x: {
      ticks: {
        padding: 10,
        font: {
          family: "Roboto",
          size: function (context) {
            if (document.documentElement.clientWidth > 992) {
              return 16;
            } else {
              return 11;
            }
          },
        },

        color: "#545454",
      },
      afterFit: function (context) {
        context.height -= 10;
      },
      grid: {
        drawOnChartArea: false,
        drawBorder: false,
        drawTicks: false,
      },
      border: {
        display: false,
      },
    },
    y: {
      display: false,

      grid: {
        drawOnChartArea: false,
        drawBorder: false,
        drawTicks: false,
      },
      border: {
        display: false,
      },
    },
  },
};

const chartStyles = {
  height: "400px",
  width: "100%",
};
</script>
