<template>
  <div
    :class="[
      'profile-creation-body-content-item',
      { 'disabled-select': !selfAddress },
    ]"
  >
    <div
      id="profile-creation-accordion-one"
      class="profile-creation-body-content-item-accordion"
    >
      <div class="card">
        <div
          v-b-toggle.collapseOne
          class="card-header"
          id="headingOne"
          data-toggle="collapse"
          data-target="#collapseOne"
          aria-expanded="false"
          aria-controls="collapseOne"
        >
          <button type="button">
            <span>
              {{ $t("misc.address") }}
            </span>
            <i>
              <svg
                width="14"
                height="8"
                viewBox="0 0 14 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 0.999999L7 7L13 1"
                  stroke="#7B7B7B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </i>
          </button>
        </div>
        <b-collapse
          id="collapseOne"
          data-parent="#profile-creation-accordion-one"
        >
          <div class="card-body">
            <div class="form-item h-40">
              <v-select
                v-model="selectedCountry"
                @update:modelValue="(country) => onCountryChange(country)"
                :options="countries"
                :searchable="true"
                :clearable="true"
                :placeholder="$t('misc.country')"
                :label="$t('misc.country')"
                :get-option-label="(option) => option.name"
                :get-option-value="(option) => option.id"
              >
                <template #no-options>
                  <div class="">
                    {{ $t("misc.no_results") }}
                  </div>
                </template>
              </v-select>
            </div>

            <div class="form-item h-40" v-if="states">
              <v-select
                @option:selecting="(state) => onStateChange(state)"
                v-model="selectedState"
                :options="states"
                :searchable="true"
                :clearable="true"
                :placeholder="$t('misc.state')"
                :label="$t('misc.state')"
                :get-option-label="(option) => option.name"
                :get-option-value="(option) => option.id"
              >
                <template #no-options>
                  <div class="">
                    {{ $t("misc.no_results") }}
                  </div>
                </template>
              </v-select>
            </div>

            <div class="form-item h-40" v-if="cities && cities.length">
              <v-select
                @option:selecting="(city) => onCityChange(city)"
                v-model="selectedCity"
                :options="cities"
                :searchable="true"
                :clearable="true"
                :placeholder="$t('misc.city')"
                :label="$t('misc.city')"
                :get-option-label="(option) => option.name"
                :get-option-value="(option) => option.id"
              >
                <template #no-options>
                  <div class="">
                    {{ $t("misc.no_results") }}
                  </div>
                </template>
              </v-select>
            </div>

            <div
              class="form-item h-40"
              v-if="neighborhoods && neighborhoods.length"
            >
              <v-select
                @option:selecting="
                  (neighborhood) => onNeighborhoodChange(neighborhood)
                "
                :options="neighborhoods"
                v-model="selectedNeighborhood"
                :searchable="true"
                :clearable="true"
                :placeholder="$t('misc.neighborhood')"
                :label="$t('misc.neighborhood')"
                :get-option-label="(option) => option.name"
                :get-option-value="(option) => option.id"
              >
                <template #no-options>
                  <div class="">
                    {{ $t("misc.no_results") }}
                  </div>
                </template>
              </v-select>
            </div>

            <div class="form-item h-40" v-if="streets && streets.length">
              <v-select
                @option:selecting="(street) => onStreetChange(street)"
                :options="streets"
                v-model="selectedStreet"
                :searchable="true"
                :clearable="true"
                :placeholder="$t('misc.street')"
                :label="$t('misc.street')"
                :get-option-label="(option) => option.name"
                :get-option-value="(option) => option.id"
              >
                <template #no-options>
                  <div class="">
                    {{ $t("misc.no_results") }}
                  </div>
                </template>
              </v-select>
            </div>

            <div class="form-item">
              <textarea
                :placeholder="$t('misc.address')"
                rows="3"
                v-on:input="onAddressInput"
                :value="data.address"
              ></textarea>
              <div class="form-item-error-text">
                {{ $t("profile.address.description") }}
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import store from "@/store";

const { t } = useI18n();
const props = defineProps({
  data: {
    type: Object,
    required: false,
  },
  isApplyWsRules: {
    type: Boolean,
    default: true,
    required: false,
  },
});

const workspace = computed(() => store.getters["account/workspace"]);
const isInitDataAdded = ref(true);
const isInitCountryAdded = ref(false);
const data = ref({});
const isMainCorporateProfile = ref(false);
const selfAddress = ref(true);
const countries = computed(() => {
  const countries = store.getters["location/countries"];

  if (countries && !isInitDataAdded.value && !isInitCountryAdded.value) {
    selectedCountry.value = countries.find(
      (country) => country.iso2 === data.value.country
    );
    isInitCountryAdded.value = true;
    onCountryChange(selectedCountry.value);
  }

  return countries;
});

const states = computed(() => {
  const states = store.getters["location/states"];
  if (states && !isInitDataAdded.value && data.value.state) {
    selectedState.value = states.find(
      (state) => state.iso2 === data.value.state
    );
    onStateChange(selectedState.value);
  }

  return states;
});

const cities = computed(() => {
  if(!selectedCountry?.value?.iso2){
    return;
  }

  const cities = store.getters["location/cities"];

  if (cities && !isInitDataAdded.value && data.value.city) {
    const findCity = cities.find(
      (city) => city.id === parseInt(data.value.city)
    );

    if (!findCity) {
      return cities;
    }

    selectedCity.value = findCity;

    if (selectedCity.value) {
      onCityChange(selectedCity.value);
    }

    if (selectedCountry.value.iso2 != "TR") {
      isInitDataAdded.value = true;
    }

    // isInitDataAdded.value = true;
  }

  return cities;
});

const neighborhoods = computed(() => {
  const neighborhoods = store.getters["location/neighborhoods"];

  if (neighborhoods && !isInitDataAdded.value && data.value.neighborhood) {
    selectedNeighborhood.value = neighborhoods.find(
      (neighborhood) => neighborhood.id === parseInt(data.value.neighborhood)
    );

    if (selectedNeighborhood.value) {
      onNeighborhoodChange(selectedNeighborhood.value);
    }

    // isInitDataAdded.value = true;
  }

  return neighborhoods;
});

const streets = computed(() => {
  const streets = store.getters["location/streets"];

  if (streets && !isInitDataAdded.value && data.value.street) {
    selectedStreet.value = streets.find(
      (street) => street.id === parseInt(data.value.street)
    );

    if (selectedStreet.value) {
      isInitDataAdded.value = true;
    }
  }

  return streets;
});

const selectedCountry = ref(null);
const selectedState = ref(null);
const selectedCity = ref(null);
const selectedNeighborhood = ref(null);
const selectedStreet = ref(null);

watch(
  () => selectedCountry.value,
  (country) => {
    if (!country) {
      store.commit("profile/set_country", null);
      return;
    }
  }
);

watch(
  () => selectedState.value,
  (state) => {
    if (!state) {
      store.commit("profile/set_state", null);
      return;
    }
  }
);

watch(
  () => selectedCity.value,
  (city) => {
    if (!city) {
      store.commit("profile/set_city", null);
      return;
    }
  }
);

watch(
  () => selectedNeighborhood.value,
  (neighborhood) => {
    if (!neighborhood) {
      store.commit("profile/set_neighborhood", null);
      return;
    }
  }
);

watch(
  () => selectedStreet.value,
  (street) => {
    if (!street) {
      store.commit("profile/set_street", null);
      return;
    }
  }
);

const onCountryChange = (country) => {
  selectedState.value = null;
  selectedCity.value = null;
  selectedNeighborhood.value = null;
  selectedStreet.value = null;

  store.commit("location/states", []);
  store.commit("location/cities", []);
  store.commit("location/neighborhoods", []);
  store.commit("location/streets", []);

  if (!country) {
    selectedCountry.value = null;
    return;
  }

  store.commit("profile/set_country", country.iso2);
  store.dispatch("location/states", country.iso2);
};

const onStateChange = (state) => {
  if(!selectedCountry?.value?.iso2){
    return;
  }
  store.dispatch("location/cities", {
    country: selectedCountry.value.iso2,
    state: state?.iso2,
  });

  selectedCity.value = null;
  selectedNeighborhood.value = null;
  selectedStreet.value = null;

  store.commit("location/cities", []);
  store.commit("location/neighborhoods", []);
  store.commit("location/streets", []);

  store.commit("profile/set_state", state?.iso2);
};

const onCityChange = (city) => {
  if(!selectedCountry?.value?.iso2){
    return;
  }

  store.dispatch("location/neighborhoods", {
    country: selectedCountry.value.iso2,
    state: selectedState.value.iso2,
    city: city.id,
  });

  selectedNeighborhood.value = null;
  selectedStreet.value = null;

  store.commit("location/neighborhoods", []);
  store.commit("location/streets", []);

  store.commit("profile/set_city", city.id);
};

const onNeighborhoodChange = (neighborhood) => {
  if(!selectedCountry?.value?.iso2){
    return;
  }

  store.dispatch("location/streets", {
    country: selectedCountry.value.iso2,
    state: selectedState.value.iso2,
    city: selectedCity.value.id,
    neighborhood: neighborhood.id,
  });

  store.commit("profile/set_neighborhood", neighborhood.id);

  selectedStreet.value = null;

  store.commit("location/streets", []);
};

const onStreetChange = (street) => {
  store.commit("profile/set_street", street.id);
};

const onAddressInput = (e) => {
  store.commit("profile/set_address", e.target.value);
};

onMounted(() => {
  const workspaceType = workspace.value.type;
  const workspaceSettings = workspace.value.settings;
  store.dispatch("location/countries");

  store.commit("location/states", null);
  store.commit("location/cities", []);
  store.commit("location/neighborhoods", []);
  store.commit("location/streets", []);

  store.commit("profile/set_country", '');
  store.commit("profile/set_state", '');
  store.commit("profile/set_city", '');
  store.commit("profile/set_neighborhood", '');
  store.commit("profile/set_street", '');


  if (props.data) {
    isMainCorporateProfile.value =
      props.data.acf?.is_main_corporate_profile || false;
  }

  if (props.data?.acf?.address) {
    data.value = props.data.acf.address;
    isInitDataAdded.value = false;
    store.commit("profile/set_address", data.value.address);
  }

  if (
    workspaceType === "corporate" &&
    workspaceSettings.user_address &&
    !isMainCorporateProfile.value &&
    props.isApplyWsRules
  ) {
    data.value = workspaceSettings.user_address;
    isInitDataAdded.value = false;
    selfAddress.value = false;
    // store.commit("profile/set_address", data.value.address);
  }
});
</script>
