<template>
  <div class="profile-creation-head-nav-item is-individual">
    <button>
      <div class="profile-creation-head-nav-item-content">
        <b-skeleton
          class="profile-creation-head-nav-item-content-bg skeleton-bg"
        ></b-skeleton>
        <b-skeleton
          class="profile-creation-head-nav-item-content-title"
        ></b-skeleton>
      </div>
    </button>
  </div>
</template>
