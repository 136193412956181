import { createApp } from "vue";
import App from "./App.vue";
import { createMetaManager, plugin as metaPlugin } from "vue-meta";
import i18n from "@/utils/i18n";
import Store from "./store";
import VueCountdown from "@chenfengyuan/vue-countdown";
import vSelect from "vue-select";
import BootstrapVue3 from "bootstrap-vue-3";
import router from "@/router";
import LitepieDatepicker from "litepie-datepicker";
import VueTippy from "vue-tippy";
import Maska from "maska";
import { setLocale } from "yup";
import vue3GoogleLogin from "vue3-google-login";
import VueClipboard from "vue3-clipboard";
import { BToastPlugin } from "bootstrap-vue-3";
import { msalPlugin } from "@/utils/msalPlugin";
import { msalInstance } from "@/utils/msalAuth";
import { AuthenticationResult, EventType } from "@azure/msal-browser";
import dayjstr from "@/utils/dayjs-tr";
import VueGtag from "vue-gtag";
import VueClickAway from "vue3-click-away";

setLocale({
  mixed: {
    required: ({ label }) => i18n.global.t("validation.required", { label }),
    oneOf: ({ label, ref, values }) =>
      i18n.global.t("validation.oneOf", { values, label, ref }),
    notType: ({ label, type }) =>
      i18n.global.t("validation.not_type", { label, type }),
  },
  string: {
    email: ({ label }) => i18n.global.t("validation.email", { label }),
    min: ({ label, min }) => i18n.global.t("validation.min", { label, min }),
    max: ({ label, max }) => i18n.global.t("validation.max", { label, max }),
  },
  number: {
    required: ({ label }) => i18n.global.t("validation.required", { label }),
    min: ({ label, min }) => i18n.global.t("validation.min", { label, min }),
    max: ({ label, max }) => i18n.global.t("validation.max", { label, max }),
  },
});

import "tippy.js/dist/tippy.css"; // optional for styling

// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "./dist/css/main.min.css";
import "vue-select/dist/vue-select.css";
import "swiper/swiper-bundle.min.css";

const metaManager = createMetaManager();

const app = createApp(App)
  .use(router)
  .use(metaPlugin)
  .use(metaManager)
  .use(i18n)
  .use(Store)
  .use(BootstrapVue3)
  .use(LitepieDatepicker)
  .use(VueTippy)
  .use(Maska)
  .use(BToastPlugin)
  .use(msalPlugin, msalInstance)
  .use(vue3GoogleLogin, {
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    redirectUri: process.env.VUE_APP_GOOGLE_REDIRECT_URI,
  })
  .use(VueClipboard, {
    autoSetContainer: true,
  })
  .use(VueClickAway)
  .use(VueGtag, {
    config: { id: "G-P1KQZL7FVS" },
  })
  .component(VueCountdown.name, VueCountdown)
  .component("v-select", vSelect)
  .mount("#app");

console.log("app running");
