<template>
  <div class="interest-chart">
    <Loading type="skeleton" v-if="isLoading" />

    <div class="interest-chart-title">
      <h3>{{ $t("stats.interests.title") }}</h3>
    </div>
    <div class="interest-chart-block" v-if="stats">
      <Bar
        :chart-data="chartData"
        :chart-options="chartOptions"
        :plugins="[beforeDraw]"
      />
    </div>
    <div class="interest-chart-button" v-if="chartDataLabels.length > 4">
      <button type="button" @click="setModalStatus('stats_interests', true)">
        {{ $t("stats.interests.view_more") }}
      </button>
    </div>
  </div>

  <InterestsModal
    v-if="getModalStatus('stats_interests')"
    :items="{ labels: chartDataLabels, values: chartDataValues }"
  />
</template>

<script setup>
import { computed, ref } from "vue";
import { Bar } from "vue-chartjs";
import { setModalStatus, getModalStatus } from "@/utils/Helpers";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Plugin,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";
import InterestsModal from "@/components/Stats/Modals/Interests.vue";
import store from "@/store";
import Loading from "../../Common/Loading.vue";
import i18n from "@/utils/i18n.vue";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  TimeScale
);

const windowWidth = document.documentElement.clientWidth;
const isLoading = computed(() => store.getters["stats/isLoading"]);
const chartDataLabels = ref([]);
const chartDataValues = ref([]);
const chartDataValuesMutation = ref([]);
const chartData = ref({
  labels: [],
  datasets: [
    {
      data: [],
      borderColor: "#167FFC",
      backgroundColor: "#167FFC",
      borderWidth: 2,
      borderRadius: 8,
      borderSkipped: "left",
      maxHeight: "10",
      color: "red",
    },
  ],
});

const stats = computed(() => {
  chartDataValuesMutation.value = [];

  const stats = store.getters["stats/statsFiltered"];
  if (stats[0].data?.interest) {
    const interests = stats[0].data.interest;
    const labels = Object.keys(interests);
    const values = Object.values(interests);

    chartDataLabels.value = labels;
    chartDataValues.value = values;

    chartData.value.labels = [];

    labels.map((label, index) => {
      if (index <= 3) {
        chartData.value.labels.push(label);
      }
    });

    chartData.value.datasets[0].data = values;

    for (let index = 0; index < chartDataValues.value.length; index++) {
      let mutationDataValue = chartDataValues.value[index];
      if (mutationDataValue >= 100) {
        mutationDataValue = "99+";
      }

      chartDataValuesMutation.value.push(mutationDataValue);
    }
  }

  return stats;
});

const chartOptions = {
  maintainAspectRatio: false,
  events: [],
  responsive: true,
  barThickness: 24,
  indexAxis: "y",

  animation: {
    duration: 1,
    onComplete: function () {
      const chart = this;
      const ctx = chart.ctx;
      const labels = chart.data.labels;

      ctx.textAlign = "left";
      ctx.textBaseline = "center";

      if (!labels.length) {
        const width = chart.width;
        const height = chart.height;
        chart.clear();
        ctx.save();
        ctx.fillStyle = "#000";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = "18px normal 'Helvetica Nueue'";
        ctx.fillText(
          i18n.global.t("stats.no_data_text"),
          width / 2,
          height / 2
        );

        ctx.restore();

        return;
      }

      this.data.datasets.forEach(function (dataset, i) {
        var meta = chart.getDatasetMeta(i);

        meta.data.forEach(function (bar, index) {
          const data = dataset.data[index];
          const dataLength = data.toString().length;
          const dataPx = dataLength == 3 ? 8 : 4;
          const calcX = bar.x - bar.width / 2;

          ctx.font = "bold 14px Roboto";
          ctx.fillStyle = "#fff";
          ctx.fillText(data, bar.width - dataPx - 30, bar.y + 5.5);
          ctx.fillStyle = "#000";
          ctx.font = "16px Roboto regular";
          ctx.fillText(labels[index], bar.width + 10, bar.y + 4);
        });
      });
    },
  },
  chartArea: {
    backgroundColor: "rgba(251, 85, 85, 0.4)",
  },
  layout: {
    padding: 0,
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    x: {
      display: false,

      grid: {
        lineWidth: 0,
        tickWidth: 0,
        tickLength: 0,
      },
      max: function (context) {
        if (windowWidth > 992) {
          return 130;
        } else {
          return 170;
        }
      },
    },
    y: {
      display: false,
      position: "right",

      grid: {
        drawOnChartArea: false,
        drawBorder: false,
        lineWidth: 0,
        tickWidth: 0,
        tickLength: 0,
      },
      barPercentage: 0,
    },
  },
};

const beforeDraw = {
  id: "beforeDraw",
  beforeDraw: function (chart, args, options) {
    const ctx = chart.ctx;
    const barThicknessValue = chart.options.barThickness;
    const chartLength = chart.data.labels.length;
    const chartHeight = (barThicknessValue + 34) * chartLength;

    if (chartHeight) {
      chart.canvas.parentNode.style.height = chartHeight + "px";
    }
  },
};

const chartStyles = {
  height: "400px",
  width: "100%",
};
</script>
