<template>
  <div class="settings-modal-card-match is-show">
    <div class="settings-modal-card-match-title">
      <h5>{{ $t("settings.match.modal.title") }}</h5>
    </div>
    <template v-if="formMode === 'match'">
      <div class="settings-modal-card-match-description">
        <p>
          {{ $t("settings.match.modal.text") }}
        </p>
      </div>
      <div class="settings-modal-card-match-profile">
        <div class="settings-modal-card-match-profile-head">
          <h6>{{ $t("settings.match.modal.profiles") }}</h6>
        </div>
        <div class="settings-modal-card-match-profile-body">
          <div
              :class="[
            'settings-modal-card-match-profile-body-item is-' +
              profile.acf.color,
            { 'is-active': selectedProfile == profile.id },
          ]"
              v-for="(profile, index) in profiles"
              :key="index"
              @click="selectedProfile = profile.id"
          >
            <div class="settings-modal-card-match-profile-body-item-bg"></div>
            <div class="settings-modal-card-match-profile-body-item-title">
              <span>{{ profile.title }}</span>
            </div>
          </div>
        </div>
      </div>
      <Form
          @submit="match"
          :validation-schema="schema"
          v-slot="{ errors }"
          ref="form"
      >
        <div class="settings-modal-card-match-id">
          <div class="settings-modal-card-match-id-title">
            <h5>{{ $t("settings.match.modal.id") }}</h5>
          </div>

          <div class="settings-modal-card-match-id-input">
            <Field
                :errors="errors"
                name="card_number"
                :placeholder="$t('settings.match.modal.id')"
            />
          </div>
        </div>
        <div class="settings-modal-card-match-button">
          <template v-if="formMode == 'match'">
            <button
                type="button"
                class="btn btn-sm btn-white btn-round-4 btn-400"
                @click="setModalStatus('settings_match_card', false)"
            >
              {{ $t("misc.cancel") }}
            </button>
            <Button
                type="submit"
                class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
                :state="state"
                :success_text="$t('settings.match.modal.success_button_label')"
            >
              {{ $t("settings.match.modal.button_label") }}
            </Button>
          </template>
          <template v-else>
            <button
                type="button"
                class="btn btn-sm btn-white btn-round-4 btn-400"
                @click="tryAgain"
            >
              {{
                $t("settings.match.modal.already_matched.try_again_button_label")
              }}
            </button>
            <Button
                type="submit"
                class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
                :state="state"
                :success_text="
              $t('settings.match.modal.already_matched.success_button_label')
            "
            >
              {{ $t("settings.match.modal.already_matched.button_label") }}
            </Button>
          </template>
        </div>
      </Form>
    </template>

    <template v-if="formMode === 'confirm'">
      <div class="settings-modal-card-match-description">
        <p v-html="$t('settings.match.modal.confirm.text')"></p>
      </div>

      <div class="settings-modal-card-match-button">

        <button
            type="button"
            class="btn btn-sm btn-white btn-round-4 btn-400"
            @click="formMode = 'match'"
        >
          {{
            $t("misc.cancel")
          }}
        </button>
        <Button
            @click="match"
            type="submit"
            class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
            :state="state"
            :success_text="
              $t('settings.match.modal.success_button_label')
            "
        >
          {{ $t("misc.confirm") }}
        </Button>
      </div>
    </template>

    <div class="settings-modal-card-match-close">
      <button
          type="button"
          @click="setModalStatus('settings_match_card', false)"
      >
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M18 6L6 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
          <path
              d="M6 6L18 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, computed} from "vue";
import {
  getModalStatus,
  setModalStatus,
  getModalData,
  getErrorMessage,
  getErrorType,
} from "@/utils/Helpers";
import {Form} from "vee-validate";
import * as yup from "yup";
import i18n from "@/utils/i18n.vue";
import store from "@/store";
import Field from "@/components/inputs/Field.vue";
import Button from "@/components/inputs/Button";

const state = ref("default");
const form = ref(null);
const formMode = ref("match");
const selectedProfile = ref(null);
const cardNumber = ref(null);
const profiles = computed(() => {
  const profiles = store.getters["profiles/items"];

  if (!profiles || !profiles.length) {
    return [];
  }

  selectedProfile.value = profiles[0].id;

  return profiles;
});

const schema = yup.object().shape({
  card_number: yup
      .string()
      .required()
      .label(i18n.global.t("settings.match.modal.id")),
});

const match = (values) => {
  if (!selectedProfile.value) {
    return;
  }

  if (formMode.value === 'match') {
    formMode.value = 'confirm';
    cardNumber.value = values.card_number;

    return;
  }

  state.value = "loading";

  if (formMode.value == "review_request") {
    store
        .dispatch("account/sendRequest", {
          type: "match_error",
          card_number: values.card_number,
          profile_id: selectedProfile.value,
        })
        .then((response) => {
          state.value = "success";

          setTimeout(() => {
            setModalStatus("settings_match_card", false);
          }, 1000);
        });

    return;
  }


  store
      .dispatch("profiles/match", {
        id: selectedProfile.value,
        card_number: cardNumber.value,
      })
      .then(() => {
        state.value = "success";

        setTimeout(() => {
          setModalStatus("settings_match_card", false);
        }, 1000);
      })
      .catch((e) => {
        state.value = "default";
        formMode.value = "match";
        const errorType = getErrorType(e);

        setTimeout(() => {
          form.value.setErrors({
            card_number: getErrorMessage(e),
          });
        }, 100);

        if (errorType === "already_matched") {
          formMode.value = "review_request";
        }
      });
};

const tryAgain = () => {
  formMode.value = "match";

  form.value.resetForm();
};

onMounted(() => {
});
</script>
