<template>
  <Loading :is-full-screen="true" v-if="isLoading" />
  <SecondLayout v-else>
    <section class="reset-password">
      <div class="reset-password-contain">
        <div class="reset-password-main">
          <router-link :to="{ name: 'login' }">
            <div class="reset-password-error-cancel">
              <i>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 1L1 13"
                    stroke="#979797"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1 1L13 13"
                    stroke="#979797"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </i>
            </div>
          </router-link>
          <div class="reset-password-icon">
            <img src="/img/static/icon-unlock.svg" alt="" />
          </div>
          <template v-if="mode === 'form'">
            <div class="reset-password-title">
              <h1>{{ $t("auth.reset_password.title") }}</h1>
            </div>
            <div class="reset-password-form reset-password-form-group">
              <Form
                @submit="submit"
                :validation-schema="schema"
                v-slot="{ errors }"
              >
                <div
                  :class="['form-item h-52', errors.password ? 'is-error' : '']"
                >
                  <VField v-slot="{ field }" name="password">
                    <PasswordInput
                      :bind-value="field"
                      :placeholder="$t('auth.common.password')"
                    />
                  </VField>
                </div>

                <div
                  :class="[
                    'form-item h-52',
                    errors.confirm_password ? 'is-error' : '',
                  ]"
                >
                  <VField v-slot="{ field }" name="confirm_password">
                    <PasswordInput
                      :bind-value="field"
                      :placeholder="$t('auth.common.password_confirmation')"
                    />
                  </VField>
                </div>

                <div class="reset-password-form-send-btn">
                  <Button
                    type="submit"
                    class="btn btn-md btn-full btn-primary-blue btn-round-8 btn-500"
                    :state="state"
                  >
                    {{ $t("auth.reset_password.button_label") }}
                  </Button>
                </div>

                <div
                  class="login-left-side-form-error-text"
                  v-if="errorMessage"
                >
                  <p>
                    {{ errorMessage }}
                  </p>
                </div>
                <div class="reset-password-form-line">
                  <span>{{ $t("auth.common.or") }}</span>
                </div>
                <div class="reset-password-form-footer">
                  <router-link to="/sign-up">
                    {{ $t("auth.common.create_account") }}
                  </router-link>
                </div>
              </Form>
            </div>
          </template>
          <template v-else>
            <div class="reset-password-text">
              <p>
                {{ $t("auth.reset_password.success_text") }}
              </p>
            </div>
            <div class="reset-password-btn">
              <router-link
                :to="{ name: 'login' }"
                class="btn btn-md btn-primary-blue btn-round-8 btn-500"
              >
                {{ $t("auth.login.button_label") }}
              </router-link>
            </div>
          </template>
        </div>
      </div>
    </section>
  </SecondLayout>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { Form, Field as VField } from "vee-validate";
import * as yup from "yup";
import { useRouter, useRoute } from "vue-router";
import Button from "@/components/inputs/Button.vue";
import i18n from "../../utils/i18n.vue";
import store from "@/store";
import Loading from "@/components/Common/Loading.vue";
// Components
import SecondLayout from "@/layouts/SecondLayout.vue";
import PasswordInput from "@/components/inputs/PasswordInput.vue";

const isLoading = ref(true);
const route = useRoute();
const router = useRouter();
const state = ref("default");
const mode = ref("form");
const errorMessage = ref("");
const schema = yup.object({
  password: yup
    .string()
    .required()
    .min(4)
    .label(i18n.global.t("auth.common.password")),

  confirm_password: yup
    .string()
    .required()
    .min(4)
    .label(i18n.global.t("auth.common.password")),
});

const submit = (values) => {
  state.value = "loading";
  const token = route.params.token;
  store
    .dispatch("auth/resetPassword", {
      password: values.password,
      confirm_password: values.confirm_password,
      key: token,
    })
    .then(() => {
      state.value = "success";
      mode.value = "success";
    })
    .catch((error) => {
      const message = error.response.data.data.message;
      state.value = "default";
      errorMessage.value = message;
    });
};

onMounted(() => {
  const token = route.params.token;

  store
    .dispatch("auth/resetPasswordTokenCheck", { token })
    .then(() => {
      isLoading.value = false;
    })
    .catch(() => {
      router.push({ name: "login" });
    });
});
</script>
