<template>
  <div class="settings-accordion-item accordion-item">
    <div
      class="settings-accordion-item-btn accordion-header"
      id="preferences-heading"
      data-bs-toggle="collapse"
      data-bs-target="#preferences"
      aria-expanded="false"
      aria-controls="preferences"
    >
      <h2 class="accordion-button">
        {{ $t("settings.preferences.title") }}
      </h2>
    </div>
    <div
      id="preferences"
      class="settings-accordion-item-content is-site-preferences-content accordion-collapse collapse"
      data-bs-parent="#settings-accordions"
      aria-labelledby="preferences-heading"
    >
      <div class="accordion-body">
        <b-card>
          <div class="settings-accordion-item-content-title">
            <h4>{{ $t("settings.preferences.language") }}</h4>
          </div>
          <div class="settings-accordion-item-content-text">
            <p>{{ $t("settings.preferences.language_text") }}</p>
          </div>
          <div class="settings-accordion-item-content-form">
            <form>
              <div class="form-item h-30">
                <v-select
                  v-model="selectedLanguage"
                  :options="languages.data"
                  :searchable="true"
                  :clearable="true"
                  :placeholder="$t('settings.preferences.language')"
                  :label="$t('settings.preferences.language')"
                  :get-option-label="(option) => option.name"
                  :get-option-value="(option) => option.locale"
                >
                  <template #no-options>
                    <div class="">
                      {{ $t("misc.no_results") }}
                    </div>
                  </template>
                </v-select>
              </div>
              <div class="settings-accordion-item-content-form-btn">
                <Button
                  class="btn btn-sm btn-primary-blue btn-round-4 btn-400"
                  :state="state"
                  @click.prevent="save"
                >
                  {{ $t("misc.save") }}
                </Button>
              </div>
            </form>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import store from "@/store";
import Button from "@/components/inputs/Button";

const languages = computed(() => store.getters["misc/languages"]);
const me = computed(() => store.getters["account/me"]);

const selectedLanguage = ref(null);
const state = ref("default");

const save = () => {
  state.value = "loading";
  store
    .dispatch("account/updatePreferences", {
      language: selectedLanguage.value.locale,
    })
    .then(() => {
      // reload the page to apply the new language
      store.dispatch("account/me").then(() => {
        setTimeout(() => {
          state.value = "success";
          window.location.reload();
        }, 1000);
      });
    });
};

onMounted(() => {
  setTimeout(() => {
    selectedLanguage.value = languages.value.data.find(
      (language) => language.locale === me.value.preferences.language
    );
  }, 200);
});
</script>
