import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import store from "@/store";
import { getErrorMessage, setModalStatus } from "@/utils/Helpers";

const router = useRouter();
const route = useRoute();

export default function useAuth({
  buttonState = {},
  errorMessage = {},
  router = {},
}) {
  const login = (payload) => {
    buttonState.value = "loading";
    store.commit("auth/error_message", "");

    return store.dispatch("auth/login", payload).then(
      async (response) => {
        const me = await store.dispatch("account/me");
        const profileItems = await store.dispatch("profiles/items");

        if (!response.user_email) {
          router.push({ name: "set-email" });
          return response;
        }

        if (!response.onboarding) {
          buttonState.value = "success";
          store.dispatch("onboarding/list").then((response) => {
            router.push({ path: "/onboarding" });
          });
        } else {
          buttonState.value = "success";
          router.push({
            params: { workspace: response.last_workspace },
            name: "profile-main",
          });
        }

        return response;
      },
      (error) => {
        buttonState.value = "default";
        store.commit("auth/error_message", getErrorMessage(error));

        return Promise.reject(error);
      }
    );
  };

  const register = (payload) => {
    buttonState.value = "loading";
    store.commit("auth/error_message", "");

    return store
      .dispatch("auth/register", payload)
      .then(() => {
        buttonState.value = "success";

        if (payload.mode === "corporate_pre") {
          setModalStatus("auth_register_corporate_confirm", true, {
            email: payload.email,
          });
        } else {
          setTimeout(() => {
            router.push({ name: "login" });
          }, 1000);
        }

        return true;
      })
      .catch((error) => {
        buttonState.value = "default";
        store.commit("auth/error_message", getErrorMessage(error));
        return Promise.reject(error);
      });
  };

  return {
    login,
    register,
  };
}
