<template>
  <div class="settings-accordion-item accordion-item">
    <div
      class="settings-accordion-item-btn accordion-header"
      id="archived-heading"
      data-bs-toggle="collapse"
      data-bs-target="#archived"
      aria-expanded="false"
      aria-controls="archived"
    >
      <h2 class="accordion-button">
        {{ $t("settings.archived.title") }}
      </h2>
    </div>
    <div
      id="archived"
      class="settings-accordion-item-content is-archived accordion-collapse collapse"
      data-bs-parent="#settings-accordions"
      aria-labelledby="archived-heading"
    >
      <div class="accordion-body">
        <b-card>
          <div class="is-archived-block">
            <div class="is-archived-block-item">
              <router-link :to="{ name: 'archived-notes' }" class="is-notes">
                Notlar
              </router-link>
            </div>
            <div class="is-archived-block-item">
              <router-link :to="{ name: 'archived-reminders' }" class="is-reminders">
                Anımsatıcılar
              </router-link>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import store from "@/store";
import Button from "@/components/inputs/Button";

const languages = computed(() => store.getters["misc/languages"]);
const me = computed(() => store.getters["account/me"]);

const selectedLanguage = ref(null);
const state = ref("default");

const save = () => {
  state.value = "loading";
  store
    .dispatch("account/updatePreferences", {
      language: selectedLanguage.value.locale,
    })
    .then(() => {
      // reload the page to apply the new language
      store.dispatch("account/me").then(() => {
        setTimeout(() => {
          state.value = "success";
          window.location.reload();
        }, 1000);
      });
    });
};

onMounted(() => {
  setTimeout(() => {
    selectedLanguage.value = languages.value.data.find(
      (language) => language.locale === me.value.preferences.language
    );
  }, 200);
});
</script>
