<template>
  <Line
    v-if="stats || stats === 0"
    height="400px"
    :styles="chartStyles"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :plugins="chartPlugins"
    :activeTab="activeTab"
  />
</template>

<script setup>
import { computed, ref } from "vue";
import { Line } from "vue-chartjs";
import { useWindowSize } from "vue-window-size";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Plugin,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";
import store from "@/store";
import i18n from "@/utils/i18n.vue";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale
);

const activeTab = computed(() => {
  const activeTab = store.getters["stats/activeTab"];
  chartData.value.datasets.map((dataset) => {
    dataset.backgroundColor = activeTab.color;
    dataset.borderColor = activeTab.color;
    dataset.pointHoverBorderColor = activeTab.color;
  });

  return activeTab;
});

const activeSubTab = computed(() => store.getters["stats/activeSubTab"]);

const stats = computed(() => {
  const stats = store.getters["stats/statsFiltered"];
  if (stats.length) {
    chartData.value.datasets = [];
    stats.map((dataset, index) => {
      if (dataset.data) {
        const id = dataset.id;
        const datasetLabel =
          id === "view"
            ? i18n.global.t("stats.view")
            : i18n.global.t("stats.download");
        const statsValues = Object.values(dataset.data.months);
        const statsLabels = Object.keys(dataset.data.months);

        // if (statsLabels.length > chartData.value.labels.length) {
        chartData.value.labels = statsLabels;
        // }

        chartData.value.datasets.push(
          chartDataset(statsValues, datasetLabel, id)
        );
        // chartData.value.datasets.push(chartDataset([12,32], "adasd"));
      } else {
        chartData.value.datasets.push(chartDataset([], "", id));
      }
    });

    // reverse
    // chartData.value.datasets = chartData.value.datasets.reverse();

    console.log("sorts", chartData.value.datasets);

    // const statsValues = stats.map((stat) => stat.totalUsers);
    // const statsLabels = stats.map((stat) => stat.date);
    // chartData.value.datasets[0].data = statsValues;
    // chartData.value.labels = statsLabels;
  } else {
    // chartData.value.datasets[0].data = [];
    // chartData.value.labels = [];
  }

  return stats;
});
const { width } = useWindowSize();

const chartDataset = (data, label, id) => {
  return {
    label,
    backgroundColor: "#167FFC",
    borderColor: "rgba(22, 127, 252, 1)",
    data,
    borderWidth: 2,
    cubicInterpolationMode: "monotone",
    // pointBorderWidth: '8',
    pointBorderWidth: function (context) {
      if (width > 992) {
        return 8;
      } else {
        return 6;
      }
    },
    // hoverBorderWidth: '8',
    hoverBorderWidth: function (context) {
      if (width > 992) {
        return 8;
      } else {
        return 6;
      }
    },
    pointBorderColor: "rgba(0, 0, 0, 0)",
    pointBackgroundColor: "rgba(0, 0, 0, 0)",
    pointHoverBorderColor: "#167FFC",
    tension: 0.05,
    borderDash: id === "download" ? [5, 5] : [],
  };
};

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector("ul");

  if (!listContainer) {
    listContainer = document.createElement("ul");
    listContainer.style.display = "flex";
    listContainer.style.flexDirection = "row";
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: "htmlLegend",
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, activeSubTab.value.legendId);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item) => {
      const li = document.createElement("li");
      li.style.alignItems = "center";
      li.style.cursor = "pointer";
      li.style.display = "flex";
      li.style.flexDirection = "row";
      li.style.marginLeft = "10px";

      li.onclick = () => {
        const { type } = chart.config;
        if (type === "pie" || type === "doughnut") {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(
            item.datasetIndex,
            !chart.isDatasetVisible(item.datasetIndex)
          );
        }
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement("span");
      boxSpan.style.background = item.fillStyle;
      boxSpan.style.borderColor = item.strokeStyle;
      boxSpan.style.borderWidth = item.lineWidth + "px";
      boxSpan.style.display = "inline-block";
      boxSpan.style.height = "20px";
      boxSpan.style.marginRight = "10px";
      boxSpan.style.width = "20px";

      // Text
      const textContainer = document.createElement("p");
      textContainer.style.color = item.fontColor;
      textContainer.style.margin = 0;
      textContainer.style.padding = 0;
      textContainer.style.textDecoration = item.hidden ? "line-through" : "";

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};

const chartOptions = {
  responsive: true,

  interaction: {
    mode: "index",
    intersect: false,
  },
  plugins: {
    title: {},
    htmlLegend: {
      containerID: "legend-container",
    },
    tooltip: {
      enabled: false,

      external: function (context) {
        // Tooltip Element
        let tooltipEl = document.getElementById("chartjs-tooltip");

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = document.createElement("div");
          tooltipEl.id = "chartjs-tooltip";
          tooltipEl.innerHTML = "<table></table>";
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        const tooltipModel = context.tooltip;
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove("above", "below", "no-transform");
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add("no-transform");
        }

        function getBody(bodyItem) {
          return bodyItem.lines;
        }

        // Set Text
        if (tooltipModel.body) {
          const titleLines = tooltipModel.title || [];
          const bodyLines = tooltipModel.body.map(getBody);

          let innerHtml = "<thead>";

          // titleLines.forEach(function (title) {
          //   innerHtml += '<tr><th>' + title + '</th></tr>'
          // })
          innerHtml += "</thead><tbody>";

          bodyLines.forEach(function (body, i) {
            const colors = tooltipModel.labelColors[i];
            let style = "background:" + colors.backgroundColor;
            style += "; border-color:" + colors.borderColor;
            style += "; border-width: 2px";
            const span = '<span style="' + style + '"></span>';
            body = body[0].split(":");
            innerHtml += "<tr><td>" + span + body[1] + "</td></tr>";
          });
          innerHtml += "</tbody>";

          let tableRoot = tooltipEl.querySelector("table");
          tableRoot.innerHTML = innerHtml;
        }

        const position = context.chart.canvas.getBoundingClientRect();
        // console.log(tooltipModel.options.bodyFont);
        // const bodyFont = ChartJS.helpers.toFont(tooltipModel.options.bodyFont);

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = "absolute";
        tooltipEl.style.left =
          position.left + window.pageXOffset + tooltipModel.caretX + "px";
        // tooltipEl.style.top = position.top + window.pageYOffset + -30 + tooltipModel.caretY + 'px'
        // tooltipEl.style.font = bodyFont.string;
        tooltipEl.style.padding =
          tooltipModel.padding + "px " + tooltipModel.padding + "px";
        tooltipEl.style.pointerEvents = "none";
        tooltipEl.style.top =
          position.top + window.pageYOffset + -30 + tooltipModel.caretY + "px";
      },
    },
    legend: {
      // align: 'end',
      display: false,

      labels: {
        color: "#545454",
        pointStyle: "rectRounded",
      },
    },
  },

  scales: {
    x: {
      bounds: "ticks",
      type: "time",
      time: {
        unit: "month",
        displayFormats: {
          month: "MMM",
        },
      },
      ticks: {
        // padding: 20,
        padding: function (context) {
          return 20;
        },
        maxTicksLimit: 12,
      },
      grid: {
        drawOnChartArea: false,
        drawBorder: false,
      },
    },
    y: {
      ticks: {
        padding: 20,
      },
      grid: {
        drawOnChartArea: false,
        drawBorder: false,
      },
    },
  },
};

const chartData = ref({
  labels: [],
  datasets: [chartDataset()],
});

const chartStyles = {
  height: "400px",
  width: "100%",
};

const chartPlugins = [htmlLegendPlugin];
</script>
