<template>
  <div class="email-signature-details">
    <div class="lead-title">
      <h3>
        {{ $t("email_signature.tabs.title") }}
      </h3>
    </div>
    <div class="lead-text">
      <p>{{ $t("email_signature.page_description") }}</p>
    </div>
    <ul>
      <li>
        <a href="javascript:;" class="dropdown-link" @click="toggleDropdown">
          {{ $t("email_signature.tabs.tab_1_title") }}
          <i>
            <img
              src="/img/static/email-signature-dropdown-icon.svg"
              aria-hidden="true"
            />
          </i>
        </a>
        <Type />
      </li>
      <li>
        <a
          href="javascript:;"
          title="Banner Alanı"
          class="dropdown-link"
          @click="toggleDropdown"
        >
          {{ $t("email_signature.tabs.tab_2_title") }}
          <i>
            <img
              src="/img/static/email-signature-dropdown-icon.svg"
              aria-hidden="true"
            />
          </i>
        </a>
        <Banner />
      </li>
      <li>
        <a
          href="javascript:;"
          title="Metin Alanı"
          class="dropdown-link"
          @click="toggleDropdown"
        >
          {{ $t("email_signature.tabs.tab_3_title") }}
          <i>
            <img
              src="/img/static/email-signature-dropdown-icon.svg"
              aria-hidden="true"
            />
          </i>
        </a>
        <Text />
      </li>
    </ul>
  </div>
</template>

<script setup>
import Type from "./Settings/Type.vue";
import Banner from "./Settings/Banner.vue";
import Text from "./Settings/Text.vue";
import { slideToggle } from "@/utils/Helpers";

const toggleDropdown = (e) => {
  const target = e.target;
  const parent = target.closest("li");
  const emailDetailActions = parent.querySelector(".email-detail-actions");

  target.classList.toggle("is-active");

  // apply slideToggle to emailDetailActions
  slideToggle(emailDetailActions);
};
</script>
