<template>
  <div class="profile-creation-body-block-item" v-if="!isMainCorporateProfile">
    <div class="form-item-drop-img">
      <div class="dropzone dz-clickable" data-type="profile-photo">
        <div class="dropzone-image-edit">
          <div class="dropzone-image-edit-block">
            <div class="dropzone-image-edit-block-button">
              <button type="button" @click="togglePhotoMenu('profile-photo')">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.1654 15.8333C19.1654 16.2754 18.9898 16.6993 18.6772 17.0118C18.3646 17.3244 17.9407 17.5 17.4987 17.5H2.4987C2.05667 17.5 1.63275 17.3244 1.32019 17.0118C1.00763 16.6993 0.832031 16.2754 0.832031 15.8333V6.66667C0.832031 6.22464 1.00763 5.80072 1.32019 5.48816C1.63275 5.17559 2.05667 5 2.4987 5H5.83203L7.4987 2.5H12.4987L14.1654 5H17.4987C17.9407 5 18.3646 5.17559 18.6772 5.48816C18.9898 5.80072 19.1654 6.22464 19.1654 6.66667V15.8333Z"
                    stroke="#545454"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.0013 14.1667C11.8423 14.1667 13.3346 12.6743 13.3346 10.8333C13.3346 8.99238 11.8423 7.5 10.0013 7.5C8.16035 7.5 6.66797 8.99238 6.66797 10.8333C6.66797 12.6743 8.16035 14.1667 10.0013 14.1667Z"
                    stroke="#545454"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div
              :class="[
                'dropzone-image-edit-block-content',
                { 'is-show': isShowPhotoMenu === 'profile-photo' },
              ]"
            >
              <div class="dropzone-image-edit-block-content-item">
                <button type="button" @click="addPhoto('profile-photo')">
                  {{ $t("misc.add_photo") }}
                </button>
              </div>
              <div class="dropzone-image-edit-block-content-item">
                <button type="button" @click="removePhoto('profile-photo')">
                  {{ $t("misc.remove_photo") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="dz-message" data-dz-message="">
          <i>
            <svg
              width="33"
              height="32"
              viewBox="0 0 33 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.5625 16C20.9825 16 24.5625 12.42 24.5625 8C24.5625 3.58 20.9825 0 16.5625 0C12.1425 0 8.5625 3.58 8.5625 8C8.5625 12.42 12.1425 16 16.5625 16ZM16.5625 20C11.2225 20 0.5625 22.68 0.5625 28V32H32.5625V28C32.5625 22.68 21.9025 20 16.5625 20Z"
                fill="#167FFC"
              ></path>
            </svg>
          </i>
          <p>
            <span>
              {{ $t("profile.add_profile_photo") }}
            </span>
            <em> ({{ $t("misc.optional") }}) </em>
          </p>
          <i>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 13V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V13"
                stroke="#167FFC"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M5 8L10 13L15 8"
                stroke="#167FFC"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M10 13V1"
                stroke="#167FFC"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </i>
        </div>
      </div>
    </div>
  </div>
  <div class="profile-creation-body-block-item">
    <div class="form-item-drop-img">
      <div class="dropzone dz-clickable" data-type="company-logo">
        <div class="dropzone-image-edit">
          <div
            class="dropzone-image-edit-block"
            v-if="
              (workspace.type === 'corporate' && isMainCorporateProfile) ||
              workspace.type === 'individual' ||
              !isApplyWsRules
            "
          >
            <div class="dropzone-image-edit-block-button">
              <button type="button" @click="togglePhotoMenu('company-logo')">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.1654 15.8333C19.1654 16.2754 18.9898 16.6993 18.6772 17.0118C18.3646 17.3244 17.9407 17.5 17.4987 17.5H2.4987C2.05667 17.5 1.63275 17.3244 1.32019 17.0118C1.00763 16.6993 0.832031 16.2754 0.832031 15.8333V6.66667C0.832031 6.22464 1.00763 5.80072 1.32019 5.48816C1.63275 5.17559 2.05667 5 2.4987 5H5.83203L7.4987 2.5H12.4987L14.1654 5H17.4987C17.9407 5 18.3646 5.17559 18.6772 5.48816C18.9898 5.80072 19.1654 6.22464 19.1654 6.66667V15.8333Z"
                    stroke="#545454"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.0013 14.1667C11.8423 14.1667 13.3346 12.6743 13.3346 10.8333C13.3346 8.99238 11.8423 7.5 10.0013 7.5C8.16035 7.5 6.66797 8.99238 6.66797 10.8333C6.66797 12.6743 8.16035 14.1667 10.0013 14.1667Z"
                    stroke="#545454"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div
              :class="[
                'dropzone-image-edit-block-content',
                { 'is-show': isShowPhotoMenu === 'company-logo' },
              ]"
            >
              <div class="dropzone-image-edit-block-content-item">
                <button type="button" @click="addPhoto('company-logo')">
                  {{ $t("misc.add_photo") }}
                </button>
              </div>
              <div class="dropzone-image-edit-block-content-item">
                <button type="button" @click="removePhoto('company-logo')">
                  {{ $t("misc.remove_photo") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="dz-message" data-dz-message="">
          <i>
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M36 32V4C36 1.8 34.2 0 32 0H4C1.8 0 0 1.8 0 4V32C0 34.2 1.8 36 4 36H32C34.2 36 36 34.2 36 32ZM11 21L16 27.02L23 18L32 30H4L11 21Z"
                fill="#167FFC"
              ></path>
            </svg>
          </i>
          <p>
            <span>
              {{ $t("profile.add_company_logo") }}
            </span>
            <em> ({{ $t("misc.optional") }}) </em>
          </p>
          <i>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 13V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V13"
                stroke="#167FFC"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M5 8L10 13L15 8"
                stroke="#167FFC"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M10 13V1"
                stroke="#167FFC"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </svg>
          </i>
        </div>
      </div>
    </div>
  </div>
  <PhotoUpload v-if="getModalStatus('profile_photo_upload')" />
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits, computed } from "vue";
import Dropzone from "dropzone";
import { setModalStatus, getModalStatus } from "@/utils/Helpers";
import store from "@/store";
import authHeader from "@/services/AuthHeader";
import PhotoUpload from "./Modals/PhotoUpload.vue";

const props = defineProps({
  item: {
    type: Object,
    required: false,
  },
  isApplyWsRules: {
    type: Boolean,
    default: true,
    required: false,
  },
});

const workspace = computed(() => store.getters["account/workspace"]);
const isMainCorporateProfile = ref(false);
const profileType = ref("individual");
const isShowPhotoMenu = ref(false);
const emit = defineEmits(["success", "process", "remove"]);
const dropzones = [];
const initFiles = [];

const togglePhotoMenu = (type) => {
  isShowPhotoMenu.value = isShowPhotoMenu.value === type ? false : type;
};

const removePhoto = (dropzoneType) => {
  const dropzone = dropzones.find((dz) => dz.type === dropzoneType)?.dropzone;
  const file = initFiles.find((f) => f.type === dropzoneType)?.file;
  dropzone.removeFile(file);

  initFiles.splice(
    initFiles.findIndex((f) => f.type === dropzoneType),
    1
  );

  emit("remove", dropzoneType);

  togglePhotoMenu(dropzoneType);
};

const addPhoto = (dropzoneType) => {
  const dropzone = dropzones.find((dz) => dz.type === dropzoneType)?.dropzone;

  // remove all files
  removePhoto(dropzoneType);

  // add new file
  dropzone.hiddenFileInput.click();
};

onMounted(() => {
  const dropzoneElements = document.querySelectorAll(".dropzone");
  const dropzoneElementsArray = Array.from(dropzoneElements);
  const workpaceType = workspace.value.type;
  const workspaceSettings = workspace.value.settings;
  let isApplyWSRulesForMember = false;
  let item = props.item;
  if (workpaceType === "corporate" && props.isApplyWsRules) {
    item = {
      ...item,
      acf: {
        ...item?.acf,
        company_logo: workspaceSettings.company_logo,
        profile_type: workpaceType,
      },
    };

    isMainCorporateProfile.value =
      item?.acf?.is_main_corporate_profile || false;

    isApplyWSRulesForMember = !isMainCorporateProfile.value;
  }

  if (item) {
    profileType.value = item.acf?.profile_type;
  }

  dropzoneElementsArray.map((dropzoneElement) => {
    const type = dropzoneElement.dataset.type;

    let dropzone = new Dropzone(dropzoneElement, {
      clickable:
        type === "company-logo" && isApplyWSRulesForMember ? false : true,
      url: process.env.VUE_APP_API_DOMAIN + "/misc/upload_file",
      headers: authHeader(),
      maxFiles: 1,
      maxThumbnailFilesize: 10,
      parallelUploads: 1,
      uploadMultiple: false,
      acceptedFiles: "image/*",
      addRemoveLinks: false,
      autoProcessQueue: false,
      thumbnailWidth: null,
      thumbnailHeight: null,
      sending: function (file, xhr, formData) {
        formData.append("type", type);
      },
      init: function () {
        const self = this;

        if (!item) {
          return;
        }

        const profilePhotoData = item.acf.profile_photo;
        const companyLogoData = item.acf.company_logo;

        if (profilePhotoData && type === "profile-photo") {
          const profilePhotoMockFile = {
            name: profilePhotoData.filename,
            size: profilePhotoData.filesize,
            type: profilePhotoData.mime_type,
          };

          self.emit("addedfile", profilePhotoMockFile);
          self.emit("thumbnail", profilePhotoMockFile, profilePhotoData.url);
          self.emit("success", profilePhotoMockFile);
          self.emit("complete", profilePhotoMockFile);
          
          emit("success", {
            type: "profile-photo",
            id: profilePhotoData.unique_id,
          });
        }

        if (companyLogoData && type === "company-logo") {
          const companyLogoMockFile = {
            name: companyLogoData.filename,
            size: companyLogoData.filesize,
            type: companyLogoData.mime_type,
          };

          self.emit("addedfile", companyLogoMockFile);
          self.emit("success", companyLogoMockFile);
          self.emit("thumbnail", companyLogoMockFile, companyLogoData.url);
          self.emit("complete", companyLogoMockFile);

          emit("success", {
            type: "company-logo",
            id: companyLogoData.unique_id,
          });
        }
      },
      success: function (file) {
        initFiles.push({
          type,
          file,
        });
      },
    });

    dropzones.push({
      type,
      dropzone,
    });

    dropzone.on("success", (file, response) => {
      const id = response.uniq_id;
      if (type === "profile-photo") {
        emit("success", {
          type: "profile-photo",
          id,
        });
      } else if (type === "company-logo") {
        emit("success", {
          type: "company-logo",
          id,
        });
      }
    });

    // is proccess file
    dropzone.on("processing", (file) => {
      emit("process", true);
    });

    dropzone.on("thumbnail", (file) => {
      if (file.cropped) {
        return;
      }

      setModalStatus("profile_photo_upload", true, {
        file,
        dropzone: dropzone,
      });
    });
  });
});
</script>
