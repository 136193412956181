<template>
  <div class="age-chart">
    <Loading type="skeleton" v-if="isLoading" />

    <div class="age-chart-title">
      <h3>{{ $t("stats.age.title") }}</h3>
    </div>
    <div class="age-chart-block">
      <Bar
        v-if="stats"
        :chart-options="options"
        :chart-data="chartData"
        :plugins="chartPlugins"
      />
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { Bar } from "vue-chartjs";
import { setModalStatus, getModalStatus } from "@/utils/Helpers";
import {
  Chart as ChartJS,
  Title,
  ArcElement,
  Tooltip,
  Legend,
  Plugin,
} from "chart.js";
import "chartjs-adapter-date-fns";
import LocationsModal from "@/components/Stats/Modals/Locations.vue";
import store from "@/store";
import Loading from "../../Common/Loading.vue";
import i18n from "@/utils/i18n.vue";
const isLoading = computed(() => store.getters["stats/isLoading"]);

ChartJS.register(ArcElement, Title, Tooltip, Legend);

const stats = computed(() => {
  const stats = store.getters["stats/statsFiltered"];

  if (stats[0]?.data?.ages) {
    const ages = stats[0].data.ages;
    const labels = Object.keys(ages);
    const values = Object.values(ages);

    chartData.value.labels = labels;
    chartData.value.datasets[0].data = values;
  }

  return stats;
});

const chartData = ref({
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: ["#167FFC", "#36A549", "#FCB20E"],
      hoverBackgroundColor: ["#167FFC", "#36A549", "#FCB20E"],
      borderColor: "#167FFC",
      backgroundColor: "#167FFC",
      borderWidth: 0,
      borderRadius: 6,
      borderSkipped: "bottom",
    },
  ],
});

const options = {
  maintainAspectRatio: false,
  events: [],
  responsive: true,
  barThickness: "28",
  animation: {
    duration: 1,
    // https://github.com/jtblin/angular-chart.js/issues/605
    onComplete: function () {
      var chart = this;
      var ctx = chart.ctx;
      ctx.textAlign = "left";
      ctx.textBaseline = "center";
      if (!this.data.labels || !this.data.labels.length) {
        const width = chart.width;
        const height = chart.height;

        chart.clear();
        ctx.save();
        ctx.fillStyle = "#000";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = "18px normal 'Helvetica Nueue'";

        ctx.fillText(
          i18n.global.t("stats.no_data_text"),
          width / 2,
          height / 2
        );

        ctx.restore();

        ctx.save();

        return;
      } else {
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chart.getDatasetMeta(i);
          // console.log(meta)

          meta.data.forEach(function (bar, index) {
            const data = dataset.data[index];
            const dataLength = data.toString().length;
            const dataPx = dataLength == 3 ? 4 : 7;
            const calcX = bar.x - bar.width / 2;
            ctx.fillStyle = "#fff";
            ctx.font = "bold 12px Roboto";

            ctx.fillText(
              bar.$context.raw,
              calcX + dataPx,
              bar.y + bar.height - 10
            );
          });
        });
      }
    },
  },
  chartArea: {
    backgroundColor: "rgba(251, 85, 85, 0.4)",
  },
  layout: {
    padding: 0,
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    x: {
      ticks: {
        padding: 20,
        font: {
          family: "Roboto",
          size: 14,
        },
        color: "#545454",
      },
      afterFit: function (context) {
        context.height -= 20;
      },
      border: {
        display: false,
      },

      grid: {
        drawOnChartArea: false,
        drawTicks: false,
      },
    },
    y: {
      border: {
        display: false,
      },
      ticks: {
        font: {
          family: "Roboto",
          size: 11,
        },
        color: "#545454",
      },
      grid: {
        drawOnChartArea: false,
        drawTicks: false,
      },
    },
  },
};

const chartPlugins = [];
</script>
