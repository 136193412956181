<template>
  <div class="profile-creation-body-block-color">
    <div class="profile-creation-body-block-color-head">
      <h4>{{ $t("profile.background_color") }}</h4>
      <p>
        {{ $t("profile.background_color_description") }}
      </p>
    </div>
    <div
      class="profile-creation-body-block-color-button"
      v-if="backgroundColorGroups"
    >
      <div
        class="profile-creation-body-block-color-button-item"
        v-for="(backgroundColorGroup, index) in backgroundColorGroups"
        :key="index"
      >
        <div
          class="profile-creation-body-block-color-button-item-block"
          v-for="color in backgroundColorGroup"
          :key="color.id"
        >
          <button
            type="button"
            :class="[
              'is-' + color.id,
              { 'is-active': chosenBackgroundColorId === color.id },
            ]"
            @click="chooseBackgroundColor(color.id)"
          ></button>
        </div>
      </div>
    </div>
  </div>

  <div class="color-palette">
    <div class="color-palette-checked" v-if="isOpenedCustomColorPicker">
      <button @click="isOpenedCustomColorPicker = false">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 6L9 17L4 12"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
    <Sketch
      v-model="chosenCustomBackgroundColor"
      v-if="isOpenedCustomColorPicker"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, computed, defineEmits, defineProps, watch } from "vue";
import store from "@/store";
import { Sketch } from "@ckpack/vue-color";
import tinycolor from "@ctrl/tinycolor";

const props = defineProps({
  item: {
    type: Object,
    required: false,
  },
});
const emit = defineEmits(["chosen"]);
const backgroundColorGroups = ref([]);
const definedBackgroundColors = store.getters["defines/backgroundColors"];
const chosenBackgroundColorId = ref(null);
const chosenBackgroundColor = ref(null);
const chosenCustomBackgroundColor = ref("#ff0000");
const isOpenedCustomColorPicker = ref(false);

watch(
  () => chosenCustomBackgroundColor.value,
  (newValue) => {
    chosenBackgroundColor.value = newValue?.hex || newValue;
    emit("chosen", chosenBackgroundColor.value);
  }
);

const chooseBackgroundColor = (colorId) => {
  let color = "";

  if (colorId === "gradient") {
    isOpenedCustomColorPicker.value = !isOpenedCustomColorPicker.value;
  } else {
    isOpenedCustomColorPicker.value = false;
  }

  chosenBackgroundColorId.value = colorId;

  if (colorId === "gradient") {
    color =
      chosenCustomBackgroundColor.value?.hex ||
      chosenCustomBackgroundColor.value;
  } else {
    color = store.getters["defines/backgroundColor"](colorId).color;
  }

  emit("chosen", color);

  chosenBackgroundColor.value = color;
};

onMounted(() => {
  for (let i = 0; i < definedBackgroundColors.length; i += 4) {
    backgroundColorGroups.value.push(definedBackgroundColors.slice(i, i + 4));
  }

  const item = props.item;

  if (item) {
    const currentBackgroundColor = item.acf.background_color;
    const currentBackgroundColorId = store.getters[
      "defines/backgroundColorByColor"
    ](currentBackgroundColor);
    chosenBackgroundColor.value = item.acf.background_color;

    if (!currentBackgroundColorId) {
      chosenBackgroundColorId.value = "gradient";
      chosenCustomBackgroundColor.value = currentBackgroundColor;
    } else {
      chosenBackgroundColorId.value = currentBackgroundColorId.id;
    }
  }
});
</script>
