import { ref, computed, onMounted } from "vue";

import store from "@/store";
import QRCode from "qrcode";

const getProxyURL = () => {
  return process.env.VUE_APP_API_DOMAIN + "/misc/proxy/?url=";
};

const getWithProxyURL = (url) => {
  const proxy = getProxyURL();

  return proxy + url;
};

const getWorkspaceSlug = () => {
  // const route = useRoute();
  // return route.params.workspace;
};

const getI18n = (keyword, isApply = true) => {
  const mode = store.getters["common/mode"];
  if (mode === "individual" || !isApply) {
    return keyword;
  }

  keyword = keyword.replace(/(\.+)(?=[^.]+$)/g, `.${mode}.`);

  return keyword;
};

const getLetters = (name) => {
  const letters = name.split(" ");

  if (letters.length > 1) {
    return letters[0].charAt(0) + letters[1].charAt(0);
  }

  return letters[0].charAt(0);
};

const setModalStatus = (name, status, data = {}) => {
  store.commit("common/set_modal_status", {
    name,
    value: status,
    data,
  });

  const hypedName = name.replace(/_/g, "-");

  if (status) {
    const excludeBodyClass = [
      "page_premium",
      "settings_membership_cancel",
      "settings_package_pairing",
      "header_notifications",
    ];
    const excludeOverflow = [
      "page_premium",
      "bg_upload",
      "profile_photo_upload",
      "email_visibility",
    ];

    if (!excludeBodyClass.includes(name)) {
      document.querySelector("main").classList.add("main-background");
    }

    if (!excludeOverflow.includes(name)) {
      document.querySelector("body").classList.add("body-overflow-hidden");
    }

    document.querySelector("body").classList.add(hypedName);
  } else {
    const excludeBodyClass = [
      "note_delete",
      "note_archive",
      "network_stay_touch",
      "reminder_delete",
      "reminder_notify",
    ];

    if (!excludeBodyClass.includes(name)) {
      document.querySelector("main").classList.remove("main-background");
    }

    document.querySelector("body").classList.remove("body-overflow-hidden");

    document.querySelector("body").classList.remove(hypedName);
  }
};

const getModalStatus = (name) => {
  return store.getters["common/modal_status"](name);
};

const getModalData = (name) => {
  return store.getters["common/modal_data"](name);
};

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const qrInit = (text) => {
  const canvas = document.getElementById("qr-canvas");

  QRCode.toCanvas(canvas, text, function (error) {
    if (error) console.error(error);
  });
};

const qrDownload = () => {
  const canvas = document.getElementById("qr-canvas");
  const link = document.createElement("a");

  link.download = "qr-code.png";
  link.href = canvas.toDataURL("image/png");
  link.click();
};

const copyListener = (str) => {
  function listener(e) {
    e.clipboardData.setData("text/html", str);
    e.clipboardData.setData("text/plain", str);
    e.preventDefault();
  }
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
};

const copyClipboardElement = (element) => {
  const block_level_elements = [
    "P",
    "H1",
    "H2",
    "H3",
    "H4",
    "H5",
    "H6",
    "OL",
    "UL",
    "DIV",
    "FORM",
    "HR",
    "TABLE",
  ];

  //create new Element so we can vhange elments like we need
  const newelment = document.createElement("div");
  //copy target Element to the new Element
  newelment.innerHTML = document.getElementById(element).innerHTML;

  //hide new Element to body
  newelment.style.opacity = 0;

  // add new Element to body
  document.body.appendChild(newelment);

  //get all element childs
  const descendents = newelment.getElementsByTagName("*");

  //loop in childs
  for (let i = 0; i < descendents.length; ++i) {
    //get defult Style
    const style = window.getComputedStyle(descendents[i]);
    const dis = style.getPropertyValue("display");
    //get defult tag name
    const tagname = descendents[i].tagName;

    //---------------------------
    //this part is little tricky
    //---------------------------
    //true : Element is a block level elements and css display is inline
    if (dis.includes("inline") && block_level_elements.includes(tagname)) {
      //get all Element style include default style
      const defultcss = document.defaultView.getComputedStyle(
        descendents[i],
        ""
      ).cssText;
      //chang Element tag from block level elements to inline level elements (span)
      descendents[i].outerHTML = descendents[i].outerHTML.replace(
        new RegExp(tagname, "ig"),
        "span"
      ); //todo: need to change RegExp to tag name only not inner text
      //add all Element style include default style to new tag
      descendents[i].style.cssText = defultcss;
    }
  }

  //-----------------copy new Element--------------
  const doc = document;
  let range, selection;

  if (doc.body.createTextRange) {
    range = doc.body.createTextRange();
    range.moveToElementText(newelment);
    range.select();
  } else if (window.getSelection) {
    selection = window.getSelection();
    range = doc.createRange();
    range.selectNodeContents(newelment);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  document.execCommand("copy");
  window.getSelection().removeAllRanges();

  // remove new Element from document
  document.body.removeChild(newelment);
};

const createAndDownloadFile = (filename, text) => {
  // current window reference
  const windowReference = window;
  const vcfString = text;
  const blob = new Blob([vcfString], { type: "text/x-vcard" });
  const downloadUrl = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = URL.createObjectURL(blob);
  a.download = filename;

  // file reader window open
  const reader = new FileReader();
  reader.onload = function (e) {
    windowReference.location = e.target.result;
  };

  reader.readAsDataURL(blob);
  // document.body.appendChild(a);
  // a.click();
};

const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

const getErrorMessage = (error) => {
  return error.response.data?.data?.message || error.response.data.message;
};

const getErrorType = (error) => {
  return error.response.data?.data?.type || error.response.data.type;
};

const slideUp = (target, duration = 500) => {
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = duration + "ms";
  target.style.boxSizing = "border-box";
  target.style.height = target.offsetHeight + "px";
  target.offsetHeight;
  target.style.overflow = "hidden";
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  window.setTimeout(() => {
    target.style.display = "none";
    target.style.removeProperty("height");
    target.style.removeProperty("padding-top");
    target.style.removeProperty("padding-bottom");
    target.style.removeProperty("margin-top");
    target.style.removeProperty("margin-bottom");
    target.style.removeProperty("overflow");
    target.style.removeProperty("transition-duration");
    target.style.removeProperty("transition-property");
    //alert("!");
  }, duration);
};

const slideDown = (target, duration = 500) => {
  target.style.removeProperty("display");
  let display = window.getComputedStyle(target).display;

  if (display === "none") display = "block";

  target.style.display = display;
  let height = target.offsetHeight;
  target.style.overflow = "hidden";
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  target.offsetHeight;
  target.style.boxSizing = "border-box";
  target.style.transitionProperty = "height, margin, padding";
  target.style.transitionDuration = duration + "ms";
  target.style.height = height + "px";
  target.style.removeProperty("padding-top");
  target.style.removeProperty("padding-bottom");
  target.style.removeProperty("margin-top");
  target.style.removeProperty("margin-bottom");
  window.setTimeout(() => {
    target.style.removeProperty("height");
    target.style.removeProperty("overflow");
    target.style.removeProperty("transition-duration");
    target.style.removeProperty("transition-property");
  }, duration);
};

const slideToggle = (target, duration = 500) => {
  if (window.getComputedStyle(target).display === "none") {
    return slideDown(target, duration);
  } else {
    return slideUp(target, duration);
  }
};

const isAccessForCorporate = (item = "") => {
  if (!item) {
    item = computed(() => {
      const item = store.getters["profiles/item"];

      return item;
    });
  }

  item = item?.value || item;

  const workpsace = computed(() => store.getters["account/workspace"]);
  const workpsaceType = workpsace.value?.type;
  const isMainCorporateProfile = item.acf?.is_main_corporate_profile || false;

  if (workpsaceType !== "corporate") {
    return true;
  }

  if (isMainCorporateProfile) {
    return true;
  }

  return false;
};

export {
  getProxyURL,
  getWithProxyURL,
  getLetters,
  setModalStatus,
  getModalStatus,
  getModalData,
  validateEmail,
  copyListener,
  copyClipboardElement,
  qrInit,
  qrDownload,
  getWorkspaceSlug,
  createAndDownloadFile,
  getBase64FromUrl,
  getErrorMessage,
  getErrorType,
  getI18n,
  slideUp,
  slideDown,
  slideToggle,
  isAccessForCorporate,
};
