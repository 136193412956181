<template>
  <section class="log-out-modal is-show is-default">
    <div class="log-out-modal-close">
      <i>
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 1.72656L1 13.7266"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 1.72656L13 13.7266"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </i>
    </div>
    <p>
      {{ $t("network.reminders.notify.text") }}
    </p>
    <div class="log-out-modal-buttons">
      <a
        href="javascript:;"
        class="btn btn-sm btn-white btn-round-4 btn-400"
        @click="setModalStatus('reminder_notify', false)"
      >
        {{ $t("network.reminders.notify.dont_notify") }}
      </a>
      <Button
        type="button"
        class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
        :state="state"
        @click="notify"
      >
        {{ $t("network.reminders.notify.notify") }}
      </Button>
    </div>
  </section>
</template>
<script setup>
import { ref, computed } from "vue";
import { getModalData, setModalStatus, getErrorMessage } from "@/utils/Helpers";
import Button from "@/components/inputs/Button.vue";
import store from "@/store";
import { useToast } from "bootstrap-vue-3";
import i18n from "@/utils/i18n.vue";

const state = ref("default");
const toast = useToast();
const data = getModalData("reminder_notify");
const notify = () => {
  state.value = "loading";

  store
    .dispatch("network/notifyReminder", {
      network_id: data.networkId,
      reminder_id: data.reminderId,
    })
    .then(() => {
      state.value = "success";

      setTimeout(() => {
        setModalStatus("reminder_notify", false);
      }, 1000);
    })
    .catch((err) => {
      state.value = "default";

      toast.show({
        title: i18n.global.t("misc.error_occurred"),
        body: getErrorMessage(err),
        autoHideDelay: 5000,
      });
    });
};
</script>
