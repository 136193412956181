<template>
  <div class="members-search" v-if="members">
    <input
      type="text"
      :placeholder="$t('team.members.search.placeholder')"
      class="members-search-input"
      @input="search"
    />
    <button type="button" class="members-search-button">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20.9999 21.0004L16.6499 16.6504"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import store from "@/store";

let membersSalt = [];
const members = computed(() => {
  const members = store.getters["member/items"];

  if (members.length && !membersSalt.length) {
    membersSalt = members;
    return members;
  }

  return members;
});

const search = (e) => {
  const value = e.target.value;
  const filtered = membersSalt.filter((member) => {
    const name = member.name.toLowerCase();
    const id = member.id.toString();
    const email = member.email.toLowerCase();
    const teams = member.teams.map((team) => team.name.toLowerCase());

    return (
      name.includes(value.toLowerCase()) ||
      id.includes(value.toLowerCase()) ||
      email.includes(value.toLowerCase()) ||
      teams.map((team) => team.includes(value.toLowerCase())).includes(true)
    );
  });

  store.commit("member/setItems", filtered);
};
</script>
