<template>
  <div :class="['email-signature-copy-modal is-show', modalData.colorClass]">
    <a
      href="javascript:;"
      class="email-signature-copy-modal-main-close"
      @click="setModalStatus('email_copy', false)"
    >
      <i>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 1L1 15"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 1L15 15"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </i>
    </a>
    <div class="email-signature-copy-modal-main-title">
      <h4>
        {{ $t("email_signature.copy") }}
      </h4>
    </div>
    <div class="email-signature-copy-modal-main-text">
      <p>
        {{ $t("email_signature.description") }}
        <br /><br /><br /><br />
        {{ $t("email_signature.notice") }}
      </p>
    </div>
    <div class="email-signature-copy-modal-main-buttons">
      <a
        href="javascript:;"
        class="btn btn-md btn-secondary-orange btn-round-4 btn-500"
        @click="goService"
      >
        {{ modalData.buttonLabel }}
      </a>
      <Button
        class="btn btn-md btn-primary-blue btn-round-4 btn-500"
        @click="copy"
        :state="copyState"
      >
        {{ $t("email_signature.copy") }}
      </Button>
    </div>
  </div>
</template>
<script setup>
import { defineProps, ref, computed, onMounted } from "vue";
import {
  setModalStatus,
  getModalData,
  copyClipboardElement,
  getProxyURL,
  getWithProxyURL,
} from "@/utils/Helpers";
import { MembershipLevelEnum } from "@/enums";
import store from "@/store";
import Button from "@/components/inputs/Button.vue";
import domtoimage from "dom-to-image";

const me = computed(() => store.getters["account/me"]);
const modalData = getModalData("email_copy");
const emailSignatureSrc = ref(null);
const copyState = ref("default");

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const goService = () => {
  window.open(modalData.url, "_blank");
};


const copy = () => {
  copyState.value = "loading";
  copyClipboardElement("email-copy-view");
  setTimeout(() => {
    copyState.value = "success";
  }, 400);
};

onMounted(() => {
  // generate();
});
</script>
