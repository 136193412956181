export const profile = {
  namespaced: true,
  state: {
    address: {
      address: "",
      country: "",
      state: "",
      city: "",
      neighborhood: "",
      street: "",
    },
    file_input: null,
  },
  actions: {},
  mutations: {
    set_country(state, data) {
      state.address.country = data;
    },
    set_state(state, data) {
      state.address.state = data;
    },
    set_city(state, data) {
      state.address.city = data;
    },
    set_neighborhood(state, data) {
      state.address.neighborhood = data;
    },
    set_street(state, data) {
      state.address.street = data;
    },
    set_address(state, data) {
      state.address.address = data;
    },
    set_file_input(state, data) {
      state.file_input = data;
    },
  },
  getters: {
    address: (state) => {
      return state.address;
    },
    file_input: (state) => {
      return state.file_input;
    },
  },
};
