<template>
  <section class="log-out-modal is-button-right is-show">
    <div
      class="log-out-modal-close"
      @click="setModalStatus('membership_upgrade', false)"
    >
      <i>
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 1.72656L1 13.7266"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 1.72656L13 13.7266"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </i>
    </div>
    <ul v-if="selectedMembers">
      <li v-for="member in selectedMembers" :key="member.id">
        {{ member.name }}
      </li>
    </ul>
    <p v-html="$t('team.membership.upgrade.text')"></p>
    <div class="log-out-modal-buttons is-button-right">
      <a
        href="javascript:;"
        class="btn btn-sm btn-white btn-round-4 btn-400"
        @click="setModalStatus('membership_upgrade', false)"
      >
        {{ $t("misc.cancel") }}
      </a>

      <Button
        href="javascript:;"
        class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
        :state="state"
        @click="changeMembership"
      >
        {{ $t("team.membership.upgrade.button_label") }}
      </Button>
    </div>
  </section>
</template>
<script setup>
import { ref, computed } from "vue";
import { getModalData, setModalStatus } from "@/utils/Helpers";
import Button from "@/components/inputs/Button.vue";
import store from "@/store";

const state = ref("default");
const selectedMembers = computed(() => store.getters["team/selectedMembers"]);
const data = getModalData("membership_upgrade");
const changeMembership = () => {
  state.value = "loading";

  const members = selectedMembers.value.map((m) => m.id);

  store
    .dispatch("member/changeMembership", {
      members,
      membership: data.selectedMembership,
    })
    .then(() => {
      state.value = "success";

      setTimeout(() => {
        setModalStatus("membership_upgrade", false);
        setModalStatus("membership_upgrade_success", true);
      }, 1000);
    });
};
</script>
