<template>
  <section class="log-out-modal is-show">
    <p>
      <i18n-t keypath="auth.register.corporate.confirm.text">
        <template v-slot:email>
          <b>{{ data.email }}</b>
        </template>
      </i18n-t>
    </p>
    <div class="log-out-modal-buttons">
      <span></span>
      <a
        href="javascript:;"
        class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
        @click="goLogin"
      >
        {{ $t("misc.done") }}
      </a>
    </div>
  </section>
</template>
<script setup>
import { useRouter } from "vue-router";
import { getModalStatus, getModalData, setModalStatus } from "@/utils/Helpers";

const data = getModalData("auth_register_corporate_confirm");
const router = useRouter();

const goLogin = () => {
  setModalStatus("auth_register_corporate_confirm", false);
  router.push({ name: "login" });
};
</script>
