<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>

  <MainLayout
    :title="$t('profile.view_page_title')"
    :head="false"
    :class="['main-statistics', { 'is-loading': isLoading }]"
  >
    <div class="statistics">
      <div class="statistics-picker">
        <div class="statistics-picker-head">
          <h1>
            {{ $t("stats.title") }}
          </h1>
        </div>

        <Date />
      </div>

      <ProfileHead :add-profile="false" />
      <div class="statistics-block" v-if="isLoaded">
        <div class="statistics-block-head">
          <Search
            v-if="appVersion === 3 && currentWorkspace?.role !== 'member'"
          />
          <Head />
          <Tabs />
          <Charts />
          <Location v-if="appVersion === 3 && currentWorkspace?.type === 'corporate'" />
          <Interest v-if="appVersion === 3 && currentWorkspace?.type === 'corporate'" />
        </div>

        <div class="statistics-block-body">
          <Side />
          <Devices v-if="appVersion === 3 && currentWorkspace?.type === 'corporate'" />
          <Genders v-if="appVersion === 3 && currentWorkspace?.type === 'corporate'" />
          <Age v-if="appVersion === 3 && currentWorkspace?.type === 'corporate'" />
        </div>
      </div>

      <b-skeleton v-else class="profile-action-skeleton"></b-skeleton>
    </div>
  </MainLayout>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import store from "@/store";
import MainLayout from "@/layouts/MainLayout.vue";
import Head from "@/components/Stats/Head.vue";
import Tabs from "@/components/Stats/Tabs.vue";
import Charts from "@/components/Stats/Charts.vue";
import Side from "@/components/Stats/Side.vue";
import Date from "@/components/Stats/Date.vue";
import ProfileHead from "@/components/Profile/Head";
import { useMeta } from "vue-meta";
import i18n from "@/utils/i18n.vue";
import { setModalStatus } from "@/utils/Helpers";
import Location from "@/components/Stats/Charts/Locations.vue";
import Interest from "@/components/Stats/Charts/Interest.vue";
import Devices from "@/components/Stats/Charts/Devices.vue";
import Genders from "@/components/Stats/Charts/Genders.vue";
import Age from "@/components/Stats/Charts/Age.vue";
import Views from "@/components/Stats/Charts/Views.vue";
import Search from "../../components/Stats/Search.vue";
import { useRoute, useRouter } from "vue-router";

//meta
useMeta({
  title: i18n.global.t("stats.title"),
});

const isLoaded = ref(false);
const isLoading = computed(() => store.getters["stats/isLoading"]);
const appVersion = computed(() => store.getters["common/app_version"]);
const stats = computed(() => store.getters["stats/get"]);
const dateValue = ref([]);
const currentWorkspace = computed(() => store.getters["account/workspace"]);
const route = useRoute();
const item = computed(() => {
  const item = store.getters["profiles/item"];
  return item;
});

onMounted(async () => {
  const { id } = route.params;
  setModalStatus("page_premium", false);
  await store
    .dispatch("stats/get", {
      profile_id: id,
      // start_date: "2022-11-19",
      // end_date: "2023-11-19",
    })
    .then(() => {
      store.commit("stats/setIsLoading", false);
    });

  await store.dispatch("member/items");
  await store.dispatch("team/items");

  isLoaded.value = true;
});
</script>
