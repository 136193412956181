<template>
  <div class="members-body-content is-team-view">
    <div class="members-body-content-item team-view">
      <div class="members-body-content-item-block">
        <div class="members-body-content-item-block-teams">
          <ListItem v-for="item in items" :key="item.name" :item="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import ListItem from "./ListItem.vue";
import store from "@/store";
const items = computed(() => store.getters["team/items"]);
</script>
