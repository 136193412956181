<template>
  <section class="modal-dialog is-show" v-if="options">
    <div class="modal-dialog-contain">
      <div class="modal-dialog-main">
        <div class="modal-dialog-head">
          <div class="modal-dialog-head-title">
            <h3>{{ options.terms[termsType].title }}</h3>
          </div>
          <div
            class="modal-dialog-head-close"
            @click="setModalStatus('auth_register_terms', false)"
          >
            <i>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.7216 13.6088C12.243 14.1302 13.0884 14.1302 13.6098 13.6088C14.1312 13.0873 14.1312 12.2419 13.6098 11.7205L8.88892 6.99955L13.6086 2.27985C14.13 1.75843 14.13 0.913037 13.6086 0.391614C13.0871 -0.129809 12.2418 -0.12981 11.7203 0.391613L7.00071 5.11131L2.28053 0.391068C1.75912 -0.130355 0.913736 -0.130356 0.39232 0.391067C-0.129096 0.912491 -0.129096 1.75788 0.39232 2.27931L5.1125 6.99955L0.391062 11.7211C-0.130354 12.2425 -0.130354 13.0879 0.391062 13.6093C0.912477 14.1307 1.75786 14.1307 2.27927 13.6093L7.00071 8.88779L11.7216 13.6088Z"
                  fill="white"
                />
              </svg>
            </i>
          </div>
        </div>
        <div class="modal-dialog-body" v-html="options.terms[termsType].content"></div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import store from "@/store";
import { setModalStatus, getModalData } from "@/utils/Helpers";

const data = ref(null);
const termsType = ref("membership");
const options = computed(() => store.getters["misc/options"]);

onMounted(() => {
  data.value = getModalData("auth_register_terms");
  termsType.value = data.value.type;
});
</script>