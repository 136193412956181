<template>
  <div class="email-signature-block" v-if="item">
    <div class="email-signature-row" id="email-signature-row">
      <div class="email-signature-col">
        <div class="email-signature-comment">
          <p>
            {{ $t("email_signature.page_description") }}
          </p>
        </div>
        <EmailTemplate :item="item" :visibility="visibility" />
      </div>
      <div class="email-signature-col">
        <Slide />
      </div>
    </div>
    <CopyModal v-if="getModalStatus('email_copy')" @copy="copy" :item="item" />
    <CopyView v-if="getModalStatus('email_copy_view')" :item="item" />
    <div id="email-copy-view" class="d-none">
      <a :href="item.urls.email.view">
        <img :src="generatedImageURL" />

        <p
          style="
            margin-top: 10px;
            margin-bottom: 10px;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            line-height: 16px;
            color: #545454;
            text-align: center;
          "
        >
          E-posta imzamı görüntüleyemiyor musunuz? Buraya tıklayın.
        </p>

        <p
          style="
            margin-top: 10px;
            margin-bottom: 10px;
            font-family: 'Open Sans', sans-serif;
            font-size: 12px;
            line-height: 16px;
            color: #545454;
            text-align: center;
          "
        >
          If you can't see my email signature, click here.
        </p>
      </a>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import useProfile from "@/composables/profile";
import store from "@/store";
import { getModalStatus, copyClipboardElement } from "@/utils/Helpers";

import EmailTemplate from "../EmailTemplate.vue";
import Slide from "./Slide.vue";
import CopyModal from "@/components/Email/Modals/Copy.vue";
import CopyView from "@/components/Email/CopyView.vue";
import { useMeta } from "vue-meta";
import i18n from "@/utils/i18n.vue";

//meta
useMeta({
  title: i18n.global.t("email_signature.page_title"),
});

const item = computed(() => {
  const item = store.getters["profiles/item"];

  if (item) {
    visibility.value = item.acf.preferences.email_visibility;
  }

  return item;
});

const generatedImageURL = computed(
  () => store.getters["email/generatedImageURL"]
);
const visibility = ref({});
const isShowCopyView = ref(false);

const copy = () => {
  isShowCopyView.value = !isShowCopyView.value;
};
</script>
