<template>
  <div :class="['import-your-links', { 'is-show': state === 'choses' }]">
    <div class="import-your-links-block">
      <div class="import-your-links-block-head">
        <h4>
          {{ $t("network.import.title") }}
        </h4>
        <p>
          {{ $t("network.import.text") }}
        </p>
      </div>
      <div class="import-your-links-block-body">
        <div class="import-your-links-block-body-item">
          <button type="button" @click="choseImportOption('excel')">
            <div class="import-your-links-block-body-item-image">
              <img src="/img/static/excel-icon.png" alt="" />
            </div>
            <div class="import-your-links-block-body-item-label">
              <span>
                {{ $t("network.import.option_excel") }}
              </span>
            </div>
          </button>
        </div>
      </div>
      <div class="import-your-links-block-close">
        <button type="button" @click="setModalStatus('network_import', false)">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div :class="['import-from-excel', { 'is-show': state === 'excel' }]">
    <div class="import-from-excel-block">
      <div class="import-from-excel-block-title">
        <h4>
          {{ $t("network.import.excel.title") }}
        </h4>
      </div>
      <div class="import-from-excel-block-list">
        <div class="import-from-excel-block-list-item">
          <div class="import-from-excel-block-list-item-head">
            <span>01</span>
          </div>
          <div class="import-from-excel-block-list-item-content">
            <p>
              {{ $t("network.import.excel.step_1") }}
            </p>
            <div class="download-sample-excel-file">
              <button type="button" @click="openSampleImportFile">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5"
                    stroke="#167FFC"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.8335 8.33203L10.0002 12.4987L14.1668 8.33203"
                    stroke="#167FFC"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10 12.5V2.5"
                    stroke="#167FFC"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span>
                  {{ $t("network.import.excel.download_sample_file") }}
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class="import-from-excel-block-list-item">
          <div class="import-from-excel-block-list-item-head">
            <span>02</span>
          </div>
          <div class="import-from-excel-block-list-item-content">
            <p>
              {{ $t("network.import.excel.step_2") }}
            </p>
          </div>
        </div>
        <div class="import-from-excel-block-list-item">
          <div class="import-from-excel-block-list-item-head">
            <span>03</span>
          </div>
          <div class="import-from-excel-block-list-item-content">
            <p>
              {{ $t("network.import.excel.step_3") }}
            </p>
            <div class="upload-your-file">
              <input type="file" id="file" @change="fileSelected" />
              <label for="file">
                {{ $t("network.import.choose_file") }}
              </label>
              <span>
                {{ $t("network.import.choose_file_description") }}
              </span>
            </div>

          </div>
        </div>
      </div>
      <div class="import-from-excel-block-button">
        <button
          type="button"
          class="btn btn-sm btn-white btn-round-4 btn-400"
          @click="setModalStatus('network_import', false)"
        >
          {{ $t("misc.cancel") }}
        </button>
        <Button
          type="button"
          class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
          :state="state"
          @click="importNetworks"
        >
          {{ $t("network.import.button_label") }}
        </Button>
      </div>
      <div class="import-from-excel-block-close">
        <button type="button" @click="setModalStatus('network_import', false)">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div :class="['being-imported', { 'is-show': state === 'being-imported' }]">
    <div class="being-imported-block">
      <div class="being-imported-block-image">
        <img src="/img/static/being-imported-image.png" alt="" />
      </div>
      <div class="being-imported-block-description">
        <p>
          {{ $t("network.import.being.title") }}
        </p>
      </div>
    </div>
  </div>
  <div :class="['failed-transfer', { 'is-show': state === 'fail' }]">
    <div class="failed-transfer-block">
      <div class="failed-transfer-block-image">
        <img src="/img/static/failed-transfer-image.png" alt="" />
      </div>
      <div class="failed-transfer-block-description">
        <p>
          <i18n-t keypath="network.import.fail.text">
            <template v-slot:text_1>
              {{ $t("network.import.fail.text_1") }}
            </template>
            <template v-slot:text_2>
              <a href="javascript:;" @click="openSampleImportFile">
                {{ $t("network.import.fail.text_2") }}
              </a>
            </template>
          </i18n-t>
        </p>
      </div>
      <div class="failed-transfer-block-close">
        <button type="button" @click="setModalStatus('network_import', false)">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div :class="['successfull-transfer', { 'is-show': state === 'success' }]">
    <div class="successfull-transfer-block">
      <div class="successfull-transfer-block-image">
        <img src="/img/static/successfull-transfer-image.png" alt="" />
      </div>
      <div class="successfull-transfer-block-description">
        <p>
          {{ $t("network.import.success.text") }}
        </p>
      </div>
      <div class="successfull-transfer-block-close">
        <button type="button" @click="setModalStatus('network_import', false)">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import store from "@/store";
import { setModalStatus } from "@/utils/Helpers";

const chosenOption = ref(null);
const selectedFile = ref(null);
const state = ref("choses");
const options = computed(() => {
  return store.getters["misc/options"];
});

const fileSelected = (e) => {
  selectedFile.value = e.target.files[0];
};

const choseImportOption = (option) => {
  chosenOption.value = option;

  if (option === "excel") {
    state.value = "excel";
  }
};

const importNetworks = () => {
  if (!selectedFile.value) {
    return;
  }

  const formData = new FormData();
  formData.append("file", selectedFile.value);
  state.value = "being-imported";

  store
    .dispatch("network/importNetworks", formData)
    .then(() => {
      store.dispatch("network/items").then(() => {
        state.value = "success";
      });
    })
    .catch(() => {
      state.value = "fail";
    });
};

const openSampleImportFile = () => {
  window.open(options.value.sample_import_file, "_blank");
};

onMounted(() => {});
</script>
