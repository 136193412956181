<template>
  <div class="settings-modal settings-modal-why-freeze-account is-show">
    <a
      href="javascript:;"
      class="settings-modal-close"
      @click="setModalStatus('settings_freeze_account', false)"
    >
      <i>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 1L1 13"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 1L13 13"
            stroke="#979797"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </i>
    </a>
    <a
      href="javascript:;"
      class="settings-modal-prev btn btn-text btn-sm btn-primary-blue btn-500"
      @click="setModalStatus('settings_freeze_account', false)"
    >
      <i data-position="left">
        <svg
          width="7"
          height="15"
          viewBox="0 0 7 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.83749 14.5125C5.68809 14.513 5.54048 14.4799 5.4055 14.4158C5.27052 14.3516 5.15161 14.258 5.05749 14.1417L0.227488 8.13025C0.0804062 7.95097 0 7.7261 0 7.49403C0 7.26196 0.0804062 7.03709 0.227488 6.85781L5.22749 0.846319C5.39723 0.641712 5.64114 0.513041 5.90556 0.488615C6.16999 0.464188 6.43327 0.546007 6.63749 0.71607C6.8417 0.886134 6.97013 1.13051 6.99451 1.39545C7.01889 1.66038 6.93723 1.92416 6.76749 2.12877L2.29749 7.49904L6.61749 12.8693C6.73977 13.0164 6.81745 13.1955 6.84133 13.3854C6.86521 13.5753 6.83429 13.7681 6.75223 13.9409C6.67018 14.1138 6.54042 14.2595 6.37831 14.3608C6.2162 14.4621 6.02852 14.5147 5.83749 14.5125Z"
            fill="#167FFC"
          />
        </svg>
      </i>
      {{ $t("settings.account_management.freeze_account.title") }}
    </a>
    <div class="settings-modal-content">
      <div class="settings-modal-why-freeze-account-main">
        <div class="settings-modal-why-freeze-account-img">
          <img
            src="/img/dynamic/settings-modal-freeze-account-img.png"
            alt=""
          />
        </div>
        <div class="settings-modal-why-freeze-account-head-text">
          <p>
            {{ $t("settings.account_management.freeze_account.text_1") }}
          </p>
        </div>
        <div class="settings-modal-why-freeze-account-head-comment">
          <p>
            {{ $t("settings.account_management.freeze_account.text_2") }}
          </p>
        </div>
        <form action="" method="" v-if="questions.length">
          <div
            class="form-item-checkbox"
            v-for="question in questions"
            :key="question.id"
          >
            <input
              :id="`freeze-${question.id}`"
              type="checkbox"
              :value="question.id"
              name="freeze_question"
              v-model="freezeSelectedQuestions"
            />
            <label :for="`freeze-${question.id}`">
              {{ question.name }}
            </label>
          </div>
          <div class="form-item-checkbox">
            <input
              id="freeze-other"
              type="checkbox"
              value="other"
              name="freeze_question"
              v-model="freezeSelectedQuestions"
            />
            <label for="freeze-other">
              {{ $t("settings.account_management.freeze_account.other") }}
            </label>
          </div>

          <div
            class="form-item form-item-textarea d-block"
            v-if="freezeSelectedQuestions.includes('other')"
          >
            <textarea
              :placeholder="
                $t(
                  'settings.account_management.freeze_account.other_placeholder'
                )
              "
              v-model="freezeOtherText"
            ></textarea>
          </div>
        </form>
      </div>

      <div class="settings-modal-why-freeze-account-buttons">
        <a
          href="javascript:;"
          class="btn btn-sm btn-white btn-round-4 btn-400"
          @click="setModalStatus('settings_freeze_account', false)"
        >
          {{ $t("misc.cancel") }}
        </a>
        <a
          href="javascript:;"
          class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
          @click="next"
        >
          {{ $t("misc.next") }}
        </a>
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref, onMounted, computed } from "vue";
import store from "@/store";
import { setModalStatus } from "@/utils/Helpers";
import { useField, Form } from "vee-validate";
import * as yup from "yup";
import Field from "@/components/inputs/Field";
import Button from "@/components/inputs/Button";
import i18n from "@/utils/i18n";

const questions = computed(() => store.getters["misc/freeze_questions"]);
const freezeSelectedQuestions = ref([]);
const freezeOtherText = ref("");

const next = () => {
  let questions = freezeSelectedQuestions.value;

  questions = questions.map((id) => {
    return {
      id,
    };
  });

  questions = questions.filter((question) => question.id !== "other");

  setModalStatus("settings_freeze_account", false);
  setModalStatus("settings_confirm_password", true, {
    type: "freeze_account",
    button_label: i18n.global.t(
      "settings.account_management.freeze_account.title"
    ),
    selected_questions: questions,
    other_text: freezeOtherText.value,
  });
};

onMounted(() => {
  store.dispatch("misc/freezeRequestQuestions");
});
</script>