<template>
  <div class="link-add">
    <button
      type="button"
      class="link-add-button"
      @click="isShow = !isShow"
      v-click-away="onClickAway"
    >
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="11" width="3" height="25" fill="#167FFC"></rect>
        <rect
          y="14"
          width="3"
          height="25"
          transform="rotate(-90 0 14)"
          fill="#167FFC"
        ></rect>
      </svg>
    </button>
    <div :class="['link-add-list', { 'is-show': isShow }]">
      <div class="link-add-list-item">
        <button type="button" class="link-add-single" @click="addLink">
          {{ $t("network.add_link") }}
        </button>
      </div>
      <div class="link-add-list-item">
        <button
          type="button"
          class="link-add-multiple"
          @click="addMultipleLink"
        >
          {{ $t("network.add_multiple_links") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { setModalStatus, getLetters } from "@/utils/Helpers";

const isShow = ref(false);

const addLink = () => {
  isShow.value = false;
  setModalStatus("network_action", true, null);
};

const addMultipleLink = () => {
  isShow.value = false;
  setModalStatus("network_import", true, null);
};

const onClickAway = () => {
  isShow.value = false;
};
</script>
