<template>
  <div
    class="settings-modal-experience is-show"
    v-if="currentStep === 'experience'"
  >
    <div class="settings-modal-experience-block">
      <div class="settings-modal-experience-block-head">
        <h4>
          {{ $t("membership.cancel.title") }}
        </h4>
      </div>
      <div class="settings-modal-experience-block-body">
        <div class="settings-modal-experience-block-body-item">
          <div class="settings-modal-experience-block-body-item-image">
            <img src="/img/static/settings-modal-experience-1.png" alt="" />
          </div>
          <div class="settings-modal-experience-block-body-item-label">
            <p v-html="$t('membership.cancel.items.1')"></p>
          </div>
        </div>
        <div class="settings-modal-experience-block-body-item">
          <div class="settings-modal-experience-block-body-item-image">
            <img src="/img/static/settings-modal-experience-2.png" alt="" />
          </div>
          <div class="settings-modal-experience-block-body-item-label">
            <p v-html="$t('membership.cancel.items.2')"></p>
          </div>
        </div>
        <div class="settings-modal-experience-block-body-item">
          <div class="settings-modal-experience-block-body-item-image">
            <img src="/img/static/settings-modal-experience-3.png" alt="" />
          </div>
          <div class="settings-modal-experience-block-body-item-label">
            <p v-html="$t('membership.cancel.items.3')"></p>
          </div>
        </div>
      </div>
      <div class="settings-modal-experience-block-button">
        <button
          type="button"
          class="is-give-up"
          @click="setModalStatus('settings_membership_cancel', false)"
        >
          {{ $t("misc.cancel") }}
        </button>
        <button type="button" class="is-continue" @click="continueProccess">
          {{ $t("membership.cancel.continue") }}
        </button>
      </div>
    </div>
    <div class="settings-modal-experience-close">
      <button type="button" @click="backProccess">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 18L9 12L15 6"
            stroke="#167FFC"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span>
          {{ $t("misc.back") }}
        </span>
      </button>
    </div>
  </div>

  <div
    class="settings-modal-cancellation-process is-show"
    v-if="currentStep === 'confirm_password'"
  >
    <div class="settings-modal-cancellation-process-block">
      <div class="settings-modal-cancellation-process-block-head">
        <div
          class="settings-modal-cancellation-process-block-head-image"
          v-if="me.profile_photo"
        >
          <img :src="me.profile_photo.url" :alt="me.display_name" />
        </div>
        <div class="settings-modal-cancellation-process-block-head-label">
          <h4>
            {{ me.display_name }}
          </h4>
          <p>
            {{
              $t("membership.cancel.confirm_password_content", {
                expire_date: membershipExpireDate,
              })
            }}
          </p>
        </div>
        <div class="settings-modal-cancellation-process-block-head-password">
          <div
            class="settings-modal-cancellation-process-block-head-password-label"
          >
            <p>
              {{ $t("membership.cancel.confirm_password_description") }}
            </p>
          </div>
          <div
            class="settings-modal-cancellation-process-block-head-password-form"
          >
            <div
              class="settings-modal-cancellation-process-block-head-password-form-block"
            >
              <template v-if="me.register_type === 'google'">
                <GoogleLogin :callback="googleLoginCallback"> </GoogleLogin>
              </template>

              <template v-else-if="me.register_type === 'microsoft'">
                <a
                  class="btn btn-login btn-xs btn-full btn-white btn-white-shadow btn-round-8 btn-500"
                  href="javascript:;"
                  @click="loginWithMicrosoft"
                >
                  <img src="/img/static/icon-microsoft.svg" alt="Microsoft" />
                  {{ $t("auth.common.microsoft") }}
                </a>
              </template>

              <template v-else>
                <Form
                  @submit="confirmPassword"
                  :validation-schema="schema"
                  v-slot="{ errors }"
                >
                  <div
                    :class="[
                      'form-item h-52',
                      errors.confirm_password ? 'is-error' : '',
                    ]"
                  >
                    <VField v-slot="{ field }" name="confirm_password">
                      <PasswordInput
                        :bind-value="field"
                        :placeholder="
                          $t(
                            'settings.account_management.confirm_password.placeholder'
                          )
                        "
                      />
                    </VField>
                  </div>
                  <div class="form-item-btn">
                    <Button
                      type="submit"
                      class="btn btn-md btn-primary-blue btn-round-8 btn-500"
                      :state="state"
                    >
                      {{ $t("membership.cancel.finish") }}
                    </Button>
                  </div>
                </Form>
              </template>
            </div>
          </div>
          <div
            class="settings-modal-cancellation-process-block-head-password-button d-none"
          >
            <button type="button" v-if="me.register_type === 'default'">
              {{ $t("membership.cancel.finish") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="settings-modal-cancellation-process-close">
      <button type="button" @click="backProccess">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 18L9 12L15 6"
            stroke="#167FFC"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span>
          {{ $t("misc.back") }}
        </span>
      </button>
    </div>
  </div>

  <div
    class="settings-modal-subscription is-show"
    v-if="currentStep === 'confirm'"
  >
    <div class="settings-modal-subscription-block">
      <div class="settings-modal-subscription-block-head">
        <div class="settings-modal-subscription-block-head-label">
          <h4>
            {{ $t("membership.cancel.confirmed_title") }}
          </h4>
        </div>
        <div class="settings-modal-subscription-block-head-image">
          <img
            src="/img/dynamic/settings-modal-subscription-head-image.png"
            alt=""
          />
        </div>
        <div class="settings-modal-subscription-block-head-description">
          <p>
            {{
              $t("membership.cancel.confirmed_text", {
                expire_date: membershipExpireDate,
                email: me.email,
              })
            }}
          </p>
        </div>
      </div>
      <div class="settings-modal-subscription-block-body">
        <div class="settings-modal-subscription-block-body-label">
          <h5>
            {{ $t("membership.cancel.confirmed_questions_title") }}
          </h5>
        </div>
        <div class="settings-modal-subscription-block-body-form">
          <form action="" v-if="questions.length">
            <div
              class="form-item-checkbox"
              v-for="question in questions"
              :key="question.id"
            >
              <input
                :id="`membership-cancel-${question.id}`"
                type="checkbox"
                :value="question.id"
                name="membership_cancel_question"
                v-model="membersipCancelSelectedQuestions"
              />
              <label :for="`membership-cancel-${question.id}`">
                {{ question.name }}
              </label>
            </div>

            <div class="form-item-checkbox">
              <input
                id="membership-cancel-other"
                type="checkbox"
                value="other"
                name="membership_cancel_question"
                v-model="membersipCancelSelectedQuestions"
              />
              <label for="membership-cancel-other">
                {{ $t("settings.account_management.freeze_account.other") }}
              </label>
            </div>
            <div
              class="form-item form-item-textarea d-block"
              v-if="membersipCancelSelectedQuestions.includes('other')"
            >
              <textarea
                v-model="membersipCancelOtherText"
                :placeholder="
                  $t('membership.cancel.confirmed_questions_placeholder')
                "
              ></textarea>
            </div>
          </form>
        </div>
        <div class="settings-modal-subscription-block-body-button">
          <button
            type="button"
            class="btn btn-sm btn-white btn-round-4 btn-400 d-none"
            href="javascript:;"
            @click="continueProccess"
          >
            {{ $t("misc.skip") }}
          </button>
          <Button
            type="button"
            class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
            @click="sendCancelMembership"
            :state="requestButtonState"
          >
            {{ $t("misc.submit") }}
          </Button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="settings-modal-thanks-message is-show"
    v-if="currentStep === 'success'"
  >
    <div class="settings-modal-thanks-message-block">
      <div class="settings-modal-thanks-message-block-label">
        <h5>
          {{ $t("membership.cancel.success_title") }}
        </h5>
      </div>
      <div class="settings-modal-thanks-message-block-image">
        <img src="/img/static/settings-modal-thanks-message.png" alt="" />
      </div>
      <div class="settings-modal-thanks-message-block-description">
        <p>
          {{ $t("membership.cancel.success_text") }}
        </p>
      </div>
      <div
        class="settings-modal-thanks-message-block-button"
        @click="continueProccess"
      >
        <button type="button">
          {{ $t("membership.cancel.success_button_label") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { getModalStatus, setModalStatus } from "@/utils/Helpers";
import store from "@/store";
import { Form, Field as VField } from "vee-validate";
import * as yup from "yup";
import Button from "@/components/inputs/Button";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import { decodeCredential } from "vue3-google-login";
import useAuth from "@/composables/auth";
import { useMsal } from "@/composables/msal/useMsal";
import { loginRequest } from "@/utils/msalAuth";
import { useToast } from "bootstrap-vue-3";
import { useI18n } from "vue-i18n";
import * as dayjs from "dayjs";

const { t } = useI18n();
const toast = useToast();

const steps = ["experience", "confirm_password", "confirm", "success"];
const questions = computed(() => store.getters["misc/freeze_questions"]);
const me = computed(() => store.getters["account/me"]);
const membershipExpireDate = computed(() => {
  const expireDate = dayjs.unix(me.value.membership_expire_date);

  return expireDate.format("DD/MM/YYYY");
});
const currentStep = ref("experience");
const membersipCancelSelectedQuestions = ref([]);
const membersipCancelOtherText = ref("");
const requestButtonState = ref("default");
const state = ref("default");

const continueProccess = () => {
  const index = steps.indexOf(currentStep.value);

  if (currentStep.value === "success") {
    // reload page
    window.location.reload();
  }

  currentStep.value = steps[index + 1];
};

const backProccess = () => {
  const index = steps.indexOf(currentStep.value);

  if (index === 0) {
    setModalStatus("settings_membership_cancel", false);
  }

  currentStep.value = steps[index - 1];
};

const confirmPassword = (values) => {
  state.value = "loading";

  store
    .dispatch("account/confirmPassword", values)
    .then((response) => {
      if (response.status) {
        state.value = "success";
        continueProccess();
      }
    })
    .catch((error) => {
      const message = error.response.data.data.message;
      toast.show({
        title: t("misc.error_occurred"),
        body: message,
        autoHideDelay: 5000,
      });

      state.value = "default";
    });
};

const googleLoginCallback = (response) => {
  const credential = decodeCredential(response.credential);

  confirmPassword({
    confirm_password: credential.sub,
  });
};

const loginWithMicrosoft = () => {
  instance.loginPopup(loginRequest).then((response) => {
    const account = accounts.value[0];
    confirmPassword({
      confirm_password: account.tenantId,
    });
  });
};

const sendCancelMembership = () => {
  requestButtonState.value = "loading";

  let questions = membersipCancelSelectedQuestions.value;

  questions = questions.map((id) => {
    return {
      id,
    };
  });

  questions = questions.filter((question) => question.id !== "other");

  store
    .dispatch("account/sendRequest", {
      requests: questions,
      other_text: membersipCancelOtherText.value,
      type: "membership_cancel",
    })
    .then((response) => {
      if (response.status) {
        requestButtonState.value = "success";
        continueProccess();
      }
    });
};

onMounted(() => {
  store.dispatch("misc/freezeRequestQuestions");
});
</script>
