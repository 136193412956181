<template>
  <div class="profile-creation-body is-show">
    <div class="profile-creation-body-block">
      <PhotoArea
        :item="props.item"
        :is-apply-ws-rules="false"
        @process="processPhoto"
        @success="successPhoto"
        @remove="removePhoto"
      />
    </div>
    <div class="profile-creation-body-content">
      <Form
        @submit="submit"
        :validation-schema="schema"
        v-slot="{ errors, setFieldValue, meta }"
        ref="profileForm"
      >
        <div class="profile-creation-body-content-item" v-if="item?.id">
          <div class="profile-creation-body-content-item-label">
            <span>
              {{ $t("misc.id") }}
            </span>
          </div>
          <div class="form-item h-40 disabled-select">
            <input :placeholder="item.id" type="text" required="" />
          </div>
        </div>

        <div class="profile-creation-body-content-item">
          <div class="profile-creation-body-content-item-label">
            <span>
              {{ $t("misc.first_name") }}
            </span>
          </div>
          <Field
            :errors="errors"
            name="first_name"
            :placeholder="$t('profile.placeholders.first_name')"
          />
        </div>

        <div class="profile-creation-body-content-item">
          <div class="profile-creation-body-content-item-label">
            <span>
              {{ $t("misc.last_name") }}
            </span>
          </div>
          <Field
            :errors="errors"
            name="last_name"
            :placeholder="$t('profile.placeholders.last_name')"
          />
        </div>

        <div class="profile-creation-body-content-item">
          <div class="profile-creation-body-content-item-label">
            <span>
              {{ $t("profile.company_name") }}
            </span>
          </div>
          <Field
            :errors="errors"
            name="company_name"
            :placeholder="$t('profile.placeholders.company')"
          />
        </div>

        <div class="profile-creation-body-content-item">
          <div class="profile-creation-body-content-item-label">
            <span>
              {{ $t("profile.title") }}
            </span>
          </div>
          <Field
            :errors="errors"
            name="title"
            :placeholder="$t('profile.placeholders.title')"
          />
        </div>

        <div class="profile-creation-body-content-item">
          <div class="profile-creation-body-content-item-label">
            <span>
              {{
                $t(
                  getI18n(
                    "misc.email",
                    isMainCorporateProfile && workspace.type === "corporate"
                  )
                )
              }}
            </span>
          </div>
          <Field
            :errors="errors"
            name="email"
            :placeholder="$t('profile.placeholders.email')"
          />
        </div>

        <div class="profile-creation-body-content-item telephone-area">
          <div class="profile-creation-body-content-item-label">
            <span>
              {{ $t(getI18n("misc.phone")) }}
            </span>
          </div>

          <PhoneInput
            :errors="errors"
            :add-default="addDefaultPhoneNumber"
            name="phones"
          />
        </div>

        <Address :data="props.item" :is-apply-ws-rules="false" />

        <div :class="['profile-creation-body-content-item about-us-area']">
          <div
            id="profile-creation-accordion-two"
            class="profile-creation-body-content-item-accordion"
          >
            <div class="card">
              <div
                v-b-toggle.collapseTwo
                class="card-header"
                id="headingTwo"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <button type="button">
                  <span>
                    {{ $t("profile.description") }}
                  </span>
                  <i>
                    <svg
                      width="14"
                      height="8"
                      viewBox="0 0 14 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 0.999999L7 7L13 1"
                        stroke="#7B7B7B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </i>
                </button>
              </div>

              <b-collapse
                id="collapseTwo"
                data-parent="#profile-creation-accordion-two"
              >
                <div class="card-body">
                  <DescriptionInput v-model="description" />
                </div>
              </b-collapse>
            </div>
          </div>
        </div>

        <FieldArray name="more_items" v-slot="{ fields, push, remove }">
          <MoreItem
            v-for="(field, idx) in fields"
            :key="idx"
            :idx="idx"
            :item="field"
            :setFieldValue="setFieldValue"
            :remove="remove"
            :errors="errors"
            :class="{ 'disabled-select': !field.value.is_self }"
          />
          <AddMore :add-item="push" :fields="fields" />
        </FieldArray>

        <div class="profile-creation-body-content-buttons">
          <div class="profile-creation-body-content-buttons-item">
            <button
              class="btn btn-sm btn-white btn-round-4 btn-400"
              v-if="me.profile_count"
              @click="onCancel()"
              type="button"
            >
              {{ $t("misc.cancel") }}
            </button>
          </div>
          <div
            class="profile-creation-body-content-buttons-item is-save-button"
          >
            <Button
              class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
              type="submit"
              :state="state"
            >
              {{ $t("misc.save") }}
            </Button>

            <CreatePreview
              v-if="isShowPreview"
              @close="togglePreview"
              :id="profileId"
              :show-close-button="actionType === 'update'"
            />
          </div>
        </div>
      </Form>
    </div>
    <CancelModal v-if="getModalStatus('profile_cancel')" />
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, defineProps, computed } from "vue";
import { useI18n } from "vue-i18n";
import i18n from "@/utils/i18n.vue";
import { Form, FieldArray, useForm, useIsFormTouched } from "vee-validate";
import * as yup from "yup";
import store from "@/store";
import { setModalStatus, getModalStatus, getI18n } from "@/utils/Helpers";
import { useToast } from "bootstrap-vue-3";
import { useRouter } from "vue-router";
import useProfile from "@/composables/profile";
import AddMore from "@/components/Profile/AddMore.vue";
import PhoneInput from "@/components/Profile/PhoneInput.vue";
import DescriptionInput from "@/components/Profile/DescriptionInput.vue";
import MoreItem from "@/components/Profile/MoreItem.vue";
import Field from "@/components/inputs/Field.vue";
import Address from "@/components/Profile/Address.vue";
import CreatePreview from "@/components/Profile/CreatePreview.vue";
import PhotoArea from "@/components/Profile/PhotoArea.vue";
import Modal from "@/components/Common/Modal.vue";
import Button from "@/components/inputs/Button.vue";
import CancelModal from "@/components/Profile/Modals/Cancel.vue";

const { preparePhones } = useProfile();
const { t } = useI18n();
const toast = useToast();
const router = useRouter();
const me = computed(() => store.getters["account/me"]);
const workspace = computed(() => store.getters["account/workspace"]);
const profileForm = ref(null);
const addDefaultPhoneNumber = ref(true);
const isShowPreview = ref(false);
const description = ref("");
const descriptionIsSelf = ref(true);
const profileId = ref(null);
const profilePhoto = ref(null);
const companyLogo = ref(null);
const actionType = ref("create");
const state = ref("default");
const fileInput = computed(() => store.getters["profile/file_input"]);
const profileType = ref("individual");
const isMainCorporateProfile = ref(false);
const isTouched = useIsFormTouched();
const props = defineProps({
  item: {
    type: Object,
    required: false,
  },
  isModal: {
    type: Boolean,
    required: false,
    default: false,
  },
});
let formValues = {};

const togglePreview = () => {
  isShowPreview.value = !isShowPreview.value;

  if (isShowPreview.value) {
    document.querySelector("main").classList.add("main-background");
  } else {
    document.querySelector("main").classList.remove("main-background");
  }
};
const schema = yup.object().shape({
  first_name: yup.string().required().label(i18n.global.t("misc.first_name")),
  last_name: yup.string().required().label(i18n.global.t("misc.last_name")),
  // title: yup.string().required().label(i18n.global.t("misc.title")),
  email: yup.string().email().label(i18n.global.t("misc.email")),
  more_items: yup
    .array()
    .of(
      yup.object().shape({
        url: yup.string().url().label("Url"),
        text: yup.string(),
      })
    )
    .strict(),
});

onMounted(() => {
  let more_items = [];
  if (props.item) {
    actionType.value = "update";
    addDefaultPhoneNumber.value = false;
    profileType.value = props.item.acf.profile_type;

    if (props.item.acf.social_medias) {
      more_items = props.item.acf.social_medias.map((item) => {
        return {
          text: item.link_text,
          url: item.url,
          date: item.date,
          file: item.file,
          type: item.social_media_type,
          is_self: true,
        };
      });
    }

    const phones = props.item.acf.phones;

    formValues = {
      first_name: props.item.acf.first_name,
      last_name: props.item.acf.last_name,
      title: props.item.acf.title,
      email: props.item.acf.email,
      company_name: props.item.acf.company_name,
      phones: phones
        ? preparePhones(phones)
        : [
            {
              phone_type: store.getters["defines/get_phone_type"]("mobile"),
            },
          ],
      more_items,
    };

    profileForm.value.setValues(formValues);

    description.value = props.item.acf.description;
  }
});

const processPhoto = () => {
  state.value = "disabled";
};

const successPhoto = (payload) => {
  if (payload.type === "profile-photo") {
    profilePhoto.value = payload.id;
  } else {
    companyLogo.value = payload.id;
  }

  state.value = "default";
};

const removePhoto = (type) => {
  if (type === "profile-photo") {
    profilePhoto.value = null;
  } else {
    companyLogo.value = null;
  }
};

const onCancel = () => {
  setModalStatus("network_action", false);
};

const submit = (values) => {
  state.value = "loading";

  let address = store.getters["profile/address"];
  let social_medias;

  if (values.more_items) {
    social_medias = values.more_items.map((item) => {
      return {
        social_media_type: item.type,
        url: item.url,
        date: item.date,
        file: fileInput.value,
        link_text: item.text,
        is_self: item.is_self,
      };
    });

    social_medias = social_medias.filter((item) => {
      return (
        item.is_self &&
        (item.social_media_type === "file" ||
          item.social_media_type === "date" ||
          item.url)
      );
    });
  }

  if (values.phones) {
    values.phones = values.phones.map((item) => {
      return {
        number: item.phone_number,
        type: item.phone_type.id,
      };
    });

    // remove empty phone numbers
    values.phones = values.phones.filter((item) => item.number);
  }

  const data = {
    ...values,
    address,
    social_medias,
    profile_photo: profilePhoto.value,
    company_logo: companyLogo.value,
    profile_type: profileType.value,
  };

  if (description.value) {
    data.description = description.value;
  }

  if (!address.country) {
    delete data.address;
  }

  if (!props.item) {
    store
      .dispatch("network/create", data)
      .then((res) => {
        store.dispatch("network/items").then(() => {
          state.value = "success";
          setModalStatus("network_action", false);
        });
      })
      .catch((err) => {
        toast.show({
          title: t("misc.error_occurred"),
          body: err.response.data.data.message,
          autoHideDelay: 5000,
        });
        state.value = "default";
      });
  } else {
    store
      .dispatch("network/update", { data, id: props.item.id })
      .then((res) => {
        store.dispatch("network/items").then(() => {
          state.value = "success";
          setModalStatus("network_action", false);
        });
      })
      .catch((err) => {
        toast.show({
          title: t("misc.error_occurred"),
          body: err.response.data.data.message,
          autoHideDelay: 5000,
        });
        state.value = "default";
      });
  }
};
</script>
