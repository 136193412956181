<template>
  <div class="devices-chart">
    <Loading type="skeleton" v-if="isLoading" />
    <div class="devices-chart-title">
      <h3>{{ $t("stats.devices.title") }}</h3>
    </div>
    <div class="devices-chart-block">
      <button id="devices-legend" class="devices-legend"></button>
      <Doughnut
        v-if="stats"
        :chart-options="options"
        :chart-data="chartData"
        :plugins="chartPlugins"
      />
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { Doughnut } from "vue-chartjs";
import { setModalStatus, getModalStatus } from "@/utils/Helpers";
import {
  Chart as ChartJS,
  Title,
  ArcElement,
  Tooltip,
  Legend,
  Plugin,
} from "chart.js";
import "chartjs-adapter-date-fns";
import LocationsModal from "@/components/Stats/Modals/Locations.vue";
import store from "@/store";
import Loading from "../../Common/Loading.vue";
import i18n from "@/utils/i18n.vue";

const isLoading = computed(() => store.getters["stats/isLoading"]);

ChartJS.register(ArcElement, Title, Tooltip, Legend);

const stats = computed(() => {
  const stats = store.getters["stats/statsFiltered"];

  if (stats[0]?.data?.devices) {
    const devices = stats[0].data.devices;
    const labels = Object.keys(devices);
    const values = Object.values(devices);
    chartData.value.labels = labels;
    chartData.value.datasets[0].data = values;
  }

  return stats;
});

const chartData = ref({
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: ["#167FFC", "#36A549", "#FCB20E"],
      hoverBackgroundColor: ["#167FFC", "#36A549", "#FCB20E"],
      hoverOffset: 4,
      borderWidth: 2,
      borderColor: "#fff",
      hoverBorderColor: "#fff",
    },
  ],
});

const options = {
  animation: {
    // duration: 0,
    onComplete: function () {
      const chart = this;
      const ctx = chart.ctx;

      ctx.textAlign = "left";
      ctx.textBaseline = "center";

      if (!this.data.labels.length) {
        const width = chart.width;
        const height = chart.height;

        chart.clear();
        ctx.save();
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.font = "18px normal 'Helvetica Nueue'";
        ctx.fillText(
          i18n.global.t("stats.no_data_text"),
          width / 2,
          height / 2
        );

        ctx.restore();

        return;
      }
    },
  },
  cutout: 90,

  plugins: {
    htmlLegend: {
      containerID: "devices-legend",
    },
    legend: {
      // position: 'bottom',
      // onHover: (event, chartElement) => {
      //   event.native.target.style.cursor = 'pointer'
      // },
      // onLeave: (event, chartElement) => {
      //   event.native.target.style.cursor = 'default'
      // },
      display: false,

      labels: {
        color: "#707070",
        usePointStyle: true,
        pointStyle: "circle",
        boxHeight: 8,
      },
    },
  },
};

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer.querySelector("ul");

  if (!listContainer) {
    listContainer = document.createElement("ul");
    listContainer.style.display = "flex";
    listContainer.style.flexDirection = "row";
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: "htmlLegend",
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item, i) => {
      const li = document.createElement("li");
      li.style.cursor = "pointer";
      li.style.display = "flex";
      li.style.marginLeft = "10px";
      li.style.setProperty("--circle-color", item.fillStyle);

      li.onclick = () => {
        const { type } = chart.config;
        if (type === "pie" || type === "doughnut") {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(
            item.datasetIndex,
            !chart.isDatasetVisible(item.datasetIndex)
          );
        }
        chart.update();
      };

      // Color box
      const boxSpan = document.createElement("span");
      // boxSpan.style.background = item.fillStyle
      // boxSpan.style.borderColor = item.strokeStyle
      // boxSpan.style.borderWidth = item.lineWidth + 'px'
      // boxSpan.style.borderRadius = 50 + '%'
      // boxSpan.style.display = 'inline-block'
      // boxSpan.style.height = '8px'
      // boxSpan.style.marginRight = '8px'
      // boxSpan.style.width = '8px'

      const dataValues = chart.data.datasets[0].data;
      let dataSum = null;

      for (let i = 0; i < dataValues.length; i++) {
        dataSum = dataValues[i] + dataSum;
      }

      const percent = parseFloat(
        (chart.data.datasets[0].data[i] * 100) / dataSum
      );

      const dataText = document.createTextNode(
        `%${percent.toFixed(percent === 100 ? 0 : 1)}`
      );
      boxSpan.appendChild(dataText);

      // Text
      const textContainer = document.createElement("p");
      textContainer.style.color = "#707070";
      textContainer.style.padding = 0;
      textContainer.style.textDecoration = item.hidden ? "line-through" : "";

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};

const chartPlugins = [htmlLegendPlugin];
</script>
