<template>
  <div
    :class="[
      'members-body-content-item-block-list-content-item',
      { 'is-checked': isChecked() },
    ]"
  >
    <div
      class="members-body-content-item-block-list-content-item-name"
      v-if="!isExcludeColumn('name')"
    >
      <div class="form-item-checkbox">
        <input
          v-if="currentWorkspace?.role !== 'member'"
          type="checkbox"
          @change="onChange"
          :checked="isChecked()"
          :id="`member-${props.item.id}`"
        />
        <label :for="`member-${props.item.id}`">
          {{ props.item.name }}
        </label>
      </div>
    </div>
    <div
      :class="[
        'members-body-content-item-block-list-content-item-subscription',
        {
          'is-orange': props.item.membership === 'networkit',
          'waiting-approval': props.item.is_waiting_upgrade,
        },
      ]"
    >
      <span>
        {{
          props.item.is_waiting_upgrade
            ? $t("team.membership.waiting")
            : props.item.membership === "networkit"
            ? $t("team.membership.corporate")
            : $t("team.membership.free")
        }}
      </span>
    </div>
    <div
      :class="[
        'members-body-content-item-block-list-content-item-team',
        { 'is-toggled': isShowAllTeams },
      ]"
      v-if="!isExcludeColumn('teams')"
    >
      <template v-if="props.item.teams.length">
        <div
          class="members-body-content-item-block-list-content-item-team-item is-soft-green"
          v-for="team in props.item.teams"
          :key="team.id"
        >
          {{ team.name }}
        </div>

        <div
          class="members-body-content-item-block-list-content-item-team-toggle"
          @click="toggleShowAllTeams"
          v-if="props.item.teams.length > 2"
        >
          <button type="button">
            <svg
              width="18"
              height="8"
              viewBox="0 0 18 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.5 0.999999L9 7L16.5 1"
                stroke="#979797"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </template>
      <template v-else>
        <div
          class="members-body-content-item-block-list-content-item-team-item is-soft-purple"
        >
          {{ $t("team.members.no_team") }}
        </div>
      </template>
    </div>
    <div
      class="members-body-content-item-block-list-content-item-id"
      v-if="!isExcludeColumn('id')"
    >
      <span>
        {{ props.item.id }}
      </span>
    </div>
    <div class="members-body-content-item-block-list-content-item-role">
      <RoleArea v-if="!isExcludeColumn('role')" :role="props.item.role" />
      <button
        type="button"
        :class="['is-profile', { 'is-loading': isLoading }]"
        @click="viewProfile"
        v-if="currentWorkspace?.role !== 'member'"
      >
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 18.5L15 12.5L9 6.5"
            stroke="#B1B1B1"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <div class="spinner-container xs-spinner">
          <div class="loading-spinner"></div>
        </div>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, computed, onMounted } from "vue";
import store from "@/store";
import RoleArea from "../Common/RoleArea.vue";
import { setModalStatus } from "@/utils/Helpers";

const isLoading = ref(false);
const currentWorkspace = computed(() => store.getters["account/workspace"]);
const isShowAllTeams = ref(false);
const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  excludeColumns: {
    type: Array,
    required: false,
    default: () => [],
  },
});

const isExcludeColumn = (column) => {
  return props.excludeColumns.includes(column);
};

const onChange = (e) => {
  const value = e.target.value;
  const isCheck = e.target.checked;

  if (isCheck) {
    store.dispatch("team/addMember", props.item);
  } else {
    store.dispatch("team/removeMember", props.item);
  }
};

const isChecked = () => {
  return store.getters["team/isSelectedMember"](props.item);
};

const toggleShowAllTeams = () => {
  isShowAllTeams.value = !isShowAllTeams.value;
};

const isLoadedProfile = () => {
  isLoading.value = false;
};

const viewProfile = () => {
  isLoading.value = true;
  setModalStatus("member_view", true, {
    id: props.item.profile_id,
    loaded: isLoadedProfile,
  });
};

onMounted(() => {
  if (props.item.is_empty) {
    props.item.role = {
      key: "default",
      label: "Default",
    };
  }
});
</script>
