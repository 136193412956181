import i18n from "@/utils/i18n";
export const common = {
  namespaced: true,
  state: {
    app_version: "2",
    sidebar_status: false,
    auth_error_message: "",
    modals: {
      header_notifications: {
        status: false,
      },
      logout: {
        status: false,
      },
      edit_account: {
        status: false,
      },
      public_profile_download: {
        status: false,
      },
      public_profile_share: {
        status: false,
      },
      public_profile_register: {
        status: false,
      },
      page_premium: {
        status: false,
      },
      profile_photo_upload: {
        status: false,
      },
      profile_premium: {
        status: false,
      },
      profile_cancel: {
        status: false,
      },
      profile_phone_premium: {
        status: false,
      },
      profile_visibility: {
        status: false,
      },
      profile_trash: {
        status: false,
      },
      email_copy: {
        status: false,
      },
      email_copy_view: {
        status: false,
      },
      email_visibility: {
        status: false,
      },
      email_add_pattern: {
        status: false,
      },
      email_service: {
        status: false,
      },
      bg_download: {
        status: false,
      },
      bg_upload: {
        status: false,
      },
      bg_done: {
        status: false,
      },
      bg_new: {
        status: false,
      },
      settings_change_email: {
        status: false,
      },
      settings_change_password: {
        status: false,
      },
      settings_freeze_account: {
        status: false,
      },
      settings_confirm_password: {
        status: false,
      },
      settings_delete_account: {
        status: false,
      },
      settings_change_password_success: {
        status: false,
      },
      settings_freeze_account_success: {
        status: false,
      },
      settings_delete_account_success: {
        status: false,
      },
      settings_package_chose: {
        status: false,
      },
      settings_payment_status: {
        status: false,
      },
      settings_match_card: {
        status: false,
      },
      settings_match_confirm: {
        status: false,
      },
      settings_membership_cancel: {
        status: false,
      },
      settings_package_pairing: {
        status: false,
      },
      settings_billing_information: {
        status: false,
      },
      auth_register_terms: {
        status: false,
      },
      auth_register_corporate_confirm: {
        status: false,
      },
      member_invite: {
        status: false,
      },
      delete_team: {
        status: false,
      },
      remove_member: {
        status: false,
      },
      reset_member: {
        status: false,
      },
      membership_upgrade: {
        status: false,
      },
      membership_cancel: {
        status: false,
      },
      membership_upgrade_success: {
        status: false,
      },
      membership_cancel_success: {
        status: false,
      },
      team_add_member: {
        status: false,
      },
      member_view: {
        status: false,
      },
      member_edit: {
        status: false,
      },
      group_action: {
        status: false,
      },
      group_delete: {
        status: false,
      },
      group_networks: {
        status: false,
      },
      remove_from_group: {
        status: false,
      },
      network_remove: {
        status: false,
      },
      network_export: {
        status: false,
      },
      network_import: {
        status: false,
      },
      network_action: {
        status: false,
      },
      network_view: {
        status: false,
      },
      network_stay_touch: {
        status: false,
      },
      stats_locations: {
        status: false,
      },
      stats_interests: {
        status: false,
      },
      note_delete: {
        status: false,
      },
      note_archive: {
        status: false,
      },
      note_unarchive: {
        status: false,
      },
      reminder_delete: {
        status: false,
      },
      reminder_unarchive: {
        status: false,
      },
      reminder_notify: {
        status: false,
      },
    },
    modes: {
      individual: "individual",
      corporate: "corporate",
    },
  },
  actions: {
    apply_mode({ commit }, value = "") {
      commit("apply_mode");
    },
  },
  mutations: {
    set_modal_status(state, { name, value, data }) {
      state.modals[name] = {
        status: value,
        data: data,
      };
    },
    set_sidebar_status(state, value) {
      state.sidebar_status = value;
    },
    close_all_modals(state) {
      for (const key in state.modals) {
        state.modals[key].status = false;
      }
    },
    set_auth_error_message(state, value) {
      state.auth_error_message = value;
    },
    apply_mode(state, { type = "", role = "" }) {
      if (type === "") {
        type = localStorage.getItem("mode") || state.modes.individual;
      }

      if (type === state.modes.individual) {
        document.body.classList.remove("is-corporate");
        document.body.classList.add("is-individual");
      } else {
        document.body.classList.remove("is-individual");
        document.body.classList.add("is-corporate");
      }

      if (role === "member") {
        document.body.classList.add("is-member");
      } else {
        document.body.classList.add("is-admin");
      }

      if (process.env.VUE_APP_VERSION == 3) {
        document.body.classList.add("v3-module");
      }

      if (i18n.global?.locale) {
        document.body.classList.add("language-" + i18n.global.locale);
      }

      localStorage.setItem("mode", type);
    },
  },
  getters: {
    app_version: (state) => {
      return parseInt(process.env.VUE_APP_VERSION || state.app_version);
    },
    modal_status: (state) => (modal) => {
      return state.modals[modal].status;
    },
    modal_data: (state) => (modal) => {
      return state.modals[modal].data;
    },
    active_modals: (state) => {
      let activeModals = [];

      for (let modal in state.modals) {
        if (state.modals[modal].status) {
          activeModals.push(modal);
        }
      }

      return activeModals;
    },
    sidebar_status: (state) => {
      return state.sidebar_status;
    },
    auth_error_message: (state) => {
      return state.auth_error_message;
    },
    mode: (state) => {
      return localStorage.getItem("mode") || state.modes.individual;
    },
    modes: (state) => {
      return state.modes;
    },
  },
};
