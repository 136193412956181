<template>
  <div class="add-image-modal-container is-show">
    <div class="add-image-modal-container-inner">
      <div class="image-crop-container">
        <img ref="imagePreview" :src="selectedImageSrc" alt="" />
      </div>

      <div class="format-container" v-if="modalData.type !== 'banner'">
        <cropper-zoom
          v-if="cropper"
          :cropper="cropper"
          :image-container="imagePreview"
        />

        <p>{{ $t("email_signature.format") }}</p>
        <div class="email-signature-mail-template-type-btn">
          <button
            type="button"
            :class="[
              'mail-template-row',
              { 'is-active': selectedLayoutMode == 'horizontal' },
            ]"
            @click="changeLayoutMode('horizontal')"
          >
            <i>
              <svg
                width="23"
                height="13"
                fill="none"
                viewBox="0 0 23 13"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="22"
                  height="12"
                  rx="2.5"
                  stroke="#6C489E"
                ></rect>
              </svg>
            </i>
          </button>
          <button
            type="button"
            :class="[
              'mail-template-column',
              { 'is-active': selectedLayoutMode == 'vertical' },
            ]"
            @click="changeLayoutMode('vertical')"
          >
            <i>
              <svg
                width="14"
                height="19"
                fill="none"
                viewBox="0 0 14 19"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="13.5"
                  y="0.5"
                  width="18"
                  height="13"
                  rx="2.5"
                  transform="rotate(90 13.5 0.5)"
                ></rect>
              </svg>
            </i>
          </button>
        </div>
      </div>
      <div class="format-container" v-else>
        <cropper-zoom
          v-if="cropper"
          :cropper="cropper"
          :image-container="imagePreview"
        />
      </div>
      <div class="image-crop-disclaimer">
        <p>
          {{
            $t("email_signature.add_image_description", {
              size:
                modalData.type === "banner"
                  ? "650x150 px"
                  : selectedLayoutMode === "horizontal"
                  ? "650x300 px"
                  : "200x300 px",
            })
          }}
        </p>
      </div>
      <div class="image-crop-actions">
        <a
          href="javascript:;"
          class="close-btn"
          @click="setModalStatus('email_add_pattern', false)"
        >
          {{ $t("misc.cancel") }}
        </a>
        <Button
          :state="uploadState"
          class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
          @click="upload"
        >
          {{ $t("misc.upload") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.add-image-modal-container .image-crop-container {
  padding: 0 !important;
}
</style>

<script setup>
import { ref, computed, onMounted } from "vue";
import store from "@/store";
import Button from "@/components/inputs/Button.vue";
import { setModalStatus, getModalData } from "@/utils/Helpers";
import Cropper from "cropperjs";
import cropperZoom from "@/components/Common/CropperZoom.vue";

const uploadState = ref("default");
const imagePreview = ref(null);
const modalData = getModalData("email_add_pattern");
const selectedImage = modalData.image;
const selectedImageType = modalData.type;
const selectedImageSrc = URL.createObjectURL(selectedImage);
const cropper = ref(null);
const selectedLayoutMode = computed(() => store.getters["email/layoutMode"]);
const item = computed(() => {
  const item = store.getters["profiles/item"];

  return item;
});

const changeLayoutMode = (mode) => {
  store.commit("email/setLayoutMode", mode);

  cropper.value.setCropBoxData({
    width: mode === "horizontal" ? 650 : 200,
    height: mode === "horizontal" ? 300 : 300,
  });
};

const upload = () => {
  const canvas = cropper.value.getCroppedCanvas();
  const image = canvas.toDataURL("image/png");
  const formData = new FormData();

  canvas.toBlob(function (blob) {
    formData.append("file", blob, "image.png");
    uploadState.value = "loading";
    store
      .dispatch("profiles/addEmailImage", {
        file: formData.get("file"),
        id: item.value.id,
        layout_mode: selectedLayoutMode.value,
        type: selectedImageType,
      })
      .then(() => {
        uploadState.value = "success";
        store.dispatch("profiles/get", item.value.id);
        setModalStatus("email_add_pattern", false);
      })
      .catch((err) => {
        state.value = "default";
      });
  });
};

onMounted(() => {
  imagePreview.value.addEventListener("ready", () => {
    changeLayoutMode("horizontal");
  });

  cropper.value = new Cropper(imagePreview.value, {
    // aspectRatio: 16 / 9,
    viewMode: 1,
    zoomable: true,
    scalable: false,
    rotatable: false,
    cropBoxResizable: true,
    cropBoxMovable: true,
    dragMode: "move",
    ready() {},
  });
});
</script>
