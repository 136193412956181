<template>
  <div class="members-body-content-item-block-head-settings-item">
    <button
      type="button"
      class="team-assignment-button"
      @click="toggleTeamArea"
      :content="$t('team.team_assign.label')"
      v-tippy
      v-click-away="onClickAway"
    >
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_14215_90155)">
          <path
            d="M16.5 21.5V19.5C16.5 18.4391 16.0786 17.4217 15.3284 16.6716C14.5783 15.9214 13.5609 15.5 12.5 15.5H5.5C4.43913 15.5 3.42172 15.9214 2.67157 16.6716C1.92143 17.4217 1.5 18.4391 1.5 19.5V21.5"
            stroke="#E3E3E3"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.5 8.5V14.5"
            stroke="#E3E3E3"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M23.5 11.5H17.5"
            stroke="#E3E3E3"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9 11.5C11.2091 11.5 13 9.70914 13 7.5C13 5.29086 11.2091 3.5 9 3.5C6.79086 3.5 5 5.29086 5 7.5C5 9.70914 6.79086 11.5 9 11.5Z"
            stroke="#E3E3E3"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_14215_90155">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0.5 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </button>
    <div
      :class="[
        'members-body-content-item-block-head-settings-item-menu team-assignment',
        { 'is-show': isShow },
      ]"
    >
      <div
        class="members-body-content-item-block-head-settings-item-menu-block"
      >
        <div
          class="members-body-content-item-block-head-settings-item-menu-block-filter"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
              stroke="#6C489E"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.5 17.5L13.875 13.875"
              stroke="#6C489E"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <input
            type="text"
            :placeholder="$t('team.team_assign.search_placeholder')"
            @input="search"
          />
        </div>
        <div
          class="members-body-content-item-block-head-settings-item-menu-block-list"
          v-if="teams.length"
        >
          <div
            class="members-body-content-item-block-head-settings-item-menu-block-list-item"
            v-for="team in teams"
            :key="team.id"
          >
            <div class="form-item-checkbox">
              <input
                :id="`team-${team.id}`"
                type="checkbox"
                @change="toggleTeam(team)"
              />
              <label :for="`team-${team.id}`">
                {{ team.name }}
              </label>
            </div>
          </div>
        </div>
        <div
          class="members-body-content-item-block-head-settings-item-menu-block-button"
        >
          <Button
            type="button"
            class="btn btn-sm btn-primary-blue btn-round-4 btn-400"
            @click="save"
            :state="state"
          >
            {{ $t("team.confirm") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, defineProps, watch } from "vue";
import store from "@/store";
import Button from "@/components/inputs/Button.vue";

const props = defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
});

let teamsSalt = [];

const teams = computed(() => {
  const teams = store.getters["team/items"];

  if (teams.length && !teamsSalt.length) {
    teamsSalt = teams;
    return teams;
  }

  return teams;
});

const selectedMembers = computed(() => store.getters["team/selectedMembers"]);
const selectedTeams = ref([]);
const isShow = ref(false);
const state = ref("default");

const toggleTeamArea = () => {
  isShow.value = !isShow.value;
};

const toggleTeam = (team) => {
  if (selectedTeams.value.includes(team)) {
    selectedTeams.value = selectedTeams.value.filter((t) => t.id !== team.id);
  } else {
    selectedTeams.value.push(team);
  }
};

const save = () => {
  const teams = selectedTeams.value.map((t) => t.id);
  const members = selectedMembers.value.map((m) => m.id);

  if (!teams.length) {
    return;
  }

  state.value = "loading";
  teams.map(async (team, index) => {
    const isLast = index === teams.length - 1;
    await store.dispatch("team/addMembers", {
      id: team,
      members,
    });

    if (isLast) {
      state.value = "default";
      isShow.value = false;
      store.dispatch("member/items");
    }
  });
};

const search = (e) => {
  const value = e.target.value;

  const filtered = teamsSalt.filter((team) => {
    return team.name.toLowerCase().includes(value.toLowerCase());
  });

  store.commit("team/setItems", filtered);
};

const onClickAway = (e) => {
  if (!e.target.closest(".team-assignment")) {
    isShow.value = false;
  }
};

onMounted(() => {
  store.dispatch("team/items");
});

watch(
  () => props.isActive,
  (value) => {
    if (!value) {
      isShow.value = false;
    }
  }
);
</script>
