<template>
  <div class="settings-modal-thanks-message is-show" v-if="status">
    <div class="settings-modal-thanks-message-block">
      <div class="settings-modal-thanks-message-block-label">
        <h5>
          {{ $t("membership.success.title") }}
        </h5>
      </div>
      <div class="settings-modal-thanks-message-block-image">
        <img src="/img/static/settings-modal-thanks-message.png" alt="" />
      </div>
      <div class="settings-modal-thanks-message-block-description">
        <p>
          {{ $t("membership.success.text") }}
        </p>
      </div>
      <div class="settings-modal-thanks-message-block-button">
        <button type="button" @click="okButton">
          {{ $t("membership.success.button_label") }}
        </button>
      </div>
    </div>
  </div>
  <div class="settings-modal-thanks-message is-show" v-else>
    <div class="settings-modal-thanks-message-block">
      <div class="settings-modal-thanks-message-block-label">
        <h5>
          {{ $t("membership.error.title") }}
        </h5>
      </div>
      <div class="settings-modal-thanks-message-block-image">
        <img src="/img/static/settings-modal-thanks-message.png" alt="" />
      </div>
      <div class="settings-modal-thanks-message-block-description">
        <p>
          {{ $t("membership.error.text") }}
        </p>
        <p v-if="data.message">
          {{ data.message }}
        </p>
      </div>
      <div class="settings-modal-thanks-message-block-button">
        <button type="button" @click="okButton">
          {{ $t("membership.error.button_label") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { getModalStatus, setModalStatus, getModalData } from "@/utils/Helpers";
import store from "@/store";

const status = ref(false);
const data = getModalData("settings_payment_status");

const okButton = () => {
  localStorage.removeItem("show_payment_status_modal");

  // get full url
  const url = window.location.href;

  // get url without query string
  const urlWithoutQueryString = url.split("?")[0];

  // redirect to url without query string
  window.location.href = urlWithoutQueryString;
};

onMounted(() => {
  // get page params
  const params = new URLSearchParams(window.location.search);
  const statusParam = params.get("status");

  // check status
  if (statusParam === "success") {
    status.value = true;
  } else {
    status.value = false;
  }

  localStorage.removeItem("show_payment_status_modal");
});
</script>
