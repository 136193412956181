<template>
  <div class="add-group">
    <div class="add-group-block">
      <div class="add-group-block-label">
        <h4>Grup Ekle</h4>
      </div>
      <div class="add-group-block-input">
        <div class="form-item h-52">
          <input placeholder="Grubuna isim ver" type="text" />
          <button type="button">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_14382_40530)">
                <path
                  d="M7.99992 14.6654C11.6818 14.6654 14.6666 11.6806 14.6666 7.9987C14.6666 4.3168 11.6818 1.33203 7.99992 1.33203C4.31802 1.33203 1.33325 4.3168 1.33325 7.9987C1.33325 11.6806 4.31802 14.6654 7.99992 14.6654Z"
                  stroke="#979797"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 11.332H8.00667"
                  stroke="#979797"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8 4.33203C8 5.66536 8 8.9987 8 8.9987"
                  stroke="#979797"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_14382_40530">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </div>
      <div class="add-group-block-button">
        <button
          type="button"
          class="btn btn-sm btn-secondary-yellow btn-round-4 btn-400"
        >
          Oluştur
        </button>
      </div>
      <div class="add-group-block-close">
        <button type="button">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
