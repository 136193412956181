import { createStore } from "vuex";

import { auth } from "./auth";
import { onboarding } from "./onboarding";
import { profiles } from "./profiles";
import { email } from "./email";
import { background } from "./background";
import { account } from "./account";
import { location } from "./location";
import { profile } from "./profile";
import { stats } from "./stats";
import { misc } from "./misc";
import { product } from "./product";
import { defines } from "./defines";
import { payment } from "./payment";
import { common } from "./common";
import { notifications } from "./notification";
import { team } from "./team";
import { member } from "./member";
import { network } from "./network";
import { exportStore } from "./export";
import { workspace } from "./workspace";
import { invitation } from "./invitation";

export default createStore({
  modules: {
    auth,
    onboarding,
    profiles,
    background,
    email,
    account,
    location,
    profile,
    stats,
    misc,
    defines,
    common,
    notifications,
    product,
    payment,
    team,
    member,
    network,
    exportStore,
    workspace,
    invitation,
  },
});
