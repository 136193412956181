<template>
  <div class="wrapper-content-free-account">
    <div class="wrapper-content-free-account-block">
      <div class="wrapper-content-free-account-block-description">
        <p>
          {{ $t(popupTextI18nKey) }}
        </p>
      </div>
      <div class="wrapper-content-free-account-block-button">
        <router-link :to="{ name: 'settings' }">
          {{ $t("misc.premium_button_label") }}
          <svg
            width="7"
            height="15"
            viewBox="0 0 7 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1L6 7.5L1 13.5"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </router-link>
      </div>
      <div class="wrapper-content-free-account-block-close" @click="closePopup">
        <button type="button">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="#979797"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { getModalStatus, getModalData } from "@/utils/Helpers";
import { onMounted, ref } from "vue";

const router = useRouter();
const popupTextI18nKey = ref("");

const closePopup = () => {
  router.push({ name: "profile-main" });
};

onMounted(() => {
  const modalData = getModalData("page_premium");
  switch (modalData.toName) {
    case "email-view":
    case "email-main":
      popupTextI18nKey.value = "email_signature.premium_text";
      break;
    case "bg-view":
    case "bg-main":
      popupTextI18nKey.value = "bg.premium_text";
      break;
    case "stats-view":
    case "stats-main":
      popupTextI18nKey.value = "stats.premium_text";
      break;
  }
});
</script>
