import { axios } from "@/services/axios";

export const login = (payload) => {
  return axios
    .post("auth/login ", payload)
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const register = (payload) => {
  return axios
    .post("auth/register", payload)
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const resetPasswordEmail = (payload) => {
  return axios
    .post("auth/send_reset_password_mail", payload)
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const resetPassword = (payload) => {
  return axios
    .post("auth/reset_password", payload)
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const resetPasswordTokenCheck = (payload) => {
  return axios
    .post("auth/password_token_check", payload)
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export default {
  login,
  register,
  resetPasswordEmail,
  resetPassword,
  resetPasswordTokenCheck,
};
